import { Directive, HostListener, EventEmitter, Output, Inject, Input } from '@angular/core';
import { FileService } from '../../../services/file.service';

@Directive({
    selector: '[downloadFile]',
})
export class DownloadFileDirective {
    constructor(@Inject(FileService) private fileService: FileService) {}

    @Input() fileUuid = '';

    @HostListener('click', ['$event']) public onClick() {
        if (this.fileUuid) {
            this.fileService
                .downloadFile(this.fileUuid)
                .then(res => this.fileService.downloadFileToOwnMachine(res))
                .catch(err => {
                    console.error(err);
                });
        }
    }
}
