import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateWithLanguagePipe } from './pipes/translate-with-language.pipe';
import { TranslatePipe } from './pipes/translate.pipe';
import { TranslateWithTokenPipe } from './pipes/translate-with-token.pipe';
import { XmlService } from './xml-service';
import { LanguageStoreService } from '../../services/language-store.service';
import { FilterWithTranslationPipe } from './pipes/filter-with-translation.pipe';
import { TranslateService } from './translate.service';
import { TranslateArrPipe } from './pipes/translate-arr.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [
        TranslatePipe,
        TranslateArrPipe,
        TranslateWithTokenPipe,
        FilterWithTranslationPipe,
        TranslateWithLanguagePipe,
    ],
    exports: [
        TranslatePipe,
        TranslateArrPipe,
        TranslateWithTokenPipe,
        FilterWithTranslationPipe,
        TranslateWithLanguagePipe,
    ],
})
export class TranslationModule {
    public static forRoot(): ModuleWithProviders<TranslationModule> {
        return {
            ngModule: TranslationModule,
            providers: [LanguageStoreService, XmlService, TranslateService],
        };
    }
}
