import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseRecordComponent } from '../../base-record.component';
import { FondaApiService } from '../../../../api/fonda-api.service';
import { AssigneeUserData } from './assignee-user-data';
import { CaseworkerDto } from '../../../../api/dto/caseworker-dto';
import { CaseworkerApplicationDto } from '../../../../api/dto/caseworker-application-dto';
import { GlobalVariables } from '../../../../services/global-variables';
import { LocalStorageService } from '../../../../services/local-storage-service';
import { TranslateService } from '../../../translation/translate.service';

@Component({
    selector: 'assignee-user-record',
    templateUrl: 'assignee-user-record.component.html',
})
export class AssigneeUserRecordComponent extends BaseRecordComponent implements OnInit {
    @Input('uuid') public uuid: string;
    @Input('value') public value: object;
    @Input('isRequired') public isRequired: boolean;
    @Input('size') public size: number;
    @Input('readonly') public readonly = false;
    @Input('onSearch') public onSearch = false;
    @Output('onChange') onChange = new EventEmitter<object>();
    public selectedAssignee: AssigneeUserData;
    public selectedUuid: string;
    public title = '';
    public caseworkerNames: Array<string> = [];
    public caseworkers: Array<AssigneeUserData> = [];
    configuration: object;
    private initValue: string;

    constructor(
        private apiService: FondaApiService,
        private applicationStorageService: LocalStorageService,
        private translateService: TranslateService
    ) {
        super();
    }

    public emitValue() {
        this.onChange.emit(this.getValue());
    }

    public reset() {
        this.chooseCaseworker(null);
    }

    public async ngOnInit() {
        await this.apiService.getBackendUsersCaseworker().then(caseworkers => {
            this.fetchData(caseworkers);
        });
        this.value = this.value ? this.value : {};
        this.initValue = this.value['uuid'] ? this.value['uuid'] : '';
        this.selectedAssignee = this.caseworkers.find(caseworker => caseworker.uuid === this.initValue);
        if (this.selectedAssignee) {
            this.selectedUuid = this.selectedAssignee.uuid;
        } else {
            this.selectedUuid = this.initValue;
        }

        this.updateData();
        this.emitValue();
    }

    public chooseCaseworker(caseworkerUsername: string) {
        this.selectedAssignee = this.caseworkers.find(caseworker => caseworker.username === caseworkerUsername);
        if (this.selectedAssignee) {
            this.selectedUuid = this.selectedAssignee.uuid;
        } else {
            this.selectedUuid = '';
        }
        this.emitValue();
    }

    public isValid(): boolean {
        return !(this.isRequired && !this.value['uuid']);
    }

    public isChanged(): boolean {
        return this.initValue != this.selectedUuid;
    }

    public isEmpty(): boolean {
        return !this.value;
    }

    public getValue(): object {
        return { uuid: this.selectedUuid };
    }

    private fetchData(caseworkers: Array<CaseworkerDto>) {
        if (this.onSearch) {
            this.caseworkers.push(
                new AssigneeUserData(
                    GlobalVariables.NO_ONE_VARIABLE,
                    true,
                    new Date(),
                    '',
                    '',
                    [],
                    GlobalVariables.NO_ONE_VARIABLE,
                    '',
                    true
                )
            );
        }
        caseworkers.forEach(caseworker => {
            this.caseworkers.push(
                new AssigneeUserData(
                    caseworker.uuid,
                    caseworker.confirmed,
                    caseworker.createdDate,
                    caseworker.email,
                    caseworker.type,
                    caseworker.roles,
                    caseworker.username,
                    caseworker.initials,
                    caseworker.active
                )
            );
        });
        if (this.applicationStorageService.isApplicationInformation()) {
            const applicationDto = this.applicationStorageService.getApplicationInformation();
            this.caseworkers.push(
                new AssigneeUserData(
                    applicationDto.applicantUuid,
                    true,
                    applicationDto.createdAt,
                    '',
                    'applicant',
                    [],
                    this.getApplicantFullName(applicationDto) +
                        ' ' +
                        this.translateService.get(['general', 'applicant']),
                    '',
                    true
                )
            );
        }
        this.caseworkerNames = this.caseworkers.map(caseworker => caseworker.username);
    }

    private getApplicantFullName(caseworkerApplicationDto: CaseworkerApplicationDto): string {
        let fullName = '';
        caseworkerApplicationDto.applicantSections.forEach(section => {
            if (section.recordValues.find(value => value.type === 'FirstnameRecord')) {
                fullName =
                    section.recordValues.find(value => value.type === 'FirstnameRecord').value['text'] +
                    ' ' +
                    section.recordValues.find(value => value.type === 'LastnameRecord').value['text'];
            }
        });
        return fullName;
    }

    /**
     * After fetching all the caseworkers we need to remove inactive ones but the one that is
     * selected
     *
     * @private
     */
    private updateData(): void {
        this.caseworkers = this.caseworkers.filter(c => this.onSearch || c.active || c.uuid === this.selectedUuid);
        this.caseworkerNames = this.caseworkers.map(c => c.username);
    }
}
