<div class="fonda-datepicker-icon-container" (click)="active = !active">
    <div class="fonda-datepicker-icon"></div>
</div>

<div class="fonda-datepicker-container" [ngClass]="{ active: active }">
    <div class="option-choose" *ngIf="!onlySpecifiedDate">
        <span [ngClass]="{ choosen: isOnSpecifiedView() }" (click)="clickChangeToSpecified()">Specific date</span>
        <span [ngClass]="{ choosen: isOnFromTo() }" (click)="clickChangeToFromTo()">From - to date</span>
    </div>

    <div class="datepicker-container">
        <my-date-range-picker
            name="mydaterange"
            [options]="myDateRangePickerOptions"
            [ngModel]="model"
            *ngIf="isOnFromTo()"
            (ngModelChange)="setDates($event)"
        >
        </my-date-range-picker>

        <my-date-picker
            name="mydaterange"
            [options]="myDatePickerOptions"
            [ngModel]="model2"
            *ngIf="isOnSpecifiedView()"
            (ngModelChange)="setSingleDate($event)"
        >
        </my-date-picker>
    </div>
</div>
<div
    class="select-box-label red"
    *ngIf="(model && model.beginDate && model.endDate) || (model2 && model2.date)"
    style="position: absolute; right: -1px"
    (click)="reset()"
>
    {{ 'general' | translate: 'delete' }}
</div>
