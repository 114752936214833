import { IRecord } from './application-schema.interface';

export enum SectionTypes {
    APPLICANT = 'applicant_application',
    CASEWORKER = 'caseworker_application',
    POST_GRANT = 'post_grant_application',
}

export class TranslationSectionTypes {
    static readonly APPLICANT_SECTION = 'applicant_section';
    static readonly CASEWORKER_SECTION = 'caseworker_section';
    static readonly POST_GRANT_SECTION = 'post_grant_section';

    static fromSectionType(sectionType: SectionTypes): string {
        switch (sectionType) {
            case SectionTypes.APPLICANT:
                return TranslationSectionTypes.APPLICANT_SECTION;
            case SectionTypes.CASEWORKER:
                return TranslationSectionTypes.CASEWORKER_SECTION;
            case SectionTypes.POST_GRANT:
                return TranslationSectionTypes.POST_GRANT_SECTION;
            default:
                throw new Error(`Unknown section type ${sectionType}`);
        }
    }
}

export interface IRecordSection {
    readonly sequence: number;
    readonly records: ReadonlyArray<IRecord>;
}

export interface IRecordSections {
    readonly applicant_application: ReadonlyArray<IRecordSection>;
    readonly caseworker_application: ReadonlyArray<IRecordSection>;
    readonly post_grant_application: ReadonlyArray<IRecordSection>;
}
