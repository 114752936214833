import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'fonda-recover-password-redirect',
    templateUrl: './recover-password-redirect.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecoverPasswordRedirectComponent implements OnInit {
    constructor(private router: Router, private route: ActivatedRoute) {}

    ngOnInit() {
        const { token, uuid } = this.route.snapshot.queryParams;
        this.router.navigate(['/landing-page'], { queryParams: { resetPassword: 'true', token, uuid } });
    }
}
