import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { TranslateService } from '../../translation/translate.service';

export interface KeyValueSelectInterface {
    value: string;
    title: string;
}

@Component({
    selector: 'key-value-select',
    templateUrl: 'key-value-select.component.html',
})
export class KeyValueSelectComponent {
    show = false;
    options: Array<KeyValueSelectInterface> = [];
    choosen: Array<KeyValueSelectInterface> = [];

    actionSelect = false;
    checkbox = false;
    placeholder = 'Checkbox';
    @Input('sort') sort = false;
    @Input('showValuesOnCheckbox') showValuesOnCheckbox = false;
    @Input('limitOfValuesOnCheckbox') limitOfValuesOnCheckbox = 0;
    @Input() containerPlace = 'left';
    @Input() uppercase = true;
    @Output('getChoosen') public getChoosen = new EventEmitter<Array<KeyValueSelectInterface>>();

    constructor(
        private _eref: ElementRef,
        private changeRef: ChangeDetectorRef,
        private translateService: TranslateService
    ) {}

    @Input('checkbox') set isCheckbox(checkbox: boolean) {
        this.checkbox = checkbox;
    }

    @Input('options') set optionsArray(options: Array<KeyValueSelectInterface>) {
        this.options = [...options];
        this.changeRef.detectChanges();
    }

    @Input('choosen') set choosenArray(choosen: Array<KeyValueSelectInterface>) {
        this.choosen = [...choosen];
        if (!this.choosen) {
            this.choosen = [];
        }
    }

    @Input('placeholder') set actualPlaceholder(placeholder: string) {
        this.placeholder = placeholder;
    }

    @Input('actionSelect') set isActionSelected(actionSelect: boolean) {
        this.actionSelect = actionSelect;
    }

    public get chosenToShow(): string {
        return this.choosen.map(c => c.value) && this.choosen.map(c => c.value).join(', ');
    }

    @HostListener('document:click', ['$event']) clickedOutside($event) {
        if (!this._eref.nativeElement.contains($event.target)) {
            // or some similar check
            this.show = false;
        }
    }

    public isChoosen(option: KeyValueSelectInterface): boolean {
        return (
            this.choosen &&
            this.choosen[0] &&
            this.choosen.map(c => c.value) &&
            this.choosen.map(c => c.value).includes(option.value)
        );
    }

    public showDropdown(): boolean {
        return this.options && this.options.length > 0;
    }

    public clickInside() {
        this.show = this.checkbox ? true : !this.show;
    }

    public choose(option: KeyValueSelectInterface) {
        if (this.checkbox) {
            const optionPosition = this.choosen.map(c => c.value).indexOf(option.value);
            if (optionPosition === -1) {
                this.choosen.push(option);
                this.choosen = [...this.choosen];
            } else {
                this.choosen.splice(optionPosition, 1);
                this.choosen = [...this.choosen];
            }
        } else {
            this.choosen[0] = option;
        }
        this.emitValues();
    }

    public shouldShowValuesOnCheckbox(): boolean {
        return this.showValuesOnCheckbox && this.choosen && this.choosen.length <= this.limitOfValuesOnCheckbox;
    }

    public emitValues() {
        this.getChoosen.emit(this.choosen);
    }
}
