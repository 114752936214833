import { SearchResponseDto } from '../dto/search-dto/search-response-dto';
import { SearchResponseApplicationDto } from '../dto/search-dto/search-response-application-dto';
import { ApplicationTemplateDto } from '../dto/search-dto/application-template-dto';
import { ApplicationRecordDto } from '../dto/search-dto/application-record-dto';
import { FiltersDto } from '../dto/search-dto/filters-dto';
import { ApplicationFiltersDto } from '../dto/search-dto/application-filters-dto';
import { ColumnDto } from '../dto/search-dto/column-dto';
import { OrderByDto } from '../dto/search-dto/order-by-dto';
import { GroupFiltersDto } from '../dto/search-dto/group-filters-dto';
import { GroupSearchResponseDto } from '../dto/search-dto/group-search-response-dto';
import { SearchGroupRowDto } from '../dto/search-dto/search-group-row-dto';
import { ProjectionOptions } from '../../caseworker/search/services/search-service';

export class SearchResponseMapper {
    public static GetSearchResponse(jsonDataObject: any): SearchResponseDto {
        const applicationsResponse: Array<SearchResponseApplicationDto> = [];
        jsonDataObject.rows.forEach(response => {
            const searchResponseApp = new SearchResponseApplicationDto();
            searchResponseApp.applicationUuid = response.application_uuid;
            searchResponseApp.lockedByOtherCaseworker = response.locked_by_other_caseworker;
            searchResponseApp.values = [];
            // eslint-disable-next-line guard-for-in
            for (const key in response.values) {
                searchResponseApp.values.push({
                    key,
                    value: response.values[key],
                });
            }

            applicationsResponse.push(searchResponseApp);
        });

        return new SearchResponseDto(applicationsResponse, jsonDataObject.total_amount);
    }

    public static GetSearchFilters(jsonDataObject: any): FiltersDto {
        const filters: Array<ApplicationFiltersDto> = [];

        jsonDataObject.filters.forEach(filter => {
            filters.push(
                new ApplicationFiltersDto(filter.reference, filter.reference_type, filter.projection, filter.params)
            );
        });

        const columns: Array<ColumnDto> = [];

        jsonDataObject.columns.forEach(column => {
            columns.push(new ColumnDto(column.reference, column.reference_type, column.projection));
        });

        const orderBy: Array<OrderByDto> = [];

        jsonDataObject.order_by.forEach(singleOrderBy => {
            orderBy.push(
                new OrderByDto(
                    singleOrderBy.reference,
                    singleOrderBy.reference_type,
                    singleOrderBy.order_type,
                    singleOrderBy.projection
                )
            );
        });

        return new FiltersDto(
            jsonDataObject.mass_text_search_filter,
            jsonDataObject.application_template_uuid,
            filters,
            columns,
            orderBy
        );
    }

    public static GetGroupBySearchResponse(jsonDataObject: any): GroupSearchResponseDto {
        const rows: Array<SearchGroupRowDto> = [];

        jsonDataObject.rows.forEach(row => {
            rows.push(new SearchGroupRowDto(row.count, row.grouped_by));
        });

        return new GroupSearchResponseDto(rows, jsonDataObject.total_amount, jsonDataObject.type);
    }
}
