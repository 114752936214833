import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[escPressed]',
})
export class EscPressedDirective {
    @Output() escPressed = new EventEmitter<KeyboardEvent>();

    @HostListener('window:keyup', ['$event'])
    onWindowKeyup(event: KeyboardEvent) {
        if (event.keyCode === 27) {
            this.escPressed.emit(event);
        }
    }
}
