<div class="cookies-container applicant-font applicant" *ngIf="showCookies">
    <div class="wrapper">
        <div class="message">
            <h2 [innerHTML]="'login_page' | translate: 'cookies_title' | safeHtml"></h2>
            <p [innerHTML]="'login_page' | translate: 'cookies_text' | safeHtml"></p>
            <a (click)="seeMoreCookies()" [innerHTML]="'login_page' | translate: 'cookies_see_more' | safeHtml"></a>
        </div>
        <div class="button-container">
            <button
                (click)="clickCookiesClose()"
                [innerHTML]="'login_page' | translate: 'cookies_accept' | safeHtml"
            ></button>
        </div>
    </div>
</div>
