import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChildren,
    AfterViewInit,
} from '@angular/core';
import { BaseRecordComponent } from '../../base-record.component';
import { PaymentObject, PaymentTypes } from './payment-object';
import { PaymentRowComponent } from './payment-row/payment-row.component';
import { EventsHandlerService } from '../../../../services/events-handler-service';
import { PaymentStoreService } from '../../../../services/payment-store-service';
import { Subscription } from 'rxjs/Subscription';
import { PaymentProfilePopupService } from '../../../components/payment-profile/payment-profile-popup-service';
import { PaymentProfileDto } from '../../../../api/dto/payment-profile-dto/payment-profile-dto';
import { ActivatedRoute } from '@angular/router';
import { FondaApiService } from '../../../../api/fonda-api.service';

@Component({
    selector: 'payment-record',
    templateUrl: 'payment-record.component.html',
})
export class PaymentRecordComponent extends BaseRecordComponent implements OnInit, OnDestroy, AfterViewInit {
    constructor(
        private activatedRoute: ActivatedRoute,
        @Inject(FondaApiService) private apiService: FondaApiService,
        private eventHandler: EventsHandlerService,
        private changeRef: ChangeDetectorRef,
        private paymentStoreService: PaymentStoreService,
        private paymentProfilePopup: PaymentProfilePopupService
    ) {
        super();
    }
    @Input('uuid') public uuid: string;
    @Input('value') public value: object;
    @Input('isRequired') public isRequired: boolean;
    @Input('size') public size: number;
    @Input('onSearch') isOnSearch = false;
    @Input('title') title: string;
    @Input('readonly') public readonly = false;
    @Output('onChange') onChange = new EventEmitter<object>();
    @Input('configuration') configuration: object;

    @ViewChildren('paymentRows') paymentRows: QueryList<PaymentRowComponent>;

    changed = false;
    initValue: object;
    pastPaymentPlanned = 0;
    grantedAmount = 0;
    paymentPaid = 0;
    total = 0;
    balance = 0;
    paymentProfiles: Array<PaymentProfileDto> = [];
    public applicationUuid = '';
    public float1 = '';
    public float2 = '';
    private _pastPaymentPlanEventSubscription: Subscription;
    private _grantedAmountPlanEventSubscription: Subscription;
    public _canSave = false;
    public _isChanged = false;

    public payments: Array<PaymentObject> = [];

    public emitValue() {
        this.onChange.emit(this.getValue());
    }

    public openPaymentProfile() {
        this.paymentProfilePopup.initPopup();
    }

    public getPaymentByUuid(uuid: string): PaymentProfileDto {
        return this.paymentProfiles.find(p => p.uuid === uuid);
    }

    public save() {
        if (this.paymentRows) {
            this.paymentRows.forEach(pay => pay.onSaveClick());
        }
        if (this.isValid()) {
            this.eventHandler.triggerSavePostGrantedSection.next();
        }
    }

    public getDueBalance() {
        return Math.max(...[0, this.pastPaymentPlanned - this.paymentPaid]);
    }

    public ngAfterViewInit() {
        this.detectChanges();
        this.initValue = this.getValue();
        this.handleRowChange();
    }

    public handleRowChange() {
        this._isChanged =
            this.changed && JSON.stringify(this.initValue) !== JSON.stringify(this.getValue()) && this.isValid();
        this._canSave = !(
            this.paymentRows &&
            this.changed &&
            JSON.stringify(this.initValue) !== JSON.stringify(this.getValue())
        );
        this.emitValue();
    }

    public ngOnDestroy() {
        this._pastPaymentPlanEventSubscription.unsubscribe();
        this._grantedAmountPlanEventSubscription.unsubscribe();
        this.changeRef.detach();
    }

    public ngOnInit() {
        if (!this.isOnSearch) {
            if (this.value) {
                const _value: any = this.value;
                _value.forEach(row => {
                    this.payments.push(
                        new PaymentObject(row.date, row.name, row.amount, row.type, row.payment_profile)
                    );
                });
            }
            this.getValue();
        } else {
            this.initValue = this.value['text'];
            this.float1 = this.value['text'][0] ? this.convertToString(this.value['text'][0]) : '';
            this.float2 = this.value['text'][1] ? this.convertToString(this.value['text'][1]) : '';
            this.emitValue();
        }

        this.fetchApplicationUuid();
        this.fetchPaymentProfiles();
        this.paymentProfilePopup.onClosePopup.subscribe(() => {
            this.fetchPaymentProfiles();
        });
        if (this.paymentStoreService.pastPaymentPlanEvent) {
            this.pastPaymentPlanned = this.paymentStoreService.pastPaymentPlanEvent.value;
        }
        this._pastPaymentPlanEventSubscription = this.paymentStoreService.pastPaymentPlanEvent.subscribe(p => {
            this.pastPaymentPlanned = p;
            this.detectChanges();
        });
        this._grantedAmountPlanEventSubscription = this.paymentStoreService.grantedAmountPlanEvent.subscribe(p => {
            this.grantedAmount = p;
            this.balance = this.grantedAmount - (this.total ? this.total : 0);
            this.detectChanges();
        });
        this.handleRowChange();
        this.emitValue();
    }

    public deleteRow(row: PaymentObject) {
        this.payments.splice(this.payments.indexOf(row), 1);
        this.detectChanges();
    }

    public addNewPayment() {
        this.payments.push(new PaymentObject(null, '', 0, null, this.getDefaultPaymentProfile().uuid));
        this.changed = true;
        this.detectChanges();
    }

    public isValid(): boolean {
        let isValid = true;
        if (this.paymentRows) {
            this.paymentRows.forEach(row => {
                if (!row || !row.isValid()) {
                    isValid = false;
                }
            });
            return isValid;
        } else {
            return false;
        }
    }

    public isChanged(): boolean {
        return this._isChanged;
    }

    public canSave(): boolean {
        return this._canSave;
    }

    public isEmpty(): boolean {
        return false;
    }

    public handleTotal(obj: PaymentObject) {
        this.total +=
            (obj.type == PaymentTypes.Paid || obj.type == PaymentTypes.Canceled) && obj.amount ? obj.amount : 0;
        this.total -= obj.type == PaymentTypes.Refunded && obj.amount ? obj.amount : 0;
    }

    public getValue(): object {
        const arrJson: Array<object> = [];
        if (!this.isOnSearch) {
            return this.getNoSearchValue();
        } else if (this.isOnSearch) {
            const obj = {};
            obj['text'] = this.getSearchValue();
            return obj;
        }
    }

    private getSearchValue(): object {
        return [
            this.convertToNumber(this.float1) ? parseFloat(this.convertToNumber(this.float1).toFixed(2)) : null,
            this.convertToNumber(this.float2) ? parseFloat(this.convertToNumber(this.float2).toFixed(2)) : null,
        ];
    }

    private getDefaultPaymentProfile(): PaymentProfileDto {
        return this.paymentProfiles.find(prof => prof.isDefault);
    }

    private fetchPaymentProfiles() {
        this.apiService.getApplicationPaymentProfiles(this.applicationUuid).then(profiles => {
            this.paymentProfiles = [...profiles];
        });
    }

    private fetchApplicationUuid() {
        this.activatedRoute.queryParams.subscribe(params => {
            this.applicationUuid = params['uuid'];
        });
    }

    private getNoSearchValue(): Array<object> {
        const arrJson: Array<object> = [];
        if (this.paymentRows && !this.readonly) {
            this.resetResults();
            this.paymentRows.forEach(payment => {
                const obj = payment.getValue();
                this.paymentPaid += obj.amount ? obj.amount : 0;
                this.handleTotal(obj);
                this.balance = this.grantedAmount - this.total;
                arrJson.push({
                    date: obj.date,
                    name: obj.title,
                    type: obj.type,
                    amount: obj.amount,
                    payment_profile: obj.paymentProfileUuid,
                });
            });
            this.detectChanges();
        }
        if (this.readonly) {
            this.refreshResults();
            this.detectChanges();
        }
        return arrJson;
    }

    private refreshResults() {
        this.resetResults();
        if (this.value) {
            Object.keys(this.value).forEach(v => {
                if (this.value[v]) {
                    this.paymentPaid += this.value[v].amount ? this.value[v].amount : 0;
                    this.handleTotal(this.value[v]);
                    this.balance = this.grantedAmount - this.total;
                }
            });
        }
    }

    private resetResults() {
        this.paymentPaid = 0;
        this.total = 0;
    }

    private convertToNumber(str: string): number {
        if (!str) {
            return;
        } else {
            str = str.toString();
            str = str.replace(/[^\d,-]/g, '');
            str = str.replace(',', '.');
            return Number(str);
        }
    }

    private convertToString(num: number): string {
        if (num) {
            const str = num.toString();
            return str.replace('.', ',');
        } else {
            return;
        }
    }

    private detectChanges() {
        if (!this.changeRef['destroyed']) {
            this.changeRef.detectChanges();
        }
    }
}
