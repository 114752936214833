import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { BaseRecordComponent } from '../../base-record.component';

@Component({
    selector: 'company-record',
    templateUrl: 'company-record.component.html',
    styleUrls: ['company-record.scss'],
})
export class CompanyRecordComponent extends BaseRecordComponent implements OnInit {
    @Input('uuid') public uuid: string;
    @Input('value') public value: object;
    @Input('isRequired') public isRequired: boolean;
    @Input('size') public size: number;
    private initValue: object;
    private backUpValue: object;
    public text = new UntypedFormControl();
    public booleanValue = false;

    @Input('onSearch') isOnSearch = false;
    @Input('title') title: string;
    @Input('readonly') public readonly = false;
    @Output('onChange') onChange = new EventEmitter<object>();
    @Input('configuration') configuration: object;

    public showText = true;
    public showCheckbox = true;
    public emitValue() {
        this.backUpValue = this.getValue();
        this.onChange.emit(this.backUpValue);
    }

    public ngOnInit() {
        if (!this.isOnSearch) {
            this.booleanValue = this.value['is_company'] ? this.value['is_company'] : false;

            this.booleanValue ? this.text.enable() : this.text.disable();

            // TODO: Uncomment when ready
            // if (this.configuration && this.configuration['min_length'] > 0) {
            //     this.text.setValidators([Validators.minLength(this.configuration['min_length'])]);
            // }
            // TODO: Delete when ready

            this.text.setValue(this.value['company_name']);
        } else {
            this.booleanValue = this.value['boolean'] ? this.value['boolean'] : false;
        }
        if (this.configuration) {
            switch (this.configuration['locked_on']) {
                case 'private': {
                    this.showCheckbox = false;
                    this.showText = false;
                    this.booleanValue = false;
                    break;
                }
                case 'company': {
                    this.showCheckbox = false;
                    this.booleanValue = true;
                    this.text.enable();
                    break;
                }
            }
        }

        this.emitValue();
        this.initValue = this.getValue();
        this.text.valueChanges.subscribe(() => {
            this.emitValue();
        });
        if (this.readonly) {
            this.text.disable();
        }
        this.emitValue();
    }

    public isValid(): boolean {
        return this.text.valid;
    }

    public isChanged(): boolean {
        return JSON.stringify(this.initValue) !== JSON.stringify(this.backUpValue);
    }
    public isEmpty(): boolean {
        return !this.value;
    }
    public getValue(): object {
        if (!this.isOnSearch) {
            this.value = {};
            this.value['is_company'] = this.booleanValue;
            this.value['company_name'] = this.booleanValue ? this.text.value : null;
        } else {
            this.value = {};
            this.value['boolean'] = this.booleanValue === true;
        }
        return this.value;
    }

    public changeAblility() {
        this.booleanValue = !this.booleanValue;
        if (this.booleanValue) {
            this.text.enable();
        } else {
            this.text.disable();
            this.text.setValue('');
        }
    }
}
