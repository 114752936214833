import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'orderByPipe',
})
export class OrderByPipe implements PipeTransform {
    transform(array: Array<string>, enable: boolean): Array<string> {
        if (!array || array === undefined || array.length === 0) {
            return null;
        }
        if (!enable) {
            return array;
        }

        array.sort((a: any, b: any) => {
            if (a < b) {
                return -1;
            } else if (a > b) {
                return 1;
            } else {
                return 0;
            }
        });
        return array;
    }
}
