import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FondaApiService } from '../../../api/fonda-api.service';
import { CorrespondenceThreadData, CorrespondenceThreadMessageData } from './correspondence-threads-data';
import { FileService } from '../../../services/file.service';
import { UploadedFondaFile } from '../../../models/uploaded-fonda-file';
import { AuthService } from '../../../auth/auth.service';
import { ErrorInterface } from '../../../models/error-interface';
import { ToasterService } from 'angular2-toaster';
import { ActivatedRoute } from '@angular/router';
import { TextEditorComponent } from '../text-editor/text-editor.component';
import { CaseworkerCorrespondencePopupService } from '../../../caseworker/components/correspondence-popup/caseworker-correspondence-popup.service';
import { TranslateService } from '../../translation/translate.service';

@Component({
    selector: 'correspondence-threads',
    templateUrl: './correspondence-threads.component.html',
    providers: [CaseworkerCorrespondencePopupService],
})
export class CorrespondenceThreadsComponent implements OnInit {
    @Input() accountEmail: string;
    @ViewChild('textCkeEditor') public textCkeEditor: TextEditorComponent;
    @Input('caseworkerMode') public caseworkerMode = true;
    @Output() public onExitClick = new EventEmitter();
    public schemaUuid = '';
    public threads: Array<CorrespondenceThreadData> = [];
    public messagesToView: Array<CorrespondenceThreadMessageData> = [];
    public showMessages = false;
    public threadPicked: CorrespondenceThreadData;
    public initialMessageUuid: string;
    public textEdit = '';
    public textWidth: number;
    public filesUploaded: Array<UploadedFondaFile> = [];
    public errorMessage = '';
    public userUuid: string;
    public amountOfNewMessages = 0;
    public isLoading = false;
    public isApplicant = false;
    public isActiveNow = false;

    constructor(
        private apiService: FondaApiService,
        private fileService: FileService,
        private authService: AuthService,
        private changeRef: ChangeDetectorRef,
        private translateService: TranslateService,
        private toasterService: ToasterService,
        private activatedRoute: ActivatedRoute,
        private messagePopupService: CaseworkerCorrespondencePopupService
    ) {
        this.isApplicant = this.authService.isApplicant();
    }

    @Input('schemaUuid')
    public set newSchemaUuid(schemaUuid: string) {
        this.schemaUuid = schemaUuid;
    }

    @Input('isActive') set isActive(isActive) {
        this.isActiveNow = isActive;
    }

    private _applicationUuid: string;

    get applicationUuid(): string {
        return this._applicationUuid;
    }

    @Input('applicationUuid') set applicationUuid(applicationUuid: string) {
        this._applicationUuid = applicationUuid;
        this.getInitialMessageUuid();
        this.initialiseThreadsCoponent();
    }

    ngOnInit(): void {
        this.messagePopupService.messageSend.subscribe(message => {
            this.handleNewThreadSend(message);
        });
    }

    public initialiseThreadsCoponent() {
        this.fetchThreads().then(threads => {
            this.threads = threads.sort(this.compareThreads);
            this.amountOfNewMessages = this.getAmountOfNewThreads(threads);
        });
        this.textWidth = window.innerWidth * 0.5 * 0.7;
        this.userUuid = this.authService.getUuid();
    }

    public onResize() {
        this.textWidth = window.innerWidth * 0.5 * 0.7;
    }

    public clickOnThread(thread: CorrespondenceThreadData) {
        this.initMessageView(thread.messages);
        this.showMessages = true;
        if (!thread.read) {
            this.apiService.patchApplicationThreadSetRead(this.applicationUuid, thread.uuid);
            thread.read = true;
            this.amountOfNewMessages = this.getAmountOfNewThreads(this.threads);
        }
        this.dialogueToBottom();
    }

    public clickSend(threadUuid: string) {
        this.errorMessage = '';
        if (this.canSendMessage()) {
            this.sendMessage(threadUuid)
                .then(() => {
                    this.fetchThreads().then(threads => {
                        this.clearMessage();
                        this.threads = threads.sort(this.compareThreads);
                        this.initMessageView(this.threads.find(thread => thread.uuid == threadUuid).messages);
                        this.dialogueToBottom();
                        return;
                    });
                })
                .catch((error: ErrorInterface) => {});
        } else {
            this.errorMessage = this.translateService.get(['messages', 'popup_message_mandatory']);
        }
    }

    public handleNewThreadSend(callBackInformation: {
        threadTitle: string;
        messageBody: string;
        statusName: string;
        filesUploaded: Array<UploadedFondaFile>;
        correspondenceTemplateUuid?: string;
    }) {
        this.createNewThread(
            callBackInformation.threadTitle,
            callBackInformation.messageBody,
            callBackInformation.filesUploaded,
            callBackInformation.correspondenceTemplateUuid
        )
            .then(() => {
                this.fetchThreads().then(threads => {
                    this.threads = threads.sort(this.compareThreads);
                });
            })
            .catch(error => {});
    }

    public handleTextChange(text: string) {
        this.textEdit = text;
        this.errorMessage = '';
    }

    public openCorrespondencePopup(parentLetterUuid?: string) {
        this.messagePopupService.openCorrespondencePopup(
            this.applicationUuid,
            this.schemaUuid,
            parentLetterUuid,
            this.accountEmail
        );
    }

    public addFile(uploadedNcfFile: UploadedFondaFile) {
        this.filesUploaded.push(uploadedNcfFile);
    }

    public deleteFile(uuid: string) {
        this.filesUploaded.splice(this.filesUploaded.map(file => file.uuid).indexOf(uuid), 1);
    }

    public downloadFile(uuid: string) {
        this.fileService.downloadFile(uuid).then(file => {
            this.fileService.downloadFileToOwnMachine(file);
        });
    }

    public canSendMessage(): boolean {
        return !!this.textEdit && !this.isLoading;
    }

    private getAmountOfNewThreads(threadsData: Array<CorrespondenceThreadData>): number {
        let amount = 0;
        threadsData.forEach(thread => {
            if (!thread.read) {
                amount = amount + 1;
            }
        });

        return amount;
    }

    private dialogueToBottom() {
        const doc = document.getElementById('message-window');
        if (doc) {
            setTimeout(() => {
                doc.scrollTop = doc.scrollHeight;
            }, 125);
            setTimeout(() => {
                doc.scrollTop = doc.scrollHeight;
            }, 250);
        }
    }

    private sendMessage(threadUuid: string): Promise<void> {
        return this.apiService.postApplicationThreadMessage(
            this.applicationUuid,
            threadUuid,
            this.textEdit,
            this.filesUploaded
        );
    }

    private createNewThread(
        threadTitle: string,
        messageBody: string,
        uploadedFiles: Array<UploadedFondaFile>,
        choosenTemplateUuid: string
    ): Promise<void> {
        return this.apiService
            .postApplicationThread(threadTitle, messageBody, uploadedFiles, this.applicationUuid, choosenTemplateUuid)
            .then(identifier => {
                return;
            })
            .catch(error => {
                throw error;
            });
    }

    private clearMessage() {
        this.textEdit = '';
        this.textCkeEditor.clear();
        this.filesUploaded = [];
        this.changeRef.detectChanges();
    }

    private initMessageView(messages: Array<CorrespondenceThreadMessageData>) {
        this.messagesToView = messages;
        this.threadPicked = this.threads.find(thread => thread.messages == messages);
    }

    private fetchThreads(): Promise<Array<CorrespondenceThreadData>> {
        this.isLoading = true;
        return this.apiService
            .getApplicationThreads(this.applicationUuid)
            .then(threads => {
                const resultThreads = [];
                this.isLoading = false;
                threads.forEach(thread => {
                    const messages: Array<CorrespondenceThreadMessageData> = [];
                    thread.messages.forEach(message => {
                        messages.push(
                            new CorrespondenceThreadMessageData(
                                message.uuid,
                                message.sentAt,
                                message.body && message.body.replace(/&nbsp;/g, ' '),
                                message.sender,
                                message.attachements
                            )
                        );
                    });
                    resultThreads.push(
                        new CorrespondenceThreadData(
                            thread.uuid,
                            thread.applicationUuid,
                            thread.creator.username,
                            this.getLastDate(messages.map(message => message.sentAt)),
                            thread.locked,
                            thread.title,
                            messages,
                            thread.read,
                            thread.readByApplicant
                        )
                    );
                });
                return resultThreads;
            })
            .catch(() => {
                this.isLoading = false;
                return [];
            });
    }

    private getLastDate(dates: Array<Date>): Date {
        let lastDate = dates[0];

        dates.forEach(date => {
            if (lastDate < date) {
                lastDate = date;
            }
        });

        return lastDate;
    }

    private getInitialMessageUuid() {
        this.activatedRoute.queryParams.subscribe(params => {
            this.initialMessageUuid = params['message_uuid'];
        });
    }

    private compareThreads(a: CorrespondenceThreadData, b: CorrespondenceThreadData) {
        return (
            new Date(b.messages[b.messages.length - 1].sentAt).getTime() -
            new Date(a.messages[a.messages.length - 1].sentAt).getTime()
        );
    }

    getLastMessageFirstWords(messages) {
        if (!messages.length) return;
        const text = messages[messages.length - 1].body.split(' ').slice();
        if (text.length > 8) {
            return this.removeTags(text.slice(0, 8).join(' ')) + '...';
        }
        return this.removeTags(text.join(' '));
    }

    removeTags(text: string): string {
        const div = document.createElement('div');
        div.innerHTML = text;
        return div.textContent;
    }
}
