import { Component, Input, Output, EventEmitter, HostListener, ElementRef } from '@angular/core';
import { DatepickerOptions } from 'ng2-datepicker';

@Component({
    selector: 'fonda-basic-datepicker',
    templateUrl: 'fonda-basic-datepicker.component.html',
})
export class FondaBasicDatepickerComponent {
    get date(): Date {
        return this._date;
    }

    @Input() options: DatepickerOptions;

    @Input() set date(value: Date) {
        if (value) {
            this._date = new Date(value);
        }
    }

    @Output() dateChange = new EventEmitter<Date>();

    private _date: Date = new Date();

    public handleDate(date: Date) {
        this.date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
        this.dateChange.emit(this.date);
    }
}
