<div *ngIf="!readonly" class="form-item" [ngClass]="{ half: size === 1 }">
    <input
        id="application-integer"
        type="number"
        name="value2"
        [formControl]="text"
        placeholder=" "
        [required]="isRequired"
    />
    <label for="application-integer">{{ uuid | translate: 'title' }}</label>
    <div class="form-focus"></div>
    <div class="form-help"></div>
    <div class="form-error" [ngClass]="{ active: !text.valid }">
        <!--Udfyld dette felt-->
    </div>
</div>

<a class="form-item" *ngIf="readonly" [ngClass]="{ full: size === 2 }">
    <p>{{ uuid | translate: 'title' }}</p>
    <span>{{ text.value ? text.value : '-' }}</span>
    <div class="form-focus"></div>
    <div class="form-error"></div>
</a>
