<div class="overlay">
    <div class="popup approve-popup medium files-converter">
        <div class="popup-header">
            <h2>{{ 'converter' | translate: 'converter_pop_up_title' }}</h2>
            <a class="close-btn smaller" (click)="closePopup()"><a class="icon-close"></a></a>
        </div>

        <h4>{{ 'converter' | translate: 'converter_pop_up_info_text' }}</h4>

        <form>
            <ul>
                <li>
                    <div class="checkbox">
                        {{ 'converter' | translate: 'original_file' }}
                    </div>
                    <div class="checkbox">
                        {{ 'converter' | translate: 'converted_file' }}
                    </div>
                </li>
                <li *ngFor="let file of filesToConvert">
                    <div class="checkbox">
                        <input
                            id="convert{{ file.uuid }}"
                            name="convert{{ file.uuid }}"
                            [(ngModel)]="file.picked"
                            type="checkbox"
                        />
                        <label for="convert{{ file.uuid }}">{{ file.fileName }}</label>
                    </div>

                    <div class="div-between">
                        <button
                            class="button smaller"
                            *ngIf="!file.loading && !file.convertedFileModel"
                            (click)="clickConvertSingle(file)"
                        >
                            {{ 'converter' | translate: 'generate_document' }}
                        </button>
                        <div class="small-loader-container" *ngIf="file.loading"><div class="loader"></div></div>
                    </div>

                    <div class="checkbox">
                        <ng-container *ngIf="file.convertedFileModel">
                            <input
                                id="{{ file.convertedFileModel.uuid }}"
                                name="{{ file.convertedFileModel.uuid }}"
                                [(ngModel)]="file.convertedFileModel.picked"
                                type="checkbox"
                            />
                            <label for="{{ file.convertedFileModel.uuid }}">{{
                                file.convertedFileModel.fileName
                            }}</label>
                        </ng-container>

                        <ng-container *ngIf="!file.convertedFileModel">-</ng-container>
                    </div>
                </li>
            </ul>
        </form>
        <a class="button" (click)="save()">Save</a>
        <!--<a class="button" (click)="onlyConverted()">onlyConverted</a>-->
        <!--<a class="button" (click)="notConvert()">notConvert</a>-->
    </div>
</div>
