<caseworker-upload-files [uuid]="uuid"></caseworker-upload-files>

<div *ngIf="!readonly" class="form-item" [ngClass]="{ half: size === 1 }">
    <label style="position: initial; float: left">{{ uuid | translate: 'title' }}</label>
</div>

<div class="upload" *ngIf="!readonly">
    <div class="upload-head" *ngFor="let file of files">
        <div class="upload-filename">{{ file.fileName }}</div>
        <div class="upload-size">{{ file.size | fileSize }}</div>
        <div class="upload-delete" (click)="deleteFile(file.uuid)">
            <a class="icon delete-file"><div class="icon-delete-file"></div></a>
        </div>
    </div>

    <div class="upload-head" *ngIf="fileUpload && fileUpload.loading">
        <div class="upload-filename">{{ fileUpload.fileName }}</div>
        <div class="upload-size">{{ fileUpload.fileMBSize }} MB</div>
        <div class="upload-process" [ngStyle]="{ width: fileUpload.loadingProgress + '%' }"></div>
    </div>

    <file-upload-component (fileAdded)="addNewFile($event)" #fileUploadComponent [num]="uuid">
        <div class="upload-dragdrop">
            <div class="upload-dragdrop-icon"></div>
            <p>{{ uuid | translate: 'box' }}</p>
        </div>
    </file-upload-component>
</div>

<div class="stable" style="padding: 0" *ngIf="readonly">
    <ul class="files readonly" *ngIf="files">
        <li class="full">
            <ul class="header">
                <li>{{ uuid | translate: 'name' }}</li>
                <li></li>
                <li>{{ uuid | translate: 'size' }}</li>
            </ul>
            <ng-container *ngIf="files.length > 0">
                <ul *ngFor="let file of files" (click)="download(file)">
                    <li>{{ file.fileName }}</li>
                    <li></li>
                    <li>{{ file.size | fileSize }}</li>
                </ul>
            </ng-container>
            <ng-container *ngIf="files.length === 0">
                <ul class="non-selectable">
                    <li>
                        <i>{{ uuid | translate: 'no_files_found' }}</i>
                    </li>
                </ul>
            </ng-container>
        </li>
    </ul>
</div>
