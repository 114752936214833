import { SectionDto } from './section-dto';

export class ApplicationDto {
    constructor(
        readonly sections: Array<SectionDto>,
        readonly uuid: string,
        readonly applicationSchemaUuid: string,
        readonly categoryUuid: string,
        readonly createdAt: string,
        readonly publicStatus: string,
        readonly isDraft: boolean,
        readonly submittedAt: string,
        readonly deletionDate: string
    ) {}
}
