import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, Provider } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { API_BASE_URL } from './api-base-url';

@Injectable()
export class TokenHttpInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService, @Inject(API_BASE_URL) private readonly apiBaseUrl: string) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const isApiRequest = req.url.startsWith(this.apiBaseUrl);
        if (!isApiRequest) return next.handle(req);

        let headers = req.headers;

        if (this.authService.isLoggedIn()) {
            headers = headers.set('X-AUTH-JWT', this.authService.getToken() || '');
        }

        if (this.authService.isImpersonating()) {
            headers = headers.set('X-AUTH-IMPERSONATOR-JWT', this.authService.getImpersonatorToken() || '');
        }

        const modifiedRequest = req.clone({
            headers,
        });

        return next.handle(modifiedRequest);
    }
}

export const TOKEN_HTTP_INTERCEPTOR_PROVIDER: Provider = {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenHttpInterceptor,
    multi: true,
};
