import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { FondaApiGlobalVariables } from '../api/fonda-api-global-variables';
import { AuthService } from '../auth/auth.service';

export enum CaseworkerReviewRedirectionSubpage {
    default = 0,
    internalChat = 1,
    messages = 2,
}

export enum CaseworkerReturnUrls {
    Search = 'caseworker/search',
    LandingPage = 'caseworker/landing-page',
    BoardMeeting = 'caseworker/board-meeting',
}

@Injectable()
export class RedirectionService {
    constructor(
        public readonly router: Router,
        private readonly globalVariables: FondaApiGlobalVariables,
        private readonly authService: AuthService
    ) {}

    public toLandingPage(returnUrl?: string): Promise<boolean> {
        const queryParams: any = {};
        if (returnUrl) {
            queryParams.returnUrl = returnUrl;
        }

        return this.router.navigate(['landing-page'], { queryParams });
    }

    public toLandingPageWithPasswordReset(token: string, uuid: string) {
        return this.router.navigate(['landing-page'], {
            queryParams: { token, uuid, resetPassword: true },
        });
    }

    public toApplicantLandingPage() {
        return this.router.navigate(['applicant/landing-page']);
    }

    public toApplication(uuid: string) {
        return this.router.navigate(['applicant/application'], { queryParams: { uuid } });
    }

    public toBoardMeetingAgendaCreator(uuid: string) {
        return this.router.navigate(['caseworker/board-meeting'], { queryParams: { uuid } });
    }

    public toBoardMeetingViewer(uuid?: string) {
        return this.router.navigate(['caseworker/board-meeting/viewer'], { queryParams: { uuid } });
    }

    public toProfilePanel() {
        return this.router.navigate(['applicant/profile']);
    }

    public toCaseworkerLandingPage() {
        return this.router.navigate(['caseworker/landing-page']);
    }

    public toCaseworkerSearch() {
        return this.router.navigate(['caseworker/search']);
    }

    public toCaseworkerEconomy() {
        return this.router.navigate(['caseworker/economy']);
    }

    public toCaseworkerReview(uuid: string, subpage?: CaseworkerReviewRedirectionSubpage) {
        if (subpage) {
            this.globalVariables.reviewSubPageEmitter.next(subpage);
        }
        return this.router.navigate(['caseworker/review'], {
            queryParams: { uuid, subpage: subpage ? subpage : null },
        });
    }

    public toCaseworkerReviewFromSearch(uuid: string) {
        return this.router.navigate(['caseworker/review'], { queryParams: { uuid, returnUrl: this.router.url } });
    }

    public toCaseworkerReviewFromBoardMeeting(uuid: string) {
        return this.router.navigate(['caseworker/review'], { queryParams: { uuid, returnUrl: this.router.url } });
    }

    public toCaseworkerReviewFromLanding(uuid: string) {
        return this.router.navigate(['caseworker/review'], {
            queryParams: { uuid, returnUrl: CaseworkerReturnUrls.LandingPage },
        });
    }

    public strictToCaseworkerReview(uuid: string) {
        return this.router.navigateByUrl(
            'caseworker/review',
            /* Removed unsupported properties by Angular migration: queryParams. */ {}
        );
    }

    public toApplicationReview(uuid: string) {
        return this.router.navigate(['application/review'], { queryParams: { uuid } });
    }

    public toAdministration(): Promise<boolean> {
        return this.router.navigate(['/caseworker', 'administration']);
    }

    public toAny(url: string, caseUuid?: string) {
        const _url = caseUuid ? url + '&caseUuid=' + caseUuid : url;
        return this.router.navigateByUrl(_url);
    }

    public getUrl(): string {
        return this.router && this.router.url;
    }

    toUserLandingPage(): Promise<boolean> {
        if (this.authService.isApplicant()) {
            return this.toApplicantLandingPage();
        }

        if (this.authService.isCaseworker()) {
            return this.toCaseworkerLandingPage();
        }

        if (this.authService.isBoardMeetingViewer()) {
            return this.toBoardMeetingViewer();
        }

        if (this.authService.isAdministrator()) {
            return this.toAdministration();
        }

        return this.toLandingPage();
    }

    toApplicationSchema(uuid: string) {
        return this.router.navigate(['caseworker', 'administration', 'application-schema-editor', uuid]);
    }

    toBackendLogin(): Promise<boolean> {
        return this.router.navigate(['/backend']);
    }

    toAdministrationBoardMeetings(): Promise<boolean> {
        return this.router.navigate(['/caseworker', 'administration', 'board-meetings']);
    }
}
