import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { RedirectionService } from '../services/redirection-service';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthenticationGuard implements CanActivate, CanActivateChild {
    constructor(private authService: AuthService, private redirectionService: RedirectionService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.authService.hasIdentity()) {
            return true;
        } else {
            this.redirectionService.toLandingPage(state.url);
            return false;
        }
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(childRoute, state);
    }
}
