import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { ImpersonatorService } from '../impersonator.service';

@Component({
    selector: 'fonda-impersonator-toast',
    templateUrl: './impersonator-toast.component.html',
    styleUrls: ['./impersonator-toast.component.scss'],
})
export class ImpersonatorToastComponent {
    constructor(private authService: AuthService, private impersonatorService: ImpersonatorService) {}

    get email() {
        return this.authService.getAccountDescriptor().email;
    }

    isImpersonating() {
        return this.authService.isImpersonating();
    }

    stopImpersonating() {
        this.impersonatorService.stopImpersonating();
    }
}
