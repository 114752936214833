<div class="form-item acceptance-record" [ngClass]="{ half: size === 1 }" *ngIf="!readonly && !onSearch">
    <div class="checkbox">
        <input
            type="checkbox"
            placeholder=" "
            style="width: 24px; height: 24px"
            [(ngModel)]="booleanValue"
            (ngModelChange)="emitValue()"
            id="{{ uuid }}"
            name="{{ uuid }}"
        />
        <label for="{{ uuid }}" style="font-size: 1em">
            <p>{{ uuid | translate: 'acceptance_text' }} <span *ngIf="isRequired">*</span></p>
        </label>
    </div>
    <button class="button color-blue" (click)="showPopup()">{{ uuid | translate: 'acceptance_button' }}</button>
</div>

<a *ngIf="readonly && false" class="readonly acceptance-record" [ngClass]="{ full: size === 2 }">
    <ul class="readonly-checkbox checkbox">
        <li class="form-item readonly-checkbox">
            <input
                type="checkbox"
                placeholder=" "
                style="width: 24px; height: 24px; cursor: auto"
                [ngModel]="booleanValue"
                disabled
                id="{{ uuid }}"
                name="{{ uuid }}"
            />
            <label for="{{ uuid }}" style="font-size: 1em">
                <p>{{ uuid | translate: 'title' }}</p>
            </label>
        </li>
    </ul>
    <button class="button color-blue" (click)="showPopup()">{{ uuid | translate: 'acceptance_button' }}</button>
</a>
