import {
    Component,
    ContentChild,
    DoCheck,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    TemplateRef,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { MultipleSelectModel, SelectModel, SelectModelValue, SingleSelectModel } from './select.model';
import { SelectOptionDirective } from './select-option.directive';
import { fromEvent, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ControlValueAccessor } from '@angular/forms';
import { OptionComponent } from './option/option.component';

export type SelectInputValue<T> = undefined | null | T | T[];
export type SelectValue<T> = null | T | T[];
export interface OptionTemplateContext<T> {
    readonly $implicit: T;
}

@Component({
    selector: 'fonda-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'fonda-select',
        '[class.is-box-display]': 'boxDisplay',
        '[class.is-input-display]': '!boxDisplay',
    },
})
export class SelectComponent<T> implements OnInit, OnChanges, OnDestroy, DoCheck, ControlValueAccessor {
    @Input() value: SelectInputValue<T> = null;
    @Input() options: T[] = [];
    @Input() multiple: boolean = false;
    @Input() canUnselect = true;
    @Input() placeholder: string = '';
    @Input() label: string = '';
    @Input() disabled: boolean;
    @Input() showSearch: boolean = false;
    @Input() boxDisplay: boolean = false;
    @Output() valueChange = new EventEmitter<SelectValue<T>>();

    @ViewChild('noTemplate', { read: TemplateRef, static: true }) noTemplate: TemplateRef<OptionTemplateContext<T>>;
    @ViewChildren(OptionComponent, { read: OptionComponent }) optionComponents: QueryList<OptionComponent<T>>;
    @ContentChild(SelectOptionDirective, { static: true }) template?: SelectOptionDirective<T>;

    multipleLabel = '';
    searchText = '';
    open = false;

    private model: SelectModel<T>;
    private readonly destroy$ = new Subject<void>();
    private onchange: (value: SelectModelValue<T>) => void = () => {};
    private ontouched: () => void = () => {};

    constructor(private readonly hostElementRef: ElementRef<HTMLElement>) {}

    get selectedOptions(): T[] {
        return this.model.getValueArray();
    }

    get renderTemplate(): TemplateRef<OptionTemplateContext<T>> {
        return this.template ? this.template.template : this.noTemplate;
    }

    get isEmpty(): boolean {
        return this.model.isEmpty;
    }

    ngOnInit() {
        if (!this.model) this.initModel(this.value);
        this.registerClickedOutsideListener();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.value || changes.multiple || changes.canUnselect) {
            const value = this.model ? this.model.getValue() : this.value;
            this.initModel(value);
        }
    }

    ngDoCheck() {
        this.updateMultipleLabel();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    select(value: T): void {
        if (!this.disabled) {
            this.model.select(value);
            this.emitValue();
        }

        if (!this.multiple) {
            this.close();
        }
    }

    toggle(): void {
        this.ontouched();
        this.open = !this.open;
    }

    close(): void {
        this.open = false;
    }

    getContext(option: T): OptionTemplateContext<T> {
        return { $implicit: option };
    }

    isSelected(option: T): boolean {
        return this.model.isSelected(option);
    }

    registerOnChange(fn: any) {
        this.onchange = fn;
    }

    registerOnTouched(fn: any) {
        this.ontouched = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    writeValue(obj: any) {
        this.initModel(obj);
    }

    private initModel(value?: SelectModelValue<T>): void {
        this.model = this.multiple ? new MultipleSelectModel(value) : new SingleSelectModel(this.canUnselect, value);
    }

    private emitValue(): void {
        const value = this.model.getValue();
        this.valueChange.emit(value);
        this.onchange(value);
    }

    private registerClickedOutsideListener() {
        fromEvent(document, 'click')
            .pipe(
                filter(() => this.open),
                takeUntil(this.destroy$)
            )
            .subscribe((e: MouseEvent) => {
                if (!this.hostElementRef.nativeElement.contains(e.target as Node)) {
                    this.open = false;
                }
            });
    }

    private updateMultipleLabel() {
        if (!this.optionComponents) return;
        const optionComponents = this.optionComponents.toArray();
        this.multipleLabel = this.selectedOptions
            .map(option => optionComponents.find(oc => oc.value === option))
            .filter(Boolean)
            .map(oc => oc.text)
            .join(', ');
    }
}
