import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GlobalVariables } from '../../../../services/global-variables';
import { BaseRecordComponent } from '../../base-record.component';
import { FondaApiService } from '../../../../api/fonda-api.service';

@Component({
    selector: 'schema-record',
    templateUrl: 'schema-record.component.html',
})
export class SchemaRecordComponent extends BaseRecordComponent implements OnInit {
    @Input('value') public set value(value: object) {
        this._value = value;
    }

    public get value(): object {
        return this._value;
    }

    constructor(private apiService: FondaApiService) {
        super();
    }
    @Input('uuid') public uuid: string;

    @Input('title') public title: string;
    @Input('isRequired') public isRequired: boolean;
    @Input('size') public size: number;
    @Input('readonly') public readonly = false;
    @Input('onSearch') public onSearch = false;
    @Output('onChange') onChange = new EventEmitter<object>();

    public schemas: Array<string> = [];
    public selectedUuid;
    public show = false;
    public _value;
    private initValue: string;
    configuration: object;
    public emitValue() {
        this.onChange.emit(this.getValue());
    }

    public async ngOnInit() {
        await this.apiService.getApplicationSchemas().then(res => {
            if (this.onSearch) {
                // this.addNoOption();
            }
            this.schemas = this.schemas.concat(res);
        });
        this.value = this.value ? this.value : {};
        this.initValue = this.value['text'];
        this.selectedUuid = this.value['text'];
        this.emitValue();
    }

    public reset() {
        this.chooseSchema(null);
        this.emitValue();
    }

    public chooseSchema(uuid: string) {
        this.selectedUuid = uuid;
        this.emitValue();
    }

    public isValid(): boolean {
        return !(this.isRequired && !this.value['text']);
    }

    public isChanged(): boolean {
        return this.initValue != this.selectedUuid;
    }
    public isEmpty(): boolean {
        return !this.value;
    }
    public getValue(): object {
        return { text: this.selectedUuid ? this.selectedUuid : null };
    }

    private addNoOption() {
        this.schemas.push(GlobalVariables.NO_ONE_VARIABLE);
    }
}
