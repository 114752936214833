<div class="options-container space-between">
    <div>
        {{ 'general' | translate: 'show' }}
        <select-component
            *ngIf="showSelect"
            [options]="PaginationOptions"
            [choosen]="[howMuchApplicationsCanBeShownAtOnce]"
            [placeholder]="howMuchApplicationsCanBeShownAtOnce"
            [actionSelect]="true"
            (getChoosen)="onOptionChoose($event[0])"
        >
        </select-component>
        <ng-container *ngIf="!showSelect"> {{ howMuchApplicationsCanBeShownAtOnce }} </ng-container>
        {{ 'general' | translate: 'out_of' }} {{ totalNumberOfApplications }} {{ 'general' | translate: 'results' }}
    </div>
    <ul class="pagination">
        <li>
            <a
                class="icon-arrow-left inactive"
                *ngIf="showPagination"
                [ngClass]="{ inactive: pagesArray[0] === currentSite }"
                (click)="pageSwitchClick(currentSite - 1)"
            >
            </a>
        </li>
        <li *ngFor="let page of arrayPagesToShow">
            <a [class.active]="page === currentSite" (click)="pageSwitchClick(page)">
                {{ page }}
            </a>
        </li>
        <li>
            <a
                class="icon-arrow-right inactive"
                *ngIf="showPagination"
                [ngClass]="{ inactive: pagesArray[pagesArray.length - 1] === currentSite }"
                (click)="pageSwitchClick(currentSite + 1)"
            >
            </a>
        </li>
    </ul>
    <div style="width: 175px"></div>
</div>
