import { WorksDatabaseDto } from '../dto/works-database-dto/works-database-dto';
import { ArtworksDto } from '../dto/works-database-dto/artworks-dto';

export class WorksDatabaseMapper {
    public static GetWorksDto(jsonDataObject: any): WorksDatabaseDto {
        const artworks: Array<ArtworksDto> = [];

        if (jsonDataObject.artworks) {
            jsonDataObject.artworks.forEach(artwork => {
                artworks.push(
                    new ArtworksDto(
                        artwork.author,
                        artwork.title,
                        artwork.origin_year,
                        artwork.photo,
                        artwork.material,
                        artwork.artwork_id,
                        artwork.location
                    )
                );
            });
        }

        return new WorksDatabaseDto(jsonDataObject.application_uuid, artworks);
    }
}
