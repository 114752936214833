import { DOCUMENT } from '@angular/common';
import {
    ApplicationRef,
    ComponentFactoryResolver,
    ComponentRef,
    EmbeddedViewRef,
    Inject,
    Injectable,
    Injector,
    Type,
} from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class DOMService {
    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private applicationRef: ApplicationRef,
        private injector: Injector,
        @Inject(DOCUMENT) private document: Document
    ) {}

    appendComponentToBody<C>(
        component: Type<C>,
        resolver?: ComponentFactoryResolver,
        injector?: Injector
    ): ComponentRef<C> | null {
        resolver = resolver || this.componentFactoryResolver;
        injector = injector || this.injector;

        try {
            const componentRef = resolver.resolveComponentFactory<C>(component).create(injector);

            this.applicationRef.attachView(componentRef.hostView);
            const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
            this.document.body.appendChild(domElem);
            return componentRef;
        } catch (e) {
            console.error(e);
            return null;
        }
    }

    removeComponentFromBody(componentRef: ComponentRef<any>) {
        this.applicationRef.detachView(componentRef.hostView);
        componentRef.destroy();
    }

    appendScript(src: string, async: boolean = true, modify?: (script: HTMLScriptElement) => void): Observable<void> {
        return new Observable(subscriber => {
            const script = this.document.createElement('script');
            script.type = 'text/javascript';

            if (modify && typeof modify === 'function') modify(script);

            script.src = src;
            script.async = async;
            script.onload = () => {
                subscriber.next();
                subscriber.complete();
            };

            this.document.head.appendChild(script);
        });
    }
}
