import { Directive, HostBinding, Input } from '@angular/core';

let randomId = 0;

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'input[fondaInput]',
})
export class FondaInputDirective {
    @Input() @HostBinding() id = 'fonda-input-' + (++randomId).toString();
    @HostBinding() placeholder = ' ';
    @Input() @HostBinding() type = 'text';

    @Input() @HostBinding('attr.disabled') disabled: boolean;
    @Input() @HostBinding('attr.readonly') readonly: boolean;
}
