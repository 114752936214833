import { SearchProjectionDto } from './search-projection-dto';

export class ApplicationRecordDto {
    constructor(
        readonly uuid: string,
        readonly behaviour: string,
        readonly section: number,
        readonly sequence: number,
        readonly fieldSize: number,
        readonly type: string,
        readonly refUuid: string,
        readonly isRequired: boolean,
        readonly parentRecordUuid: string,
        readonly searchProjections: Array<SearchProjectionDto>,
        readonly configuration: Array<object>
    ) {}
}
