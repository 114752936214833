import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewContainerRef } from '@angular/core';
import { BaseRecordComponent } from '../../base-record.component';
import { Sizes } from '../../../components/confirmation-popup/confirmation-popup.component';
import { DynamicPopupService } from '../../../components/confirmation-popup/dynamic-popup-service';
import { TranslateService } from '../../../translation/translate.service';

@Component({
    selector: 'acceptance-record',
    templateUrl: 'acceptance-record.component.html',
    styleUrls: ['acceptance-record.scss'],
})
export class AcceptanceRecordComponent extends BaseRecordComponent implements OnInit {
    @Input('uuid') public uuid: string;
    @Input('value') public value: object;
    @Input('isRequired') public isRequired: boolean;
    @Input('size') public size: number;
    @Input('readonly') public readonly = false;
    @Input('onSearch') onSearch = false;
    @Output('onChange') onChange = new EventEmitter<object>();
    public showError = false;
    public booleanValue = false;
    public title = '';
    configuration: object;
    private initValue: boolean;

    constructor(
        private translateService: TranslateService,
        private confirmationPopupService: DynamicPopupService<void>,
        @Inject(ViewContainerRef) private viewContainerRef
    ) {
        super();
    }

    public emitValue() {
        this.onChange.emit(this.getValue());
    }

    public ngOnInit() {
        this.value = this.value ? this.value : {};
        this.initValue = this.value['boolean'];
        this.booleanValue = this.value['boolean'] ? this.value['boolean'] : false;
        this.emitValue();
    }

    public showPopup() {
        this.confirmationPopupService.initPopup(
            this.translateService.get(['general', 'declaration_of_consent_pop_up_title']),
            this.translateService.get(['general', 'declaration_of_consent_pop_up_text']),
            '',
            '',
            this.viewContainerRef,
            Sizes.Big
        );
    }

    public isValid(): boolean {
        return !(this.isRequired && !this.value['boolean']);
    }

    public isChanged(): boolean {
        return this.initValue != this.booleanValue;
    }

    public isEmpty(): boolean {
        return !this.value;
    }

    public getValue(): object {
        this.showError = !this.booleanValue;
        return { boolean: this.booleanValue };
    }
}
