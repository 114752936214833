import { ApplicationRecordDto } from './application-record-dto';
import { TransitionDto } from './transition-dto';

export class ApplicationTemplateDto {
    constructor(
        readonly categoryUuid: string,
        readonly applicationRecord: Array<ApplicationRecordDto>,
        readonly statusSettings: Array<{
            name: string;
            publicName: string;
            transitions: Array<TransitionDto>;
        }>,
        readonly postGrantStatusSettings: Array<{
            name: string;
            publicName: string;
            transitions: Array<TransitionDto>;
        }>,
        readonly schemas: Array<string>
    ) {}
}
