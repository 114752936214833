<div *ngIf="!readonly">
    <section class="application-economy" style="margin: 0">
        <h3>{{ uuid | translate: 'title' }}</h3>
        <div class="bank-record">
            <div class="form-item half">
                <input
                    id="application-economy-bank-regnr"
                    type="text"
                    [formControl]="registrationNumber"
                    placeholder=""
                />
                <label for="application-economy-bank-regnr">{{
                    uuid | translate: 'bank_details_registration_number'
                }}</label>
                <div class="form-focus"></div>
                <div class="form-error" [ngClass]="{ active: registrationNumber.value && !registrationNumber.valid }">
                    Udfyld dette felt med 4 cifre
                </div>
            </div>
            <div class="form-item half half-last">
                <input
                    id="application-economy-bank-account"
                    name="ec"
                    type="text"
                    [formControl]="accountNumber"
                    placeholder=""
                />
                <label for="application-economy-bank-account">{{
                    uuid | translate: 'bank_details_account_number'
                }}</label>
                <div class="form-focus"></div>
                <div class="form-error" [ngClass]="{ active: accountNumber.value && !accountNumber.valid }">
                    Udfyld dette felt med 6 - 10 cifre
                </div>
            </div>
        </div>
    </section>
</div>

<ng-container *ngIf="readonly" class="full">
    <a class="form-item">
        <p>{{ uuid | translate: 'bank_details_registration_number' }}</p>
        <span>{{ registrationNumber.value ? registrationNumber.value : '-' }}</span>
        <div class="form-focus"></div>
        <div class="form-error"></div>
    </a>
    <a class="form-item second">
        <p>{{ uuid | translate: 'bank_details_account_number' }}</p>
        <span>{{ accountNumber.value ? accountNumber.value : '-' }}</span>
        <div class="form-focus"></div>
        <div class="form-error"></div>
    </a>
</ng-container>
