<ng-container *ngIf="!readonly && !isOnSearch">
    <div class="form-item-container">
        <div class="form-item half" *ngIf="showCheckbox">
            <div class="checkbox">
                <input
                    type="checkbox"
                    placeholder=" "
                    style="width: 24px; height: 24px"
                    [ngModel]="booleanValue"
                    [readonly]="configuration && configuration.is_locked"
                    (ngModelChange)="emitValue(); changeAblility()"
                    id="application-awaiting-setting"
                    name=""
                />
                <label for="application-awaiting-setting" style="font-size: 1em">{{
                    uuid | translate: 'is_company'
                }}</label>
            </div>
        </div>
        <div class="form-item no-margin" [ngClass]="{ readonly: !booleanValue, 'half last': showCheckbox }">
            <input
                id="{{ uuid }}"
                type="text"
                name="value2"
                [formControl]="text"
                placeholder=" "
                [required]="isRequired && booleanValue"
                [maxlength]="configuration && configuration.max_length"
            />
            <label for="{{ uuid }}">
                {{ uuid | translate: 'company_name' }}
            </label>
            <div class="form-focus"></div>
            <div class="form-error" [ngClass]="{ active: text.errors?.minlength && booleanValue }">
                Indsæt mindst {{ configuration && configuration.min_length }} tegn
            </div>
            <div
                class="form-error configuration active"
                *ngIf="configuration && configuration.max_length && text && text.value"
            >
                {{ text.value.length }} / {{ configuration.max_length }}
            </div>
        </div>
    </div>
</ng-container>

<div class="form-item" [ngClass]="{ half: size === 1 }" *ngIf="!readonly && isOnSearch">
    <div class="checkbox search">
        <input
            type="checkbox"
            placeholder=" "
            style="width: 24px; height: 24px"
            [(ngModel)]="booleanValue"
            (ngModelChange)="emitValue()"
            id="{{ uuid }}2"
            name=""
        />
        <label for="{{ uuid }}2" style="font-size: 1em">{{ uuid | translate: 'title' }}</label>
    </div>
</div>

<a *ngIf="readonly && !isOnSearch && (showCheckbox || showText)" class="full readonly">
    <ul class="readonly-checkbox">
        <li class="form-item readonly-checkbox" *ngIf="showCheckbox">
            <input type="checkbox" placeholder=" " [ngModel]="booleanValue" disabled id="readonly-iscompany" name="" />
            <label for="readonly-iscompany" style="font-size: 1em">{{ uuid | translate: 'is_company' }}</label>
        </li>
        <li class="form-item" *ngIf="showText" [ngClass]="{ full: !showCheckbox }">
            <p>{{ uuid | translate: 'company_name' }}</p>
            <span>{{ text.value ? text.value : '-' }}</span>
            <div class="form-focus"></div>
            <div class="form-error"></div>
        </li>
    </ul>
</a>
