import * as pdfMake from 'pdfmake/build/pdfmake';
import { FileService } from '../services/file.service';
import { Inject, Injectable } from '@angular/core';
import { BoardMeetingAgendaDto } from '../api/dto/board-meeting/board-meeting-agenda-dto';

declare const PDFMAKEVFS: any;

export class SectionData {
    sectionName: string;
    isCategory: boolean;
    manualText: string;
    applications: Array<{
        applicationName: string;
        files: Array<string>;
    }>;
}

export interface SectionListInterface {
    sections: SectionData[];
    boardMeetingData: BoardMeetingAgendaDto;
}

@Injectable()
export class PdfListService {
    constructor(@Inject(FileService) private fileService: FileService) {
        pdfMake.vfs = PDFMAKEVFS;
    }

    public getBlobPdf(entry: SectionListInterface): Promise<string> {
        const content: Array<object> = [];

        entry.sections.forEach(section => {
            const olAr = [];

            olAr.push({
                text: section.sectionName,
                style: 'subHeader',
            });
            section.applications.forEach(application => {
                const ulAr = [];
                ulAr.push({ text: application.applicationName, style: 'subSubHeader' });
                olAr.push({
                    ul: ulAr,
                });
            });
            if (section.applications.length == 0) {
                const ulAr = [];
                ulAr.push({ text: 'Ingen ansøgninger indstillet til bestyrelsesmødet.', style: 'subSubHeader' });
                olAr.push({
                    ul: ulAr,
                });
            }

            content.push({
                type: 'none',
                ul: olAr,
            });
        });

        const pdf = {
            pageSize: 'LEGAL',
            content,
            styles: {
                body: {
                    marginLeft: 140,
                    marginRight: 140,
                    fontSize: 10,
                },
                subSubHeader: {
                    fontSize: 14,
                    marginTop: 5,
                },
                subHeader: {
                    fontSize: 16,
                    marginTop: 10,
                },
                header: {
                    fontSize: 19,
                    marginTop: 15,
                },
            },
            pageMargins: [20, 20, 20, 60],
        };

        return new Promise(resolve => {
            pdfMake.createPdf(pdf).getDataUrl((dataURL: string) => {
                resolve(dataURL);
            });
        });
    }

    public getHtmlList(entry: SectionListInterface): string {
        const result = [];

        const months = [
            'januar',
            'februar',
            'marts',
            'april',
            'maj',
            'juni',
            'juli',
            'august',
            'september',
            'oktober',
            'november',
            'december',
        ];

        const date = new Date(entry.boardMeetingData.date);

        /* eslint-disable max-len */
        result.push(`
            <li style='padding-bottom: 10px'>
                Bestyrelsen afholder sit ${entry.boardMeetingData.name}. møde ${date.getDate()}. ${
            months[date.getMonth()]
        } ${date.getFullYear()}
                <br /> <br/ > <br/ > <br/ > <span style='font-size: 13pt;'>DAGSORDEN</span>
            </li>
        `);
        /* eslint-enable max-len */

        entry.sections.forEach(section => {
            result.push(`
                <li style='width: 100%;padding:30px 0px 0px 0px;'>
                    <table style='width:100%;'>
                        <tr style='width:100%;'>
                            <td style='width:100%; text-align: left;'>${section.sectionName}</td>
                        </tr>
                    </table>
                </li>
            `);

            section.applications.forEach(application => {
                if (section.isCategory) {
                    result.push(this.getLiHtml(application.applicationName));
                }
            });

            if (section.manualText) {
                result.push(this.getLiHtml(section.manualText));
            }

            if (section.applications.length == 0) {
                result.push(this.getLiHtml('Ingen ansøgninger indstillet til bestyrelsesmødet.'));
            }
        });

        return `<ul style="list-style-type: none;width:500px;padding-left: 196px; padding-top: 178px;padding-bottom: 50px;">
                    ${result.join('')}
                </ul>`;
    }

    private getLiHtml(content: string): string {
        return `<li style='width: 100%;padding:10px 0px 0px 0px;'>
                    <table style='width:100%;'>
                            <tr style='width:100%;'>
                                <td style='width:100%; text-align: left;'>${content}</td>
                            </tr>
                    </table>
                </li>`;
    }
}
