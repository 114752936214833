import { ApplicationFiltersDto } from './application-filters-dto';
import { ColumnDto } from './column-dto';
import { OrderByDto } from './order-by-dto';

export class FiltersDto {
    constructor(
        readonly massTextSearchFilter: string,
        readonly applicationTemplateUuid: string,
        readonly filters: Array<ApplicationFiltersDto>,
        readonly columns: Array<ColumnDto>,
        readonly orderBy: Array<OrderByDto>
    ) {}
}
