export class ApplicationFiltersDto {
    constructor(
        readonly reference: string,
        readonly referenceType: string,
        readonly projectionType: string,
        readonly params: {
            readonly type: string;
            readonly values: Array<string>;
        }
    ) {}
}
