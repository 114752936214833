import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[disallowComma]',
})
export class DisallowCommaDirective {
    constructor() {}

    @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
        // Prevent comma key (ASCII code 44) from being entered
        if (event.key === ',') {
            event.preventDefault();
        }
    }

    @HostListener('input', ['$event']) onInput(event: Event) {
        // Prevent comma from being inserted via input event
        const inputElement = event.target as HTMLInputElement;
        inputElement.value = inputElement.value.replace(/,/g, '');
    }

    @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
        // Prevent comma from being pasted
        event.preventDefault();
        const clipboardData = event.clipboardData.getData('text/plain');
        const newValue = clipboardData.replace(/,/g, '');
        document.execCommand('insertText', false, newValue);
    }
}
