import { CaseworkerApplicationDto } from '../api/dto/caseworker-application-dto';
import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {
    readonly CookiesInformationKey = 'cookies-info';

    public clearLocalStorage() {
        if (this.isCookieAccepted()) {
            localStorage.clear();
            this.setCookiesAccepted();
        } else {
            localStorage.clear();
        }
    }

    public setNewApplicationInformation(caseworkerApplicationDto: CaseworkerApplicationDto) {
        localStorage.setItem('basicApplicationInformation', JSON.stringify(caseworkerApplicationDto));
    }

    public isApplicationInformation(): boolean {
        return !!localStorage.getItem('basicApplicationInformation');
    }

    public getApplicationInformation(): CaseworkerApplicationDto {
        return JSON.parse(localStorage.getItem('basicApplicationInformation'));
    }

    public setNewCurrentSection(applicationUuid: string, currentSectionId: number) {
        localStorage.setItem(applicationUuid, currentSectionId.toString());
    }

    public getCurrentSection(applicationUuid: string): number {
        return localStorage.getItem(applicationUuid) ? Number(localStorage.getItem(applicationUuid)) : 0;
    }

    public isCookieAccepted(): boolean {
        return !!localStorage.getItem(this.CookiesInformationKey);
    }

    public setCookiesAccepted() {
        localStorage.setItem(this.CookiesInformationKey, 'hidden');
    }
}
