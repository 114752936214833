import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

export type BadgeTypes = 'fonda' | 'success' | 'error';

@Component({
    selector: 'fonda-badge',
    templateUrl: './badge.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent {
    @Input() type: BadgeTypes = 'error';
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('class') className = '';

    @HostBinding('class')
    get classType() {
        return `badge badge--${this.type} ${this.className}`;
    }
}
