<form class="payment-container" *ngIf="!readonly && !isOnSearch">
    <div>
        <h3>
            {{ uuid | translate: 'title' }}
        </h3>
        <div>
            <button class="button color-blue smaller" (click)="openPaymentProfile()">
                {{ 'payment_profile' | translate: 'manage_payment_profiles' }}
            </button>
            <button
                class="button color-blue smaller"
                [disableOnApiCalls]="false"
                *ngIf="!canSave()"
                [disabled]="canSave()"
                (click)="save()"
            >
                {{ uuid | translate: 'save' }}
            </button>
            <button
                class="button color-blue smaller"
                [disableOnApiCalls]="false"
                *ngIf="canSave()"
                [disabled]="canSave()"
                (click)="save()"
            >
                {{ uuid | translate: 'saved' }}
            </button>
            <button class="button color-green smaller add" (click)="addNewPayment()">+</button>
        </div>
    </div>

    <table *ngIf="payments && payments.length > 0" class="payment">
        <tr>
            <th>{{ uuid | translate: 'description' }}</th>
            <th>{{ uuid | translate: 'date' }}</th>
            <th>{{ uuid | translate: 'amount' }}</th>
            <th></th>
            <th></th>
        </tr>
        <ng-container *ngFor="let payment of payments; let ind = index">
            <tr
                payment-row
                [uuid]="uuid"
                [paymentProfiles]="paymentProfiles"
                [rowEntry]="payment"
                [ind]="ind"
                #paymentRows
                (onChange)="changed = true; handleRowChange()"
                (onDelete)="deleteRow(payment); changed = true"
            ></tr>
        </ng-container>
        <tr class="results-row">
            <td colspan="5">
                <div class>
                    <span>{{ uuid | translate: 'total' }}: {{ total | commasInInput }}kr.</span>
                    <span>{{ uuid | translate: 'balance' }}: {{ balance | commasInInput }}kr.</span>
                    <span>{{ uuid | translate: 'due_balance' }}: {{ getDueBalance() | commasInInput }}kr.</span>
                </div>
            </td>
        </tr>
    </table>
    <div *ngIf="!payments || payments.length == 0">
        {{ uuid | translate: 'no_payments' }}
    </div>
</form>

<ng-container *ngIf="!readonly && isOnSearch">
    <div style="display: flex; position: relative">
        <div class="form-item between even first">
            <input
                id="{{ uuid }}-float2"
                type="text"
                step="0.01"
                name="value2"
                OnlyNumber="true"
                [ngModel]="float1 | commasInInput"
                (ngModelChange)="float1 = $event; emitValue()"
                placeholder=" "
                [required]="isRequired"
            />
            <label for="{{ uuid }}-float2">{{ title }} from</label>
            <div class="form-focus"></div>
        </div>
        <div class="form-item between even second">
            <input
                id="{{ uuid }}-float3"
                type="text"
                step="0.01"
                name="value2"
                OnlyNumber="true"
                [ngModel]="float2 | commasInInput"
                (ngModelChange)="float2 = $event; emitValue()"
                placeholder=" "
                [required]="isRequired"
            />
            <label for="{{ uuid }}-float3">{{ title }} to</label>
            <div class="form-focus"></div>
        </div>
    </div>
</ng-container>
<form class="payment-container" *ngIf="readonly">
    <div>
        <h3>
            {{ uuid | translate: 'title' }}
        </h3>
        <div>
            <button class="button color-blue smaller" (click)="openPaymentProfile()">
                {{ 'payment_profile' | translate: 'manage_payment_profiles' }}
            </button>
        </div>
    </div>
    <div *ngIf="!payments || payments.length == 0">
        {{ uuid | translate: 'no_payments' }}
    </div>
    <table *ngIf="payments && payments.length > 0" class="view-only payment">
        <tr>
            <th>{{ uuid | translate: 'description' }}</th>
            <th>{{ uuid | translate: 'date' }}</th>
            <th>{{ uuid | translate: 'amount' }}</th>
            <th>Type</th>
        </tr>
        <ng-container *ngFor="let payment of payments; let ind = index">
            <tr>
                <td>{{ payment.title }}</td>
                <td>{{ payment.date | date: 'dd-MM-yyyy' }}</td>
                <td>{{ payment.amount | commasInInput }}</td>
                <td>{{ uuid | translate: payment.type }}</td>
                <td>{{ getPaymentByUuid(payment.paymentProfileUuid)?.name }}</td>
            </tr>
        </ng-container>
        <tr class="results-row">
            <td colspan="5">
                <div class>
                    <span>{{ uuid | translate: 'balance' }}: {{ balance | commasInInput }}kr.</span>
                    <span>{{ uuid | translate: 'total' }}: {{ total | commasInInput }}kr.</span>
                    <span>{{ uuid | translate: 'due_balance' }}: {{ getDueBalance() | commasInInput }}kr.</span>
                </div>
            </td>
        </tr>
    </table>
</form>
