import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { UserChatWidgetService } from './user-chat-widget.service';

@Injectable()
export class UserChatWidgetHideGuard implements CanActivate, CanActivateChild {
    constructor(private userChatWidgetService: UserChatWidgetService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        this.userChatWidgetService.hide();
        return true;
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        this.userChatWidgetService.hide();
        return true;
    }
}
