import { CorrespondenceThreadDto } from '../dto/correspondence-dto/correspondence-thread-dto';
import { CorrespondenceMessageDto } from '../dto/correspondence-dto/correspondence-message-dto';
import { BackendUserData } from '../dto/backend-user-data';
import { UploadedFondaFile } from '../../models/uploaded-fonda-file';
import { CorrespondenceMessageAttachmentDto } from '../dto/correspondence-dto/correspondence-message-attachment-dto';

export class CorrespondenceMapper {
    public static GetCorrespondenceThreads(jsonDataObject: any): Array<CorrespondenceThreadDto> {
        const correspondenceThreats: Array<CorrespondenceThreadDto> = [];
        jsonDataObject.forEach(threatTmp => {
            const messagesTmp: Array<CorrespondenceMessageDto> = [];
            threatTmp.messages.forEach(message => {
                const sender = new BackendUserData(
                    message.sender.initials,
                    message.sender.type,
                    message.sender.username,
                    message.sender.uuid
                );

                const attachments: Array<CorrespondenceMessageAttachmentDto> = [];
                message.attachments.forEach(attachment => {
                    attachments.push(
                        new CorrespondenceMessageAttachmentDto(
                            attachment.uuid,
                            attachment.file_size,
                            attachment.file_name
                        )
                    );
                });

                messagesTmp.push(
                    new CorrespondenceMessageDto(message.uuid, sender, message.sent_at, message.body, attachments)
                );
            });

            const creator = new BackendUserData(
                threatTmp.creator.initials,
                threatTmp.creator.type,
                threatTmp.creator.username,
                threatTmp.creator.uuid
            );

            correspondenceThreats.push(
                new CorrespondenceThreadDto(
                    threatTmp.uuid,
                    threatTmp.application_uuid,
                    creator,
                    threatTmp.created_at,
                    threatTmp.locked,
                    threatTmp.title,
                    messagesTmp,
                    threatTmp.read,
                    threatTmp.read_by_applicant
                )
            );
        });

        return correspondenceThreats;
    }

    public static GetInternalMessages(jsonDataObject: any): Array<CorrespondenceMessageDto> {
        const messagesTmp: Array<CorrespondenceMessageDto> = [];

        if (jsonDataObject) {
            jsonDataObject.forEach(message => {
                const sender = new BackendUserData(
                    message.sender_profile.initials,
                    message.sender_profile.type,
                    message.sender_profile.username,
                    message.sender_profile.uuid
                );

                const attachments: Array<CorrespondenceMessageAttachmentDto> = [];
                message.attachments.forEach(attachment => {
                    attachments.push(
                        new CorrespondenceMessageAttachmentDto(
                            attachment.uuid,
                            attachment.file_size,
                            attachment.file_name
                        )
                    );
                });

                messagesTmp.push(
                    new CorrespondenceMessageDto(message.uuid, sender, message.sent_at, message.message, attachments)
                );
            });
        }

        return messagesTmp;
    }
}
