import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';

export enum Sizes {
    Small,
    Medium,
    Big,
}

@Component({
    selector: 'confirmation-popup',
    templateUrl: 'confirmation-popup.component.html',
})
export class ConfirmationPopupComponent<T> {
    @Output() onApprove = new EventEmitter<T>();
    @Output() onClickCancel = new EventEmitter();

    @ViewChild('container', { static: true }) container: ElementRef;

    actualSize = Sizes.Small;

    savingPopup = false;
    header: string;
    body: string;
    approveButton: string;
    cancelButton: string;
    show = false;

    private additionalData: T;

    isBig(): boolean {
        return this.actualSize === Sizes.Big;
    }

    isMedium(): boolean {
        return this.actualSize === Sizes.Medium;
    }

    isSmall(): boolean {
        return this.actualSize === Sizes.Small;
    }

    public initPopup(
        header: string,
        body: string,
        approveButton: string,
        cancelButton: string,
        data?: T,
        savingPopup?: boolean
    ) {
        this.show = true;
        this.header = header;
        this.body = body;
        this.approveButton = approveButton;
        this.cancelButton = cancelButton;
        this.additionalData = data;
        this.savingPopup = savingPopup;
    }

    public onCancel() {
        this.show = false;
        this.additionalData = null;
        this.onClickCancel.emit();
    }

    public onApproveClick() {
        this.show = false;
        this.onApprove.emit(this.additionalData);
        this.additionalData = null;
    }

    public onContainerClick(target: HTMLElement) {
        // if the target is the mask then close popup
        if (target === this.container.nativeElement) {
            this.onCancel();
        }
    }
}
