<ul class="list">
    <li *ngFor="let tab of tabs; let i = index" (click)="changeCurrentTabIndex(i)" class="list-element">
        <div class="list-element__text">
            <ng-container *ngIf="tab.header; else noHeaderTemplate">
                <ng-container *ngTemplateOutlet="tab.header.template"></ng-container>
            </ng-container>
            <ng-template #noHeaderTemplate>{{ tab.name }}</ng-template>
        </div>
        <div class="list-element__ripple" [class.list-element__ripple--active]="currentTabIndex === i"></div>
    </li>
</ul>
<div class="tab-body-container" [@tabsTransition]="transition">
    <ng-container *ngTemplateOutlet="selectedTabTemplate"></ng-container>
</div>
