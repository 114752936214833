import { Injectable, Optional } from '@angular/core';
import { NgxSlackLogger } from '@linkfactory/ngx-slack';

@Injectable({
    providedIn: 'root',
})
export class FondaLoggerService {
    constructor(@Optional() private slackLogger: NgxSlackLogger) {}

    log(...args: any[]): void {
        if (this.slackLogger) {
            this.slackLogger.log(...args);
        }

        console.log(...args);
    }

    error(...args: any[]): void {
        if (this.slackLogger) {
            this.slackLogger.error(...args);
        }

        console.error(...args);
    }
}
