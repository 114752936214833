import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { OperatorFunction } from 'rxjs/internal/types';
import { filter, take } from 'rxjs/operators';
import { PACKAGE_MANAGER_API_KEY } from '../package-manager-api-key';

export interface Packages {
    readonly isApplicationSchemaEditor: boolean;
    readonly isApplicantModuleImpersonator: boolean;
    readonly isApplicantModuleCreateApplicant: boolean;
    readonly isUserDotComChatBox: boolean;
}

type Nullable<T> = T | null;
type NotNullable<T> = T extends null ? never : T;

const notNull = <X>(): OperatorFunction<X, NotNullable<X>> => {
    const isNotNull = (value: X): value is NotNullable<X> => value !== null;
    return source => source.pipe(filter(isNotNull));
};

const ALL_DISABLED_PACKAGES: Packages = {
    isUserDotComChatBox: false,
    isApplicantModuleCreateApplicant: false,
    isApplicantModuleImpersonator: false,
    isApplicationSchemaEditor: false,
};

@Injectable({
    providedIn: 'root',
})
export class PackageManagerService {
    public static readonly URL: string = 'https://package-manager.grantcontrol.net/site-package';

    private readonly _packages$: BehaviorSubject<Nullable<Packages>> = new BehaviorSubject<Packages>(null);

    constructor(@Inject(PACKAGE_MANAGER_API_KEY) private readonly packageManagerApiKey: string, http: HttpClient) {
        http.get<Packages>(PackageManagerService.URL, { headers: { 'X-API-KEY': packageManagerApiKey } }).subscribe({
            next: res => this._packages$.next(res),
            error: err => {
                this._packages$.next(ALL_DISABLED_PACKAGES);
                console.error(err);
            },
        });
    }

    packages(): Nullable<Packages> {
        return this._packages$.getValue();
    }

    packages$(): Observable<Packages> {
        return this._packages$.pipe(notNull());
    }

    whenReady(): Observable<Packages> {
        return this.packages$().pipe(take(1));
    }
}
