<div *ngIf="!readonly && !onSearch" class="form-item date" [ngClass]="{ half: size === 1 }">
    <input
        id="{{ uuid }}"
        name="value1"
        placeholder=" "
        type="text"
        [formControl]="dateValue"
        (ngModelChange)="formatInputToDatePicker($event); emitValue()"
        [required]="isRequired"
    />
    <label for="{{ uuid }}">{{ uuid | translate: 'title' }}</label>
    <div class="form-focus"></div>
    <div class="form-error" *ngIf="!dateValue.value" [ngClass]="{ active: dateValue.hasError('isFieldNotFilled') }">
        <!--Udfyld dette felt-->
    </div>
    <div
        class="form-error"
        *ngIf="dateValue.value"
        [ngClass]="{ active: dateValue.hasError('isValidDate') && !dateValue.hasError('isFieldNotFilled') }"
    >
        Please correct format to dd-mm-yyyy
    </div>
    <div class="only-when-not-in-search">
        <fonda-basic-datepicker
            [date]="dateInput"
            (dateChange)="dateInput = $event; formatDatePickerToInput($event)"
            [options]="options"
        ></fonda-basic-datepicker>
    </div>
</div>

<ng-container *ngIf="!readonly && onSearch">
    <div style="display: flex; position: relative">
        <div class="form-item between first" [ngClass]="{ half: size === 1 }">
            <input
                id="{{ uuid }}2"
                name="value1"
                placeholder=" "
                type="text"
                [formControl]="dateValueFrom"
                [required]="isRequired"
            />
            <label for="{{ uuid }}2">{{ title }} from</label>
            <div class="form-focus"></div>
        </div>
        <div class="form-item between second" [ngClass]="{ half: size === 1 }">
            <input
                id="{{ uuid }}3"
                name="value1"
                placeholder=" "
                type="text"
                [formControl]="dateValueTo"
                [required]="isRequired"
            />
            <label for="{{ uuid }}3">{{ title }} to</label>
            <div class="form-focus"></div>
        </div>
        <fonda-datepicker [dates]="dateInput" (onSetDates)="handleDates($event)"></fonda-datepicker>
    </div>
</ng-container>

<a class="form-item" *ngIf="readonly" [ngClass]="{ full: size === 2 }">
    <p>{{ uuid | translate: 'title' }}</p>
    <span>{{ initValue ? initValue : '-' }}</span>
    <div class="form-focus"></div>
    <div class="form-error"></div>
</a>
