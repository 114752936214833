import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'fonda-toggle',
    templateUrl: './toggle.component.html',
    styleUrls: ['./toggle.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ToggleComponent), multi: true }],
})
export class ToggleComponent implements ControlValueAccessor {
    @Input() disabled: boolean = false;
    @Input() value: boolean = false;
    @Output() valueChange = new EventEmitter();

    private onChange: (v: boolean) => void = () => {};
    private onTouched: () => void = () => {};

    constructor(private readonly cdRef: ChangeDetectorRef) {}

    writeValue(obj: any): void {
        this.modelToViewUpdate(!!obj);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    toggle() {
        this.onTouched();
        this.viewToModelUpdate(!this.value);
    }

    focused() {
        this.onTouched();
    }

    private modelToViewUpdate(value: boolean) {
        this.value = value;
        this.cdRef.markForCheck();
    }

    private viewToModelUpdate(value: boolean) {
        this.value = value;
        this.valueChange.emit(value);
        this.onChange(value);
    }
}
