import {
    AllMoneyValue,
    ResultApplication,
    ResultApplicationValue,
    ResultsInterface,
} from '../../../shared/search-utils/search-results/results-interface';
import { SearchResponseDto } from '../../../api/dto/search-dto/search-response-dto';
import { SearchOrderBy } from './search-objects';
import { RecordsInterface } from '../components/search-filters/records-interface';
import { RecordsStore } from '../../../services/records-store';
import { SearchResponseInterface } from '../../../models/search-response-interface';
import { SearchResponseAdapter } from '../../../adapters/search-response-adapter';
import { TranslateService } from '../../../shared/translation/translate.service';

export class ResultAdapter implements ResultsInterface {
    public allMoneyValues: Array<AllMoneyValue> = [];
    public applications: Array<ResultApplication>;

    constructor(
        private searchResponseDto: SearchResponseDto,
        private translateService: TranslateService,
        private _orderBy: Array<SearchOrderBy>,
        private filterEntry: Array<RecordsInterface>
    ) {
        this.applications = this.initApplication();
    }

    public get orderBy(): Array<SearchOrderBy> {
        return this._orderBy;
    }

    private initApplication(): Array<ResultApplication> {
        const applications: Array<ResultApplication> = [];
        let values: Array<ResultApplicationValue>;
        this.searchResponseDto.application.forEach(application => {
            values = [];
            application.values.forEach(value => {
                if (this.isItMoneyRecord(value.key)) {
                    this.addMoneyAmountValue(value.key, value.value);
                }
                const response: SearchResponseInterface = new SearchResponseAdapter(
                    this.translateService,
                    value.key,
                    value.value,
                    this.filterEntry
                );
                values.push({
                    uuid: response.uuid,
                    title: response.key,
                    value: response.value,
                    orderAble: true,
                });
            });
            applications.push({
                applicationUuid: application.applicationUuid,
                applicationValues: values,
                lockedByOtherCaseworker: application.lockedByOtherCaseworker,
            });
        });
        return applications;
    }

    private addMoneyAmountValue(uuid: string, _value: string) {
        const value = Number(_value);
        if (!this.allMoneyValues) {
            this.allMoneyValues = [];
        }
        if (this.allMoneyValues.find(_val => _val.uuid === uuid)) {
            this.allMoneyValues.find(_val => _val.uuid === uuid).value += value;
        } else {
            const allMoneyValue = new AllMoneyValue();
            allMoneyValue.uuid = uuid;
            allMoneyValue.value = value;
            this.allMoneyValues.push(allMoneyValue);
        }
    }

    private isItMoneyRecord(uuid: string): boolean {
        const moneyRecords = RecordsStore.MoneyValuesRecords;
        const result = false;
        // TODO: FIX LATER
        return result;
    }
}
