import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FondaApiService } from '../../../../api/fonda-api.service';
import { BaseRecordComponent } from '../../base-record.component';
import { BoardMeetingData } from './board-meeting-data';

@Component({
    selector: 'board-meeting-record',
    templateUrl: 'board-meeting-record.component.html',
})
export class BoardMeetingRecordComponent extends BaseRecordComponent implements OnInit {
    @Input() value: Array<{ uuid: string }> | { uuid: string } | null;
    @Input() uuid: string;
    @Input() title: string;
    @Input() isRequired: boolean;
    @Input() size: number;
    @Input() readonly = false;
    @Input() onSearch = false;
    @Input() configuration: object;
    @Output() onChange = new EventEmitter<object>();

    private initialSelectedBoardMeetingUuid: string[] | string | null = null;
    public selectedBoardMeetingUuids: null | string | string[] = null;
    public boardMeetings: Array<BoardMeetingData> = [];
    public boardMeetingUuids: string[] = [];
    public previousBoardMeetingUuid: string | null = null;
    public nextBoardMeetingUuid: string | null = null;

    constructor(private apiService: FondaApiService, private _eref: ElementRef) {
        super();
    }

    get selectedName(): string {
        if (Array.isArray(this.selectedBoardMeetingUuids)) {
            return this.selectedBoardMeetingUuids.map(uuid => this.displayName(uuid)).join(', ');
        }

        return this.displayName(this.selectedBoardMeetingUuids);
    }

    public emitValue() {
        this.onChange.emit(this.getValue());
    }

    public async ngOnInit() {
        if (this.value) {
            this.initialSelectedBoardMeetingUuid = Array.isArray(this.value)
                ? this.value.map(v => v.uuid)
                : this.value.uuid || null;
            this.selectedBoardMeetingUuids = this.initialSelectedBoardMeetingUuid;
        }
        await this.apiService.getBoardMeetings().then(res => {
            this.boardMeetings = res.map(BoardMeetingData.fromDto);
            this.boardMeetingUuids = this.boardMeetings.map(bm => bm.uuid);
            this.calculateNextAndPreviousBoardMeeting(this.boardMeetings);
        });

        this.emitValue();
    }

    public reset() {
        this.chooseBoardMeeting(null);
    }

    public chooseBoardMeeting(uuid: string | null | string[]) {
        this.selectedBoardMeetingUuids = uuid;
        this.emitValue();
    }

    public isValid(): boolean {
        return !(this.isRequired && !this.value['uuid']);
    }

    public isChanged(): boolean {
        if (Array.isArray(this.selectedBoardMeetingUuids) && !Array.isArray(this.initialSelectedBoardMeetingUuid))
            return true;
        if (Array.isArray(this.selectedBoardMeetingUuids)) {
            return this.selectedBoardMeetingUuids.some(uuid => !this.initialSelectedBoardMeetingUuid.includes(uuid));
        } else {
            return this.selectedBoardMeetingUuids !== this.initialSelectedBoardMeetingUuid;
        }
    }

    public isEmpty(): boolean {
        return !this.value;
    }

    public getValue(): object {
        if (Array.isArray(this.selectedBoardMeetingUuids)) {
            return this.selectedBoardMeetingUuids.map(uuid => ({ uuid }));
        }

        return { uuid: this.selectedBoardMeetingUuids };
    }

    public displayName(uuid: string): string {
        const bm = this.findBoardMeetingByUuid(uuid);
        if (!bm) return '';
        return `${bm.name} - ${this.formatDate(bm.date)}`;
    }

    public formatDate(date: string): string {
        const leadingZero = (n: number) => (n > 0 && n < 10 ? '0' : '') + n.toString(10);
        const d = new Date(date);
        return [d.getFullYear(), d.getMonth() + 1, d.getDate()].map(leadingZero).join('-');
    }

    private calculateNextAndPreviousBoardMeeting(boardMeetings: BoardMeetingData[]): void {
        const now = Date.now();
        const getTime = (d: string) => new Date(d).getTime();

        const nextBoardMeeting = boardMeetings.filter(b => getTime(b.date) > now);
        if (nextBoardMeeting.length) this.nextBoardMeetingUuid = nextBoardMeeting[nextBoardMeeting.length - 1].uuid;

        const previousBoardMeeting = boardMeetings.filter(b => getTime(b.date) < now);
        if (previousBoardMeeting.length) this.previousBoardMeetingUuid = previousBoardMeeting[0].uuid;
    }

    private findBoardMeetingByUuid(uuid: string): BoardMeetingData | undefined {
        return this.boardMeetings.find(b => b.uuid === uuid);
    }
}
