import { Input, Output, EventEmitter, Directive } from '@angular/core';
import { RecordComponentInterface } from './record-component-interface';

@Directive()
export abstract class BaseRecordComponent implements RecordComponentInterface {
    @Input('uuid') public abstract uuid: string;
    @Input('value') public abstract value: object | Array<any>;
    @Input('isRequired') public abstract isRequired: boolean;
    @Input('size') public size: number;
    @Input('title') public abstract title: string;
    @Input('configuration') public abstract configuration?: object;
    @Input('readonly') public abstract readonly: boolean;
    @Input('onSearch') public onSearch?: boolean;
    @Output('onChange') public abstract onChange: EventEmitter<object>;
    public abstract isChanged(): boolean;
    public abstract isValid(): boolean;
    public abstract isEmpty(): boolean;
    public abstract getValue(): object;
    constructor() {}
}
