import { Directive, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[href]',
})
export class HrefDirective {
    @Input() href: string;

    @HostListener('click', ['$event'])
    openInNewTab(event: MouseEvent) {
        event.preventDefault();
        window.open(this.href, '_blank');
    }
}
