import { Component, Input, TemplateRef, ViewChild, ContentChild } from '@angular/core';
import { SlideTabHeaderDirective } from './slide-tab-header.directive';

@Component({
    selector: 'slide-tab',
    template: `
        <ng-template #template>
            <ng-content></ng-content>
        </ng-template>
    `,
})
export class SlideTabComponent {
    @Input() name: string;
    @ViewChild('template', { read: TemplateRef, static: true }) template: TemplateRef<any>;
    @ContentChild(SlideTabHeaderDirective) header: SlideTabHeaderDirective;
}
