import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { BaseRecordComponent } from '../../base-record.component';

@Component({
    selector: 'big-text-record',
    templateUrl: 'big-text-record.component.html',
})
export class BigTextRecordComponent extends BaseRecordComponent implements OnInit {
    @Input('readonly') public set readonly(readonly: boolean) {
        this._readonly = readonly;
        this.ngOnInit();
    }
    public get readonly(): boolean {
        return this._readonly;
    }
    @Input('uuid') public uuid: string;
    @Input('value') public value: object;
    @Input('isRequired') public isRequired: boolean;
    @Input('size') public size: number;
    @Input('title') title: string;
    @Input('onSearch') onSearch = false;
    @Input('configuration') configuration: object;

    @Output('onChange') onChange = new EventEmitter<object>();

    private _readonly;
    private initValue: string;
    public text = new UntypedFormControl();
    public emitValue() {
        this.onChange.emit(this.getValue());
    }
    public ngOnInit() {
        let newValue: string;
        if (this.readonly && this.value['text']) {
            newValue = this.value['text'].replace(/(?:\\r\\n|\\r|\\n)/g, '<br>');
            this.initValue = newValue;
            this.text.setValue(newValue, { emitEvent: true });
        } else {
            newValue = this.value['text'];
            this.initValue = newValue;
            this.text.setValue(newValue, { emitEvent: true });
            this.text.valueChanges.subscribe(() => {
                this.emitValue();
            });
            this.emitValue();
        }
        if (this.isRequired) {
            this.text.setValidators(Validators.required);
        }
    }

    public isValid(): boolean {
        return !(this.isRequired && !this.value['text']);
    }

    public isChanged(): boolean {
        return this.initValue != this.text.value;
    }

    public isEmpty(): boolean {
        return !this.value;
    }

    public getValue(): object {
        this.value['text'] = this.text.value;
        return this.value;
    }

    getReadonlyValue() {
        const text = this.text && this.text.value;
        return text && typeof text === 'string' && text.replace(/\n/g, '<br />');
    }
}
