import { Component, Input, Output, EventEmitter, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { BaseRecordComponent } from '../../base-record.component';
import { FileUploadComponent } from '../../../basic-form-controls/file-upload/file-upload.component';
import { UploadedFondaFile } from '../../../../models/uploaded-fonda-file';
import { FileService } from '../../../../services/file.service';

@Component({
    selector: 'upload-record',
    templateUrl: 'upload-record.component.html',
})
export class UploadRecordComponent extends BaseRecordComponent implements OnInit {
    constructor(private fileService: FileService) {
        super();
    }
    @Input('uuid') public uuid: string;
    @Input('value') public value: object;
    @Input('isRequired') public isRequired: boolean;
    @Input('size') public size: number;

    @Input('readonly') public readonly = false;
    @Input('title') title: string;
    @Output('onChange') onChange = new EventEmitter<object>();
    private initValue: object;
    files: Array<UploadedFondaFile> = [];
    configuration: object;

    @ViewChild('fileUploadComponent') fileUpload: FileUploadComponent;

    public emitValue() {
        this.onChange.emit(this.getValue());
    }

    public ngOnInit() {
        this.value = this.value ? { files: this.value } : {};

        this.value['files'].forEach(file => {
            this.files.push(new UploadedFondaFile(file.uuid, file.file_name, '', file.file_size));
        });

        this.initValue = this.getValue();
        this.emitValue();
    }

    public download(file: UploadedFondaFile) {
        this.fileService.downloadFile(file.uuid).then(res => {
            this.fileService.downloadFileToOwnMachine(res);
        });
    }

    public isValid(): boolean {
        return true;
    }

    public isChanged(): boolean {
        return JSON.stringify(this.getValue()) !== JSON.stringify(this.initValue);
    }
    public isEmpty(): boolean {
        return !this.value;
    }
    public getValue(): object {
        this.value = {};
        const tmpArray: Array<{
            uuid: string;
            file_name: string;
            file_size: number;
        }> = [];
        this.files.forEach(file => {
            const tmpFile = {
                uuid: file.uuid,
                file_name: file.fileName,
                file_size: file.size,
            };
            tmpArray.push(tmpFile);
        });

        return tmpArray;
    }

    public addNewFile(uploadedNcfFile: UploadedFondaFile) {
        this.files.push(uploadedNcfFile);
        this.emitValue();
    }

    public deleteFile(uuid: string) {
        this.files.splice(this.files.map(file => file.uuid).indexOf(uuid), 1);
        this.emitValue();
    }
}
