export class ArtworksDto {
    constructor(
        readonly author: string,
        readonly title: string,
        readonly originYear: number,
        readonly photo: string,
        readonly material: string,
        readonly artworkId: string,
        readonly location: string
    ) {}
}
