export class CorrespondencePopupModel {
    constructor(
        readonly uuid: string,
        readonly refUuid: string,
        readonly name: string,
        readonly subject: string,
        readonly body: string,
        readonly pdfBody: string,
        readonly parentUuid: string,
        readonly statusTransition: string,
        readonly staticTokens: Array<{
            key: string;
            value: string;
        }>,
        readonly files: Array<string>,
        readonly pdfGeneratorRecordUuid: string
    ) {}
}
