import { Component, Input, Output, EventEmitter, HostListener, ElementRef } from '@angular/core';
import { IMyDrpOptions } from 'mydaterangepicker';
import { IMyDpOptions } from 'mydatepicker';

@Component({
    selector: 'fonda-datepicker',
    templateUrl: 'fonda-datepicker.component.html',
})
export class FondaDatepickerComponent {
    constructor(private _eref: ElementRef) {}

    @Input() set dates(dates: { dateFrom: Date; dateTo: Date }) {
        if (dates && dates.dateFrom && dates.dateTo) {
            const startDate = new Date(dates.dateFrom);
            const endDate = new Date(dates.dateTo);
            this.model = {
                beginDate: { year: startDate.getFullYear(), month: startDate.getMonth() + 1, day: startDate.getDate() },
                endDate: { year: endDate.getFullYear(), month: endDate.getMonth() + 1, day: endDate.getDate() },
            };

            this.model2 = {
                date: { year: startDate.getFullYear(), month: startDate.getMonth() + 1, day: startDate.getDate() },
            };
            this.onSetDates.emit(dates);
        }
    }

    readonly SPECIFIED_DATE_VIEW = 1;
    readonly FROM_TO_DATE_VIEW = 2;

    public active = false;
    public actualView: number = this.SPECIFIED_DATE_VIEW;

    @Input() onlySpecifiedDate = false;

    @Output() onSetDates = new EventEmitter<{
        dateFrom: Date;
        dateTo: Date;
    }>();

    myDateRangePickerOptions: IMyDrpOptions = {
        dateFormat: 'dd.mm.yyyy',
        inline: true,
        dayLabels: { su: 'SØN', mo: 'MAN', tu: 'TIR', we: 'ONS', th: 'TOR', fr: 'FRE', sa: 'LØR' },
        monthLabels: {
            1: 'Jan',
            2: 'Feb',
            3: 'Mar',
            4: 'Apr',
            5: 'Maj',
            6: 'Juni',
            7: 'Juli',
            8: 'Aug',
            9: 'Sep',
            10: 'Okt',
            11: 'Nov',
            12: 'Dec',
        },
    };

    public myDatePickerOptions: IMyDpOptions = {
        dateFormat: 'dd.mm.yyyy',
        inline: true,
        dayLabels: { su: 'SØN', mo: 'MAN', tu: 'TIR', we: 'ONS', th: 'TOR', fr: 'FRE', sa: 'LØR' },
        monthLabels: {
            1: 'Jan',
            2: 'Feb',
            3: 'Mar',
            4: 'Apr',
            5: 'Maj',
            6: 'Juni',
            7: 'Juli',
            8: 'Aug',
            9: 'Sep',
            10: 'Okt',
            11: 'Nov',
            12: 'Dec',
        },
    };

    public model2;

    public model;

    public reset() {
        this.model = null;
        this.model2 = null;
        this.onSetDates.emit({ dateFrom: null, dateTo: null });
    }

    public clickChangeToSpecified() {
        this.actualView = this.SPECIFIED_DATE_VIEW;
    }
    public setDates(obj: any) {
        this.onSetDates.emit({
            dateFrom: this.getDate(obj.beginDate),
            dateTo: this.getDate(obj.endDate),
        });
        this.active = false;
        this.model = obj;
    }

    public setSingleDate(obj: any) {
        this.onSetDates.emit({
            dateFrom: this.getDate(obj.date),
            dateTo: this.getDate(obj.date),
        });
        this.model2 = obj;
        this.active = false;
    }

    public clickChangeToFromTo() {
        this.actualView = this.FROM_TO_DATE_VIEW;
    }

    public isOnSpecifiedView() {
        return this.actualView === this.SPECIFIED_DATE_VIEW;
    }

    public isOnFromTo() {
        return this.actualView === this.FROM_TO_DATE_VIEW;
    }

    @HostListener('document:click', ['$event']) clickedOutside($event) {
        if (!this._eref.nativeElement.contains($event.target)) {
            // or some similar check
            this.active = false;
        }
    }

    private getDate(date: { year: number; day: number; month: number }): Date {
        return new Date(Date.UTC(date.year, date.month - 1, date.day));
    }
}
