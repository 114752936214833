<form [formGroup]="form">
    <slide-tabs>
        <slide-tab *ngFor="let language of languages" [name]="language.abbr | uppercase">
            <fonda-input *ngFor="let describer of translations" [formGroupName]="language.name">
                <fonda-label>
                    {{ describer.label }}
                </fonda-label>
                <input
                    *ngIf="!describer.long; else textareaInput"
                    fonda-input
                    [formControlName]="describer.name"
                    (blur)="_onTouched()"
                />
                <ng-template #textareaInput>
                    <textarea [formControlName]="describer.name" placeholder=" " (blur)="_onTouched()"></textarea>
                </ng-template>
            </fonda-input>
        </slide-tab>
    </slide-tabs>
</form>
