export class TextDto {
    constructor(
        readonly uuid: string,
        readonly type: string,
        readonly language: string,
        readonly identifier: string,
        readonly subIdentifier: string,
        readonly subSubIdentifier: string,
        readonly text: string,
        readonly referenceDetails: {
            refUuid: string;
            type: string;
            section?: number;
            sequence?: number;
            recordType?: string;
        }
    ) {}
}
