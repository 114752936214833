import { PaymentProfileDto } from '../../../../api/dto/payment-profile-dto/payment-profile-dto';

export class PaymentTypes {
    public static GetTypes(): Array<{ value: string; title: string }> {
        return [
            { value: 'paid', title: 'paid' },
            { value: 'canceled', title: 'canceled' },
            { value: 'refunded', title: 'refunded' },
        ];
    }

    static get Paid(): string {
        return 'paid';
    }
    static get Canceled(): string {
        return 'canceled';
    }
    static get Refunded(): string {
        return 'refunded';
    }
}

export class PaymentObject {
    constructor(
        readonly date: Date,
        readonly title: string,
        readonly amount: number,
        readonly type: string,
        readonly paymentProfileUuid: string
    ) {}
}
