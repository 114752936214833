import { Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { FondaApiGlobalVariables } from '../../../api/fonda-api-global-variables';
import { asapScheduler, Subscription } from 'rxjs';
import { filter, observeOn } from 'rxjs/operators';

@Directive({
    selector: 'button',
})
export class ButtonDisablerDirective implements OnInit, OnChanges, OnDestroy {
    @Input() disableOnApiCalls = true;
    @Input() neverDisable = false;
    @Input() disabled = false;

    private apiDisable = false;
    private subs = Subscription.EMPTY;

    constructor(
        private variables: FondaApiGlobalVariables,
        private elementRef: ElementRef,
        private renderer2: Renderer2
    ) {}

    ngOnInit(): void {
        this.updateDisabledAttr();
        this.subs = this.variables.isRightNowSomethingInProcess
            .pipe(
                filter<boolean>(() => !this.neverDisable && this.disableOnApiCalls),
                observeOn(asapScheduler)
            )
            .subscribe(value => {
                this.apiDisable = value;
                this.updateDisabledAttr();
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.disabled) {
            this.updateDisabledAttr();
        }
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    private updateDisabledAttr() {
        this.renderer2.setProperty(this.elementRef.nativeElement, 'disabled', this.disabled || this.apiDisable);
    }
}
