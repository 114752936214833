import { Component, ContentChild } from '@angular/core';
import { FondaInputDirective } from './fonda-input.directive';

@Component({
    selector: 'fonda-input',
    templateUrl: './fonda-input.component.html',
    styleUrls: ['./fonda-input.component.scss'],
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'form-item',
    },
})
export class FondaInputComponent {
    @ContentChild(FondaInputDirective) input: FondaInputDirective;
}
