import { SearchResponseInterface } from '../models/search-response-interface';
import { TypeResolverService } from '../services/type-resolver.service';
import { WordService } from '../services/word-service';
import { ProjectionOptions, SearchService } from '../caseworker/search/services/search-service';
import { SearchRecordTypes } from '../caseworker/search/search-page/search-record-types';
import { RecordsInterface } from '../caseworker/search/components/search-filters/records-interface';
import { TranslateService } from '../shared/translation/translate.service';

export class SearchResponseAdapter implements SearchResponseInterface {
    beginingValue: any;
    key: string;
    value: string;
    uuid: string;
    projectionType: string;

    constructor(
        private translateService: TranslateService,
        private _key: string,
        private _value: string,
        private _filterEntry: Array<RecordsInterface>
    ) {
        this.projectionType = this.getUuidAndProjectionType(this._key).projectionType;
        this.uuid = this.getUuidAndProjectionType(this._key).uuid;
        this.key = this.resolveTitle(this.uuid, this.projectionType);
        this.value = this.resolveValue(this._value, this._key);
        this.beginingValue = this._value;
    }

    private resolveTitle(uuid: string, projectionType: string): string {
        return SearchService.getTitle(
            this.translateService,
            uuid,
            projectionType && projectionType === ProjectionOptions.Main ? '' : projectionType
        );
    }

    private getUuidAndProjectionType(reference: string): { uuid: string; projectionType: string } {
        return WordService.transformSearchResponseKey(reference);
    }

    private canAddCommas(uuid: string): boolean {
        return TypeResolverService.isFloat(this.getUuidAndProjectionType(uuid), this._filterEntry);
    }

    private isItBoolean(uuid: string): boolean {
        return TypeResolverService.isBoolean(this.getUuidAndProjectionType(uuid), this._filterEntry);
    }

    private resolveValue(value: string, key: string): any {
        switch (SearchService.resolveRecordType(key)) {
            case SearchRecordTypes.RecordValue: {
                if (WordService.isUuid(value)) {
                    return this.translateService.get([value]);
                } else if (WordService.isStringDate(value)) {
                    return WordService.convertToDate(value);
                } else if (this.canAddCommas(key)) {
                    return WordService.addCommas(value);
                } else if (this.isItBoolean(key)) {
                    return this.translateBoolean(value);
                } else {
                    return value;
                }
            }
            case SearchRecordTypes.IsClosed:
                return value;
            case SearchRecordTypes.Category:
                return this.translateService.get([value]);
            case SearchRecordTypes.PostGrantStatus:
                return value ? this.translateService.get([value]) : null;
            case SearchRecordTypes.ApplicationUuid:
                return this.translateService.get([value]);
            case SearchRecordTypes.ApplicationSchemaUuid:
                return this.translateService.get([value, 'title']);
            case SearchRecordTypes.SubmittedAt:
                return WordService.convertToDate(value);
            case SearchRecordTypes.Status:
                return this.translateService.get([value]);
            case SearchRecordTypes.CreatedAt:
                return WordService.convertToDate(value);
            default: {
                console.error('Type with key' + key + ' not found');
                throw new Error('Type with key' + key + ' not found');
            }
        }
    }

    private translateBoolean(value: string) {
        return this.translateService.get(['general', value && value.toString() == '1' ? 'yes' : 'no']);
    }
}
