import { Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { RecordComponentInterface } from '../../records/record-component-interface';
import { RecordInputObject } from './record-input-object';
import { RecordOutputObject } from './record-output-object';
import { RecordValuesEntry } from './record-values-entry';

@Component({
    selector: 'record-values-edit',
    templateUrl: './record-values-edit.component.html',
})
export class RecordValuesEditComponent {
    @ViewChildren('recordResolver') recordResolvers: QueryList<RecordComponentInterface>;
    @Input('readonlyGroup') public readonlyGroup = false;
    @Output('onChange') onChange = new EventEmitter<object>();
    @Output() afterInit = new EventEmitter();
    public isOnCaseworkerMode = false;
    public recordValues: Array<RecordInputObject> = [];
    public _onlyChanged: Array<RecordOutputObject> = [];

    constructor() {}

    @Input('recordValuesEditInput')
    public set recordValuesInput(recordValuesEntry: RecordValuesEntry) {
        if (recordValuesEntry) {
            this.recordValues = [...recordValuesEntry.recordValues];
            this.isOnCaseworkerMode = recordValuesEntry.isOnCaseworkerMode;
        }
    }

    onAfterInit() {
        this.afterInit.emit();
    }

    public getArrayOfDivIndexWithMargin(ind: number): boolean {
        const indexesWithMargin: Array<number> = [];
        let iterate = 0;
        this.recordValues.forEach(singleValue => {
            if (singleValue.size === 1) {
                if (iterate++ === 1) {
                    indexesWithMargin.push(this.recordValues.indexOf(singleValue));
                    iterate = 0;
                }
            } else {
                iterate = 0;
            }
        });

        return indexesWithMargin.includes(ind);
    }

    public handleChange() {
        if (this.recordResolvers && this.recordResolvers.length > 0) {
            this._onlyChanged = this.recordResolvers
                .filter(record => record.isChanged())
                .map(record => new RecordOutputObject(record.uuid, record.getValue()));
        } else {
            this._onlyChanged = [];
        }
    }

    public getOnlyChanged(): Array<RecordOutputObject> {
        return this._onlyChanged ? this._onlyChanged : [];
    }

    public getStaticGetChanged(): Array<RecordOutputObject> {
        this.handleChange();
        return this.getOnlyChanged();
    }
}
