<div *ngIf="!readonly && !isOnSearch" class="form-item" [ngClass]="{ half: size === 1 }">
    <input
        id="application-float"
        type="text"
        OnlyNumber="true"
        name="value2"
        [ngModel]="text | commasInInput"
        (ngModelChange)="text = $event; emitValue()"
        placeholder=" "
        [required]="isRequired"
    />
    <label for="application-float">{{ uuid | translate: 'title' }}</label>
    <div class="form-focus"></div>
    <div class="form-help"></div>
    <div class="form-error" [ngClass]="{ active: !text }">
        <!--Udfyld dette felt-->
    </div>
</div>

<ng-container *ngIf="!readonly && isOnSearch">
    <div style="display: flex; position: relative">
        <div class="form-item between even first" [ngClass]="{ half: size === 1 }">
            <input
                id="{{ uuid }}-float2"
                type="text"
                step="0.01"
                name="value2"
                OnlyNumber="true"
                [ngModel]="float1 | commasInInput"
                (ngModelChange)="float1 = $event; emitValue()"
                placeholder=" "
                [required]="isRequired"
            />
            <label for="{{ uuid }}-float2">{{ title }} from</label>
            <div class="form-focus"></div>
        </div>
        <div class="form-item between even second" [ngClass]="{ half: size === 1 }">
            <input
                id="{{ uuid }}-float3"
                type="text"
                step="0.01"
                OnlyNumber="true"
                [ngModel]="float2 | commasInInput"
                (ngModelChange)="float2 = $event; emitValue()"
                placeholder=" "
                [required]="isRequired"
            />
            <label for="{{ uuid }}-float3">{{ title }} to</label>
            <div class="form-focus"></div>
        </div>
    </div>
</ng-container>

<a class="form-item" *ngIf="readonly" [ngClass]="{ full: size === 2 }">
    <p>{{ uuid | translate: 'title' }}</p>
    <span>{{ text ? (text | commasInInput) : '-' }}</span>
    <div class="form-focus"></div>
    <div class="form-error"></div>
</a>
