import { Component, Input, OnInit } from '@angular/core';
import { FondaApiService } from '../../../../../api/fonda-api.service';
import { CaseworkerFileDto } from '../../../../../api/dto/caseworker-file-dto';
import { FileService } from '../../../../../services/file.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'caseworker-upload-files',
    templateUrl: 'caseworker-upload-files.component.html',
})
export class CaseworkerUploadFilesComponent implements OnInit {
    @Input() uuid = '';
    public files: Array<CaseworkerFileDto> = [];
    public isLoading = false;
    private _applicationUuid: string;

    get applicationUuid(): string {
        return this._applicationUuid;
    }

    set applicationUuid(applicationUuid: string) {
        this._applicationUuid = applicationUuid;
    }

    constructor(
        private apiService: FondaApiService,
        private fileService: FileService,
        private activatedRoute: ActivatedRoute
    ) {}

    public ngOnInit() {
        this.fetchApplicationUuid();
        this.fetchFiles();
    }

    public downloadFile(file: CaseworkerFileDto) {
        this.fileService
            .downloadFile(file.fileUuid)
            .then(downloadedFile => {
                this.fileService.downloadFileToOwnMachine(downloadedFile);
            })
            .catch(err => {
                console.error(err);
            });
    }

    public fetchFiles() {
        if (this.applicationUuid) {
            this.isLoading = true;
            this.apiService.getApplicationFiles(this.applicationUuid).then(files => {
                this.files = files;
                this.isLoading = false;
            });
        }
    }

    private fetchApplicationUuid() {
        this.activatedRoute.queryParams.subscribe(params => {
            this.applicationUuid = params['uuid'];
        });
    }
}
