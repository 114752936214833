import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { FondaApiService } from '../../../../api/fonda-api.service';
import { CountryDto } from '../../../../api/dto/country-dto';
import { BaseRecordComponent } from '../../base-record.component';
import { GlobalVariables } from '../../../../services/global-variables';

@Component({
    selector: 'country-record',
    templateUrl: './country-record.component.html',
})
export class CountryRecordComponent extends BaseRecordComponent implements OnInit {
    @Input('value') public set value(value: object) {
        this._value = value;
    }

    public get value(): object {
        return this._value;
    }

    constructor(private apiService: FondaApiService) {
        super();
    }

    public countries: Array<CountryDto> = [];
    public selectedUuid;
    public countryUuids: Array<string> = [];
    public selectedName;
    public _value;
    private initValue: string;
    @Input('uuid') public uuid: string;

    @Input('title') public title: string;
    @Input('isRequired') public isRequired: boolean;
    @Input('size') public size: number;
    @Input('readonly') public readonly = false;
    @Input('onSearch') public onSearch = false;
    @Output('onChange') onChange = new EventEmitter<object>();
    configuration: object;
    public emitValue() {
        this.onChange.emit(this.getValue());
    }

    public async ngOnInit() {
        await this.fetchCountries();
        this.value = this.value ? this.value : {};
        this.initValue = this.value['uuid'];
        this.selectedUuid = this.value['uuid'];

        this.countryUuids = this.countries.map(country => country.uuid);

        if (this.selectedUuid) {
            this.selectedName = this.countries.find(boardMeeting => boardMeeting.uuid === this.selectedUuid).name;
        }

        this.emitValue();
    }

    public chooseCountry(uuid: string) {
        if (uuid) {
            this.selectedUuid = uuid;
        } else {
            this.selectedUuid = '';
        }
        this.emitValue();
    }

    public reset() {
        this.chooseCountry(null);
    }

    public isValid(): boolean {
        return !(this.isRequired && !this.value['uuid']);
    }

    public isChanged(): boolean {
        return this.initValue != this.selectedUuid;
    }
    public isEmpty(): boolean {
        return !this.value;
    }
    public getValue(): object {
        return { uuid: this.selectedUuid };
    }

    private fetchCountries(): Promise<void> {
        this.countries = [];
        return this.apiService.getCountries().then(res => {
            res.forEach(country => {
                this.countries.push(new CountryDto(country.uuid, country.name));
            });

            return;
        });
    }
}
