import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'not',
    pure: true,
})
export class NotPipe implements PipeTransform {
    transform(value: any): boolean {
        return !value;
    }
}
