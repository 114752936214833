import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '../translate.service';

@Pipe({
    pure: true,
    name: 'translateArr',
})
export class TranslateArrPipe implements PipeTransform {
    constructor(private translateService: TranslateService) {}

    transform(value: Array<string>): string {
        return this.translateService.get(value);
    }
}
