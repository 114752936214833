import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filter',
})
export class FilterPipe implements PipeTransform {
    transform(array: Array<any>, arg: Array<{ key: string; value: string }>): Array<any> {
        if (!arg || arg.length === 0 || !array || array.length === 0) {
            return array;
        }

        arg.forEach(argument => {
            if (
                argument &&
                (argument.value || (argument.value && argument.value.toString() == '0' && argument.value !== '')) &&
                argument.key
            ) {
                array = array.filter(_array => _array[argument.key] == argument.value);
            }
        });
        return array;
    }
}
