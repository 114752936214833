import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseRecordComponent } from '../../base-record.component';
import { FondaApiService } from '../../../../api/fonda-api.service';
import { ApplicationTemplateDto } from '../../../../api/dto/search-dto/application-template-dto';
import { environment } from '../../../../../environments/environment';

@Component({
    selector: 'post-grant-status-record',
    templateUrl: 'post-grant-status-record.component.html',
})
export class PostGrantStatusRecordComponent extends BaseRecordComponent implements OnInit {
    constructor(private apiService: FondaApiService) {
        super();
    }

    public postGrantStatuses: Array<string> = [];
    public selectedStatus = '';
    public initValue = '';

    @Input('uuid') public uuid: string;
    @Input('value') public value: object;
    @Input('isRequired') public isRequired: boolean;
    @Input('size') public size: number;
    @Input('onSearch') public onSearch: boolean;
    @Input('title') title: string;
    @Input('readonly') public readonly = false;
    @Input('configuration') configuration: object;
    @Output('onChange') onChange = new EventEmitter<object>();
    public isChanged(): boolean {
        return this.selectedStatus === this.initValue;
    }

    public isValid(): boolean {
        return true;
    }

    public isEmpty(): boolean {
        return false;
    }

    public getValue(): object {
        return { uuid: this.selectedStatus };
    }
    public emitValue() {
        this.onChange.emit(this.getValue());
    }

    public reset() {
        this.selectedStatus = '';
        this.emitValue();
    }

    public ngOnInit() {
        if (this.onSearch) {
            this.initOnSearchFlow();
        } else {
            this.initNotOnSearchFlow();
        }
    }

    private initOnSearchFlow() {
        this.value = this.value ? this.value : {};
        this.initValue = this.value['text'] ? this.value['text'] : '';
        this.selectedStatus = this.initValue;
        this.fetchPostGrantedStatuses();
        this.emitValue();
    }

    private initNotOnSearchFlow() {
        this.value = this.value ? this.value : {};
        this.initValue = this.value['uuid'] ? this.value['uuid'] : '';
        this.selectedStatus = this.initValue;
        this.fetchPostGrantedStatuses();
        this.emitValue();
    }

    private fetchPostGrantedStatuses() {
        this.apiService.getApplicationTemplate(environment.APPLICATION_TEMPLATE_UUID).then(async res => {
            this.postGrantStatuses = await this.getStatuses(res);
        });
    }

    private async getStatuses(templateDto: ApplicationTemplateDto): Promise<Array<string>> {
        let statuses: Array<string> = [];

        for (const schemaUuid of templateDto.schemas) {
            if (!this.apiService._getApplicationSchema[schemaUuid]) {
                this.apiService._getApplicationSchema[schemaUuid] = this.apiService.getApplicationSchema(schemaUuid);
            }
            const schema = await this.apiService._getApplicationSchema[schemaUuid];
            statuses = statuses.concat(schema.postGrantStatusSettings.map(s => s.name));
        }

        return Array.from(new Set(statuses));
    }
}
