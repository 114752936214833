import { ErrorInterface } from '../models/error-interface';

export class ApiResponseErrorAdapter implements ErrorInterface {
    constructor(private error: any, private errorId?: string) {}

    public get errorCode(): number {
        return this.error && this.error.error_code;
    }

    public get errorException(): string {
        return this.error && this.error.error_exception;
    }

    public get errorMessage(): string {
        return this.error && this.error.error_message;
    }

    public get errorParameters(): any {
        return this.error && this.error.error_parameters;
    }

    public get errorReferenceUrl(): string {
        return this.error && this.error.error_reference_url;
    }

    public get errorIdentifier(): string {
        if ((this.errorCode && this.errorCode === 500) || !this.errorId) {
            return 'general';
        } else {
            return this.errorId;
        }
    }
}
