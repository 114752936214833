import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseRecordComponent } from '../../base-record.component';

import { UntypedFormControl, Validators } from '@angular/forms';
import { ApplicantValidator } from '../../../../applicant/applicant-validator';

@Component({
    selector: 'email-record',
    templateUrl: 'email-record.component.html',
})
export class EmailRecordComponent extends BaseRecordComponent implements OnInit {
    @Input('uuid') public uuid: string;
    @Input('value') public value: object;
    @Input('isRequired') public isRequired: boolean;
    @Input('size') public size: number;
    private initValue: string;
    public text = new UntypedFormControl();
    @Input('onSearch') onSearch = false;
    @Input('title') title: string;
    @Input('readonly') public readonly = false;
    @Output('onChange') onChange = new EventEmitter<object>();
    @Input('configuration') configuration: object;
    public emitValue() {
        this.onChange.emit(this.getValue());
    }

    public ngOnInit() {
        this.initValue = this.value['text'];
        if (this.isRequired) {
            this.text.setValidators(Validators.required);
        }

        this.text.setValidators(ApplicantValidator.EmailRequired);
        this.text.setValue(this.value['text']);

        this.text.valueChanges.subscribe(() => {
            this.emitValue();
        });
        this.emitValue();
    }

    public isValid(): boolean {
        return !(this.isRequired && !this.value['text']);
    }

    public isChanged(): boolean {
        return this.initValue != this.text.value;
    }
    public isEmpty(): boolean {
        return !this.value;
    }

    public getValue(): object {
        this.value['text'] = this.text.value;
        return this.value;
    }
}
