import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { PopupService } from '../../shared/popup/popup.service';
import { CookieCategories, CookieStrategy } from '../cookie-strategy';
import { CookieBannerComponent } from './cookie-banner.component';

@Injectable({
    providedIn: 'root',
})
export class CookieBannerStrategy implements CookieStrategy {
    constructor(private modalService: PopupService) {}

    initCookieConsent(): Observable<CookieCategories> {
        return this.modalService
            .spawnPopup(CookieBannerComponent)
            .pipe(mapTo({ functional: true, marketing: true, statistic: true }));
    }
}
