import { RecordValuesEntry } from '../record-values-edit/record-values-entry';
import { PaymentProfileRecordValueDto } from '../../../api/dto/payment-profile-dto/payment-profile-record-value-dto';
import { RecordInputObject } from '../record-values-edit/record-input-object';

export class RecordsAdapter implements RecordValuesEntry {
    constructor(private records: Array<PaymentProfileRecordValueDto>, private forceLock: boolean) {
        this.recordValues = this.getValues();
    }

    public isOnCaseworkerMode = true;
    public recordValues: Array<RecordInputObject>;

    private getValues(): Array<RecordInputObject> {
        const records: Array<RecordInputObject> = [];

        this.records.forEach(record => {
            records.push(
                new RecordInputObject(
                    record.uuid,
                    record.value,
                    record.fieldSize,
                    record.isRequired,
                    record.type,
                    this.forceLock ? false : record.isEditable,
                    record.configuration
                )
            );
        });

        return records;
    }
}
