import { Component, EventEmitter } from '@angular/core';
import { RecordValuesEntry } from '../../shared/components/record-values-edit/record-values-entry';
import { SectionDto } from '../../api/dto/section-dto';

@Component({
    selector: 'applicant-case-overview-popup',
    templateUrl: 'applicant-case-overview-popup.component.html',
    styleUrls: ['applicant-case-overview-popup.scss'],
})
export class ApplicantCaseOverviewPopupComponent {
    public sections: Array<SectionDto> = [];

    public send = new EventEmitter();
    public close = new EventEmitter();

    public initPopup(sections: Array<SectionDto>) {
        this.sections = sections;
    }

    public onCancel() {
        this.close.emit();
    }
    public onSend() {
        this.send.emit();
    }
}
