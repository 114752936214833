import { Input, HostBinding, AfterViewInit, OnInit, ElementRef, HostListener, Directive } from '@angular/core';

@Directive({
    selector: '[setMaxHeight]',
})
export class SetMaxHeightDirective implements OnInit {
    INNER_HEIGHT_LIMIT_FOR_OFFSET = 400;

    @HostBinding('style.max-height.px') private height: number;

    private el: HTMLElement;
    constructor(private _eref: ElementRef) {
        this.el = this._eref.nativeElement;
    }

    _offset = 0;

    @Input() set offset(num: number) {
        this._offset = num;
        this.height = this.getHeight();
    }

    @Input() withHeader = false;

    @HostListener('window:resize', ['$event.target'])
    onResize(): void {
        this.height = this.getHeight();
    }

    public ngOnInit() {
        this.height = this.getHeight();
        this.el.style.overflow = 'auto';
    }

    private getHeight(): number {
        return window.innerHeight > this.INNER_HEIGHT_LIMIT_FOR_OFFSET || this.withHeader
            ? window.innerHeight - this._offset
            : window.innerHeight;
    }
}
