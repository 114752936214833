import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { BaseRecordComponent } from '../base-record.component';
import { RecordComponentInterface } from '../record-component-interface';
import { EmailRecordComponent } from '../complex-records/email-record/email-record.component';
import { TextRecordComponent } from '../complex-records/text-record/text-record.component';
import { BankRecordComponent } from '../complex-records/bank-record/bank-record.component';
import { CompanyRecordComponent } from '../complex-records/company-record/company-record.component';
import { SubcategoryRecordComponent } from '../complex-records/subcategory-record/subcategory-record.component';
import { DateRecordComponent } from '../complex-records/date-record/date-record.component';
import { IntegerRecordComponent } from '../complex-records/integer-record/integer-record.component';
import { FloatRecordComponent } from '../complex-records/float-record/float-record.component';
import { MoneyAmountRecordComponent } from '../complex-records/money-amount-record/money-amount-record.component';
import { GrantedAmountRecordComponent } from '../complex-records/granted-amount-record/granted-amount-record.component';
import { PostcodeRecordComponent } from '../complex-records/postcode-record/postcode-record.component';
import { MobileRecordComponent } from '../complex-records/mobile-record/mobile-record.component';
import { BigTextRecordComponent } from '../complex-records/big-text-record/big-text-record.component';
import { UrlRecordComponent } from '../complex-records/url-record/url-record.component';
import { BoardMeetingRecordComponent } from '../complex-records/board-meeting-record/board-meeting-record.component';
import { SchemaRecordComponent } from '../complex-records/schema-record/schema-record.component';
import { ResponsibleCaseworkerRecordComponent } from '../complex-records/responsible-caseworker-record/responsible-caseworker-record.component';
import { AssigneeUserRecordComponent } from '../complex-records/assignee-user-record/assignee-user-record.component';
import { BooleanDefaultFalseRecordComponent } from '../complex-records/boolean-default-false-record/boolean-default-false-record.component';
import { AcceptanceRecordComponent } from '../complex-records/acceptance-record/acceptance-record.component';
import { UploadRecordComponent } from '../complex-records/upload-record/upload-record-component';
import { CaseworkerUploadRecordComponent } from '../caseworder-records/caseworker-upload-record/caseworker-upload-record.component';
import { CorrespondenceTemplatePdfGeneratorComponent } from '../complex-records/correspondence-template-pdf-generator/correspondence-template-pdf-generator.component';
import { EconomyRecordComponent } from '../complex-records/economy-record/economy-record.component';
import { CountryRecordComponent } from '../complex-records/country-record/country-record.component';
import { PaymentPlanRecordComponent } from '../complex-records/payment-plan-record/payment-plan-record.component';
import { PaymentRecordComponent } from '../complex-records/payment-record/payment-record.component';
import { PostGrantStatusRecordComponent } from '../complex-records/post-grant-status-record/post-grant-status-record.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export const RECORD_TYPES = {
    EmailRecord: EmailRecordComponent,
    TextRecord: TextRecordComponent,
    FirstnameRecord: TextRecordComponent,
    LastnameRecord: TextRecordComponent,
    TitleRecord: TextRecordComponent,
    CVRRecord: TextRecordComponent,
    CPRRecord: TextRecordComponent,
    CaseNumberRecord: TextRecordComponent,
    BankRecord: BankRecordComponent,
    CompanyRecord: CompanyRecordComponent,
    SubCategoryRecord: SubcategoryRecordComponent,
    DateRecord: DateRecordComponent,
    IntegerRecord: IntegerRecordComponent,
    FloatRecord: FloatRecordComponent,
    DueBalanceRecord: MoneyAmountRecordComponent,
    SuggestedAmountRecord: MoneyAmountRecordComponent,
    GrantedAmountRecord: GrantedAmountRecordComponent,
    PostcodeRecord: PostcodeRecordComponent,
    MobileRecord: MobileRecordComponent,
    BigTextRecord: BigTextRecordComponent,
    URLRecord: UrlRecordComponent,
    BoardMeetingRecord: BoardMeetingRecordComponent,
    SchemaRecord: SchemaRecordComponent,
    ResponsibleCaseworkerRecord: ResponsibleCaseworkerRecordComponent,
    AssignedUserRecord: AssigneeUserRecordComponent,
    BooleanDefaultFalseRecord: BooleanDefaultFalseRecordComponent,
    AcceptanceRecord: AcceptanceRecordComponent,
    UploadRecord: UploadRecordComponent,
    CaseworkerUploadRecord: CaseworkerUploadRecordComponent,
    CorrespondenceTemplatePDFGeneratorRecord: CorrespondenceTemplatePdfGeneratorComponent,
    EconomyRecord: EconomyRecordComponent,
    CountryRecord: CountryRecordComponent,
    PaymentPlanRecord: PaymentPlanRecordComponent,
    PaymentRecord: PaymentRecordComponent,
    PostGrantStatusRecord: PostGrantStatusRecordComponent,
};

@Component({
    selector: 'record-resolver',
    templateUrl: 'record-resolver.component.html',
})
export class RecordResolverComponent implements RecordComponentInterface, OnInit, AfterViewInit, OnChanges, OnDestroy {
    @Input('uuid') uuid: string;
    @Input('value') value: object;
    @Input('isRequired') isRequired: boolean;
    @Input('type') type: string;
    @Input('size') size: number;
    @Input('title') title: string;
    @Input('readonly') public readonly = false;
    @Input('onSearch') onSearch = false;
    @Input('configuration') configuration: object;
    @Output('onChange') onChange = new EventEmitter<object>();
    @Output('initHeight') initHeight = new EventEmitter<number>();
    @Output() afterInit = new EventEmitter();

    child: BaseRecordComponent;
    @ViewChild('injectContainer', { read: ViewContainerRef, static: true }) injectContainer: ViewContainerRef;

    private unsub$ = new Subject();
    private mountedComponentRef: ComponentRef<BaseRecordComponent>;

    constructor(
        private el: ElementRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private changeDetectionRef: ChangeDetectorRef
    ) {}

    public ngOnInit() {
        this.onChange.emit();
    }

    public ngAfterViewInit() {
        setTimeout(() => {
            this.mountComponent();
            this.afterInit.emit();
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.type && changes.type.currentValue !== changes.type.previousValue) {
            this.mountComponent();
        } else {
            this.updateMountedComponentProps();
        }
    }

    public ngOnDestroy(): void {
        this.unsub$.next();
        this.unsub$.unsubscribe();
    }

    public isValid(): boolean {
        return this.child.isValid();
    }

    public isChanged(): boolean {
        if (this.child) {
            return this.child.isChanged();
        } else {
            return false;
        }
    }

    public isEmpty(): boolean {
        return this.child.isEmpty();
    }

    public getValue(): object {
        return this.child.getValue();
    }

    public mountComponent() {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory<BaseRecordComponent>(
            RECORD_TYPES[this.type]
        );
        this.injectContainer.clear();
        this.mountedComponentRef = this.injectContainer.createComponent(componentFactory);
        this.mountedComponentRef.instance.onChange.pipe(takeUntil(this.unsub$)).subscribe(event => {
            this.onChange.emit(event);
        });
        this.child = this.mountedComponentRef.instance;
        this.updateMountedComponentProps();
    }

    public updateMountedComponentProps() {
        const componentRef = this.mountedComponentRef;
        componentRef.instance.configuration = this.configuration;
        componentRef.instance.title = this.title;
        componentRef.instance.size = this.size;
        componentRef.instance.isRequired = this.isRequired;
        componentRef.instance.uuid = this.uuid;
        componentRef.instance.value = this.value;
        componentRef.instance.onSearch = this.onSearch;
        componentRef.instance.readonly = this.readonly;
        this.changeDetectionRef.detectChanges();
    }
}
