import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Pagination } from '../../../caseworker/search/search-page/search-objects';
import { PaginationDto } from '../../../api/dto/search-dto/pagination-dto';

@Component({
    selector: 'search-pagination-component',
    templateUrl: 'search-pagination.component.html',
})
export class SearchPaginationComponent {
    readonly PaginationOptions = [20, 50, 100, 250, 500];

    @Input() maxApplicationPerPage = 20;
    @Input() showSelect = true;
    @Input('paginationEntry') set newPagination(totalNumberOfApplications: number) {
        this.totalNumberOfApplications = totalNumberOfApplications;
        this.initPages();
        this.showPagination = this.totalNumberOfApplications > this.howMuchApplicationsCanBeShownAtOnce;
    }
    @Input('pagination') set newPaginationState(pagination: PaginationDto | undefined) {
        if (!pagination) return;
        this.currentSite = pagination.pageNumber;
        this.howMuchApplicationsCanBeShownAtOnce = pagination.applicationsPerPage;
    }
    @Output('onPageChange') onPageChange = new EventEmitter<Pagination>();
    @Output('changeMaxApplicationAmount') changeMaxApplicationAmount = new EventEmitter<number>();

    public totalNumberOfApplications = 400;
    public get howMuchApplicationsCanBeShownAtOnce(): number {
        return this.totalNumberOfApplications < this._howMuchApplicaitonsCanBeShownAtOnce
            ? this.totalNumberOfApplications
            : this._howMuchApplicaitonsCanBeShownAtOnce;
    }
    public set howMuchApplicationsCanBeShownAtOnce(value: number) {
        this._howMuchApplicaitonsCanBeShownAtOnce = value;
    }
    private _howMuchApplicaitonsCanBeShownAtOnce: number = 20;
    public pagesArray: Array<number> = [];
    public arrayPagesToShow: Array<number> = [];
    public currentSite = 1;
    public showPagination = false;

    public pageSwitchClick(page: number) {
        if (this.totalNumberOfApplications > this.howMuchApplicationsCanBeShownAtOnce) {
            this.pageSwitch(page);
            this.sendPaginationInformation();
        }
    }

    public onOptionChoose(num: number) {
        this.currentSite = 1;
        this.howMuchApplicationsCanBeShownAtOnce = num;
        this.initPages();
        this.sendPaginationInformation();
    }

    private initPages() {
        this.pagesArray = [];
        for (
            let i = 1;
            i < Math.ceil(this.totalNumberOfApplications / this.howMuchApplicationsCanBeShownAtOnce) + 1;
            i++
        ) {
            this.pagesArray.push(i);
        }
        this.pageSwitch(this.currentSite);
    }

    private sendPaginationInformation() {
        this.onPageChange.emit(new Pagination(this.currentSite, this.howMuchApplicationsCanBeShownAtOnce));
    }

    private canSwitchPage(page: number): boolean {
        return this.pagesArray.find(p => p === page) != null;
    }

    private pageSwitch(page: number) {
        this.arrayPagesToShow = [];
        let endPage: number;
        let startPage: number;
        if (page <= 3) {
            startPage = 1;
            endPage = Math.min(this.pagesArray.length, 6);
        }

        if (page > this.pagesArray.length - 3) {
            endPage = this.pagesArray.length + 1;
            startPage = Math.max(this.pagesArray.length - 4, 1);
        }

        if (page <= this.pagesArray.length - 3 && page > 3) {
            startPage = page - 2;
            endPage = page + 3;
        }

        for (let i = startPage; i < endPage; i++) {
            if (this.canSwitchPage(i)) {
                this.arrayPagesToShow.push(i);
            }
        }

        if (this.canSwitchPage(page)) {
            this.currentSite = page;
        }
    }
}
