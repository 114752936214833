import { Directive, Output, HostListener, ElementRef } from '@angular/core';

@Directive({
    selector: '[autocompleteDisable]',
})
export class AutocompleteDisableDirective {
    constructor(private _eref: ElementRef) {}

    @HostListener('focus', ['$event']) onFocus($event) {
        const name = this._eref.nativeElement.getAttribute('name');
        const id = this._eref.nativeElement.getAttribute('id');
        if (name) {
            this._eref.nativeElement.setAttribute('data-old-name', name);
            this._eref.nativeElement.removeAttribute('name');
        }

        if (id) {
            this._eref.nativeElement.setAttribute('data-old-id', id);
            this._eref.nativeElement.removeAttribute('id');
        }
    }

    @HostListener('blur', ['$event']) onBlur($event) {
        const name = this._eref.nativeElement.getAttribute('data-old-name');
        const id = this._eref.nativeElement.getAttribute('data-old-id');
        if (name) {
            this._eref.nativeElement.setAttribute('name', name);
            this._eref.nativeElement.removeAttribute('data-old-name');
        }

        if (id) {
            this._eref.nativeElement.setAttribute('id', id);
            this._eref.nativeElement.removeAttribute('data-old-id');
        }
    }
}
