import { BoardMeetingDto } from '../../../../api/dto/board-meeting/board-meeting-dto';

export class BoardMeetingData {
    constructor(
        readonly name: string,
        readonly fiscalYear: number,
        readonly date: string,
        readonly acceptingApplicationLastDate: string,
        readonly isSelectable: boolean,
        readonly uuid: string
    ) {}

    static fromDto(dto: BoardMeetingDto): BoardMeetingData {
        return new BoardMeetingData(
            dto.name,
            dto.fiscalYear,
            dto.date,
            dto.acceptingApplicationLastDate,
            dto.isSelectable,
            dto.uuid
        );
    }

    static compare(a: BoardMeetingData, b: BoardMeetingData): number {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
    }
}
