import { DOMService } from '../../../services/dom-service';
import { CorrespondencePopupComponent, PdfGenerateOutput } from './correspondence-popup.component';
import { ComponentRef, EventEmitter, Inject, Injectable } from '@angular/core';
import { TransitionDto } from '../../../api/dto/search-dto/transition-dto';
import { UploadedFondaFile } from '../../../models/uploaded-fonda-file';
import { LocalStorageService } from '../../../services/local-storage-service';
import { SectionDto } from '../../../api/dto/section-dto';
import { RecordValueDto } from '../../../api/dto/record-value-dto';
import { CorrespondencePopupModel } from './correspondence-popup-model';
import { FileService } from '../../../services/file.service';
import { CorrespondencePdfService } from '../../../services/correspondence-pdf-service';
import { PdfFileCreator } from '../../../services/pdf-file-creator';
import { take } from 'rxjs/operators';

@Injectable()
export class CaseworkerCorrespondencePopupService {
    public messageSend = new EventEmitter<{
        threadTitle: string;
        messageBody: string;
        statusName: string;
        filesUploaded: Array<UploadedFondaFile>;
        correspondenceTemplateUuid?: string;
    }>();
    statusChangeWithoutMessage = new EventEmitter<{ statusName: string }>();

    constructor(
        private domService: DOMService,
        private localStorageService: LocalStorageService,
        private fileService: FileService,
        private correspondencePdfService: CorrespondencePdfService,
        private pdfCreator: PdfFileCreator
    ) {}

    public async initOnlyPdfTemplateEditor(
        applicationUuid: string,
        templateUuid: string,
        title: string,
        pdfText: string
    ): Promise<CorrespondencePopupComponent> {
        const component = this.initPopup();
        const instance = component.instance;

        instance.popupTitle = title;
        instance.onlyPdf = true;
        instance.hideSubjectDropDown = true;
        instance.selectedIndex = 1;

        instance.closed.pipe(take(1)).subscribe(() => this.removeComponent(component));

        if (pdfText) {
            instance.pdfBody = pdfText;
        }

        await instance.initPopupComponent(applicationUuid, templateUuid);

        return instance;
    }

    public async initAutomaticMessagePopupComponent(
        applicationUuid: string,
        transitionDto: TransitionDto,
        schemaUuid: string
    ) {
        if (transitionDto.correspondenceTemplateUuid) {
            const component = this.initPopup();
            const instance = component.instance;
            instance.closed.subscribe(() => this.removeComponent(component));
            instance.statusChangeName = transitionDto.statusName;
            instance.messageSend.subscribe(m => {
                this.messageSend.emit(m);
            });

            await instance.initPopupComponent(applicationUuid, transitionDto.correspondenceTemplateUuid, schemaUuid);

            if (instance.templates) {
                const pdfData = this.getPrefilledPdf(instance.templates, transitionDto.correspondenceTemplateUuid);
                if (pdfData) {
                    instance.loading = true;
                    const { value, tokens } = await this.correspondencePdfService.encodeTokens(pdfData.bodyText);
                    const pdfBase64 = await this.pdfCreator.createPdf(
                        this.correspondencePdfService.decodeTokensWithContentValue(value, tokens)
                    );
                    await instance.onGeneratePdf(
                        {
                            fileName: pdfData.fileName,
                            bodyText: this.correspondencePdfService.decodeTokenToOriginValues(value, tokens),
                            baseUri: pdfBase64,
                        },
                        true
                    );
                    instance.loading = false;
                }
            }

            instance.hideSubjectDropDown = true;
            instance.selectedIndex = 1;
            instance.changeDet.detectChanges();
            instance.selectedIndex = 0;
            instance.changeDet.detectChanges();
        } else {
            this.statusChangeWithoutMessage.emit({ statusName: transitionDto.statusName });
        }
    }

    public async openCorrespondencePopup(
        applicationUuid: string,
        schemaUuid: string,
        parentLetterUuid?: string,
        accountEmail?: string
    ) {
        const component = this.initPopup();
        const instance = component.instance;
        instance.accountEmail = accountEmail;
        instance.closed.subscribe(() => this.removeComponent(component));
        instance.applicationUuid = applicationUuid;
        if (schemaUuid) {
            await instance.fetchTemplates(schemaUuid);
        }
        instance.messageSend.subscribe(m => {
            this.messageSend.emit(m);
        });
        if (parentLetterUuid) {
            instance.initTemplatedMessagePopupComponent(parentLetterUuid);
        } else {
            instance.initManualMessagePopupComponent();
        }
    }

    private initPopup(): ComponentRef<CorrespondencePopupComponent> {
        return this.domService.appendComponentToBody(CorrespondencePopupComponent);
    }

    private removeComponent(component) {
        this.domService.removeComponentFromBody(component);
    }

    private getPrefilledPdf(
        templates: Array<CorrespondencePopupModel>,
        templateUuid: string
    ): PdfGenerateOutput | null {
        const template = templates.find(_template => _template.uuid == templateUuid);
        if (template && this.localStorageService.getApplicationInformation()) {
            const record = this.getPdfRecord(
                this.localStorageService.getApplicationInformation().caseworkerSections,
                template.pdfGeneratorRecordUuid
            );
            if (record && record.value && record.value['text'] && record.value['name']) {
                return new PdfGenerateOutput(record.value['name'], record.value['text']);
            }
        }
        return null;
    }

    private getPdfRecord(sections: Array<SectionDto>, recordUuid: string): RecordValueDto {
        let record: RecordValueDto;
        sections.forEach(section => {
            const _record = section.recordValues.find(rec => rec.uuid == recordUuid);
            if (_record) {
                record = _record;
            }
        });
        return record;
    }
}
