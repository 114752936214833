import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[arrows-directive]',
})
export class ArrowsDirective {
    @Output('leftArrowPress') leftArrowPress = new EventEmitter();
    @Output('rightArrowPress') rightArrowPress = new EventEmitter();

    @HostListener('document:keydown', ['$event'])
    onKeyDown($event: KeyboardEvent): void {
        if ($event.keyCode === 37) {
            this.leftArrowPress.emit();
        }
        if ($event.keyCode === 39) {
            this.rightArrowPress.emit();
        }
    }
}
