import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseRecordComponent } from '../../base-record.component';
import { UntypedFormControl, Validators } from '@angular/forms';

@Component({
    selector: 'text-record',
    templateUrl: 'text-record.component.html',
})
export class TextRecordComponent extends BaseRecordComponent implements OnInit {
    @Input('uuid') public uuid: string;
    @Input('value') public value: object;
    @Input('isRequired') public isRequired: boolean;
    @Input('size') public size: number;
    private initValue: string;
    public text = new UntypedFormControl();
    @Input('title') title: string;
    @Input('readonly') public readonly = false;
    @Output('onChange') onChange = new EventEmitter<object>();
    @Input('configuration') configuration: object;
    public emitValue() {
        this.onChange.emit(this.getValue());
    }

    public ngOnInit() {
        this.initValue = this.value['text'];
        if (this.isRequired) {
        }
        if (this.configuration && this.configuration['min_length'] > 0) {
            this.text.setValidators([Validators.minLength(this.configuration['min_length'])]);
        }
        this.text.setValue(this.value['text']);
        this.text.valueChanges.subscribe(() => {
            this.emitValue();
        });
        this.emitValue();
    }

    public isValid(): boolean {
        return this.text.valid;
    }

    public isChanged(): boolean {
        return (this.initValue || this.text.value) && this.initValue != this.text.value;
    }
    public isEmpty(): boolean {
        return !this.value;
    }
    public getValue(): object {
        this.value['text'] = this.text.value;
        return this.value;
    }
}
