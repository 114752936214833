import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { GlobalVariables } from '../../../services/global-variables';
import { PaymentColors } from '../../records/complex-records/payment-record/payment-colors';

export interface BasicSelectComponentEntryWithColor {
    value: string;
    color: PaymentColors;
}

@Component({
    selector: 'basic-select',
    templateUrl: 'basic-select.component.html',
    styleUrls: ['basic-select.scss'],
})
export class BasicSelectComponent {
    show = false;
    values: Array<string> = [];
    _valuesWithColor: Array<BasicSelectComponentEntryWithColor> = [];
    selectedValue = '';

    get valuesWithColor(): Array<BasicSelectComponentEntryWithColor> {
        return this._valuesWithColor;
    }

    @ViewChild('textInput') textInput: ElementRef;

    public paymentColor: typeof PaymentColors = PaymentColors;

    public massSearchText = '';
    public globalVariables: typeof GlobalVariables = GlobalVariables;

    constructor(private _eref: ElementRef) {}

    @Input('label') public label = '';
    @Input('translate') public translate = false;

    @Input('choosenInput') set newSelectedValue(value: string) {
        this.selectedValue = value;
    }

    @Input('argumentTranslation') public argumentTranslation = '';

    @Input('translateLabel') public translateLabel = false;

    @Input('placeholder') placeholder: string;

    @Input('values') set newValues(values: Array<string>) {
        this.values = values.filter(value => value !== '');
    }

    @Input('valuesWithColor') set valuesWithColor(valuesWithColor: Array<BasicSelectComponentEntryWithColor>) {
        this._valuesWithColor = valuesWithColor;
    }

    @Input('includeNoOneOption') includeNoOneOption = false;

    @Input('readonly') readonly = false;

    @Input('colorMode') colorMode = false;

    @Output('getChoosen') public getChoosen = new EventEmitter<string>();

    @HostListener('document:click', ['$event']) clickedOutside($event) {
        if (!this._eref.nativeElement.contains($event.target)) {
            // or some similar check
            this.show = false;
        }
    }

    public choose(value: string) {
        if (!this.readonly) {
            this.selectedValue = value;
            this.getChoosen.emit(value);
        }
    }

    public chooseValueWithColor(value: { value: string; color: string }) {
        this.selectedValue = value.value;
        this.getChoosen.emit(value.value);
    }

    public clickInside() {
        this.show = !this.show;
        if (this.show) {
            this.focusElement();
        }
    }

    public isActualValue(color: PaymentColors): boolean {
        return (
            this.colorMode &&
            this.selectedValue &&
            this.valuesWithColor.find(v => v.value === this.selectedValue).color == color
        );
    }

    private focusElement() {
        setTimeout(() => {
            if (this.textInput && this.textInput.nativeElement) {
                this.textInput.nativeElement.focus();
            }
        }, 50);
    }
}
