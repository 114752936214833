import { Subject } from 'rxjs/Subject';
import { CaseworkerApplicationDto } from '../api/dto/caseworker-application-dto';
import { RecordsStore } from './records-store';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class PaymentStoreService {
    pastPaymentPlanEvent: BehaviorSubject<number>;
    grantedAmountPlanEvent: BehaviorSubject<number>;

    public setGrantedAmount(applicationDto: CaseworkerApplicationDto) {
        let grantedAmount = 0;
        applicationDto.caseworkerSections.forEach(section => {
            const record = section.recordValues.find(_record => _record.type === RecordsStore.GrantedAmountRecord);
            if (record && record.value) {
                grantedAmount = 0;
                Object.keys(record.value).forEach(key => {
                    grantedAmount += record.value[key] ? record.value[key].amount : 0;
                });
            }
        });
        this.grantedAmountPlanEvent = new BehaviorSubject(grantedAmount);
    }
}
