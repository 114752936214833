<div id="downloadableThing" class="admin-application-review applicant downloading">
    <div class="container" *ngIf="_sections && _sections.length > 0">
        <ng-container *ngFor="let section of _sections; let ind = index">
            <!--<div class="html2pdf__page-break" *ngIf="section.recordValues?.length > 0 && ind !== 0"></div>-->
            <div class="application-flow" *ngIf="section.recordValues?.length > 0" [ngClass]="{ break: ind != 0 }">
                <h2>{{ section.sectionType | sectionTranslate: section.id:'title':section.schemaUuid }}</h2>
                <record-values-edit
                    #editRecords
                    (afterInit)="onAfterInit()"
                    [recordValuesEditInput]="{ recordValues: section.recordValues, isOnCaseworkerMode: true }"
                    [readonlyGroup]="true"
                ></record-values-edit>
            </div>
        </ng-container>
    </div>
</div>
