<ckeditor [config]="config" [(ngModel)]="text" [ngModelOptions]="{ standalone: true }" [debounce]="50" #ckeditor>
    <ckbutton
        [label]="'Add New File'"
        [name]="'uploadButton'"
        [command]="'insert_name'"
        [toolbar]="'paragraph,2'"
        (click)="fileUpload.forceAddFileClick()"
    >
    </ckbutton>
</ckeditor>

<file-upload-component #fileUpload (fileAdded)="onFileAdding.emit($event)"> </file-upload-component>

<ng-container *ngIf="allowTokensAdding">
    <div style="display: none">
        <div id="tokens-options-adder" class="tokens-options-container">
            <label class="cke_dialog_ui_labeled_label cke_required">Vælg nøgle</label>
            <select
                [ngModel]="option"
                class="ckeditor-tokens-second-options"
                (ngModelChange)="emitPopupTokensValue($event)"
            >
                <option value="{{ noOption }}">{{ noOption }}</option>
                <option value="line_break_after">line_break_after</option>
            </select>
        </div>
    </div>
</ng-container>
