export class Params {
    constructor(public type: string, public values: Array<any>) {}
}

export class ApplicationFilters {
    constructor(
        readonly reference: string,
        readonly referenceType: string,
        readonly projectionType: string,
        public params: Params
    ) {}
}

export class SearchColumn {
    constructor(readonly reference: string, readonly referenceType: string, readonly projectionType: string) {}
}

export class ParamTypes {
    static readonly EQUALS = 'equals';
    static readonly CONTAINS = 'contains';
    static readonly BETWEEN = 'between';
}

export class ReferenceTypes {
    static readonly APPLICATION_TEMPLATE_RECORD_UUID = 'application_template_record_uuid';
    static readonly APPLICATION_FIELD = 'application_field';
    static readonly ACTION_PERFORMED_STARTED_THREAD = 'action_performed:correspondence_thread_started';
    static readonly GRANT_FIELD_BY_BYM = 'grant_field_by_bm';

    static readonly ARRAY = [
        ReferenceTypes.ACTION_PERFORMED_STARTED_THREAD,
        ReferenceTypes.APPLICATION_FIELD,
        ReferenceTypes.APPLICATION_TEMPLATE_RECORD_UUID,
    ];
}

export class OrderTypes {
    static readonly ASC = 'ASC';
    static readonly DESC = 'DESC';
}

export class SearchOrderBy {
    constructor(
        readonly reference: string,
        readonly referenceType: string,
        readonly orderType: string,
        readonly projectionType: string
    ) {}
}

export class Pagination {
    constructor(readonly pageNumber: number, readonly applicationsPerPage: number) {}
}

export class SearchFilters {
    public massSearch: string;
    public applicationFilters: Array<ApplicationFilters>;
    public searchColumns: Array<SearchColumn>;
    public searchOrdersBy: Array<SearchOrderBy>;
}
