<div *ngIf="!readonly && !isOnSearch">
    <section class="application-economy" *ngIf="configuration && configuration.show_expenses">
        <h3>{{ uuid | translate : 'expenses_title' }}</h3>
        <div class="form-item budget valuta">
            <input
                id="application-economy-amount"
                type="text"
                OnlyNumber="true"
                [ngModel]="overallBudget | commasInInput"
                disallowComma
                placeholder="0"
                (ngModelChange)="overallBudget = $event; emitValue()"
            />
            <label for="application-economy-amount">{{ uuid | translate : 'expenses_overall_budget' }}:</label>
            <div class="form-focus"></div>
            <div class="form-error"></div>
        </div>
    </section>

    <section
        class="application-economy"
        *ngIf="configuration && (configuration.show_own_incomes || configuration.show_external_incomes)"
    >
        <h3>{{ uuid | translate : 'income_title' }}</h3>
        <ng-container *ngIf="configuration.show_own_incomes">
            <!--<div class="form-item budget valuta" style="float: none">-->
            <!--<input id="application-economy-investment-personal"-->
            <!--type="text"-->
            <!--OnlyNumber="true"-->
            <!--[ngModel]="overallOwnDepositValue | commasInInput"-->
            <!--placeholder="0"-->
            <!--(ngModelChange)="overallOwnDepositValue = $event; emitValue()"-->
            <!--/>-->
            <!--<label for="application-economy-investment-personal">{{uuid | translate: 'income_own_deposit'}}</label>-->
            <!--<div class="form-focus"></div>-->
            <!--<div class="form-error"></div>-->
            <!--</div>-->
            <div *ngFor="let ownDeposit of ownDeposits; let ind = index" class="out-of-box">
                <div class="form-item half">
                    <input
                        id="application-economy-investment-new-title"
                        type="text"
                        (ngModelChange)="ownDeposit.revenueTitle = $event; emitValue()"
                        [ngModel]="ownDeposit.revenueTitle"
                        placeholder=" "
                    />
                    <label for="application-economy-investment-new-title">{{
                        uuid | translate : 'income_own_deposit_line'
                    }}</label>
                    <div class="form-focus"></div>
                    <div class="form-error"></div>
                </div>
                <div class="form-item half half-last valuta">
                    <input
                        id="application-economy-investment-new-amount"
                        type="text"
                        OnlyNumber="true"
                        [ngModel]="ownDeposit.amount | commasInInput"
                        disallowComma
                        placeholder="0"
                        (ngModelChange)="ownDeposit.amount = $event; emitValue()"
                    />
                    <label for="application-economy-investment-new-amount"></label>
                    <div class="form-focus"></div>
                    <div class="form-error"></div>
                </div>
                <a class="close-btn red smaller" (click)="deleteOwnDeposit(ind)"><span class="icon-close"></span></a>
            </div>

            <a class="button color-blue" (click)="ownDeposits.push({ revenueTitle: '', amount: null })">{{
                uuid | translate : 'income_add_button'
            }}</a>
        </ng-container>
        <ng-container *ngIf="configuration.show_external_incomes && false">
            <div class="checkbox-wrapper" [ngClass]="{ checked: externalInvestmentsGranted }">
                <div class="checkbox">
                    <input
                        type="checkbox"
                        id="application-econony-external-investments-2"
                        name=""
                        [checked]="externalInvestmentsGranted"
                        (change)="externalInvestmentsGranted = !externalInvestmentsGranted"
                    />
                    <label for="application-econony-external-investments-2">{{
                        uuid | translate : 'income_external_investments'
                    }}</label>
                </div>
                <div class="external-investments-wrapper" *ngIf="externalInvestmentsGranted">
                    <div
                        *ngFor="let externalInvestment of externalInvestments; let ind = index"
                        class="forms-with-cancel"
                    >
                        <div class="form-item half">
                            <input
                                id="application-economy-investment-new-title-2"
                                type="text"
                                (ngModelChange)="externalInvestment.revenueTitle = $event; emitValue()"
                                [ngModel]="externalInvestment.revenueTitle"
                                placeholder=" "
                            />
                            <label for="application-economy-investment-new-title-2">{{
                                uuid | translate : 'income_title'
                            }}</label>
                            <div class="form-focus"></div>
                            <div class="form-error"></div>
                        </div>
                        <div class="form-item half half-last valuta">
                            <input
                                id="application-economy-investment-new-amount-2"
                                type="text"
                                OnlyNumber="true"
                                [ngModel]="externalInvestment.amount | commasInInput"
                                disallowComma
                                placeholder="0"
                                (ngModelChange)="externalInvestment.amount = $event; emitValue()"
                            />
                            <label for="application-economy-investment-new-amount-2"></label>
                            <div class="form-focus"></div>
                            <div class="form-error"></div>
                        </div>
                        <a class="close-btn red smaller" (click)="deleteExternalInvestment(ind)"
                            ><span class="icon-close"></span
                        ></a>
                    </div>
                    <a
                        class="button color-blue"
                        (click)="externalInvestments.push({ revenueTitle: '', amount: null })"
                        >{{ uuid | translate : 'income_add_button' }}</a
                    >
                </div>
            </div>
        </ng-container>
    </section>

    <section class="application-economy" *ngIf="configuration && configuration.show_apply_for">
        <div class="form-item readonly budget valuta">
            <input
                id="application-economy-wanted-amount"
                type="text"
                OnlyNumber="true"
                [ngModel]="financialNeeds"
                placeholder="0"
                [disabled]="true"
            />
            <label for="application-economy-wanted-amount">{{ uuid | translate : 'financial_needs' }}</label>
            <div class="form-focus"></div>
            <div class="form-error"></div>
        </div>
        <div class="form-item budget valuta">
            <input
                id="application-economy-wanted-amount"
                type="text"
                OnlyNumber="true"
                [ngModel]="willApplyFor | commasInInput"
                disallowComma
                placeholder="0"
                (ngModelChange)="willApplyFor = $event; emitValue()"
            />
            <label for="application-economy-wanted-amount">{{ uuid | translate : 'financial_will_apply_for' }}</label>
            <div class="form-focus"></div>
            <div class="form-error"></div>
        </div>
    </section>
</div>

<ng-container *ngIf="!readonly && isOnSearch">
    <div style="display: flex; position: relative">
        <div class="form-item between even first" [ngClass]="{ half: size === 1 }">
            <input
                id="{{ uuid }}-float2"
                type="text"
                step="0.01"
                name="value2"
                OnlyNumber="true"
                [ngModel]="float1 | commasInInput"
                disallowComma
                (ngModelChange)="float1 = $event; emitValue()"
                placeholder=" "
            />
            <label for="{{ uuid }}-float2">{{ uuid | translate : 'financial_will_apply_for' }} from</label>
            <div class="form-focus"></div>
        </div>
        <div class="form-item between even second" [ngClass]="{ half: size === 1 }">
            <input
                id="{{ uuid }}-float3"
                type="text"
                step="0.01"
                name="value2"
                OnlyNumber="true"
                [ngModel]="float2 | commasInInput"
                disallowComma
                (ngModelChange)="float2 = $event; emitValue()"
                placeholder=" "
            />
            <label for="{{ uuid }}-float3">{{ uuid | translate : 'financial_will_apply_for' }} to</label>
            <div class="form-focus"></div>
        </div>
    </div>
</ng-container>

<a *ngIf="readonly" class="full">
    <ng-container *ngIf="configuration && configuration.show_expenses">
        <ul>
            <li class="full economy">
                <h3>{{ uuid | translate : 'expenses_title' }}</h3>
                <ul>
                    <li class="amount">
                        <p>{{ uuid | translate : 'expenses_overall_budget' }}:</p>
                        <span class="valuta">{{ overallBudget | commasInInput }}</span>
                    </li>
                </ul>
            </li>
        </ul>
    </ng-container>
    <ul>
        <li class="full economy" *ngIf="configuration && configuration.show_own_incomes">
            <h3>{{ uuid | translate : 'income_title' }}</h3>
            <ul>
                <li class="amount" *ngFor="let ownDeposit of ownDeposits">
                    <p>{{ ownDeposit.revenueTitle }}</p>
                    <span class="valuta">{{ ownDeposit.amount | commasInInput }}</span>
                </li>
            </ul>
        </li>
        <li
            class="full economy"
            *ngIf="
                false &&
                configuration &&
                configuration.show_external_incomes &&
                externalInvestments &&
                externalInvestments.length > 0
            "
        >
            <h3>Eksterne investeringer</h3>
            <ul>
                <li class="amount" *ngFor="let externalInvestment of externalInvestments">
                    <p>{{ externalInvestment.revenueTitle }}</p>
                    <span class="valuta">{{ externalInvestment.amount | commasInInput }}</span>
                </li>
            </ul>
        </li>
        <li class="full economy" *ngIf="configuration && configuration.show_apply_for">
            <h3>{{ uuid | translate : 'financial_needs' }}</h3>
            <ul>
                <li class="amount">
                    <p>{{ uuid | translate : 'financial_will_apply_for' }}</p>
                    <span class="valuta">{{ willApplyFor | commasInInput }}</span>
                </li>
            </ul>
        </li>
    </ul>
</a>
