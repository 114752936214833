import { Directive, ElementRef, Inject, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { API_BASE_URL } from '../../../api/api-base-url';

@Directive({
    selector: '[backgroundImg]',
})
export class BackgroundImageDirective implements OnChanges {
    @Input() backgroundImg: string;

    constructor(
        private ref: ElementRef,
        private renderer: Renderer2,
        @Inject(API_BASE_URL) private apiBaseUrl: string
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.backgroundImg) {
            const element = this.ref.nativeElement;
            this.renderer.setStyle(
                element,
                'background-image',
                `url(${this.apiBaseUrl}/${changes.backgroundImg.currentValue})`
            );
        }
    }
}
