import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { setAutoFreeze } from 'immer';

if (environment.production) {
  enableProdMode();
}

setAutoFreeze(!environment.production); // we set it to true not in production.

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
