import {
    ChangeDetectorRef,
    Component,
    Inject,
    Input,
    Output,
    EventEmitter,
    OnInit,
    ViewChild,
    OnDestroy,
} from '@angular/core';
import { BaseRecordComponent } from '../../base-record.component';

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApplicantValidator } from '../../../../applicant/applicant-validator';
import { FileUploadComponent } from '../../../basic-form-controls/file-upload/file-upload.component';
import { EconomyRecordDataService } from './economy-record-data-service';
import { FondaApiService } from '../../../../api/fonda-api.service';
import { UploadedFondaFile } from '../../../../models/uploaded-fonda-file';
import { FileService } from '../../../../services/file.service';
import { WordService } from '../../../../services/word-service';

@Component({
    selector: 'economy-record',
    templateUrl: 'economy-record.component.html',
    styleUrls: ['economy-record.scss'],
})
export class EconomyRecordComponent extends BaseRecordComponent implements OnInit, OnDestroy {
    constructor(private cdr: ChangeDetectorRef, private fileService: FileService) {
        super();
    }
    @Input('uuid') public uuid: string;
    @Input('value') public value: object;
    @Input('isRequired') public isRequired: boolean;
    @Input('size') public size: number;
    @Input('onSearch') isOnSearch = false;
    @Input('readonly') public readonly = false;
    @Input('title') title: string;
    @Output('onChange') onChange = new EventEmitter<object>();
    @Input('configuration') configuration: object;

    public initValue: object;
    public economyRecordData: EconomyRecordDataService = new EconomyRecordDataService();
    public overallBudget: string;
    public overallOwnDepositValue: string;
    public ownDeposits: Array<{
        revenueTitle: string;
        amount: string;
    }> = [];

    public externalInvestments: Array<{
        revenueTitle: string;
        amount: string;
    }> = [];

    public willApplyFor: string;

    public float1 = '';
    public float2 = '';

    public externalInvestmentsGranted = false;

    public financialNeeds: string;
    public emitValue() {
        this.financialNeeds = this.getFinancingNeeds();
        this.onChange.emit(this.getValue());
    }

    public ngOnDestroy() {
        this.cdr.detach();
    }

    public ngOnInit() {
        if (!this.isOnSearch) {
            this.initNoSearch();
        } else {
            this.initSearch();
        }
        this.emitValue();
    }

    public downloadFile(file: UploadedFondaFile) {
        this.fileService.downloadFile(file.uuid).then(res => {
            this.fileService.downloadFileToOwnMachine(res);
        });
    }

    public deleteOwnDeposit(index: number) {
        this.ownDeposits.splice(index, 1);
    }

    public deleteExternalInvestment(index: number) {
        this.externalInvestments.splice(index, 1);
    }

    public getFinancingNeeds(): string {
        let costs = 0;
        costs =
            costs +
            (this.convertToNumberRemoveCommas(this.overallOwnDepositValue)
                ? this.convertToNumberRemoveCommas(this.overallOwnDepositValue)
                : 0);
        this.ownDeposits.forEach(
            deposit =>
                (costs =
                    costs +
                    (this.convertToNumberRemoveCommas(deposit.amount)
                        ? this.convertToNumberRemoveCommas(deposit.amount)
                        : 0))
        );
        this.externalInvestments.forEach(
            externalInvestment => (costs = costs + this.convertToNumberRemoveCommas(externalInvestment.amount))
        );
        return this.convertToNumberRemoveCommas(this.overallBudget) - costs
            ? this.convertResult_V2(this.convertToNumberRemoveCommas(this.overallBudget) - costs)
            : '';
    }

    public isValid(): boolean {
        return true;
    }

    public isChanged(): boolean {
        return JSON.stringify(this.getValue()) !== JSON.stringify(this.initValue);
    }

    public isEmpty(): boolean {
        return !this.value;
    }

    public getValue(): object {
        let mock;
        if (!this.isOnSearch) {
            mock = this.getNoSearchValue();
        } else {
            mock = this.getSearchValue();
        }
        return mock;
    }

    // This is V2 for convertResult function to completely avoid comma as decimal separator.
    private convertResult_V2(num: number): string {
        if (num) {
            let str = num.toString();
            return WordService.addDots_V2(str);
        } else {
            return;
        }
    }

    private convertResult(num: number): string {
        if (num) {
            let str = num.toFixed(2);
            str = str.replace(/\./g, ',');
            return WordService.addCommas(str);
        } else {
            return;
        }
    }

    private getNoSearchValue(): object {
        const ownDeposits = [];
        this.ownDeposits.forEach(depo => {
            if (depo.revenueTitle || depo.amount) {
                ownDeposits.push({
                    revenueTitle: depo.revenueTitle,
                    amount: this.convertToNumberRemoveCommas(depo.amount),
                });
            }
        });
        const externalInvestments = [];
        this.externalInvestments.forEach(invest => {
            externalInvestments.push({
                revenueTitle: invest.revenueTitle,
                amount: this.convertToNumberRemoveCommas(invest.amount),
            });
        });
        return {
            overallBudget: this.overallBudget ? this.convertToNumberRemoveCommas(this.overallBudget) : 0,
            overallOwnDepositValue: this.overallOwnDepositValue
                ? this.convertToNumberRemoveCommas(this.overallOwnDepositValue)
                : 0,
            ownDeposits: JSON.parse(JSON.stringify(ownDeposits)),
            externalInvestments: JSON.parse(JSON.stringify(externalInvestments)),
            willApplyFor: this.willApplyFor ? this.convertToNumberRemoveCommas(this.willApplyFor) : 0,
        };
    }

    private getSearchValue(): object {
        const mock = {};
        mock['text'] = [
            this.convertToNumberRemoveCommas(this.float1)
                ? parseFloat(this.convertToNumberRemoveCommas(this.float1).toFixed(2))
                : null,
            this.convertToNumberRemoveCommas(this.float2)
                ? parseFloat(this.convertToNumberRemoveCommas(this.float2).toFixed(2))
                : null,
        ];
        return mock;
    }

    private convertToNumberRemoveCommas(str: string): number {
        if (!str) {
            return;
        } else {
            str = str.toString();
            str = str.replace(/[^\d,-]/g, '');
            str = str.replace(',', '.');
            return Number(str);
        }
    }

    private convertToNumber(str: string): number {
        if (!str) {
            return;
        } else {
            return Number(str);
        }
    }

    private convertToString(num: number): string {
        if (num || num == 0) {
            const str = num.toString();
            return str.replace('.', ',');
        } else {
            return;
        }
    }

    private initNoSearch() {
        this.economyRecordData.setVales(this.value);
        this.overallBudget = this.convertToString(this.economyRecordData.overallBudget);
        this.overallOwnDepositValue = this.convertToString(this.economyRecordData.overallOwnDepositValue);
        this.ownDeposits = [];
        this.economyRecordData.ownDeposits &&
            this.economyRecordData.ownDeposits.forEach(ownDeposit => {
                this.ownDeposits.push({
                    revenueTitle: ownDeposit.revenueTitle,
                    amount: this.convertToString(ownDeposit.amount),
                });
            });
        this.externalInvestments = [];
        this.economyRecordData.externalInvestments &&
            this.economyRecordData.externalInvestments.forEach(investment => {
                this.externalInvestments.push({
                    revenueTitle: investment.revenueTitle,
                    amount: this.convertToString(investment.amount),
                });
            });
        if (this.ownDeposits.length == 0) {
            this.ownDeposits.push({
                revenueTitle: '',
                amount: '',
            });
        }
        this.willApplyFor = this.convertToString(this.economyRecordData.willApplyFor);
        this.externalInvestmentsGranted = this.externalInvestments.length > 0;
        this.cdr.detectChanges();
        this.initValue = this.getValue();
    }

    private initSearch() {
        this.float1 = this.value['text'][0] ? this.value['text'][0] : '';
        this.float2 = this.value['text'][1] ? this.value['text'][1] : '';

        this.initValue = this.getValue();
    }
}
