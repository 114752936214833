import { Component, Inject, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { BaseRecordComponent } from '../../base-record.component';
import { FormControl, Validators } from '@angular/forms';

@Component({
    selector: 'money-amount-record',
    templateUrl: 'money-amount-record.component.html',
})
export class MoneyAmountRecordComponent implements OnInit {
    @Input('uuid') public uuid: string;
    @Input('value') public value: object;
    @Input('isRequired') public isRequired: boolean;
    @Input('size') public size: number;
    @Input('title') title: string;
    @Input('onSearch') isOnSearch = false;
    @Input('readonly') public readonly = false;
    @Output('onChange') onChange = new EventEmitter<object>();

    private initValue: string;
    public text = '';

    public float1 = '';
    public float2 = '';
    public emitValue() {
        this.onChange.emit(this.getValue());
    }

    public ngOnInit() {
        this.initValue = this.value['text'];

        if (!this.isOnSearch) {
            this.text = this.convertToString(this.value['text']);
        } else {
            this.float1 = this.value['text'][0] ? this.convertToString(this.value['text'][0]) : '';
            this.float2 = this.value['text'][1] ? this.convertToString(this.value['text'][1]) : '';
        }
        this.emitValue();
    }

    public isValid(): boolean {
        return !(this.isRequired && !this.value['text']);
    }

    public isChanged(): boolean {
        if (!this.readonly && (this.initValue || this.text)) {
            return this.initValue != this.text;
        } else {
            return false;
        }
    }

    public isEmpty(): boolean {
        return !this.value;
    }

    public getValue(): object {
        this.value = {};
        let tmp;

        if (this.isOnSearch) {
            tmp = [
                this.convertToNumber(this.float1) ? parseFloat(this.convertToNumber(this.float1).toFixed(2)) : null,
                this.convertToNumber(this.float2) ? parseFloat(this.convertToNumber(this.float2).toFixed(2)) : null,
            ];
        } else {
            if (this.text) {
                tmp = parseFloat(this.convertToNumber(this.text).toFixed(2));
            }
        }
        this.value['text'] = tmp;
        return this.value;
    }

    private convertToNumber(str: string): number {
        if (!str) {
            return;
        }
        str = str.replace(/[^\d,-]/g, '');
        str = str.replace(',', '.');
        return Number(str);
    }

    private convertToString(num: number): string {
        if (num) {
            const str = num.toString();
            return str.replace('.', ',');
        } else {
            return;
        }
    }
}
