<div
    class="select-component"
    (click)="clickInside()"
    id="{{ placeholder }}"
    [ngClass]="{ checkbox: checkbox, expanded: show }"
    *ngIf="showDropdown()"
>
    <ng-container *ngIf="!shouldShowValuesOnCheckbox()">
        {{ checkbox || !(choosen[0] && choosen[0].title) || actionSelect ? placeholder : choosen[0]?.title }}
    </ng-container>

    <ng-container *ngIf="shouldShowValuesOnCheckbox()">
        {{
            !(choosen[0] && choosen[0].title) || actionSelect
                ? (placeholder | removeCamelcase)
                : (chosenToShow | removeCamelcase)
        }}
    </ng-container>
    <div
        class="option-component-container"
        [ngClass]="{ 'right-placed': containerPlace == 'right' }"
        *ngIf="options && options.length > 0 && show"
    >
        <div
            class="option-component"
            [ngClass]="{ choosen: isChoosen(option) && !actionSelect }"
            *ngFor="let option of options | multipleOrderBy: ['title']:sort"
            (click)="choose(option)"
        >
            <div class="checkbox-icon" *ngIf="checkbox"></div>
            <ng-container *ngIf="uppercase"><abbr [innerHTML]="option.title | removeCamelcase"></abbr></ng-container>
            <ng-container *ngIf="!uppercase"><abbr [innerHTML]="option.title"></abbr></ng-container>
        </div>
    </div>
</div>
