<div *ngIf="!readonly" class="form-item" [ngClass]="{ half: size === 1 }">
    <input
        id="{{ uuid }}"
        type="text"
        name="value2"
        [formControl]="text"
        placeholder=" "
        [required]="isRequired"
        [maxlength]="configuration && configuration.max_length"
    />
    <label for="{{ uuid }}">
        {{ title ? title : (uuid | translate: 'title') }}
    </label>
    <div class="form-focus"></div>
    <div class="form-error" [ngClass]="{ active: text.errors?.minlength }">
        Indsæt mindst {{ configuration && configuration.min_length }} tegn
    </div>
    <div
        class="form-error configuration active"
        *ngIf="configuration && configuration.max_length && text && text.value"
    >
        {{ text.value.length }} / {{ configuration.max_length }}
    </div>
</div>

<a class="form-item" *ngIf="readonly" [ngClass]="{ full: size === 2 }">
    <p>{{ title ? title : (uuid | translate: 'title') }}</p>
    <span>{{ text.value ? text.value : '-' }}</span>
    <div class="form-focus"></div>
    <div class="form-error"></div>
</a>
