import { FiltersDto } from './filters-dto';

export class SavedSearchDto {
    constructor(
        readonly createdAt: string,
        readonly name: string,
        readonly requester: string,
        readonly search: FiltersDto
    ) {}
}
