import {
    ApplicationFilters,
    Params,
    ParamTypes,
    ReferenceTypes,
    SearchColumn,
    SearchOrderBy,
} from '../search-page/search-objects';
import { RecordsInterface } from '../components/search-filters/records-interface';
import { SearchRecordTypes } from '../search-page/search-record-types';
import { RecordsStore } from '../../../services/records-store';
import { SearchProjectionModel } from '../search-page/search-projection-model';
import { TranslateService } from '../../../shared/translation/translate.service';

export class SearchService {
    public static getFilterType(uuid: string): string {
        const regex = new RegExp('[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}');
        return regex.test(uuid) ? ReferenceTypes.APPLICATION_TEMPLATE_RECORD_UUID : ReferenceTypes.APPLICATION_FIELD;
    }

    public static resolveRecordType(word: string): SearchRecordTypes {
        const regex = new RegExp('[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}');
        if (regex.test(word) || word === 'uuid' || word.includes(RecordsStore.getAmountByBoardMeetingReference(''))) {
            return SearchRecordTypes.RecordValue;
        } else {
            switch (word) {
                case 'category':
                    return SearchRecordTypes.Category;
                case 'category_uuid':
                    return SearchRecordTypes.Category;
                case 'status':
                    return SearchRecordTypes.Status;
                case 'created_at':
                    return SearchRecordTypes.CreatedAt;
                case 'post_granted_status':
                    return SearchRecordTypes.PostGrantStatus;
                case 'application_uuid':
                    return SearchRecordTypes.ApplicationUuid;
                case 'application_schema_uuid':
                    return SearchRecordTypes.ApplicationSchemaUuid;
                case 'submitted_at':
                    return SearchRecordTypes.SubmittedAt;
                case 'is_closed':
                    return SearchRecordTypes.IsClosed;
                case 'insert':
                    return SearchRecordTypes.RecordValue; // TODO: Change later
                default:
                    throw new Error('Undefined record type : ' + word);
            }
        }
    }

    public static getTitle(translateService: TranslateService, title: string, key?: string): string {
        switch (SearchService.resolveRecordType(title)) {
            case SearchRecordTypes.RecordValue:
                return translateService.get([title, key ? key : 'title']);
            case SearchRecordTypes.Category:
                return translateService.get(['application_field', 'category']);
            case SearchRecordTypes.Status:
                return translateService.get(['application_field', 'status']);
            case SearchRecordTypes.SubmittedAt:
                return translateService.get(['application_field', 'submitted_at']);
            case SearchRecordTypes.PostGrantStatus:
                return translateService.get(['application_field', 'post_granted_status']);
            case SearchRecordTypes.ApplicationSchemaUuid:
                return translateService.get(['application_field', 'application_schema']);
            case SearchRecordTypes.CreatedAt:
                return translateService.get(['application_field', 'created_at']);
            case SearchRecordTypes.IsClosed:
                return translateService.get(['application_field', 'is_closed']);
            default:
                return title;
        }
    }

    public static getDeafultColumns(): Array<SearchColumn> {
        const columns: Array<SearchColumn> = [];
        // Case number
        columns.push(
            new SearchColumn(
                '4bea5e88-1df4-11e8-b467-0ed5f89f718d',
                'application_template_record_uuid',
                ProjectionOptions.Main
            )
        );

        // Project title
        columns.push(
            new SearchColumn(
                'a3ee01b8-004c-11e8-ba89-0ed5f89f718b',
                'application_template_record_uuid',
                ProjectionOptions.Main
            )
        );

        columns.push(new SearchColumn('category', 'application_field', ProjectionOptions.Main));

        // Economy
        columns.push(
            new SearchColumn(
                'a3ee0b90-004c-11e8-ba89-0ed5f89f718b',
                'application_template_record_uuid',
                ProjectionOptions.Main
            )
        );

        columns.push(new SearchColumn('status', 'application_field', ProjectionOptions.Main));

        // Company name
        columns.push(
            new SearchColumn(
                'a3ede5b6-004c-11e8-ba89-0ed5f89f718a',
                'application_template_record_uuid',
                ProjectionOptions.CompanyName
            )
        );

        // Second name
        columns.push(
            new SearchColumn(
                'a3ede70a-004c-11e8-ba89-0ed5f89f718b',
                'application_template_record_uuid',
                ProjectionOptions.Main
            )
        );

        columns.push(new SearchColumn('submitted_at', 'application_field', ProjectionOptions.Main));

        return columns;
    }

    public static getDefaultOrderBy(): Array<SearchOrderBy> {
        const orders: Array<SearchOrderBy> = [];

        orders.push(new SearchOrderBy('submitted_at', 'application_field', 'DESC', ProjectionOptions.Main));

        return orders;
    }
}

export enum ProjectionOptions {
    Main = 'main',
    CompanyName = 'company_name',
}

export class PrimitiveTypes {
    public static Bool = {
        primitive: 'bool',
        behaviour: 'BooleanDefaultFalseRecord',
    };

    public static Float = {
        primitive: 'float',
        behaviour: 'FloatRecord',
    };

    public static types = [
        {
            primitive: 'text',
            behaviour: 'TextRecord',
        },
        {
            primitive: 'date',
            behaviour: 'DateRecord',
        },
        PrimitiveTypes.Float,
        PrimitiveTypes.Bool,
    ];
}

export class DefaultNonRecordFilters {
    static readonly STATUS: RecordsInterface = {
        uuid: 'status',
        projections: [],
        type: ReferenceTypes.APPLICATION_FIELD,
        title: 'Status',
    };
    static readonly IS_CLOSED: RecordsInterface = {
        uuid: 'is_closed',
        projections: [],
        type: ReferenceTypes.APPLICATION_FIELD,
        title: 'Is Closed',
    };
    static readonly CREATED_AT: RecordsInterface = {
        uuid: 'created_at',
        projections: [],
        type: ReferenceTypes.APPLICATION_FIELD,
        title: 'Oprettet den',
    };
    static readonly APPLICATION_UUID: RecordsInterface = {
        uuid: 'application_uuid',
        projections: [],
        type: ReferenceTypes.APPLICATION_FIELD,
        title: '',
    };
    static readonly CATEGORY: RecordsInterface = {
        uuid: 'category',
        projections: [],
        type: ReferenceTypes.APPLICATION_FIELD,
        title: 'Kategori',
    };
    static readonly POST_GRANTED_STATUS: RecordsInterface = {
        uuid: 'post_granted_status',
        projections: [],
        type: ReferenceTypes.APPLICATION_FIELD,
        title: '',
    };
    static readonly array: Array<RecordsInterface> = [
        DefaultNonRecordFilters.STATUS,
        DefaultNonRecordFilters.CREATED_AT,
        DefaultNonRecordFilters.CATEGORY,
    ];

    public static GetArray(translateService: TranslateService): Array<RecordsInterface> {
        return [
            {
                uuid: 'status',
                projections: [],
                type: ReferenceTypes.APPLICATION_FIELD,
                title: SearchService.getTitle(translateService, 'status'),
            },

            {
                uuid: 'created_at',
                projections: [
                    new SearchProjectionModel(
                        'DateRecord',
                        ['equals', 'between', 'greater_than', 'lower_than'],
                        'date',
                        'main',
                        SearchService.getTitle(translateService, 'created_at')
                    ),
                ],
                type: ReferenceTypes.APPLICATION_FIELD,
                title: SearchService.getTitle(translateService, 'created_at'),
            },

            {
                uuid: 'category',
                projections: [],
                type: ReferenceTypes.APPLICATION_FIELD,
                title: SearchService.getTitle(translateService, 'category'),
            },

            {
                uuid: 'post_granted_status',
                projections: [
                    new SearchProjectionModel(
                        'PostGrantStatusRecord',
                        ['equals'],
                        'uuid',
                        'main',
                        SearchService.getTitle(translateService, 'post_granted_status')
                    ),
                ],
                type: ReferenceTypes.APPLICATION_FIELD,
                title: SearchService.getTitle(translateService, 'post_granted_status'),
                filtersIgnore: true,
            },

            {
                uuid: 'application_uuid',
                projections: [],
                type: ReferenceTypes.APPLICATION_FIELD,
                title: SearchService.getTitle(translateService, 'application_uuid'),
            },

            {
                uuid: 'application_schema',
                projections: [
                    new SearchProjectionModel(
                        'SchemaRecord',
                        ['equals', 'does_not_have'],
                        'uuid',
                        'main',
                        SearchService.getTitle(translateService, 'application_schema_uuid')
                    ),
                ],
                type: ReferenceTypes.APPLICATION_FIELD,
                title: SearchService.getTitle(translateService, 'application_schema_uuid'),
            },

            {
                uuid: 'submitted_at',
                projections: [
                    new SearchProjectionModel(
                        'DateRecord',
                        ['equals', 'between', 'greater_than', 'lower_than'],
                        'date',
                        'main',
                        SearchService.getTitle(translateService, 'submitted_at')
                    ),
                ],
                type: ReferenceTypes.APPLICATION_FIELD,
                title: SearchService.getTitle(translateService, 'submitted_at'),
            },

            {
                uuid: 'is_closed',
                projections: [
                    new SearchProjectionModel(
                        'BooleanDefaultFalseRecord',
                        ['equals'],
                        'bool',
                        'main',
                        SearchService.getTitle(translateService, 'is_closed')
                    ),
                ],
                type: ReferenceTypes.APPLICATION_FIELD,
                title: SearchService.getTitle(translateService, 'is_closed'),
            },
        ];
    }

    public static getIsClosedFalse(): ApplicationFilters {
        return new ApplicationFilters(
            'is_closed',
            ReferenceTypes.APPLICATION_FIELD,
            ProjectionOptions.Main,
            new Params(ParamTypes.EQUALS, [false])
        );
    }

    public static getTitleRecordUuid(filterOptionRecords: Array<RecordsInterface>): string {
        const behaviour = RecordsStore.TitleRecord;
        let responsibility = '';
        filterOptionRecords.forEach(rec => {
            rec.projections.forEach(proj => {
                if (proj.behaviour === behaviour) {
                    responsibility = rec.uuid;
                }
            });
        });
        return responsibility;
    }

    public static getResponsibilityRecordUuid(filterOptionRecords: Array<RecordsInterface>): string {
        const behaviour = RecordsStore.ResponsibleRecord;
        let responsibility;
        filterOptionRecords.forEach(rec => {
            rec.projections.forEach(proj => {
                if (proj.behaviour === behaviour) {
                    responsibility = rec.uuid;
                }
            });
        });
        return responsibility;
    }

    public static getBoardMeetingRecordUuid(filterOptionRecords: Array<RecordsInterface>): string {
        const behaviour = RecordsStore.BoardMeetingRecord;
        let responsibility;
        filterOptionRecords.forEach(rec => {
            rec.projections.forEach(proj => {
                if (proj.behaviour === behaviour) {
                    responsibility = rec.uuid;
                }
            });
        });
        return responsibility;
    }

    public static getRecordUuid(filterOptionRecords: Array<RecordsInterface>, behaviour: string): string {
        let recordUuid;
        filterOptionRecords.forEach(rec => {
            rec.projections.forEach(proj => {
                if (proj.behaviour === behaviour) {
                    recordUuid = rec.uuid;
                }
            });
        });
        return recordUuid;
    }
}
