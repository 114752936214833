import {
    Component,
    ElementRef,
    HostListener,
    Input,
    Output,
    EventEmitter,
    OnInit,
    ChangeDetectorRef,
} from '@angular/core';
import { BaseRecordComponent } from '../../base-record.component';
import { FondaApiService } from '../../../../api/fonda-api.service';
import { FormControl, Validators } from '@angular/forms';
import { CaseworkerDto } from '../../../../api/dto/caseworker-dto';
import { ResponsibleCaseworkerData } from './responsible-caseworker-data';
import { GlobalVariables } from '../../../../services/global-variables';

@Component({
    selector: 'responsible-caseworker-record',
    templateUrl: 'responsible-caseworker-record.component.html',
})
export class ResponsibleCaseworkerRecordComponent extends BaseRecordComponent implements OnInit {
    constructor(private apiService: FondaApiService, private changeRef: ChangeDetectorRef) {
        super();
    }
    @Input('uuid') public uuid: string;
    @Input('value') public value: object;
    @Input('isRequired') public isRequired: boolean;
    @Input('size') public size: number;
    @Input('readonly') public readonly = false;
    @Input('onSearch') public onSearch = false;
    @Output('onChange') onChange = new EventEmitter<object>();

    private initValue: string;
    public selectedCaseworker: ResponsibleCaseworkerData;
    public selectedUuid: string;
    public title = '';
    public caseworkerNames: Array<string> = [];
    public caseworkers: Array<ResponsibleCaseworkerData> = [];
    configuration: object;
    public emitValue() {
        this.onChange.emit(this.getValue());
    }

    public async ngOnInit() {
        await this.apiService.getBackendUsersCaseworker().then(caseworkers => {
            this.fetchData(caseworkers);
        });
        this.value = this.value ? this.value : {};
        this.initValue = this.value['uuid'] ? this.value['uuid'] : '';
        this.selectedCaseworker = this.caseworkers.find(caseworker => caseworker.uuid === this.initValue);
        if (this.selectedCaseworker) {
            this.selectedUuid = this.selectedCaseworker.uuid;
        } else {
            this.selectedUuid = this.initValue;
        }
        this.emitValue();
    }

    public chooseCaseworker(caseworkerUsername: string) {
        this.selectedCaseworker = this.caseworkers.find(caseworker => caseworker.username === caseworkerUsername);
        if (this.selectedCaseworker) {
            this.selectedUuid = this.selectedCaseworker.uuid;
        } else {
            this.selectedUuid = '';
        }
        this.emitValue();
    }

    public reset() {
        this.chooseCaseworker(null);
    }

    public isValid(): boolean {
        return !(this.isRequired && !this.value['uuid']);
    }

    public isChanged(): boolean {
        return this.initValue != this.selectedUuid;
    }

    public isEmpty(): boolean {
        return !this.value;
    }

    public getValue(): object {
        return { uuid: this.selectedUuid };
    }

    private fetchData(caseworkers: Array<CaseworkerDto>) {
        if (this.onSearch) {
            this.caseworkers.push(
                new ResponsibleCaseworkerData(
                    GlobalVariables.NO_ONE_VARIABLE,
                    true,
                    new Date(),
                    '',
                    '',
                    [],
                    GlobalVariables.NO_ONE_VARIABLE,
                    ''
                )
            );
        }
        caseworkers.forEach(caseworker => {
            this.caseworkers.push(
                new ResponsibleCaseworkerData(
                    caseworker.uuid,
                    caseworker.confirmed,
                    caseworker.createdDate,
                    caseworker.email,
                    caseworker.type,
                    caseworker.roles,
                    caseworker.username,
                    caseworker.initials
                )
            );
        });

        this.caseworkerNames = this.caseworkers.map(caseworker => caseworker.username);
    }
}
