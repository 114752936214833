import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    Directive,
    EventEmitter,
    Host,
    HostListener,
    Input,
    NgModule,
    Output,
    QueryList,
    SkipSelf,
    ViewChild,
} from '@angular/core';
import { CdkPortal, PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'page',
    template: `
        <ng-template cdkPortal>
            <ng-content></ng-content>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageComponent {
    @ViewChild(CdkPortal, { static: true }) portal: CdkPortal;
}

@Component({
    selector: 'pages',
    template: ` <ng-template [cdkPortalOutlet]="currentPage"></ng-template> `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PagesComponent {
    @Input() currentPageIndex = 0;
    @Output() currentPageIndexChange = new EventEmitter<number>();
    @ContentChildren(PageComponent) pages: QueryList<PageComponent>;

    constructor(private _cdRef: ChangeDetectorRef) {}

    get currentPage(): CdkPortal {
        return this.pages.toArray()[this.currentPageIndex].portal;
    }

    next() {
        if (this.currentPageIndex + 1 < this.pages.length) {
            this.currentPageIndexChange.emit(++this.currentPageIndex);
            this._cdRef.markForCheck();
        }
    }

    prev() {
        if (this.currentPageIndex > 0) {
            this.currentPageIndexChange.emit(--this.currentPageIndex);
            this._cdRef.markForCheck();
        }
    }
}

@Directive({
    selector: '[nextPage]',
})
export class NextPageDirective {
    constructor(@Host() @SkipSelf() private pages: PagesComponent) {}

    @HostListener('click')
    onClick() {
        this.pages.next();
    }
}

@Directive({
    selector: '[prevPage]',
})
export class PrevPageDirective {
    constructor(@Host() @SkipSelf() private pages: PagesComponent) {}

    @HostListener('click')
    onClick() {
        this.pages.prev();
    }
}

const COMPONENTS = [PageComponent, PagesComponent, NextPageDirective, PrevPageDirective];

@NgModule({
    declarations: COMPONENTS,
    exports: COMPONENTS,
    imports: [CommonModule, PortalModule],
})
export class PagesModule {}
