import { merge, Observable, of } from 'rxjs';
import { UserChatWidgetService } from './user-chat-widget.service';
import { AuthService } from '../auth/auth.service';
import { FondaApiService } from '../api/fonda-api.service';
import { debounceTime, filter, map } from 'rxjs/operators';
import { Maybe } from 'true-myth';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Injectable()
export class UserChatWidgetUserDataService {
    constructor(
        private userChatWidgetService: UserChatWidgetService,
        private authService: AuthService,
        private apiService: FondaApiService,
        private router: Router
    ) {}

    initUserDataUpdates() {
        const navigationFinished = this.router.events.pipe(filter(event => event instanceof NavigationEnd));
        merge(this.authService.change$, navigationFinished)
            .pipe(
                filter(() => this.authService.isLoggedIn()),
                debounceTime(500)
            )
            .subscribe(() => {
                this.updateUserData();
            });

        this.updateUserData();
    }

    private updateUserData() {
        const accountDescriptor = this.getAccountDescriptor();

        const foundationName = window.location.hostname.split('.')[0];
        const userName$ = Maybe.of(accountDescriptor)
            .flatMap(ad => Maybe.of(this.authService.isBackend() ? ad.uuid : null))
            .map(uuid => this.getUsernameFromBackend(uuid))
            .unwrapOr(of('Guest'));
        const email = Maybe.of(accountDescriptor)
            .map(ad => ad.email)
            .unwrapOr('');

        userName$.subscribe(userName => {
            this.userChatWidgetService.updateUser({
                name: `${foundationName}: ${userName}`,
                email,
                company: {
                    name: foundationName,
                    company_id: foundationName,
                },
                url: window.location.href,
            });
        });
    }

    private getUsernameFromBackend(uuid: string): Observable<string> {
        return this.apiService.getBackendAccount(uuid).pipe(map(a => a.username));
    }

    private getAccountDescriptor() {
        return this.authService.isLoggedIn() ? this.authService.getAccountDescriptor() : null;
    }
}
