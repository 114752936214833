import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CaseworkerCorrespondencePopupService } from '../../../../caseworker/components/correspondence-popup/caseworker-correspondence-popup.service';
import { FondaApiService } from '../../../../api/fonda-api.service';
import { LocalStorageService } from '../../../../services/local-storage-service';
import { CorrespondenceTemplateManualDto } from '../../../../api/dto/correspondence-dto/correspondence-template-manual-dto';
import { ObjectService } from '../../../../services/object-service';
import { EventsHandlerService } from '../../../../services/events-handler-service';
import { CorrespondenceTemplateStorage } from '../../../../caseworker/review/services/correspondence-template-storage';
import { CorrespondencePdfService } from '../../../../services/correspondence-pdf-service';
import { PdfFileCreator } from '../../../../services/pdf-file-creator';
import { FileService } from '../../../../services/file.service';
import { BaseRecordComponent } from '../../base-record.component';
import { PdfGenerateOutput } from '../../../../caseworker/components/correspondence-popup/correspondence-popup.component';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '../../../translation/translate.service';

@Component({
    selector: 'app-correspondence-template-pdf-generator',
    templateUrl: './correspondence-template-pdf-generator.component.html',
    styleUrls: ['./correspondence-template-pdf-generator.component.scss'],
    providers: [CaseworkerCorrespondencePopupService],
})
export class CorrespondenceTemplatePdfGeneratorComponent extends BaseRecordComponent implements OnInit {
    @Input('uuid') public uuid: string;
    @Input('value') public value: object;
    @Input('isRequired') public isRequired: boolean;
    @Input('size') public size: number;
    @Input('onSearch') isOnSearch = false;
    @Input('title') title: string;
    @Input('readonly') public readonly = false;
    @Output('onChange') onChange = new EventEmitter<object>();
    @Input('configuration') configuration: object;

    public text = '';
    public loadingPopup = false;
    public applicationUuid = '';
    public fileName = '';
    public template: CorrespondenceTemplateManualDto;

    constructor(
        private activatedRoute: ActivatedRoute,
        private popupService: CaseworkerCorrespondencePopupService,
        private apiService: FondaApiService,
        private localStorageService: LocalStorageService,
        private fileService: FileService,
        private translateService: TranslateService,
        private eventHandlerService: EventsHandlerService,
        private templateStorage: CorrespondenceTemplateStorage,
        private correspondencePdfService: CorrespondencePdfService,
        private pdfCreator: PdfFileCreator
    ) {
        super();
        this.fetchApplicationUuid();
    }

    public emitValue() {
        this.onChange.emit(this.getValue());
        this.eventHandlerService.triggerSaveCaseworkerSection.next();
    }

    public ngOnInit() {
        if (this.value && this.value['text'] && this.value['name']) {
            this.text = this.value['text'];
            this.fileName = this.value['name'];
        }
        this.templateStorage
            .getTemplate(this.localStorageService.getApplicationInformation().applicantSchemaUuid)
            .then(templates => {
                this.template = templates.find(_template => _template.pdfGeneratorRecordUuid == this.uuid);
            });
    }

    public isValid(): boolean {
        return false;
    }

    public isChanged(): boolean {
        if (!ObjectService.isEmpty(this.getValue()) && !ObjectService.isEmpty(this.value)) {
            return !ObjectService.AreSame(this.value, this.getValue());
        } else if (ObjectService.isEmpty(this.value)) {
            return !ObjectService.isEmpty(this.getValue());
        }
        return false;
    }

    public isEmpty(): boolean {
        return false;
    }

    public getValue(): object {
        return { text: this.text, name: this.fileName };
    }

    public async previewPdf() {
        const { value, tokens } = await this.correspondencePdfService.encodeTokens(this.text);

        this.pdfCreator.downloadPdf(this.correspondencePdfService.decodeTokensWithContentValue(value, tokens));
    }

    public deleteFile() {
        this.text = '';
        this.fileName = '';
        this.emitValue();
    }

    public async initPopup() {
        this.loadingPopup = true;
        const popup = await this.popupService.initOnlyPdfTemplateEditor(
            this.applicationUuid,
            this.template.uuid,
            this.translateService.get([this.uuid, 'title']),
            this.text
        );
        this.loadingPopup = false;

        popup.pdfGenerated.pipe(takeUntil(popup.closed)).subscribe((file: PdfGenerateOutput) => {
            this.text = file.bodyText;
            this.fileName = file.fileName;
            this.emitValue();
        });
    }

    private fetchApplicationUuid() {
        this.activatedRoute.queryParams.subscribe(params => {
            this.applicationUuid = params['uuid'];
        });
    }
}
