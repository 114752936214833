import { FondaApiService } from '../../../api/fonda-api.service';
import { Inject, Injectable } from '@angular/core';
import { CorrespondenceTemplateManualDto } from '../../../api/dto/correspondence-dto/correspondence-template-manual-dto';

@Injectable()
export class CorrespondenceTemplateStorage {
    private _templates: { [key: string]: Promise<Array<CorrespondenceTemplateManualDto>> } = {};

    constructor(@Inject(FondaApiService) private apiService: FondaApiService) {}

    public getTemplate(schemaUuid: string): Promise<Array<CorrespondenceTemplateManualDto>> {
        if (!this._templates[schemaUuid]) {
            this._templates[schemaUuid] = this.apiService.getApplicationSchemaCorrespondenceTemplates(schemaUuid);
        }
        return this._templates[schemaUuid];
    }
}
