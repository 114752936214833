import { Output, Component, Input, Inject, EventEmitter, OnInit } from '@angular/core';
import { AllMoneyValue, ResultApplicationValue, ResultOrderOutput, ResultsInterface } from './results-interface';
import { RedirectionService } from '../../../services/redirection-service';
import { OrderTypes } from '../../../caseworker/search/search-page/search-objects';
import { SearchStoreService } from '../../../caseworker/search/services/search-store-service';

@Component({
    selector: 'search-results-component',
    templateUrl: 'search-results.component.html',
})
export class SearchResultsComponent {
    constructor(private redirectionService: RedirectionService) {}

    @Input('searchResults') set newSearchResults(searchResults: ResultsInterface) {
        if (searchResults && searchResults.applications.length > 0) {
            this.asc = searchResults.orderBy[0].orderType === OrderTypes.ASC;
            this.searchResults = searchResults;
            this.initMoneyValues(searchResults);
        } else {
            this.searchResults = null;
        }
    }

    @Input('basicSearch') public basicSearch = false;
    @Output() onSearchClick = new EventEmitter();
    @Output() onTitleClick = new EventEmitter<ResultOrderOutput>();

    public searchResults: ResultsInterface;
    public allMoneyValues: Array<AllMoneyValue> = [];

    public asc = false;

    public redirectToReview(uuid: string) {
        if (!this.basicSearch) {
            this.redirectionService.toCaseworkerReviewFromSearch(uuid);
        } else {
            this.redirectionService.toCaseworkerReview(uuid);
            this.onSearchClick.emit();
            return;
        }
    }

    public titleClick(title: ResultApplicationValue) {
        if (title.orderAble) {
            if (
                this.searchResults &&
                this.searchResults.orderBy &&
                this.getUuid(this.searchResults.orderBy[0].reference) == this.getUuid(title.uuid)
            ) {
                this.asc = !this.asc;
            } else {
                this.asc = false;
            }

            this.onTitleClick.emit(
                new ResultOrderOutput(title.uuid === 'category_uuid' ? 'category' : title.uuid, this.asc)
            );
        }
    }

    public getUuid(uuid: string): string {
        return uuid.split('{%}')[0];
    }

    private initMoneyValues(searchResults: ResultsInterface) {
        this.allMoneyValues = [];
        searchResults.applications[0].applicationValues.forEach(record => {
            const moneyValue = new AllMoneyValue();
            moneyValue.uuid = record.uuid;
            this.allMoneyValues.push(moneyValue);
        });
        searchResults.allMoneyValues.forEach(res => {
            if (this.allMoneyValues.find(_val => _val.uuid === res.uuid)) {
                this.allMoneyValues.find(_val => _val.uuid === res.uuid).value = res.value;
            }
        });
    }
}
