import { NgModule } from '@angular/core';
import { UserChatWidgetService } from './user-chat-widget.service';
import { UserChatWidgetShowGuard } from './user-chat-widget-show.guard';
import { UserChatWidgetHideGuard } from './user-chat-widget-hide.guard';
import { UserChatWidgetUserDataService } from './user-chat-widget-user-data.service';

@NgModule({
    providers: [UserChatWidgetService, UserChatWidgetShowGuard, UserChatWidgetHideGuard, UserChatWidgetUserDataService],
})
export class UserChatWidgetModule {
    constructor(clientDataService: UserChatWidgetUserDataService) {
        clientDataService.initUserDataUpdates();
    }
}
