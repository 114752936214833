import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnchorButtonComponent, ButtonComponent } from './button/button.component';
import { CloseButtonComponent } from './close-button/close-button.component';
import { IconButtonComponent } from './icon-button/icon-button.component';

@NgModule({
    imports: [CommonModule],
    declarations: [ButtonComponent, CloseButtonComponent, IconButtonComponent, AnchorButtonComponent],
    exports: [ButtonComponent, CloseButtonComponent, IconButtonComponent, AnchorButtonComponent],
})
export class ButtonsModule {}
