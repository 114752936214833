import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopupService } from './popup.service';
import { PopupComponent } from './popup/popup.component';
import { PopupHeaderDirective } from './directives/popup-header.directive';
import { PopupActionsDirective } from './directives/popup-actions.directive';
import { ButtonsModule } from '../buttons/buttons.module';

@NgModule({
    declarations: [PopupComponent, PopupHeaderDirective, PopupActionsDirective],
    exports: [PopupComponent, PopupHeaderDirective, PopupActionsDirective],
    imports: [CommonModule, ButtonsModule],
    providers: [PopupService],
})
export class PopupModule {}
