import { Component, ContentChild, Input } from '@angular/core';
import { FondaCheckboxDirective } from './fonda-checkbox.directive';

@Component({
    selector: 'fonda-checkbox',
    templateUrl: './fonda-checkbox.component.html',
    styleUrls: ['./fonda-checkbox.component.scss'],
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'checkbox',
    },
})
export class FondaCheckboxComponent {
    @ContentChild(FondaCheckboxDirective, { static: true }) checkbox: FondaCheckboxDirective;
    @Input() label: string;
}
