import { ComponentFactoryResolver, ComponentRef, Inject, Injectable } from '@angular/core';
import { DownloadableSectionComponent } from './downloadable-section/downloadable-section.component';
import { DownloadSectionInterface } from './downloadable-section/download-section-interface';
import { DOMService } from '../services/dom-service';
import { Subscription } from 'rxjs';

declare const html2pdf: any;

@Injectable()
export class PdfApplicationService {
    public timeStamp: number;
    readonly options = {
        margin: [0, 0],
        filename: 'applicant-review-document.pdf',
        image: { type: 'jpeg', quality: 1 },
        html2canvas: { scale: 1, logging: false },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'p' },
        pagebreak: { before: '.break' },
    };
    public onCaseInit$: Subscription;

    constructor(private factoryResolver: ComponentFactoryResolver, private domService: DOMService) {}

    public downloadPDF(sectionsEntry: Array<DownloadSectionInterface>): Promise<void> {
        this.timeStamp = new Date().getTime();
        return new Promise(resolve => {
            const componentRef = this.generateComponent(sectionsEntry);
            this.onCaseInit$ = componentRef.instance.afterInit.subscribe(() => {
                const _element = document.getElementById('downloadableThing');
                this.generatePdf(_element).then(() => {
                    this.domService.removeComponentFromBody(componentRef);
                    this.onCaseInit$.unsubscribe();
                    resolve();
                });
            });
        });
    }

    public _oldDownloadPDF(sectionsEntry: Array<DownloadSectionInterface>): Promise<void> {
        this.timeStamp = new Date().getTime();
        return new Promise(resolve => {
            const componentRef = this.generateComponent(sectionsEntry);
            setTimeout(() => {
                const _element = document.getElementById('downloadableThing');
                this.generatePdf(_element).then(() => {
                    this.domService.removeComponentFromBody(componentRef);
                    resolve();
                });
            }, 500);
        });
    }

    public getPdf(sectionsEntry: Array<DownloadSectionInterface>): Promise<any> {
        return new Promise(resolve => {
            const componentRef = this.generateComponent(sectionsEntry);
            this.onCaseInit$ = componentRef.instance.afterInit.subscribe(() => {
                const _element = document.getElementById('downloadableThing');
                this.getPdfBlob(_element).then(res => {
                    this.domService.removeComponentFromBody(componentRef);
                    this.onCaseInit$.unsubscribe();
                    resolve(res);
                });
            });
        });
    }

    public _getPdf(sectionsEntry: Array<DownloadSectionInterface>): Promise<any> {
        return new Promise((resolve, reject) => {
            const componentRef = this.generateComponent(sectionsEntry);
            setTimeout(() => {
                const _element = document.getElementById('downloadableThing');
                this.getPdfBlob(_element)
                    .then(res => {
                        this.domService.removeComponentFromBody(componentRef);
                        resolve(res);
                    })
                    .catch(res => console.error(res));
            }, 500);
        });
    }

    private generateComponent(
        sectionsEntry: Array<DownloadSectionInterface>
    ): ComponentRef<DownloadableSectionComponent> {
        const component = this.domService.appendComponentToBody(DownloadableSectionComponent);
        component.instance.sections = sectionsEntry;
        return component;
    }

    private generatePdf(element): Promise<void> {
        return new Promise(resolve => {
            new html2pdf(element, this.options);
            resolve();
        });
    }

    private getPdfBlob(element): Promise<string> {
        return html2pdf()
            .set(this.options)
            .from(element)
            .outputPdf('dataurlstring')
            .catch(error => {
                console.error(error);
                throw error;
            });
    }
}
