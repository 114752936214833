import { ToasterService } from 'angular2-toaster';
import {
    ErrorInterface,
    isUpdateApplication,
    isUpdateStatus,
    UPDATE_APPLICATION_TRANSLATION,
    UPDATE_STATUS_TRANSLATION,
} from '../models/error-interface';
import { GlobalVariables } from './global-variables';
import { ToasterTimeoutHelper } from './toaster-timeout-helper';
import { TranslateService } from '../shared/translation/translate.service';
import { Maybe } from 'true-myth';

export class ApiResponseToasterHelper {
    public static handleError(
        error: ErrorInterface,
        translateService: TranslateService,
        toasterService: ToasterService
    ): void {
        const translatePath = [error.errorIdentifier, error.errorException];
        const getWithFallback = (fallbackPath?: string[]) =>
            translateService
                .getMaybe(translatePath)
                .orElse(() => (fallbackPath === undefined ? Maybe.nothing() : translateService.getMaybe(fallbackPath)))
                .orElse(() =>
                    translateService.getMaybe([GlobalVariables.ERROR_API_GLOBAL_IDENTIFIER, error.errorException])
                )
                .unwrapOrElse(() =>
                    translateService.get([GlobalVariables.ERROR_API_GLOBAL_IDENTIFIER, 'UnhandledErrorException'])
                );

        const message = isUpdateApplication(error.errorIdentifier)
            ? getWithFallback([UPDATE_APPLICATION_TRANSLATION, error.errorException])
            : isUpdateStatus(error.errorIdentifier)
            ? getWithFallback([UPDATE_STATUS_TRANSLATION, error.errorException])
            : getWithFallback();

        toasterService.pop({
            type: 'error',
            title: message,
            timeout: ToasterTimeoutHelper.getTimeout(message),
        });
    }

    public static handleSuccess(
        identifier: string,
        translateService: TranslateService,
        toasterService: ToasterService
    ) {
        const translatePath = [identifier, GlobalVariables.SUCCESS_API_RESPONSE_SUB_IDENTIFIER];
        const getWithFallback = (fallbackPath?: string[]) =>
            translateService
                .getMaybe(translatePath)
                .orElse(() => (fallbackPath === undefined ? Maybe.nothing() : translateService.getMaybe(fallbackPath)))
                .unwrapOrElse(() =>
                    translateService.get([identifier, GlobalVariables.SUCCESS_API_RESPONSE_SUB_IDENTIFIER])
                );

        const message = isUpdateApplication(identifier)
            ? getWithFallback([UPDATE_APPLICATION_TRANSLATION, GlobalVariables.SUCCESS_API_RESPONSE_SUB_IDENTIFIER])
            : isUpdateStatus(identifier)
            ? getWithFallback([UPDATE_STATUS_TRANSLATION, GlobalVariables.SUCCESS_API_RESPONSE_SUB_IDENTIFIER])
            : getWithFallback();

        toasterService.pop({
            type: 'success',
            title: message,
            timeout: ToasterTimeoutHelper.getTimeout(message),
        });
    }
}
