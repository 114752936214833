import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecordResolverComponent } from './record-resolver/record-resolver.component';
import { EmailRecordComponent } from './complex-records/email-record/email-record.component';
import { TextRecordComponent } from './complex-records/text-record/text-record.component';
import { BankRecordComponent } from './complex-records/bank-record/bank-record.component';
import { CompanyRecordComponent } from './complex-records/company-record/company-record.component';
import { SubcategoryRecordComponent } from './complex-records/subcategory-record/subcategory-record.component';
import { DateRecordComponent } from './complex-records/date-record/date-record.component';
import { IntegerRecordComponent } from './complex-records/integer-record/integer-record.component';
import { FloatRecordComponent } from './complex-records/float-record/float-record.component';
import { MoneyAmountRecordComponent } from './complex-records/money-amount-record/money-amount-record.component';
import { GrantedAmountRecordComponent } from './complex-records/granted-amount-record/granted-amount-record.component';
import { PostcodeRecordComponent } from './complex-records/postcode-record/postcode-record.component';
import { MobileRecordComponent } from './complex-records/mobile-record/mobile-record.component';
import { BigTextRecordComponent } from './complex-records/big-text-record/big-text-record.component';
import { UrlRecordComponent } from './complex-records/url-record/url-record.component';
import { BoardMeetingRecordComponent } from './complex-records/board-meeting-record/board-meeting-record.component';
import { SchemaRecordComponent } from './complex-records/schema-record/schema-record.component';
import { ResponsibleCaseworkerRecordComponent } from './complex-records/responsible-caseworker-record/responsible-caseworker-record.component';
import { AssigneeUserRecordComponent } from './complex-records/assignee-user-record/assignee-user-record.component';
import { BooleanDefaultFalseRecordComponent } from './complex-records/boolean-default-false-record/boolean-default-false-record.component';
import { AcceptanceRecordComponent } from './complex-records/acceptance-record/acceptance-record.component';
import { UploadRecordComponent } from './complex-records/upload-record/upload-record-component';
import { CaseworkerUploadRecordComponent } from './caseworder-records/caseworker-upload-record/caseworker-upload-record.component';
import { CorrespondenceTemplatePdfGeneratorComponent } from './complex-records/correspondence-template-pdf-generator/correspondence-template-pdf-generator.component';
import { EconomyRecordComponent } from './complex-records/economy-record/economy-record.component';
import { CountryRecordComponent } from './complex-records/country-record/country-record.component';
import { PaymentRecordComponent } from './complex-records/payment-record/payment-record.component';
import { PaymentPlanRecordComponent } from './complex-records/payment-plan-record/payment-plan-record.component';
import { PostGrantStatusRecordComponent } from './complex-records/post-grant-status-record/post-grant-status-record.component';
import { PrimitiveTextRecordComponent } from './primitive-records/primitive-text-record/primitive-text-record.component';
import { PaymentRowComponent } from './complex-records/payment-record/payment-row/payment-row.component';
import { PaymentPlanRowComponent } from './complex-records/payment-plan-record/payment-plan-row/payment-plan-row.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslationModule } from '../translation/translation.module';
import { BasicFormControlsModule } from '../basic-form-controls/basic-form-controls.module';
import { UtilsModule } from '../utils/utils.module';
import { CaseworkerUploadFilesComponent } from './caseworder-records/caseworker-upload-record/caseworker-upload-files/caseworker-upload-files.component';

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslationModule, BasicFormControlsModule, UtilsModule],
    declarations: [
        RecordResolverComponent,
        EmailRecordComponent,
        TextRecordComponent,
        BankRecordComponent,
        CompanyRecordComponent,
        SubcategoryRecordComponent,
        DateRecordComponent,
        IntegerRecordComponent,
        FloatRecordComponent,
        MoneyAmountRecordComponent,
        GrantedAmountRecordComponent,
        PostcodeRecordComponent,
        MobileRecordComponent,
        BigTextRecordComponent,
        UrlRecordComponent,
        BoardMeetingRecordComponent,
        SchemaRecordComponent,
        ResponsibleCaseworkerRecordComponent,
        AssigneeUserRecordComponent,
        BooleanDefaultFalseRecordComponent,
        AcceptanceRecordComponent,
        UploadRecordComponent,
        CaseworkerUploadFilesComponent,
        CaseworkerUploadRecordComponent,
        CorrespondenceTemplatePdfGeneratorComponent,
        EconomyRecordComponent,
        CountryRecordComponent,
        PaymentRecordComponent,
        PaymentPlanRecordComponent,
        PostGrantStatusRecordComponent,
        PrimitiveTextRecordComponent,
        CorrespondenceTemplatePdfGeneratorComponent,
        PaymentPlanRowComponent,
        PaymentRowComponent,
    ],
    exports: [RecordResolverComponent],
})
export class RecordsModule {}
