import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';

@Component({
    selector: 'fonda-direct-login',
    template: '',
})
export class DirectLoginComponent implements OnInit {
    constructor(private route: ActivatedRoute, private router: Router, private authService: AuthService) {}

    ngOnInit() {
        const token = this.route.snapshot.queryParamMap.get('token');
        this.authService.setIdentity(token);
        this.router.navigate(['/']);
    }
}
