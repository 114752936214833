import { Component, ElementRef } from '@angular/core';
import { ButtonComponent } from '../button/button.component';

@Component({
    selector: '[app-close-button]',
    templateUrl: './close-button.component.html',
})
export class CloseButtonComponent extends ButtonComponent {
    constructor(_eref: ElementRef) {
        super(_eref);

        this.addClass('btn-close');
    }
}
