<footer class="applicant-font">
    <div class="footer">
        <div class="container">
            <div class="widget">
                <h4 [innerHTML]="'login_page' | translate: 'footer_first_column_title' | safeHtml"></h4>
                <p [innerHTML]="'login_page' | translate: 'footer_first_column_text' | safeHtml"></p>
            </div>
            <div class="widget">
                <h4 [innerHTML]="'login_page' | translate: 'footer_second_column_title' | safeHtml"></h4>
                <p [innerHTML]="'login_page' | translate: 'footer_second_column_text' | safeHtml"></p>
            </div>
            <div class="widget">
                <h4 [innerHTML]="'login_page' | translate: 'footer_third_column_title' | safeHtml"></h4>
                <p [innerHTML]="'login_page' | translate: 'footer_thirds_column_text' | safeHtml"></p>
            </div>
        </div>
    </div>
    <div class="socket">
        <div class="container">
            <div id="links">
                <a
                    class="a-link"
                    (click)="seePersonalPrivacy()"
                    [innerHTML]="'login_page' | translate: 'bottom_bar_data_protection_policy' | safeHtml"
                ></a>
                <a
                    class="a-link"
                    (click)="seeMoreCookies()"
                    [innerHTML]="'login_page' | translate: 'bottom_bar_cookies' | safeHtml"
                ></a>
            </div>
            <div id="copyright">
                <a href="https://grantcontrol.net/" target="_blank">© GrantControl</a>
            </div>
        </div>
    </div>
</footer>
