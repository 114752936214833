import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    Self,
    ViewChild,
} from '@angular/core';

export type PopupSize = 'small' | 'medium' | 'large';

@Component({
    selector: 'fonda-popup',
    templateUrl: './popup.component.html',
    styleUrls: ['./popup.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupComponent {
    @ViewChild('contentContainer', { static: true }) contentContainerElementRef: ElementRef;
    @Output() close = new EventEmitter<void>();
    @Input() size: PopupSize = 'small';

    constructor(@Self() private elementRef: ElementRef) {}

    onCloseButtonClicked() {
        this.close.emit();
    }

    get popupClasses() {
        return ['popup-content-container--' + this.coerceSize()];
    }

    private coerceSize(): PopupSize {
        return ['small', 'medium', 'large'].includes(this.size) ? this.size : 'small';
    }
}
