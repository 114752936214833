import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { CookiesService } from '../cookies/cookies.service';
import { PackageManagerService } from '../package-manager/package-manager.service';
import { DOMService } from '../services/dom-service';

@Injectable()
export class UserChatWidgetService {
    private userengage?: any;
    private UE?: any;
    private _loaded = new ReplaySubject(1);
    private _shown = false;

    constructor(
        private packageManagerService: PackageManagerService,
        private domService: DOMService,
        private cookieService: CookiesService
    ) {
        this.cookieService.getFunctionalAccepted().subscribe(() => {
            this.init();
        });
    }

    init() {
        this.domService
            .appendScript('https://grantcontrol.user.com/widget.js', false, script => {
                script.dataset.cfasync = 'false';
                script.dataset.testid = 'user.com';
            })
            .subscribe(() => {
                this.userengage = (<any>window).userengage;
                this.UE = (<any>window).UE;

                if (this._shown) this.show();
                else this.hide();

                this._loaded.next();
                this._loaded.complete();
            });
    }

    show() {
        if (!this.canShow()) return;

        this._shown = true;

        if (!this.userengage || typeof this.userengage !== 'function') return;
        this.userengage('widget.show');
    }

    hide() {
        this._shown = false;
        if (!this.userengage || typeof this.userengage !== 'function') return;
        this.userengage('widget.hide');
    }

    updateUser(data: any): void {
        if (this.canShow()) {
            this._loaded.pipe(take(1)).subscribe(() => {
                this.UE.pageHit({
                    ...data,
                    apiKey: (<any>window).civchat.apiKey,
                });
            });
        }
    }

    private canShow() {
        const packages = this.packageManagerService.packages();
        return packages && packages.isUserDotComChatBox;
    }
}
