<td>
    <div class="form-item">
        <input
            id="name{{ ind }}"
            type="text"
            [ngModel]="name"
            (ngModelChange)="name = $event; changeValue()"
            name="name{{ ind }}"
            placeholder=" "
        />
        <label for="name{{ ind }}">{{ uuid | translate: 'description' }}</label>
        <div class="form-focus"></div>
        <div class="form-error" [ngClass]="{ active: !name && saveClicked }">Venligst udfyld dette felt</div>
    </div>
</td>
<td>
    <div class="form-item">
        <input
            id="date{{ ind }}"
            type="text"
            [ngModel]="rowDate | date: 'dd-MM-yyyy'"
            name="valu{{ ind }}"
            placeholder=" "
            disabled
        />
        <label for="date{{ ind }}">{{ uuid | translate: 'date' }}</label>
        <div class="form-focus"></div>
        <div class="form-error" [ngClass]="{ active: !rowDate && saveClicked }">Venligst udfyld dette felt</div>
        <div class="only-when-not-in-search">
            <fonda-basic-datepicker
                [date]="rowDate ? rowDate : newDate"
                [options]="options"
                (dateChange)="rowDate = $event; changeValue()"
            ></fonda-basic-datepicker>
        </div>
    </div>
</td>
<td>
    <div class="form-item">
        <input
            id="num{{ ind }}"
            type="text"
            OnlyNumber="true"
            name="value2{{ ind }}"
            placeholder=" "
            [ngModel]="amount | commasInInput"
            (ngModelChange)="amount = $event; changeValue()"
        />
        <label for="num{{ ind }}">{{ uuid | translate: 'amount' }}</label>
        <div class="form-focus"></div>
        <div class="form-error" [ngClass]="{ active: !amount && saveClicked }">Venligst udfyld dette felt</div>
    </div>
</td>
<td>
    <div class="form-item">
        <basic-select
            [label]="'payment_profile' | translate: 'payment_profiles'"
            [placeholder]="'payment_profile' | translate: 'payment_profiles'"
            [valuesWithColor]="paymentProfilesForDropdown"
            [colorMode]="true"
            (getChoosen)="handlePaymentProfileChange($event)"
            [choosenInput]="choosenPaymentProfile?.name"
        ></basic-select>
        <div class="form-focus"></div>

        <div class="form-error">Venligst udfyld dette felt</div>
    </div>
</td>

<td>
    <a class="icon trash-icon" (click)="clickDelete()"></a>
</td>
