import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export type ContentContainerColors = 'light' | 'dark' | 'blue';

@Component({
    selector: 'content-container',
    templateUrl: './content-container.component.html',
    styleUrls: ['./content-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        '[class]': '_containerClass',
    },
})
export class ContentContainerComponent {
    @Input() color: ContentContainerColors = 'light';

    get _containerClass() {
        return 'container-color--' + this.color;
    }
}
