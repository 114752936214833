import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'fileSize',
})
export class FileSizePipe implements PipeTransform {
    transform(bytes: number): string {
        if (bytes && bytes > 999999) {
            return Math.round((bytes * 100) / 1000000) / 100 + 'MB';
        } else if (bytes && bytes > 999) {
            return Math.round((bytes * 100) / 1000) / 100 + 'KB';
        } else {
            return bytes + 'B';
        }
    }
}
