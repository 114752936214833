import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';

@Component({
    selector: 'fonda-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationComponent implements AfterViewInit {
    PAGINATION_SPACER = '...';

    @Input() perPage = 20;
    @Output() perPageChange = new EventEmitter<number>();

    @Input() currentPage = 0;
    @Output() currentPageChange = new EventEmitter<number>();

    @Input() perPageOptions = [20, 50, 100, 250, 500];
    @Input() total = 0;

    @ViewChild('leftElement', { static: true }) leftElement: ElementRef<HTMLDivElement>;
    @ViewChild('rightElement', { static: true }) rightElement: ElementRef<HTMLDivElement>;

    minWidth = 'auto';

    constructor(private cdRef: ChangeDetectorRef) {}

    get options(): string[] {
        return this.perPageOptions.map(x => x.toString());
    }

    get pages(): (number | string)[] {
        const pages = this.pagesCount;
        const pa = [];

        for (let i = Math.max(this.currentPage - 2, 0); i < Math.min(pages, this.currentPage + 3); i++) {
            pa.push(i + 1);
        }

        return []
            .concat(
                !pa.includes(1) ? [1] : null,
                this.currentPage > 3 ? ['...'] : null,
                pa,
                this.currentPage < this.pagesCount - 4 ? ['...'] : null,
                !pa.includes(pages) ? [pages] : null
            )
            .filter(Boolean);
    }

    get pagesCount(): number {
        return Math.ceil(this.total / this.perPage);
    }

    updatePerPage(perPage: string): void {
        const perPageNum = parseInt(perPage, 10);
        this.perPageChange.emit(perPageNum);
    }

    updateCurrentPage(newCurrentPage: number | string): void {
        if (newCurrentPage === this.PAGINATION_SPACER) return;
        this.currentPageChange.emit(newCurrentPage as number);
    }

    ngAfterViewInit() {
        Promise.resolve().then(() => {
            this.minWidth =
                Math.max(this.leftElement.nativeElement.offsetWidth, this.rightElement.nativeElement.offsetWidth) +
                'px';
            this.cdRef.markForCheck();
        });
    }
}
