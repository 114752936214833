import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { SiteSettingsService } from '../services/site-settings.service';
import { CookieBannerStrategy } from './cookie-banner/cookie-banner-strategy';
import { CookieStrategy } from './cookie-strategy';
import { GTMCookieStrategy } from './gtm/gtm-cookie-strategy';
import { Maybe } from 'true-myth';

@Injectable({
    providedIn: 'root',
})
export class CookiesService {
    private _functionalConsent = new ReplaySubject<void>(1);
    private _marketingConsent = new ReplaySubject<void>(1);
    private _statisticConsent = new ReplaySubject<void>(1);

    constructor(
        private siteSettingsService: SiteSettingsService,
        private gtmCookieStrategy: GTMCookieStrategy,
        private cookieBannerStrategy: CookieBannerStrategy
    ) {}

    init() {
        this.getStrategy()
            .pipe(switchMap(strategy => strategy.initCookieConsent()))
            .subscribe({
                next: ({ functional, marketing, statistic }) => {
                    if (functional && !this._functionalConsent.isStopped) {
                        this._functionalConsent.next();
                        this._functionalConsent.complete();
                    }

                    if (marketing && !this._marketingConsent.isStopped) {
                        this._marketingConsent.next();
                        this._marketingConsent.complete();
                    }

                    if (statistic && !this._statisticConsent.isStopped) {
                        this._statisticConsent.next();
                        this._statisticConsent.complete();
                    }
                },
                complete: () => {
                    if (!this._statisticConsent.isStopped) {
                        this._statisticConsent.complete();
                    }

                    if (!this._marketingConsent.isStopped) {
                        this._marketingConsent.complete();
                    }

                    if (!this._functionalConsent.isStopped) {
                        this._functionalConsent.complete();
                    }
                },
            });
    }

    getFunctionalAccepted(): Observable<void> {
        return this._functionalConsent.asObservable();
    }

    private getStrategy(): Observable<CookieStrategy> {
        return this.siteSettingsService.getSettingSingle('cookie_information_gtm_id').pipe(
            take(1),
            map(gtmId => (Maybe.isJust(gtmId) ? this.gtmCookieStrategy : this.cookieBannerStrategy))
        );
    }
}
