import { Component, ElementRef, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';

@Component({
    selector: 'fonda-option',
    templateUrl: './option.component.html',
    styleUrls: ['./option.component.scss'],
})
export class OptionComponent<T> {
    @Input() value?: T;
    @Input() selected: boolean = false;
    @Input() showCheckbox: boolean = false;
    @Input() searchText: string = '';
    @Output() select = new EventEmitter<void>();
    @ViewChild('buttonElement', { static: true }) buttonElement: ElementRef<HTMLButtonElement>;

    get text(): string {
        return this.buttonElement.nativeElement ? this.buttonElement.nativeElement.textContent : '';
    }

    @HostBinding('class.hidden')
    get shouldHide() {
        return (
            !this.selected && this.searchText && this.text.toLowerCase().indexOf(this.searchText.toLowerCase()) === -1
        );
    }
}
