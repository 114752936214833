import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { PaymentPlanObject } from './payment-plan-object';
import { PaymentPlanRowComponent } from './payment-plan-row/payment-plan-row.component';
import { EventsHandlerService } from '../../../../services/events-handler-service';
import { BaseRecordComponent } from '../../base-record.component';
import { PaymentStoreService } from '../../../../services/payment-store-service';
import { PaymentProfilePopupService } from '../../../components/payment-profile/payment-profile-popup-service';
import { ActivatedRoute } from '@angular/router';
import { FondaApiService } from '../../../../api/fonda-api.service';
import { PaymentProfileDto } from '../../../../api/dto/payment-profile-dto/payment-profile-dto';
import { WordService } from '../../../../services/word-service';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'payment-plan-record',
    templateUrl: 'payment-plan-record.component.html',
})
export class PaymentPlanRecordComponent extends BaseRecordComponent implements OnInit, AfterViewInit {
    constructor(
        private activatedRoute: ActivatedRoute,
        @Inject(FondaApiService) private apiService: FondaApiService,
        private eventHandler: EventsHandlerService,
        private changeRef: ChangeDetectorRef,
        private paymentStoreService: PaymentStoreService,
        private paymentProfilePopup: PaymentProfilePopupService
    ) {
        super();
        this.paymentProfilePopup.onClosePopup.subscribe(() => {
            this.fetchPaymentProfiles();
        });
        this.fetchApplicationUuid();
        this.fetchPaymentProfiles();
        this.paymentProfilePopup.onClosePopup.subscribe(() => this.fetchPaymentProfiles());
    }

    @Input('uuid') public uuid: string;
    @Input('value') public value: object;
    @Input('isRequired') public isRequired: boolean;
    @Input('size') public size: number;
    @Input('title') title: string;
    @Input('readonly') public readonly = false;
    @Output('onChange') onChange = new EventEmitter<object>();
    @Input('configuration') configuration: object;

    @ViewChildren('paymentRows') paymentRows: QueryList<PaymentPlanRowComponent>;

    public applicationUuid = '';
    changed = false;
    initValue: object;
    paymentProfiles: Array<PaymentProfileDto> = [];

    public newDate: Array<PaymentPlanObject> = [];
    public payments: Array<PaymentPlanObject> = [];

    public emitValue() {
        this.onChange.emit(this.getValue());
    }

    public ngOnInit() {
        if (this.value) {
            const _value: any = this.value;
            _value.forEach(row => {
                this.payments.push(new PaymentPlanObject(row.name, row.date, row.amount, row.payment_profile));
            });

            this.newDate = [...this.payments];
        }
        this.emitValue();
    }

    public ngAfterViewInit() {
        this.changeRef.detectChanges();
        this.initValue = this.getValue();
    }

    public openPaymentProfile() {
        this.paymentProfilePopup.initPopup();
    }

    public save() {
        if (this.paymentRows) {
            this.paymentRows.forEach(pay => pay.onSaveClick());
        }
        if (this.isValid()) {
            this.eventHandler.triggerSavePostGrantedSection.next();
        }
    }

    public addNewPayment() {
        this.payments.push(new PaymentPlanObject('', null, 0, this.getDefaultPaymentProfile().uuid));
        this.changed = true;
        this.changeRef.detectChanges();
        this.emitValue();
    }

    public getPaymentByUuid(uuid: string): PaymentProfileDto {
        return this.paymentProfiles.find(p => p.uuid === uuid);
    }

    public isValid(): boolean {
        let isValid = true;
        if (this.paymentRows) {
            this.paymentRows.forEach(row => {
                if (!row || !row.isValid()) {
                    isValid = false;
                }
            });
            return isValid;
        } else {
            return false;
        }
    }

    public deleteRow(row: PaymentPlanObject) {
        this.payments.splice(this.payments.indexOf(row), 1);
        this.changeRef.detectChanges();
        this.emitValue();
    }

    public isChanged(): boolean {
        return this.changed && JSON.stringify(this.initValue) !== JSON.stringify(this.getValue()) && this.isValid();
    }

    public canSave(): boolean {
        return !(
            this.paymentRows &&
            this.changed &&
            JSON.stringify(this.initValue) !== JSON.stringify(this.getValue())
        );
    }

    public isEmpty(): boolean {
        return false;
    }

    public getValue(): object {
        const arrJson: Array<object> = [];
        let pastAmounts = 0;
        if (this.paymentRows) {
            this.paymentRows.forEach(payment => {
                const obj = payment.getValue();
                arrJson.push({
                    name: obj.name,
                    date: obj.date,
                    amount: obj.amount,
                    payment_profile: obj.paymentProfileUuid,
                });
                if (obj.date && WordService.isObjectDate(obj.date) && obj.date.getTime() < new Date().getTime()) {
                    pastAmounts += obj.amount ? obj.amount : 0;
                }
            });
            this.paymentStoreService.pastPaymentPlanEvent = new BehaviorSubject(pastAmounts);
        }
        return arrJson;
    }

    public getAmount(): number {
        let amount = 0;
        if (this.paymentRows) {
            this.paymentRows.forEach(row => {
                amount = amount + (row.getValue() ? row.getValue().amount : 0);
            });
        }

        return amount;
    }

    private fetchPaymentProfiles() {
        this.apiService
            .getApplicationPaymentProfiles(this.applicationUuid)
            .then(profiles => (this.paymentProfiles = [...profiles]));
    }

    private fetchApplicationUuid() {
        this.activatedRoute.queryParams.subscribe(params => {
            this.applicationUuid = params['uuid'];
        });
    }

    private getDefaultPaymentProfile(): PaymentProfileDto {
        return this.paymentProfiles.find(prof => prof.isDefault);
    }
}
