import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlideTabsComponent } from './slide-tabs.component';
import { SlideTabComponent } from './slide-tab.component';
import { SlideTabHeaderDirective } from './slide-tab-header.directive';

@NgModule({
    declarations: [SlideTabsComponent, SlideTabComponent, SlideTabHeaderDirective],
    exports: [SlideTabsComponent, SlideTabComponent, SlideTabHeaderDirective],
    imports: [CommonModule],
})
export class SlideTabsModule {}
