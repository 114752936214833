import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Host,
    Input,
    OnChanges,
    OnDestroy,
    Optional,
    SimpleChanges,
    SkipSelf,
} from '@angular/core';
import { AbstractControl, ControlContainer } from '@angular/forms';
import { Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';

@Component({
    selector: 'fonda-input-error',
    template: `
        <p class="error-message" *ngIf="displayError">
            <ng-content></ng-content>
        </p>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FondaInputErrorComponent implements OnChanges, OnDestroy {
    @Input() name: string;
    @Input() control: AbstractControl;
    @Input() errors: string | Array<string>;

    displayError = false;

    private sub = Subscription.EMPTY;

    constructor(
        @Optional() @SkipSelf() @Host() private parent: ControlContainer,
        private _changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.control || changes.name) {
            this.init();
        }
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    private init() {
        this.sub.unsubscribe();

        const control = this.getControl();
        if (!control) return;

        this.sub = control.statusChanges.pipe(startWith()).subscribe(() => {
            this.displayError = this.hasError(control);
            this._changeDetectorRef.markForCheck();
        });
    }

    private getControl(): AbstractControl {
        if (this.control) return this.control;
        if (this.name && 'control' in this.parent) {
            return this.parent.control.get(this.name);
        }

        return null;
    }

    private hasError(control: AbstractControl) {
        if (this.errors) {
            const errors = Array.isArray(this.errors) ? this.errors : [this.errors];
            return errors.some(error => control.hasError(error)) && (control.dirty || control.touched);
        }

        return control.invalid && (control.dirty || control.touched);
    }
}
