import { ComponentFactoryResolver, Injectable, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { ConfirmationPopupComponent, Sizes } from './confirmation-popup.component';
import { TranslateService } from '../../translation/translate.service';

@Injectable()
export class DynamicPopupService<T> {
    public acceptClick = new Subject<T>();
    public closeClick = new Subject();

    constructor(private factoryResolver: ComponentFactoryResolver, private translateService: TranslateService) {}

    public initPopup(
        header: string,
        body: string,
        approveButton: string,
        cancelButton: string,
        viewContainerRef: ViewContainerRef,
        size: Sizes,
        data?: T,
        savingPopup?: boolean
    ) {
        const factory = this.factoryResolver.resolveComponentFactory(ConfirmationPopupComponent);
        const component = factory.create(viewContainerRef.parentInjector);
        component.instance.actualSize = size;
        component.instance.initPopup(header, body, approveButton, cancelButton, data, savingPopup);
        component.instance.onApprove.subscribe((f: T) => this.acceptClick.next(f));
        component.instance.onClickCancel.subscribe(() => {
            viewContainerRef.remove(0);
            this.closeClick.next();
        });
        viewContainerRef.insert(component.hostView);
    }
}
