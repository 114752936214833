import { Component, ViewContainerRef } from '@angular/core';
import { Sizes } from '../confirmation-popup/confirmation-popup.component';
import { DynamicPopupService } from '../confirmation-popup/dynamic-popup-service';
import { LocalStorageService } from '../../../services/local-storage-service';
import { TranslateService } from '../../translation/translate.service';

@Component({
    selector: 'ncf-footer',
    templateUrl: 'footer.component.html',
})
export class FooterComponent {
    constructor(
        private localStorageService: LocalStorageService,
        private translateService: TranslateService,
        private confirmationPopupService: DynamicPopupService<void>,
        private viewContainerRef: ViewContainerRef
    ) {}

    public seePersonalPrivacy() {
        this.confirmationPopupService.initPopup(
            this.translateService.get(['login_page', 'data_protection_policy_title']),
            this.translateService.get(['login_page', 'data_protection_policy_text']),
            '',
            '',
            this.viewContainerRef,
            Sizes.Big
        );
    }

    public seeMoreCookies() {
        this.confirmationPopupService.initPopup(
            this.translateService.get(['login_page', 'cookies_title']),
            this.translateService.get(['login_page', 'cookies_see_more_text']),
            '',
            '',
            this.viewContainerRef,
            Sizes.Big
        );
    }
}
