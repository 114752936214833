import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { UploadedFondaFile } from '../../../models/uploaded-fonda-file';
import { FileService } from '../../../services/file.service';

export class FilesModel extends UploadedFondaFile {
    preventDeleting: boolean;
}

@Component({
    selector: 'app-files-table',
    templateUrl: './files-table.component.html',
    styleUrls: ['./files-table.component.scss'],
})
export class FilesTableComponent implements OnInit {
    get files(): Array<FilesModel> {
        return this._files;
    }

    @Input() set files(value: Array<FilesModel>) {
        this._files = value;
    }
    constructor(@Inject(FileService) private fileService: FileService) {}

    private _files: Array<FilesModel> = [];
    public staticFiles: Array<FilesModel> = [];

    @Output() fileDeleted = new EventEmitter<string>();

    ngOnInit() {}

    public deleteFile(uuid: string) {
        this.fileDeleted.emit(uuid);
        this._files.splice(this._files.map(file => file.uuid).indexOf(uuid), 1);
    }

    public downloadFile(uploadedNcfFile: FilesModel) {
        if (!uploadedNcfFile.headContent || !uploadedNcfFile.blob) {
            this.fileService
                .downloadFile(uploadedNcfFile.uuid)
                .then(file => this.fileService.downloadFileToOwnMachine(file));
        } else {
            this.fileService.downloadFileToOwnMachine(uploadedNcfFile);
        }
    }

    public addFile(uploadedNcfFile: FilesModel) {
        if (!this._files.find(file => file.uuid == uploadedNcfFile.uuid)) {
            this._files.push(uploadedNcfFile);
        }
    }

    public addStaticFile(uploadedNcfFile: FilesModel) {
        if (!this.staticFiles.find(file => file.uuid == uploadedNcfFile.uuid)) {
            this.staticFiles.push(uploadedNcfFile);
        }
    }

    public deleteStaticFile(uuid: string) {
        this.fileDeleted.emit(uuid);
        this.staticFiles.splice(this.staticFiles.map(file => file.uuid).indexOf(uuid), 1);
    }
}
