import { PaymentProfileRecordValueDto } from './payment-profile-record-value-dto';

export class PaymentProfileDto {
    constructor(
        readonly uuid: string,
        readonly applicationUuid: string,
        readonly name: string,
        readonly isDefault: boolean,
        readonly recordValues: Array<PaymentProfileRecordValueDto>
    ) {}
}
