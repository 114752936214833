<div class="search-area basic-search-area" [ngClass]="{ active: active }" (window:resize)="onResize()">
    <div class="container">
        <a id="menu-search-close" (click)="active = false"><div></div></a>
        <form id="form-search">
            <h2>
                {{ 'topbar' | translate: 'quick_search_title' }}
            </h2>
            <div class="form-item">
                <input
                    id="input-search"
                    #inputSearch
                    [ngModel]="massSearch"
                    (ngModelChange)="massSearchTrigger($event)"
                    type="search"
                    name="massSearch"
                    value=""
                    placeholder=" "
                    autofocus
                />
                <label for="input-search"></label>
                <div class="form-focus"></div>
                <div class="form-error"></div>
            </div>
        </form>
        <section class="search-list-container" [ngClass]="{ loading: loadingSearch }">
            <search-pagination-component
                *ngIf="massSearch && massSearch.length > 2"
                [paginationEntry]="totalNumberOfApplications"
                (onPageChange)="handlePageChange($event)"
            ></search-pagination-component>
            <div style="overflow: auto" [ngStyle]="{ 'max-height': height + 'px' }">
                <search-results-component
                    (onSearchClick)="active = false"
                    [basicSearch]="true"
                    *ngIf="massSearch && massSearch.length > 2"
                    [searchResults]="searchResult"
                ></search-results-component>
                <h3 style="width: 100%; text-align: center; height: 29px" *ngIf="massSearch.length < 3">
                    {{ 'topbar' | translate: 'quick_search_at_least_three_characters' }}
                </h3>
            </div>
        </section>
    </div>
    <div class="blur-bg"></div>
</div>
