import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { LocalStorageService } from '../../services/local-storage-service';
import { Sizes } from '../../shared/components/confirmation-popup/confirmation-popup.component';
import { DynamicPopupService } from '../../shared/components/confirmation-popup/dynamic-popup-service';
import { PopupManager } from '../../shared/popup/popup-manager';
import { TranslateService } from '../../shared/translation/translate.service';

@Component({
    selector: 'fonda-cookie-banner',
    templateUrl: './cookie-banner.component.html',
})
export class CookieBannerComponent implements OnInit {
    showCookies: boolean = true;

    constructor(
        private localStorageService: LocalStorageService,
        private confirmationPopupService: DynamicPopupService<void>,
        private translateService: TranslateService,
        private viewContainerRef: ViewContainerRef,
        private popupManager: PopupManager<boolean>
    ) {}

    ngOnInit(): void {
        const accepted = this.localStorageService.isCookieAccepted();
        this.showCookies = !accepted;

        if (accepted) {
            setTimeout(() => {
                this.close();
            });
        }
    }

    seeMoreCookies() {
        this.confirmationPopupService.initPopup(
            this.translateService.get(['login_page', 'cookies_title']),
            this.translateService.get(['login_page', 'cookies_see_more_text']),
            '',
            '',
            this.viewContainerRef,
            Sizes.Big
        );
    }

    clickCookiesClose() {
        this.showCookies = false;
        this.localStorageService.setCookiesAccepted();
        this.close();
    }

    private close(): void {
        this.popupManager.close(true);
    }
}
