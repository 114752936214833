import * as xml2js from 'xml2js';
import { Inject, Injectable } from '@angular/core';
import { TranslateObj } from './translate.service';
import { forkJoin, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { SectionTypes } from '../../api/models/section-types';
import { HttpClient } from '@angular/common/http';
import { API_BASE_URL } from '../../api/api-base-url';
import * as deepmerge from 'deepmerge';

export interface XMLTranslationValue {
    language: [string];
    text: [string];
}

export interface XMLObject {
    identifier: [string];
    texts?: [
        {
            text: Array<XMLObject>;
        }
    ];
    translation?: Array<XMLTranslationValue>;
}

export interface XMLRootObject {
    xml: {
        texts: [
            {
                text: Array<XMLObject>;
            }
        ];
    };
}

@Injectable()
export class XmlService {
    constructor(private http: HttpClient, @Inject(API_BASE_URL) private apiBaseUrl: string) {}

    loadData(): Observable<TranslateObj> {
        const parse = switchMap((values: string[]) => forkJoin(values.map(this._parseXml)));
        const mapToTranslateObj = map((values: any[]) => values.map(value => this._toTranslateObj(value)));
        const reduceToTranslateObj = map((values: TranslateObj[]) =>
            values.reduce((acc, value) => deepmerge(acc, value))
        );

        const standard: Observable<TranslateObj> = forkJoin([
            this.getRecordXmlFile(),
            this.getCountryXmlFile(),
            this.getErrorsXmlFile(),
            this.getSchemasXmlFile(),
            this.getCategoryXmlFile(),
            this.getStatusesXmlFile(),
            this.getCorrespondenceTemplateXmlFile(),
            this.getSubCategoryXmlFile(),
            this.getBehaviourXmlFile(),
            this.getApiResponseFile(),
        ]).pipe(parse, mapToTranslateObj, reduceToTranslateObj);

        const toArray = map((value: string) => [value]);
        const addPrefix = (prefix: string) =>
            map<TranslateObj, TranslateObj>((value: TranslateObj) => ({
                [prefix]: value,
            }));

        const siteText = this.getSiteTextXmlFile().pipe(toArray, parse, mapToTranslateObj, reduceToTranslateObj);

        const transitions = this.getTransitionsXmlFile().pipe(
            toArray,
            parse,
            mapToTranslateObj,
            reduceToTranslateObj,
            addPrefix('transitions')
        );

        const applicantSection = this.getApplicantSectionXmlFile().pipe(
            toArray,
            parse,
            mapToTranslateObj,
            reduceToTranslateObj,
            addPrefix(SectionTypes.APPLICANT)
        );

        const caseworkerSection = this.getCaseworkerSectionXmlFile().pipe(
            toArray,
            parse,
            mapToTranslateObj,
            reduceToTranslateObj,
            addPrefix(SectionTypes.CASEWORKER)
        );

        const postGrantSection = this.getPostGrantSectionXmlFile().pipe(
            toArray,
            parse,
            mapToTranslateObj,
            reduceToTranslateObj,
            addPrefix(SectionTypes.POST_GRANT)
        );

        const agendaManualSection = this.getAgendaManualSectionXmlFile().pipe(
            toArray,
            parse,
            mapToTranslateObj,
            reduceToTranslateObj,
            addPrefix('agenda_manual_section')
        );

        return forkJoin([
            transitions,
            standard,
            agendaManualSection,
            siteText,
            applicantSection,
            caseworkerSection,
            postGrantSection,
        ]).pipe(reduceToTranslateObj);
    }

    public _parseXml(value: string): Observable<any> {
        return new Observable(subscriber => {
            try {
                xml2js.parseString(value, (err, obj) => {
                    if (err) return subscriber.error(err);
                    subscriber.next(obj);
                    subscriber.complete();
                });
            } catch (e) {
                subscriber.error(e);
            }
        });
    }

    public _toTranslateObj(value: XMLRootObject): TranslateObj {
        // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
        function innerParse(translations: Array<XMLObject>): TranslateObj {
            return translations.reduce((acc: TranslateObj, obj: XMLObject) => {
                const translation =
                    obj.translation &&
                    obj.translation.reduce(
                        (trans: TranslateObj, v: XMLTranslationValue) =>
                            deepmerge(trans, { [v.language[0]]: v.text[0] }),
                        {}
                    );

                return deepmerge(acc, { [obj.identifier[0]]: obj.texts ? innerParse(obj.texts[0].text) : translation });
            }, {});
        }

        const parsed = value.xml.texts[0].text ? innerParse(value.xml.texts[0].text) : {};
        return parsed;
    }

    private getApiResponseFile(): Observable<string> {
        return this.loadXml('api_response.xml');
    }

    private getRecordXmlFile(): Observable<string> {
        return this.loadXml('record.xml');
    }

    private getSiteTextXmlFile(): Observable<string> {
        return this.loadXml('site_text.xml');
    }

    private getCountryXmlFile(): Observable<string> {
        return this.loadXml('country.xml');
    }

    private getErrorsXmlFile(): Observable<string> {
        return this.loadXml('invalid_form_error_code.xml');
    }

    private getSchemasXmlFile(): Observable<string> {
        return this.loadXml('application_schema.xml');
    }

    private getCategoryXmlFile(): Observable<string> {
        return this.loadXml('category.xml');
    }

    private getStatusesXmlFile(): Observable<string> {
        return this.loadXml('status.xml');
    }

    private getApplicantSectionXmlFile(): Observable<string> {
        return this.loadXml('applicant_section.xml');
    }

    private getCaseworkerSectionXmlFile(): Observable<string> {
        return this.loadXml('caseworker_section.xml');
    }

    private getPostGrantSectionXmlFile(): Observable<string> {
        return this.loadXml('post_grant_section.xml');
    }

    private getCorrespondenceTemplateXmlFile(): Observable<string> {
        return this.loadXml('correspondence_template.xml');
    }

    private getTransitionsXmlFile(): Observable<string> {
        return this.loadXml('transition.xml');
    }

    private getAgendaManualSectionXmlFile(): Observable<string> {
        return this.loadXml('agenda_manual_section.xml');
    }

    private getSubCategoryXmlFile(): Observable<string> {
        return this.loadXml('sub_category.xml');
    }

    private getBehaviourXmlFile(): Observable<string> {
        return this.loadXml('behaviour.xml');
    }

    private loadXml(file: string): Observable<string> {
        return this.http.get(this.apiBaseUrl + '/xmls/' + file, { responseType: 'text' });
    }
}
