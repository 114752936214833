import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot, UrlTree } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { LanguageStoreService } from './services/language-store.service';
import { PackageManagerService } from './package-manager/package-manager.service';
import { TranslateService } from './shared/translation/translate.service';
import { mapTo } from 'rxjs/operators';

@Injectable()
export class AppLoadGuard implements CanActivate, CanActivateChild {
    constructor(
        private languageStoreService: LanguageStoreService,
        private translateService: TranslateService,
        private packageManagerService: PackageManagerService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.waitForLoad();
    }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.waitForLoad();
    }

    private waitForLoad() {
        return forkJoin([
            this.languageStoreService.whenReady(),
            this.translateService.whenReady(),
            this.packageManagerService.whenReady(),
        ]).pipe(mapTo(true));
    }
}
