export const UPDATE_STATUS_TRANSLATION = 'update-status';

export function isUpdateStatus(str: string): boolean {
    return str && str.includes(UPDATE_STATUS_TRANSLATION);
}

export const UPDATE_APPLICATION_TRANSLATION = 'update-application';

export function isUpdateApplication(str: string): boolean {
    return str && str.includes(UPDATE_APPLICATION_TRANSLATION);
}

export interface ErrorInterface {
    errorCode: number;
    errorException: string;
    errorMessage: string;
    errorParameters: any;
    errorReferenceUrl: string;
    errorIdentifier: string;
}
