import { EventEmitter, Injectable } from '@angular/core';
import { DOMService } from '../../services/dom-service';
import { FilesConverterPopupComponent } from './files-converter-popup.component';
import { UploadedFondaFile } from '../../models/uploaded-fonda-file';
import { take, takeUntil } from 'rxjs/operators';

@Injectable()
export class FilesConverterPopupService {
    constructor() {}

    public finishEvent$ = new EventEmitter<Array<UploadedFondaFile>>();

    public initConverter(
        files: Array<{ uuid: string; name: string; size: number }>,
        domService: DOMService,
        mimeTypesNotConvertable: Array<string>
    ) {
        const component = domService.appendComponentToBody(FilesConverterPopupComponent);

        component.instance.filesConvertedEvent
            .pipe(take(1), takeUntil(component.instance.close))
            .subscribe(filesFromPopup => {
                this.finishEvent$.emit(filesFromPopup);
                domService.removeComponentFromBody(component);
            });

        component.instance.close.pipe(take(1), takeUntil(component.instance.filesConvertedEvent)).subscribe(() => {
            domService.removeComponentFromBody(component);
        });

        component.instance.initPopup(files, mimeTypesNotConvertable);
    }
}
