export class RecordValueDto {
    constructor(
        readonly uuid: string,
        readonly isRequired: boolean,
        readonly type: string,
        readonly fieldSize: number,
        readonly value: object,
        public isEditable: boolean,
        readonly configuration: object,
        readonly parentRecordUuid: string,
        readonly stringifiedValue: string
    ) {}
}
