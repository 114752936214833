import { CorrespondenceMessageDto } from './correspondence-message-dto';
import { BackendUserData } from '../backend-user-data';

export class CorrespondenceThreadDto {
    constructor(
        readonly uuid,
        readonly applicationUuid,
        readonly creator: BackendUserData,
        readonly createdAt: string,
        readonly locked: boolean,
        readonly title: string,
        readonly messages: Array<CorrespondenceMessageDto>,
        readonly read: boolean,
        readonly readByApplicant: boolean
    ) {}
}
