import { ApplicationRecordDto } from '../../../api/dto/search-dto/application-record-dto';
import { RecordsInterface } from '../components/search-filters/records-interface';
import { SearchProjectionModel } from './search-projection-model';
import { ProjectionOptions } from '../services/search-service';
import { TranslateService } from '../../../shared/translation/translate.service';

export class RecordAdapter implements RecordsInterface {
    constructor(readonly applicationRecordDto: ApplicationRecordDto, private translateService: TranslateService) {}

    public get uuid(): string {
        return this.applicationRecordDto.uuid;
    }

    public get projections(): Array<SearchProjectionModel> {
        const arr: SearchProjectionModel[] = [];
        // TODO: Add some translations things
        this.applicationRecordDto.searchProjections.forEach(projection => {
            arr.push(
                new SearchProjectionModel(
                    projection.behaviour,
                    projection.possibleClauses,
                    projection.primitive,
                    projection.projection,
                    this.translateService.get([
                        this.uuid,
                        projection.projection === ProjectionOptions.Main ? 'title' : projection.projection,
                    ])
                )
            );
        });
        return arr;
    }

    public get type(): string {
        return this.applicationRecordDto.type;
    }

    public get title(): string {
        return this.translateService.get([this.uuid, 'title']);
    }
}
