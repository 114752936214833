<button
    type="button"
    role="switch"
    class="fonda-toggle"
    [attr.aria-checked]="value.toString()"
    (click)="toggle()"
    (focus)="focused()"
>
    <span class="fonda-toggle__switch"></span>
</button>
