import { Subject } from 'rxjs/Subject';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class FondaApiGlobalVariables {
    static readonly GRANT_LETTER_PARENT_UUID = '14e782eb-8c80-403a-90ea-f6b3868b27a3';
    public isRightNowSomethingInProcess = new BehaviorSubject<boolean>(false);
    public uploadingFileProgress = new Subject<number>();
    public reviewSubPageEmitter = new Subject<number>();
    public actualSchemaUuid = '';
    public actualCategoryUuidEmitter = new Subject<string>();
    public actualCategoryUuid = '';
}
