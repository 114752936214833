export class BoardMeetingDto {
    constructor(
        readonly name: string,
        readonly fiscalYear: number,
        readonly date: string,
        readonly acceptingApplicationLastDate: string,
        readonly hasAgenda?: boolean
    ) {}
    public isSelectable: boolean;
    public isPublished: boolean;
    public uuid: string;
    public hasApplication: boolean;
}
