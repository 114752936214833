import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '../translate.service';

export class HtmlElements {
    static readonly Elements: Array<{
        title: string;
        htmlBody: string;
    }> = [
        {
            title: '{~red_star~}',
            htmlBody: '<span class="icon-form-error">*</span>',
        },
        {
            title: '{~lock~}',
            htmlBody: '<span class="icon-form-lock"></span>',
        },
    ];
}

@Pipe({
    name: 'translateWithToken',
    pure: false,
})
export class TranslateWithTokenPipe implements PipeTransform {
    constructor(private translateService: TranslateService) {}

    transform(identifier: string, subId?: string | number, subSubId?: string): string {
        let result = '';

        if (subSubId) {
            result = this.translateService.get([identifier, subId.toString(), subSubId]);
        } else if (subId || subId === 0) {
            result = this.translateService.get([identifier, subId.toString()]);
        } else {
            if (this.translateService.get([identifier])) {
                result = this.translateService.get([identifier]);
            } else {
                result = identifier + ' - not translated';
            }
        }

        HtmlElements.Elements.forEach(element => {
            result = result.replace(element.title, element.htmlBody);
        });

        return result;
    }
}
