import { AuthService } from '../auth/auth.service';
import { Inject, Injectable } from '@angular/core';
import { fromEvent, interval, merge, Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';

const MINUTES_UNTIL_AUTO_LOGOUT = 60; // in mins
const CHECK_INTERVAL = 5000; // in ms
const STORE_KEY = 'last-action';

@Injectable()
export class AutoLogoutService {
    constructor(private authService: AuthService, @Inject(DOCUMENT) private document: Document) {
        const documentEvents$: Observable<any>[] = [
            fromEvent(this.document, 'click', { passive: true }),
            fromEvent(this.document, 'keydown', { passive: true }),
            fromEvent(this.document, 'scroll', { passive: true }),
        ];

        merge(documentEvents$).subscribe(() => {
            this.lastAction = Date.now();
        });

        interval(CHECK_INTERVAL).subscribe(() => {
            this.check();
        });
    }

    get lastAction() {
        return parseInt(localStorage.getItem(STORE_KEY), 10);
    }

    set lastAction(value) {
        localStorage.setItem(STORE_KEY, value.toString(10));
    }

    check() {
        const now = Date.now();
        const timeLeft = this.lastAction + MINUTES_UNTIL_AUTO_LOGOUT * 60 * 1000;
        const isTimeout = timeLeft - now < 0;
        if (isTimeout && this.authService.hasIdentity()) {
            this.authService.clearLocalStorage();
            location.href = '/';
        }
    }
}
