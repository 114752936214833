<form *ngIf="files && files.length > 0">
    <div class="form-item" style="float: none">
        <div class="upload" style="margin: 15px 0">
            <div class="upload-head thread">
                <div class="upload-filename">{{ 'general' | translate: 'file_name' }}</div>
                <div class="upload-size">{{ 'general' | translate: 'file_size' }}</div>
                <div class="upload-delete">{{ 'general' | translate: 'delete' }}</div>
            </div>

            <div class="upload-head thread" *ngFor="let file of files">
                <div class="upload-filename downloadable" (click)="downloadFile(file)">{{ file.fileName }}</div>
                <div class="upload-size">{{ file.size | fileSize }}</div>
                <div class="upload-delete">
                    <a class="delete-file" (click)="deleteFile(file.uuid)" *ngIf="!file.preventDeleting">
                        <div class="icon-delete-file"></div>
                    </a>
                </div>
            </div>
            <div class="upload-head thread" *ngFor="let file of staticFiles">
                <div class="upload-filename downloadable" (click)="downloadFile(file)">{{ file.fileName }}</div>
                <div class="upload-size">{{ file.size | fileSize }}</div>
                <div class="upload-delete">
                    <a class="delete-file" (click)="deleteStaticFile(file.uuid)" *ngIf="!file.preventDeleting">
                        <div class="icon-delete-file"></div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</form>
