import { Directive, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { interval, Subscription } from 'rxjs';

@Directive({
    selector: '[forceOneLine]',
})
export class ForceOneLineDirective implements OnInit, OnDestroy {
    private el: HTMLElement;
    private initialFontSize: number;
    private _subscription = Subscription.EMPTY;

    constructor(private _eref: ElementRef) {
        this.el = this._eref.nativeElement;
    }

    ngOnInit() {
        this.initialFontSize = Number(window.getComputedStyle(this.el).lineHeight.split('px')[0]);
        this.init();
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    init() {
        this._subscription.unsubscribe();
        this._subscription = interval(100).subscribe(() => {
            if (this.elementInitialised() && !this.isGoodHeight()) {
                this.makeFontSmaller();
            }
        });
    }

    @HostListener('window:resize', [])
    onWindowResize() {
        this.el.style.fontSize = this.initialFontSize + 'px';
        this.init();
    }

    private makeFontSmaller() {
        const actualFontSize = window.getComputedStyle(this.el).fontSize.split('px');
        this.el.style.fontSize = Number(actualFontSize[0]) - 1 + 'px';
    }

    private isGoodHeight() {
        const lineHeight = window.getComputedStyle(this.el).lineHeight.split('px');
        if (Number(lineHeight[0]) * 1.5 > this.el.offsetHeight) {
            this._subscription.unsubscribe();
            return true;
        } else {
            return false;
        }
    }

    private elementInitialised(): boolean {
        return !!this.el.innerText;
    }
}
