import { ErrorInterface, isUpdateStatus, UPDATE_STATUS_TRANSLATION } from '../../models/error-interface';
import { TranslateService } from './translate.service';
import { Injectable } from '@angular/core';

export class ErrorMessagesStoreObject {
    constructor(readonly sectionId: number, public messages: Array<string>) {}
}

@Injectable()
export class ErrorTranslateService {
    constructor(private translateService: TranslateService) {}

    public getCaseworkerMessages(obj: ErrorInterface): Array<string> {
        const errorStringMessages = [];
        if (obj.errorParameters && obj.errorParameters.caseworker_application) {
            const allMessages = [].concat.apply([], Object.values(obj.errorParameters.caseworker_application));

            allMessages.forEach(uuidKey => {
                let tmpMessage = this.translateService.get([Object.keys(uuidKey)[0], 'title']);
                tmpMessage += ' : ' + this.translateService.get([uuidKey[Object.keys(uuidKey)[0]]]) + '\n';
                errorStringMessages.push(tmpMessage);
            });
        }
        if (obj.errorParameters && obj.errorParameters.post_grant_application) {
            const allMessages = [].concat.apply([], Object.values(obj.errorParameters.post_grant_application));

            allMessages.forEach(uuidKey => {
                let tmpMessage = this.translateService.get([Object.keys(uuidKey)[0], 'title']);
                tmpMessage += ' : ' + this.translateService.get([uuidKey[Object.keys(uuidKey)[0]]]) + '\n';
                errorStringMessages.push(tmpMessage);
            });
        }
        if (obj.errorParameters && obj.errorParameters.applicant_application) {
            const allMessages = [].concat.apply([], Object.values(obj.errorParameters.applicant_application));

            allMessages.forEach(uuidKey => {
                let tmpMessage = ' (Ansøger) ' + this.translateService.get([Object.keys(uuidKey)[0], 'title']);
                tmpMessage += ' : ' + this.translateService.get([uuidKey[Object.keys(uuidKey)[0]]]) + '\n';
                errorStringMessages.push(tmpMessage);
            });
        }
        return errorStringMessages;
    }

    public getApplicantMessages(obj: ErrorInterface): Array<ErrorMessagesStoreObject> {
        const errorMessages: Array<ErrorMessagesStoreObject> = [];
        if (obj.errorParameters) {
            const applicantMessages = obj.errorParameters.applicant_application;
            if (applicantMessages) {
                const allMessages = [].concat.apply([], Object.values(applicantMessages));
                const sections = Object.keys(applicantMessages);
                sections.forEach(section => {
                    const arr: Array<string> = [];
                    applicantMessages[section].forEach(uuidKey => {
                        let tmpMessage = this.translateService.get([Object.keys(uuidKey)[0], 'title']);
                        tmpMessage += ' : ' + this.translateService.get([uuidKey[Object.keys(uuidKey)[0]]]) + '\n';
                        arr.push(tmpMessage);
                    });
                    errorMessages.push(new ErrorMessagesStoreObject(Number(section), arr));
                });
            }
        }

        return errorMessages;
    }

    public getProfileMessages(obj: ErrorInterface): ErrorMessagesStoreObject {
        if (obj.errorParameters) {
            const applicantMessages = obj.errorParameters;
            if (applicantMessages) {
                const errorStringMessages = [];

                applicantMessages.forEach(uuidKey => {
                    if (uuidKey[Object.keys(uuidKey)[0]].length < 2) {
                        let tmpMessage = '<b>' + this.translateService.get([Object.keys(uuidKey)[0], 'title']) + '</b>';
                        tmpMessage += ' : ' + this.translateService.get([uuidKey[Object.keys(uuidKey)[0]]]);
                        errorStringMessages.push(tmpMessage);
                    } else {
                        uuidKey[Object.keys(uuidKey)[0]].forEach(numberOfError => {
                            const tmpMessage = this.translateService.get([numberOfError]);
                            errorStringMessages.push(tmpMessage);
                        });
                    }
                });

                return new ErrorMessagesStoreObject(0, errorStringMessages);
            }
        }
    }

    public getMessageHeader(error: ErrorInterface): string {
        if (isUpdateStatus(error.errorIdentifier)) {
            if (this.translateService.has([error.errorIdentifier, error.errorException])) {
                return this.translateService.get([error.errorIdentifier, error.errorException]);
            } else {
                return this.translateService.get([UPDATE_STATUS_TRANSLATION, error.errorException]);
            }
        } else {
            return this.translateService.get([error.errorIdentifier, error.errorException]);
        }
    }
}
