import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { FondaApiGlobalVariables } from '../../../../api/fonda-api-global-variables';
import { FondaApiService } from '../../../../api/fonda-api.service';
import { GlobalVariables } from '../../../../services/global-variables';
import { TranslateService } from '../../../translation/translate.service';
import { BaseRecordComponent } from '../../base-record.component';

@Component({
    selector: 'subcategory-record',
    templateUrl: 'subcategory-record.component.html',
})
export class SubcategoryRecordComponent extends BaseRecordComponent implements OnInit, OnDestroy {
    @Input('uuid') public uuid: string;
    @Input('value') public value: { uuid?: string };
    @Input('isRequired') public isRequired: boolean;
    @Input('size') public size: number;
    @Input('readonly') public readonly = false;
    @Input('onSearch') public onSearch = false;
    @Output('onChange') onChange = new EventEmitter<object>();
    public selectedSubCategory: { title: string; uuid: string };
    public selectedUuid: string;
    public title = '';
    public subTitles: Array<string> = [];
    public subCategories: Array<{ title: string; uuid: string }> = [];
    public configuration: object;

    private initValue: string;
    private sub = Subscription.EMPTY;

    constructor(
        private apiService: FondaApiService,
        private translateService: TranslateService,
        private storeService: FondaApiGlobalVariables
    ) {
        super();
    }

    public ngOnInit() {
        this.sub = this.storeService.actualCategoryUuidEmitter
            .pipe(startWith(this.storeService.actualCategoryUuid))
            .subscribe(uuid => {
                this.handleUuid(uuid);
            });
        this.emitValue();
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    public emitValue() {
        this.onChange.emit(this.getValue());
    }

    public chooseSubCategory(categoryTitle: string) {
        this.selectedSubCategory = this.subCategories.find(caseworker => caseworker.title === categoryTitle);
        if (this.selectedSubCategory) {
            this.selectedUuid = this.selectedSubCategory.uuid;
        } else {
            this.selectedUuid = '';
        }
        this.emitValue();
    }

    public reset() {
        this.chooseSubCategory(null);
        this.emitValue();
    }

    public isValid(): boolean {
        return !(this.isRequired && !this.value.uuid);
    }

    public isChanged(): boolean {
        return this.initValue !== this.selectedUuid;
    }

    public isEmpty(): boolean {
        return !this.value;
    }

    public getValue(): object {
        if (
            (!this.selectedSubCategory || this.selectedSubCategory.title === GlobalVariables.NO_ONE_VARIABLE) &&
            !this.onSearch
        ) {
            return {};
        }
        return { uuid: this.selectedUuid };
    }

    private async handleUuid(uuid: string) {
        const pSubCategoriesUuid = uuid
            ? this.apiService.getCategorySubCategories(uuid)
            : this.apiService.getAllSubcategories();
        await pSubCategoriesUuid.then(uuids => {
            const subCategories = uuids.map(subCat => ({
                title: this.translateService.get([subCat.uuid]),
                uuid: subCat.uuid,
            }));
            this.subCategories = [this.getNoSubCatOption()].concat(subCategories);
            this.subTitles = this.subCategories.map(cat => cat.title);
        });
        this.value = this.value || {};
        this.initValue = this.value.uuid || '';
        this.selectedSubCategory = this.subCategories.find(cat => cat.uuid === this.initValue);
        if (this.selectedSubCategory) {
            this.selectedUuid = this.selectedSubCategory.uuid;
        } else {
            this.selectedUuid = this.initValue;
        }
    }

    private getNoSubCatOption() {
        return {
            title: GlobalVariables.NO_ONE_VARIABLE,
            uuid: '',
        };
    }
}
