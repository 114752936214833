import { Component, Input } from '@angular/core';

@Component({
    selector: 'message-date-display',
    templateUrl: 'message-date-display.component.html',
})
export class MessageDateDisplay {
    @Input() createdAt;

    isToday(date) {
        return new Date().toDateString() == new Date(date).toDateString();
    }
}
