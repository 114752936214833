import { Observable } from 'rxjs';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FondaApiGlobalVariables } from './fonda-api-global-variables';
import { tap } from 'rxjs/operators';

@Injectable()
export class InProgressHttpInterceptor implements HttpInterceptor {
    readonly NonInterruptCalls = ['notification'];

    constructor(private variables: FondaApiGlobalVariables) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.NonInterruptCalls.find(c => request.url.includes(c))) {
            this.variables.isRightNowSomethingInProcess.next(true);
        }

        return next.handle(request).pipe(
            tap<any>(
                (ev: HttpEvent<any>) => {
                    if (ev instanceof HttpResponse) {
                        this.variables.isRightNowSomethingInProcess.next(false);
                    }
                },
                response => {
                    if (response instanceof HttpErrorResponse) {
                        this.variables.isRightNowSomethingInProcess.next(false);
                    }
                }
            )
        );
    }
}
