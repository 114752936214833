import { Component } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ImpersonatorService } from '../impersonator.service';
import { PopupManager } from '../../shared/popup/popup-manager';
import { IPopup } from '../../shared/popup/popup.interface';

@Component({
    selector: 'fonda-impersonator-login-popup',
    templateUrl: './impersonator-login-popup.component.html',
})
export class ImpersonatorLoginPopupComponent implements IPopup<boolean> {
    form = this.fb.group({
        email: ['', [Validators.required, Validators.email]],
    });

    constructor(
        private fb: UntypedFormBuilder,
        private impersonatorLoginService: ImpersonatorService,
        private popupManager: PopupManager<boolean>
    ) {}

    close() {
        this.popupManager.close(false);
    }

    submit() {
        if (!this.form.valid) return;
        this.impersonatorLoginService.startImpersonating(this.form.value.email).subscribe(() => {
            this.close();
        });
    }
}
