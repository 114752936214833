<div *ngIf="!readonly" class="form-item" [ngClass]="{ half: size === 1 }">
    <input
        id="application-firstname{{ uuid }}"
        type="text"
        name="value2"
        [formControl]="text"
        placeholder=" "
        [required]="isRequired"
        (focusout)="changeToCorrect()"
        [maxlength]="configuration && configuration.max_length"
    />
    <label for="application-firstname{{ uuid }}">{{ uuid | translate: 'title' }} </label>
    <div class="form-focus"></div>
    <div class="form-error" [ngClass]="{ active: text.hasError('isNotValidUrl') }">Ugyldig hjemmeside</div>
    <div
        class="form-error configuration active"
        *ngIf="configuration && configuration.max_length && text && text.value"
    >
        {{ text.value.length }} / {{ configuration.max_length }}
    </div>
</div>

<a class="form-item" *ngIf="readonly" [ngClass]="{ full: size === 2 }">
    <p>{{ uuid | translate: 'title' }}</p>
    <span>{{ text.value ? text.value : '-' }}</span>
    <div class="form-focus"></div>
    <div class="form-error"></div>
</a>
