<div class="form-item" [ngClass]="{ half: size === 1 }" *ngIf="!readonly && !onSearch">
    <div class="checkbox">
        <input
            type="checkbox"
            placeholder=" "
            style="width: 24px; height: 24px"
            [(ngModel)]="booleanValue"
            (ngModelChange)="emitValue()"
            [id]="uuid"
            name=""
        />
        <label style="font-size: 1em" [for]="uuid" class="only-icon"> </label>
        <label style="font-size: 1em">{{ uuid | translate: 'title' }}</label>
    </div>
</div>

<div class="form-item" [ngClass]="{ half: size === 1 }" *ngIf="!readonly && onSearch">
    <div class="checkbox search">
        <input
            type="checkbox"
            placeholder=" "
            style="width: 24px; height: 24px"
            [(ngModel)]="booleanValue"
            (ngModelChange)="emitValue()"
            [id]="uuid + '2'"
            name=""
        />
        <label [for]="uuid + '2'">{{ title }}</label>
    </div>
</div>

<a *ngIf="readonly" class="readonly" [ngClass]="{ full: size === 2 }">
    <div class="checkbox">
        <input
            type="checkbox"
            placeholder=" "
            style="width: 24px; height: 24px; cursor: auto"
            [ngModel]="booleanValue"
            disabled
            [id]="uuid"
            name=""
        />
        <label [for]="uuid" style="font-size: 1em">{{ uuid | translate: 'title' }}</label>
    </div>
</a>
