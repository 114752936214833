import { EnvironmentConfig } from './environment-config';

export const environment: EnvironmentConfig = {
    production: false,
    apiUrl: 'http://api.dev.ncf-fonda.hetzner.lfac.dk',
    APPLICATION_TEMPLATE_UUID: 'a3ede30e-004c-11e8-ba89-0ed5f89f718b',
    SLACK_USERNAME: 'FONDA - DEV',
    IGNORE_MISSING_TRANSLATIONS: true,
    ngrxLog: {
        actions: true,
        state: true,
    },
};

import 'zone.js/plugins/zone-error';
// import 'zone.js/dist/long-stack-trace-zone';
