import { ComponentFactoryResolver, Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { take } from 'rxjs/operators';
import { PaymentProfileComponent } from './payment-profile.component';
import { DOMService } from '../../../services/dom-service';
import { ApplicationLockComponent } from '../../../caseworker/review/components/application-lock/application-lock.component';

@Injectable()
export class PaymentProfilePopupService {
    constructor(private factoryResolver: ComponentFactoryResolver, private domService: DOMService) {}

    public onClosePopup = new Subject();
    private lockComponent: ApplicationLockComponent;

    public initLockSystem(lockComponent: ApplicationLockComponent) {
        this.lockComponent = lockComponent;
    }

    public initPopup() {
        const component = this.domService.appendComponentToBody(PaymentProfileComponent);

        component.instance.lock = this.lockComponent && !this.lockComponent.canEditApplication();
        component.instance.onCloseClick.pipe(take(1)).subscribe(() => {
            this.onClosePopup.next();
            this.domService.removeComponentFromBody(component);
        });
    }
}
