import * as jszip from 'jszip';
import { FileService } from '../file.service';
import { Injectable } from '@angular/core';
import { UploadedFondaFile } from '../../models/uploaded-fonda-file';
import { Subject } from 'rxjs/Subject';
import { PdfListService } from '../../pdf-application/pdf-list-service';
import { BoardMeetingAgendaDto } from '../../api/dto/board-meeting/board-meeting-agenda-dto';
import { FondaApiService } from '../../api/fonda-api.service';
import { SectionListAdapter } from './section-list-adapter';
import { TranslateService } from '../../shared/translation/translate.service';

export class CategoryZip {
    constructor(
        readonly categoryName: string,
        readonly applications: Array<{
            name: string;
            pdfContent: string;
            fileUuids: Array<string>;
            directoryName: string;
            sequence?: number;
        }>,
        readonly category: boolean,
        readonly sequence: number,
        readonly manualSectionText?: string
    ) {}
}

@Injectable()
export class ZipService {
    public progressInformation = new Subject<{
        progress: number;
        message: string;
    }>();

    private progress: number;
    private additionalFileCount: number;

    constructor(
        private fileService: FileService,
        private translateService: TranslateService,
        private pdfListService: PdfListService,
        private apiService: FondaApiService
    ) {}

    public async generateZip(name: string, pdfBase64: string, filesUuid: Array<string>): Promise<any> {
        const zip = new jszip();
        this.additionalFileCount = filesUuid.length;
        this.progress = 0;
        zip.file(name + '.pdf', this.fileService.getBaseContent(pdfBase64), { base64: true });
        const additionalFiles = zip.folder(this.translateService.get(['board_meeting_agenda', 'additional_files']));

        return new Promise(async resolve => {
            const files = await this.getFiles(filesUuid);
            files.forEach(file => {
                additionalFiles.file(file.fileName, this.fileService.getBaseContent(file.headContent), {
                    base64: true,
                });
            });
            await zip.generateAsync({ type: 'base64' }).then(res => {
                const file = new UploadedFondaFile('', name + '.zip', 'application/zip', 0);
                file.headContent = 'data:' + file.mimeType + ';base64,' + res;
                this.fileService.downloadFileToOwnMachine(file);
                resolve('');
            });
        });
    }

    public async downloadMassZip(
        categories: Array<CategoryZip>,
        boardMeetingData: BoardMeetingAgendaDto
    ): Promise<any> {
        const zip = await this.generateMassZip(categories, boardMeetingData.name, boardMeetingData, false);

        return await zip.generateAsync({ type: 'base64' }).then(res => {
            const file = new UploadedFondaFile('', boardMeetingData.name + '.zip', 'application/zip', 0);
            file.headContent = 'data:' + file.mimeType + ';base64,' + res;
            this.fileService.downloadFileToOwnMachine(file);
            return;
        });
    }

    public async getMassZipBase64(
        categories: Array<CategoryZip>,
        boardMeetingData: BoardMeetingAgendaDto
    ): Promise<any> {
        const zip = await this.generateMassZip(categories, boardMeetingData.name, boardMeetingData, true);
        return new Promise(resolve => {
            zip.generateAsync({ type: 'base64' }).then(res => {
                const file = new UploadedFondaFile('', boardMeetingData.name + '.zip', 'application/zip', 0);
                file.headContent = 'data:' + file.mimeType + ';base64,' + res;
                resolve(res);
            });
        });
    }

    public async downloadFirstPage(
        categories: Array<CategoryZip>,
        boardMeetingData: BoardMeetingAgendaDto
    ): Promise<any> {
        return this.apiService
            .postFileCreatePdfFromHtml(await this.getFirstPageHtml(categories, boardMeetingData))
            .then(res => 'data:' + 'application/pdf' + ';base64,' + res);
    }

    public async generateMassZip(
        categories: Array<CategoryZip>,
        fileName: string,
        boardMeetingData: BoardMeetingAgendaDto,
        pdf?: boolean
    ): Promise<any> {
        const zip = new jszip();
        this.progress = 0;
        this.additionalFileCount = this.getAmountOfFiles(categories);
        for (const categoryObject of categories) {
            if (categoryObject.category) {
                const categoryDirectory = zip.folder(
                    categoryObject.sequence + 1 + '. ' + categoryObject.categoryName.toUpperCase()
                );
                for (const application of categoryObject.applications) {
                    const applicationDirectory = categoryDirectory.folder(application.directoryName);

                    const additionalFiles = applicationDirectory.folder(
                        this.translateService.get(['board_meeting_agenda', 'additional_files'])
                    );

                    applicationDirectory.file(
                        application.name + '.pdf',
                        this.fileService.getBaseContent(application.pdfContent),
                        { base64: true }
                    );

                    const files = await this.getFiles(application.fileUuids);

                    files.forEach(file => {
                        additionalFiles.file(file.fileName, file.blob, { binary: true });
                    });
                }
            } else {
                const manualSection = categoryObject.applications[0];
                const categoryDirectory = zip.folder(
                    categoryObject.sequence + 1 + '. ' + categoryObject.categoryName.toUpperCase()
                );
                const additionalFiles = categoryDirectory.folder(
                    this.translateService.get(['board_meeting_agenda', 'additional_files'])
                );

                if (pdf && categoryObject.manualSectionText) {
                    categoryDirectory.file(manualSection.name + '.html', categoryObject.manualSectionText, {
                        binary: false,
                    });
                }

                categoryDirectory.file(
                    manualSection.name + '.pdf',
                    this.fileService.getBaseContent(manualSection.pdfContent),
                    { base64: true }
                );
                const files = await this.getFiles(manualSection.fileUuids);

                files.forEach(file => {
                    additionalFiles.file(file.fileName, file.blob, { binary: true });
                });
            }
        }
        if (pdf) {
            await this.getFirstPageBlob(categories, boardMeetingData).then(res => {
                zip.file('Dagsorden-' + boardMeetingData.name + '.pdf', this.fileService.getBaseContent(res), {
                    base64: true,
                });
                return;
            });
        } else {
            await this.downloadFirstPage(categories, boardMeetingData).then(res => {
                zip.file('Dagsorden-' + boardMeetingData.name + '.pdf', this.fileService.getBaseContent(res), {
                    base64: true,
                });
                return;
            });
        }
        return zip;
    }

    private async getFirstPageBlob(
        _categories: CategoryZip[],
        boardMeetingData: BoardMeetingAgendaDto
    ): Promise<string> {
        const categories = [..._categories].sort((a, b) => (a.sequence > b.sequence ? 1 : -1));
        return this.pdfListService.getBlobPdf(new SectionListAdapter(categories, boardMeetingData));
    }

    private async getFirstPageHtml(
        _categories: CategoryZip[],
        boardMeetingData: BoardMeetingAgendaDto
    ): Promise<string> {
        const categories = [..._categories].sort((a, b) => (a.sequence > b.sequence ? 1 : -1));
        return this.pdfListService.getHtmlList(new SectionListAdapter(categories, boardMeetingData));
    }

    private async getFiles(files: Array<string>): Promise<Array<UploadedFondaFile>> {
        const arr: Array<UploadedFondaFile> = [];
        for (const file of files) {
            arr.push(await this.fileService.downloadFile(file));
            this.progressInformation.next({
                progress: ++this.progress / this.additionalFileCount,
                message:
                    this.translateService.get(['board_meeting_agenda', 'downloading_additional_files']) +
                    ' ' +
                    this.progress +
                    '/' +
                    this.additionalFileCount,
            });
        }
        return arr;
    }

    private getAmountOfFiles(categories: Array<CategoryZip>): number {
        let length = 0;
        categories.forEach(cat => {
            cat.applications.forEach(app => {
                length += app.fileUuids.length;
            });
        });
        return length;
    }
}
