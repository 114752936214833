import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToasterConfig } from 'angular2-toaster';
import { LoaderService } from './shared/services/loader.service';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
    selector: 'fonda-app',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
    public toasterConfig: ToasterConfig = new ToasterConfig({
        positionClass: 'toast-top-right',
        timeout: 7000,
        showCloseButton: true,
        tapToDismiss: false,
    });

    private subs = new Subscription();

    constructor(private loaderService: LoaderService, private router: Router) {}

    ngOnInit(): void {
        const showSub = this.router.events
            .pipe(
                filter(event => event instanceof NavigationStart),
                this.loaderService.tapShow()
            )
            .subscribe();

        const hideSub = this.router.events
            .pipe(
                filter(
                    event =>
                        event instanceof NavigationEnd ||
                        event instanceof NavigationCancel ||
                        event instanceof NavigationError
                ),
                this.loaderService.tapHide()
            )
            .subscribe();

        this.subs.add(showSub);
        this.subs.add(hideSub);
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
