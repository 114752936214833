import { Observable, Subject } from 'rxjs';
import { Provider } from '@angular/core';

export enum PopupManagerState {
    CLOSE = 'CLOSE',
    OPEN = 'OPEN',
}

export class PopupManager<R, T = undefined> {
    constructor(private readonly _data?: T) {}

    private _state: PopupManagerState = PopupManagerState.OPEN;

    get state(): PopupManagerState {
        return this._state;
    }

    private _closed = new Subject<R>();

    get closed(): Observable<R> {
        return this._closed.asObservable();
    }

    get data(): T {
        return this._data;
    }

    close(returnData: R): void {
        this._state = PopupManagerState.CLOSE;
        this._closed.next(returnData);
        this._closed.complete();
    }
}
