import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '../translate.service';

@Pipe({
    name: 'translateWithLanguage',
    pure: false,
})
export class TranslateWithLanguagePipe implements PipeTransform {
    constructor(private translateService: TranslateService) {}

    transform(id: string, ...args: string[]): string {
        const len = args.length;
        const language = args[len - 1];
        const translatePath = [id].concat(args.slice(0, len - 1));
        return this.translateService.get(translatePath, language);
    }
}
