<div *ngIf="!readonly && !onSearch" class="form-item" [ngClass]="{ half: size === 1, 'force-full': size === 2 }">
    <textarea
        autosize
        [minHeight]="'70px'"
        id="bigTextEditor{{ uuid }}"
        name="value3"
        [formControl]="text"
        placeholder=" "
        [maxlength]="configuration && configuration.max_length"
        [required]="isRequired"
        type="text"
        rows="3"
    ></textarea>
    <label for="bigTextEditor{{ uuid }}">{{ uuid | translate: 'title' }} </label>
    <div class="form-focus"></div>
    <div class="form-error" [ngClass]="{ active: !text.valid }">
        <!--Udfyld dette felt-->
    </div>
    <div
        class="form-error configuration active"
        *ngIf="configuration && configuration.max_length && text && text.value"
    >
        {{ text.value.length }} / {{ configuration.max_length }}
    </div>
</div>

<div *ngIf="!readonly && onSearch" class="form-item" [ngClass]="{ half: size === 1 }">
    <input
        id="bigTextEditor{{ uuid }}2"
        name="value3"
        [formControl]="text"
        placeholder=" "
        [required]="isRequired"
        type="text"
    />
    <label for="bigTextEditor{{ uuid }}2">{{ title }}</label>
    <div class="form-focus"></div>
</div>

<a class="form-item" *ngIf="readonly" [ngClass]="{ full: size === 2 }">
    <p>{{ uuid | translate: 'title' }}</p>
    <span [innerHTML]="getReadonlyValue() ? getReadonlyValue() : '-'"></span>
    <div class="form-focus"></div>
    <div class="form-error"></div>
</a>
