import { GetBoardMeetingNoteDto } from './get-board-meeting-note-dto';

export class AgendaSectionsCategoryContentApplicationDto {
    constructor(
        readonly uuid: string,
        readonly sequence: number,
        readonly files: Array<{
            uuid: string;
            name: string;
            size: number;
        }>,
        readonly processed: boolean
    ) {}
}
