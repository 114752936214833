import { FileDTO } from '../file-dto';

export class AgendaSectionsManual {
    constructor(
        readonly content: {
            files: Array<{
                size: number;
                name: string;
                uuid: string;
            }>;
            text: string;
        },
        readonly sequence: number,
        readonly type: string
    ) {}
    public hasChanged = false;
}
