import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '../translate.service';

@Pipe({
    name: 'filterWithTranslation',
    pure: false,
})
export class FilterWithTranslationPipe implements PipeTransform {
    constructor(private translateService: TranslateService) {}

    transform(array: Array<any>, value: string, useTranslation: boolean, argument?: string): Array<any> {
        if (!array || array.length === 0 || !value) {
            return array;
        }

        if (useTranslation) {
            array = array.filter(
                arr =>
                    arr &&
                    this.translateService.get([arr, argument]) &&
                    this.translateService
                        .get([arr, argument])
                        .toString()
                        .toLowerCase()
                        .includes(value.toString().toLowerCase())
            );
        } else {
            array = array.filter(arr => arr && arr.toString().toLowerCase().includes(value.toString().toLowerCase()));
        }
        return array;
    }
}
