export class RecordsStore {
    public static readonly TitleRecord: string = 'TitleRecord';
    public static readonly BoardMeetingRecord: string = 'BoardMeetingRecord';
    public static readonly ResponsibleRecord: string = 'ResponsibleCaseworkerRecord';
    public static readonly GrantedAmountRecord: string = 'GrantedAmountRecord';
    public static readonly TextRecord: string = 'TextRecord';
    public static readonly CompanyRecord: string = 'CompanyRecord';
    public static readonly FirstNameRecord: string = 'FirstnameRecord';
    public static readonly LastNameRecord: string = 'LastnameRecord';
    public static readonly CaseNumberRecord: string = 'CaseNumberRecord';
    public static readonly EconomyRecord: string = 'EconomyRecord';
    public static readonly AssignedUserRecord: string = 'AssignedUserRecord';
    public static readonly CorrespondenceTemplatePdfGeneratorRecord: string =
        'CorrespondenceTemplatePDFGeneratorRecord';
    public static readonly booleanRecords: Array<string> = ['BooleanDefaultFalseRecord', 'CompanyRecord'];
    public static readonly MoneyValuesRecords: Array<string> = [
        'BankRecord',
        'SuggestedAmountRecord',
        'DueBalanceRecord',
        'GrantedAmountRecord',
    ];
    public static readonly ExceptionBehaviourRecords = [];

    public static getAmountByBoardMeetingReference(boardMeetingUuid?: string): string {
        return boardMeetingUuid ? boardMeetingUuid + ':amount' : 'amount';
    }
}
