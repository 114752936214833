import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'fonda-error-count-badge',
    templateUrl: './error-count-badge.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorCountBadgeComponent {
    @Input() count: number;
}
