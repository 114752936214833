import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { BaseButtonComponent } from '../base-button-component';

export type BtnColors = 'danger' | 'primary' | 'fonda' | 'success' | 'secondary';

@Component({
    selector: 'button[btn]',
    templateUrl: './button.component.html',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        '[attr.disabled]': 'disabled || null',
        '[attr.aria-hidden]': 'hidden',
    },
})
export class ButtonComponent implements BaseButtonComponent, OnInit {
    constructor(private _eref: ElementRef) {
        // If for some reason we do not have classList or the element is null, just ignore it.
        if (!this.hasRequiredApis()) return;

        // Add a class that applies to all buttons. This makes it easier to target if somebody
        // wants to target all buttons. We do it here rather than `host` to ensure that
        // the class is applied to derived classes.
        this.addClass('btn');
    }

    get element(): HTMLElement {
        return this._eref.nativeElement as HTMLElement;
    }

    private _outline = false;
    get outline(): boolean {
        return this._outline;
    }
    @Input() set outline(outline: boolean) {
        this._outline = outline;
        this.addClassOnCondition(`${this.color}-btn--outline`, this.outline);
        this.addClassOnCondition(`btn--outline`, this.outline);
    }

    private _disabled = false;
    get disabled(): boolean {
        return this._disabled || this.hidden;
    }
    @Input() set disabled(value: boolean) {
        this._disabled = value;
        this.addClassOnCondition(`btn--disabled`, this._disabled);
    }

    private _rounded = false;
    get rounded(): boolean {
        return this._rounded;
    }
    @Input() set rounded(value: boolean) {
        this._rounded = value;
        this.addClassOnCondition(`btn--rounded`, this._rounded);
    }

    private _color: BtnColors = 'fonda';
    get color(): BtnColors {
        return this._color;
    }
    @Input() set color(value: BtnColors) {
        this.removeClass(`${this._color}-btn--outline`);
        this.removeClass(`${this._color}-btn`);

        this._color = value;
        this.addClass(`${this._color}-btn`);
        this.addClassOnCondition(`${this._color}-btn--outline`, this.outline);
    }

    private _hidden = false;
    get hidden(): boolean {
        return this._hidden;
    }
    @Input() set hidden(isHidden: boolean) {
        this._hidden = isHidden;
        this._hidden ? this.addClass('btn-hidden-placeholder') : this.removeClass('btn-hidden-placeholder');
    }

    ngOnInit(): void {
        this.addClass(`${this.color}-btn`);
    }

    hasRequiredApis(): boolean {
        return Boolean(this.element && this.element.classList);
    }

    addClass(...classNames: string[]): void {
        if (!this.hasRequiredApis()) return;
        classNames.forEach(className => this.element.classList.add(className));
    }

    removeClass(className: string): void {
        if (!this.hasRequiredApis()) return;
        this.element.classList.remove(className);
    }

    addClassOnCondition(className: string, condition: boolean) {
        if (condition) {
            this.addClass(className);
        } else {
            this.removeClass(className);
        }
    }
}

@Component({
    selector: 'a[btn]',
    templateUrl: './button.component.html',
    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
    inputs: ['disabled', 'outline', 'color', 'rounded', 'hidden'],
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        '(click)': '_stopClickIfDisabled($event)',
    },
})
export class AnchorButtonComponent extends ButtonComponent {
    constructor(_eref: ElementRef) {
        super(_eref);
    }

    _stopClickIfDisabled(event: any) {
        if (this.disabled) {
            event.stopPropagation();
            event.preventDefault();
        }
    }
}
