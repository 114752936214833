<ng-container *ngIf="isImpersonating()">
    <div class="impersonator-toast-placeholder"></div>
    <div class="impersonator-toast">
        <p>
            Browsing as <strong>{{ email }}</strong
            >.
        </p>
        <span class="impersonator-toast__stop-button underline" (click)="stopImpersonating()">stop impersonating</span>
    </div>
</ng-container>
