import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslationEditorComponent } from './translation-editor.component';
import { SlideTabsModule } from '../slide-tabs/slide-tabs.module';
import { BasicFormControlsModule } from '../basic-form-controls/basic-form-controls.module';
import { TranslationModule } from '../translation/translation.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [TranslationEditorComponent],
    exports: [TranslationEditorComponent],
    imports: [CommonModule, ReactiveFormsModule, TranslationModule, SlideTabsModule, BasicFormControlsModule],
})
export class TranslationEditorModule {}
