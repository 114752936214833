import {
    ApplicationRef,
    ComponentFactoryResolver,
    EmbeddedViewRef,
    Inject,
    Injectable,
    Injector,
    Renderer2,
    RendererFactory2,
    Type,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Observable, Subscriber } from 'rxjs';
import { take } from 'rxjs/operators';
import { IPopup } from './popup.interface';
import { PopupManager } from './popup-manager';

@Injectable()
export class PopupService {
    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private injector: Injector,
        private appRef: ApplicationRef,
        private rendererFactory2: RendererFactory2,
        @Inject(DOCUMENT) private document: Document
    ) {}

    spawnPopup<R, T extends IPopup<R>>(type: Type<T>): Observable<R>;
    spawnPopup<R, D, T extends IPopup<R, D>>(type: Type<T>, data: D): Observable<R>;
    spawnPopup<R, D, T extends IPopup<R, D>>(type: Type<T>, data?: D): Observable<R> {
        return new Observable((subscriber: Subscriber<R>) => {
            const popupManager = new PopupManager<R, D>(data);
            const factory = this.componentFactoryResolver.resolveComponentFactory(type);
            const injector = Injector.create({
                parent: this.injector,
                providers: [{ provide: PopupManager, useValue: popupManager }],
            });
            const component = factory.create(injector);

            this.appRef.attachView(component.hostView);

            const renderer: Renderer2 = this.rendererFactory2.createRenderer(null, null);
            renderer.appendChild(this.document.body, (component.hostView as EmbeddedViewRef<any>).rootNodes[0]);

            const innerCloseSub = popupManager.closed.pipe(take(1)).subscribe({
                next: value => {
                    subscriber.next(value);
                },
                error: err => {
                    component.destroy();
                    subscriber.error(err);
                },
                complete: () => {
                    component.destroy();
                    subscriber.complete();
                },
            });

            return () => {
                innerCloseSub.unsubscribe();
                component.destroy();
            };
        });
    }
}
