export type SelectModelValue<T> = null | T | T[];

export interface SelectModel<T> {
    isEmpty: boolean;

    getValue(): SelectModelValue<T>;
    getValueArray(): T[];
    isSelected(value: T): boolean;
    select(value: T): void;
}

export class MultipleSelectModel<T> implements SelectModel<T> {
    private readonly value: Set<T> = new Set<T>();

    constructor(initialValue?: SelectModelValue<T>) {
        if (Array.isArray(initialValue) && initialValue.length > 0) {
            initialValue.forEach(v => this.value.add(v));
        } else if (!Array.isArray(initialValue) && initialValue) {
            this.value.add(initialValue);
        }
    }

    get isEmpty(): boolean {
        return this.value.size === 0;
    }

    select(value: T): void {
        if (this.value.has(value)) this.value.delete(value);
        else this.value.add(value);
    }

    getValue(): SelectModelValue<T> {
        return Array.from(this.value);
    }

    isSelected(value: T): boolean {
        return this.value.has(value);
    }

    getValueArray(): T[] {
        return Array.from(this.value);
    }
}

export class SingleSelectModel<T> implements SelectModel<T> {
    private value: null | T = null;

    constructor(private readonly canUnselect: boolean, initialValue?: T | T[]) {
        if (Array.isArray(initialValue)) this.value = initialValue[initialValue.length - 1];
        else if (initialValue) this.value = initialValue;
    }

    get isEmpty(): boolean {
        return this.value === null;
    }

    isSelected(value: T): boolean {
        return this.value === value;
    }

    getValue(): null | T | T[] {
        return this.value;
    }

    getValueArray(): T[] {
        return this.value ? [this.value] : [];
    }

    select(value: T): void {
        if (this.canUnselect && this.value === value) {
            this.value = null;
        } else {
            this.value = value;
        }
    }
}
