import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseRecordComponent } from '../../base-record.component';
import { FondaApiService } from '../../../../api/fonda-api.service';
import { BoardMeetingDto } from '../../../../api/dto/board-meeting/board-meeting-dto';
import { LocalStorageService } from '../../../../services/local-storage-service';
import { CaseworkerApplicationDto } from '../../../../api/dto/caseworker-application-dto';
import { RecordsStore } from '../../../../services/records-store';

class GrantedAmountObject {
    boardMeeting: string;
    amount: string;
}

@Component({
    selector: 'granted-amount-record',
    templateUrl: 'granted-amount-record.component.html',
    styleUrls: ['granted-amount-record.scss'],
})
export class GrantedAmountRecordComponent extends BaseRecordComponent implements OnInit {
    constructor(private storeService: LocalStorageService, private apiService: FondaApiService) {
        super();
    }
    configuration: object;
    @Input('uuid') public uuid: string;
    @Input('value') public value: object;
    @Input('isRequired') public isRequired: boolean;
    @Input('size') public size: number;
    @Input('title') title: string;
    @Input('onSearch') isOnSearch = false;
    @Input('readonly') public readonly = false;
    @Output('onChange') onChange = new EventEmitter<object>();

    private initValue: any;
    public grantedAmounts: Array<GrantedAmountObject> = [];
    public boardMeetings: Array<BoardMeetingDto> = [];

    public float1 = '';
    public float2 = '';
    public emitValue() {
        this.onChange.emit(this.getValue());
    }

    public ngOnInit() {
        if (!this.isOnSearch) {
            this.fetchBoardMeetings();
            this.getActualBoardMeeting(this.storeService.getApplicationInformation());
            this.handleGrantedAmounts(this.value);
            this.initValue = this.getValue();
        } else {
            this.initValue = this.value['text'];
            this.float1 = this.value['text'][0] ? this.convertToString(this.value['text'][0]) : '';
            this.float2 = this.value['text'][1] ? this.convertToString(this.value['text'][1]) : '';
        }
        this.emitValue();
    }

    public isValid(): boolean {
        return !(this.isRequired && !this.value['text']);
    }

    public isChanged(): boolean {
        if (!this.readonly) {
            return JSON.stringify(this.initValue) !== JSON.stringify(this.getValue());
        } else {
            return false;
        }
    }

    public addNewGrant() {
        const obj = new GrantedAmountObject();
        obj.boardMeeting = this.getActualBoardMeeting(this.storeService.getApplicationInformation());
        this.grantedAmounts.push(obj);
    }

    public isEmpty(): boolean {
        return !this.value;
    }

    public getBoardMeetingNames(): Array<string> {
        if (!this.boardMeetings || this.boardMeetings.length == 0) {
            return [];
        }
        return this.boardMeetings.map(bm => bm.name);
    }

    public getBoardMeetingByName(name: string): BoardMeetingDto {
        if (!name || !this.boardMeetings || this.boardMeetings.length == 0) {
            return null;
        }
        return this.boardMeetings.find(bm => bm.name === name);
    }

    public getBoardMeetingByUuid(uuid: string): BoardMeetingDto {
        if (!uuid || !this.boardMeetings || this.boardMeetings.length == 0) {
            return null;
        }
        return this.boardMeetings.find(bm => bm.uuid === uuid);
    }

    public deleteAmount(amount: GrantedAmountObject) {
        this.grantedAmounts.splice(this.grantedAmounts.indexOf(amount), 1);
    }

    public getValue(): object {
        this.value = {};
        let tmp;

        if (this.isOnSearch) {
            tmp = [
                this.convertToNumber(this.float1) ? parseFloat(this.convertToNumber(this.float1).toFixed(2)) : null,
                this.convertToNumber(this.float2) ? parseFloat(this.convertToNumber(this.float2).toFixed(2)) : null,
            ];
            this.value['text'] = tmp;
        } else {
            this.value = this.getOutputObject();
        }
        return this.value;
    }

    private convertToNumber(str: string): number {
        if (!str) {
            return;
        } else {
            str = str.toString();
            str = str.replace(/[^\d,-]/g, '');
            str = str.replace(',', '.');
            return Number(str);
        }
    }

    private convertToString(num: number): string {
        if (typeof num === 'number') {
            const str = num.toString();
            return str.replace('.', ',');
        } else {
            return;
        }
    }

    private handleGrantedAmounts(obj: any) {
        this.grantedAmounts = [];
        if (obj) {
            obj.forEach(_object => {
                this.grantedAmounts.push({
                    boardMeeting: _object['board_meeting'],
                    amount: this.convertToString(_object['amount']),
                });
            });
        }
    }

    private getOutputObject(): Array<any> {
        const obj = [];
        this.grantedAmounts.forEach(grantedAmount => {
            obj.push({
                board_meeting: grantedAmount.boardMeeting,
                amount: this.convertToNumber(grantedAmount.amount),
            });
        });
        return obj;
    }

    private fetchBoardMeetings() {
        this.apiService
            .getBoardMeetings()
            .then(res => {
                this.boardMeetings = [...res];
            })
            .catch(err => {});
    }

    private getActualBoardMeeting(caseworkerApplicationDto: CaseworkerApplicationDto): string {
        let uuid = '';
        if (caseworkerApplicationDto && caseworkerApplicationDto.caseworkerSections) {
            caseworkerApplicationDto.caseworkerSections.forEach(section => {
                const record = section.recordValues.find(_record => _record.type === RecordsStore.BoardMeetingRecord);
                if (record) {
                    uuid = record.value['uuid'];
                }
            });
        }
        return uuid;
    }
}
