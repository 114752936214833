<span *ngIf="!isEmpty" class="fonda-select__label">
    {{ label }}
</span>
<button
    class="fonda-select__button"
    [class.is-input-display]="!boxDisplay"
    [class.is-box-display]="boxDisplay"
    type="button"
    (click)="toggle()"
    data-testid="select"
>
    <ng-container *ngIf="isEmpty">{{ placeholder }}</ng-container>
    <ng-container *ngIf="!isEmpty">
        <span class="fonda-select__multiple-options-label">{{ multipleLabel }}</span>
    </ng-container>
</button>
<div class="fonda-select__list" [class.is-shown]="open" [class.with-search]="showSearch">
    <input
        *ngIf="showSearch"
        type="text"
        class="fonda-select__search"
        [placeholder]="'general' | translate: 'search'"
        [(ngModel)]="searchText"
    />
    <fonda-option
        *ngFor="let option of options; let index = index"
        (select)="select(option)"
        [value]="option"
        [selected]="isSelected(option)"
        [searchText]="searchText"
        [showCheckbox]="multiple"
        [attr.data-testid]="'option-' + index"
    >
        <ng-container *ngTemplateOutlet="renderTemplate; context: getContext(option)"></ng-container>
    </fonda-option>
</div>

<ng-template let-option #noTemplate>
    {{ option }}
</ng-template>
