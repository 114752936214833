<div class="form-item" [ngClass]="{ half: size === 1 }" *ngIf="!readonly">
    <fonda-select
        [label]="uuid | translate: 'title'"
        [placeholder]="uuid | translate: 'title'"
        [options]="boardMeetingUuids"
        [multiple]="this.onSearch"
        [canUnselect]="true"
        [value]="selectedBoardMeetingUuids"
        [boxDisplay]="false"
        [showSearch]="true"
        (valueChange)="chooseBoardMeeting($event)"
    >
        <ng-container *fondaSelectOption="let uuid">
            {{ displayName(uuid) }}
            <ng-container *ngIf="nextBoardMeetingUuid === uuid"> --næste-- </ng-container>
            <ng-container *ngIf="previousBoardMeetingUuid === uuid"> --sidste-- </ng-container>
        </ng-container>
    </fonda-select>
</div>

<a class="form-item" *ngIf="readonly" [ngClass]="{ full: size === 2 }">
    <p>{{ uuid | translate: 'title' }}</p>
    <span>{{ selectedName }}</span>
</a>
