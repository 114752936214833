import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FondaApiService } from '../../../api/fonda-api.service';
import { PaymentProfileDto } from '../../../api/dto/payment-profile-dto/payment-profile-dto';
import { RecordValuesEntry } from '../record-values-edit/record-values-entry';
import { RecordsAdapter } from './records-adapter';
import { RecordValueOutputDto } from '../../../api/dto/record-value-output-dto';
import { RecordValuesEditComponent } from '../record-values-edit/record-values-edit.component';
import { ToasterTimeoutHelper } from '../../../services/toaster-timeout-helper';
import { ErrorTranslateService } from '../../translation/error-translate-service';
import { ToasterService } from 'angular2-toaster';
import { Subscription } from 'rxjs/Subscription';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '../../translation/translate.service';

@Component({
    selector: 'payment-profile',
    templateUrl: 'payment-profile.component.html',
    styleUrls: ['payment-profile.scss'],
})
export class PaymentProfileComponent implements OnInit {
    @ViewChild(RecordValuesEditComponent) public recordValuesComponent: RecordValuesEditComponent;
    @Output() onCloseClick = new EventEmitter();
    public showPopup = true;
    public lock = false;
    public profileNames: Array<string> = [];
    public chosenProfile: PaymentProfileDto;
    public profiles: Array<PaymentProfileDto>;
    public recordValues: RecordValuesEntry;
    public prefilled = false;
    public name = '';
    public nameEditMode = false;
    public lockNewProfileButton = false;
    public addNewProfileMode = false;
    private urlSubscription: Subscription;

    constructor(
        private activatedRoute: ActivatedRoute,
        private apiService: FondaApiService,
        private toasterService: ToasterService,
        private translateService: TranslateService,
        private changeRef: ChangeDetectorRef
    ) {
        this.fetchApplicationUuid();
    }

    private _applicationUuid = '';

    @Input()
    public set applicationUuid(applicationUuid: string) {
        if (applicationUuid) {
            this._applicationUuid = applicationUuid;
        }
    }

    public async ngOnInit() {
        await this.fetchPaymentProfiles();
        this.chosenProfile = this.profiles.find(r => r.isDefault);
        this.recordValues = new RecordsAdapter(this.chosenProfile.recordValues, this.lockedApplication());
        this.name = this.chosenProfile.name;
    }

    public deleteProfile() {
        this.apiService
            .deletePaymentProfile(this.chosenProfile.uuid)
            .then(() => {
                this.apiService.resetPaymentProfiles();
                this.ngOnInit();
                this.abortNameChanging();
            })
            .catch(err => {});
    }

    public close() {
        this.showPopup = false;
        this.onCloseClick.emit();
    }

    public enterChangeNameMode() {
        this.nameEditMode = true;
        this.name = this.chosenProfile.name;
    }

    public abortNameChanging() {
        this.nameEditMode = false;
        this.name = this.chosenProfile.name;
    }

    public startAddingNewPaymentProfile() {
        this.addNewProfileMode = true;
        this.nameEditMode = true;
        this.prefilled = false;
        this.name = 'New Payment Profile';
    }

    public abortAddingNewPaymentProfile() {
        this.addNewProfileMode = false;
        this.nameEditMode = false;
        this.lockNewProfileButton = false;
    }

    public updatePaymentProfile() {
        this.apiService.resetPaymentProfiles();
        this.apiService
            .patchPaymentProfile(
                this.nameEditMode ? this.name : this.chosenProfile.name,
                this.getChangedRecords(),
                this.chosenProfile.uuid
            )
            .then(async () => {
                await this.fetchPaymentProfiles();
                this.chosenProfile = this.profiles.find(r => r.uuid == this.chosenProfile.uuid);
                this.recordValues = new RecordsAdapter(this.chosenProfile.recordValues, this.lockedApplication());
                this.nameEditMode = false;
            })
            .catch(error => {
                const errors = new ErrorTranslateService(this.translateService).getCaseworkerMessages(error);
                this.toasterService.pop({
                    type: 'error',
                    title: 'Fejl ved gem kladde',
                    body: errors.join(' '),
                    timeout: ToasterTimeoutHelper.getTimeout(errors.join(' ')),
                });
                throw error;
            });
    }

    public canBeSaved(): boolean {
        return (
            (this.getChangedRecords() && this.getChangedRecords().length > 0) ||
            (this.chosenProfile && this.chosenProfile.name !== this.name)
        );
    }

    public postNewPaymentProfile() {
        this.apiService.resetPaymentProfiles();
        this.lockNewProfileButton = true;
        this.apiService
            .postPaymentProfile(this._applicationUuid, this.name, this.prefilled)
            .then(async () => {
                await this.fetchPaymentProfiles();
                this.chosenProfile = this.profiles.find(r => r.name == this.name);
                this.recordValues = new RecordsAdapter(this.chosenProfile.recordValues, this.lockedApplication());
                this.abortAddingNewPaymentProfile();
            })
            .catch(err => {
                this.abortAddingNewPaymentProfile();
            });
    }

    public lockedApplication(): boolean {
        return (this.chosenProfile && this.chosenProfile.isDefault) || this.lock;
    }

    public profileChoose(profileName: string) {
        this.chosenProfile = this.profiles.find(prof => prof.name === profileName);
        this.recordValues = new RecordsAdapter(this.chosenProfile.recordValues, this.lockedApplication());
        this.abortNameChanging();
    }

    private fetchPaymentProfiles(): Promise<void> {
        return this.apiService.getApplicationPaymentProfiles(this._applicationUuid).then(res => {
            this.profiles = res;
            this.profileNames = res.map(r => r.name);
            return;
        });
    }

    private getChangedRecords(): Array<RecordValueOutputDto> {
        return this.recordValuesComponent ? this.recordValuesComponent.getOnlyChanged() : [];
    }

    private fetchApplicationUuid() {
        this.urlSubscription = this.activatedRoute.queryParams.subscribe(params => {
            this.applicationUuid = params['uuid'];
        });
    }
}
