import { EventEmitter, Input, Output, Directive } from '@angular/core';

@Directive()
export abstract class PrimitiveBaseRecordComponent {
    @Input('uuid') public abstract uuid: string;
    @Input('value') public abstract value: object | Array<any>;
    @Input('isRequired') public abstract isRequired: boolean;
    @Input('title') public abstract title: string;
    @Output('onChange') public abstract onChange: EventEmitter<object>;
    public abstract isChanged(): boolean;
    public abstract getValue(): object;
    constructor() {}
}
