import {
    Input,
    HostBinding,
    AfterViewInit,
    OnInit,
    ElementRef,
    HostListener,
    Directive,
    ChangeDetectorRef,
} from '@angular/core';

@Directive({
    selector: '[transitionHeight]',
})
export class TransitionHeightDirective {
    @HostBinding('style.height') private height: string;

    _finalHeight = 0;

    @Input() set finalHeight(num: number) {
        this._finalHeight = num;
        this.iterate(0);
    }

    constructor() {}

    private iterate(height: number) {
        setTimeout(() => {
            this.height = height + '%';
            if (height < this._finalHeight) {
                this.iterate(height + 1);
            }
        }, 5);
    }
}
