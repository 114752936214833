import { Component, ElementRef, HostListener, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { BaseRecordComponent } from '../../base-record.component';

@Component({
    selector: 'boolean-default-false-record',
    templateUrl: 'boolean-default-false-record.component.html',
    styleUrls: ['boolean-default-false-record.scss'],
})
export class BooleanDefaultFalseRecordComponent extends BaseRecordComponent implements OnInit {
    @Input('uuid') public uuid: string;
    @Input('value') public value: object;
    @Input('isRequired') public isRequired: boolean;
    @Input('size') public size: number;
    @Input('readonly') public readonly = false;
    @Input('onSearch') onSearch = false;
    @Output('onChange') onChange = new EventEmitter<object>();

    private initValue: boolean;

    public booleanValue = false;
    public title = '';
    configuration: object;
    public emitValue() {
        this.onChange.emit(this.getValue());
    }

    public ngOnInit() {
        this.value = this.value ? this.value : {};
        this.initValue = this.value['boolean'];
        this.booleanValue = this.value['boolean'] ? this.value['boolean'] : false;
        this.emitValue();
    }

    public isValid(): boolean {
        return !(this.isRequired && !this.value['boolean']);
    }

    public isChanged(): boolean {
        return this.initValue != this.booleanValue;
    }
    public isEmpty(): boolean {
        return !this.value;
    }
    public getValue(): object {
        return { boolean: this.booleanValue };
    }
}
