import { ToasterService } from 'angular2-toaster';

export class ToasterTimeoutHelper {
    public static getTimeout(str: string): number {
        if (!str) {
            return 0;
        }
        if (str.length < 15) {
            return 5000;
        } else if (str.length >= 15 && str.length < 20) {
            return 7000;
        } else if (str.length >= 20) {
            return 10000;
        }
    }
}
