import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs/Observable';
import { RedirectionService } from '../services/redirection-service';
import { Injectable } from '@angular/core';

@Injectable()
export class BackendGuard implements CanActivate {
    constructor(private authService: AuthService, private redirectionService: RedirectionService) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (this.authService.isBackend()) {
            return true;
        } else if (this.authService.isApplicant()) {
            this.redirectionService.toApplicantLandingPage();
            return false;
        } else {
            this.redirectionService.toLandingPage(state.url);
            return false;
        }
    }
}
