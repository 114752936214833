import { UploadedFondaFile } from '../../../../models/uploaded-fonda-file';

export class EconomyRecordDataService {
    public overallBudget = 0;
    public overallOwnDepositValue = 0;
    public ownDeposits: Array<{
        revenueTitle: string;
        amount: number;
    }> = [];

    public externalInvestments: Array<{
        revenueTitle: string;
        amount: number;
    }> = [];

    public willApplyFor = 0;
    public externalInvestmentsGranted: boolean;

    setVales(valueObject: object) {
        this.overallBudget = valueObject['overallBudget'];
        this.overallOwnDepositValue = valueObject['overallOwnDepositValue'];
        this.ownDeposits = valueObject['ownDeposits'] ? valueObject['ownDeposits'] : [];
        this.externalInvestments = valueObject['externalInvestments'] ? valueObject['externalInvestments'] : [];
        this.willApplyFor = valueObject['willApplyFor'];
        this.externalInvestmentsGranted = this.externalInvestments.length > 0;
    }
}
