<div
    class="select-component"
    (click)="clickInside(); headersClickInside()"
    id="{{ placeholder }}"
    [ngClass]="{ checkbox: checkbox, expanded: show }"
    *ngIf="showDropdown()"
>
    <ng-container *ngIf="!shouldShowValuesOnCheckbox() && !translate">
        {{ checkbox || !choosen[0] || actionSelect ? placeholder : choosen[0] }}
    </ng-container>

    <ng-container *ngIf="!shouldShowValuesOnCheckbox() && translate">
        {{ checkbox || !choosen[0] || actionSelect ? placeholder : (choosen[0] | translate: translationArgument) }}
    </ng-container>

    <ng-container *ngIf="shouldShowValuesOnCheckbox() && !translate">
        {{ !choosen[0] || actionSelect ? (placeholder | removeCamelcase) : (choosen.join(', ') | removeCamelcase) }}
    </ng-container>
    <ng-container *ngIf="shouldShowValuesOnCheckbox() && translate">
        {{ !choosen[0] || actionSelect ? placeholder : singleIndentService.getTitles(choosen).join(', ') }}
    </ng-container>
    <div class="option-component-container" *ngIf="itemsWithHeaders.length > 0 && show && selectWithHeaders">
        <div *ngFor="let values of itemsWithHeaders">
            <div class="option-component option-header" (click)="headersClickInside(true, $event)">
                <b>{{ values.header }}</b>
            </div>
            <div
                class="option-component"
                [ngClass]="{ choosen: choosen.indexOf(option) !== -1 && !actionSelect }"
                *ngFor="let value of values.values"
                (click)="headersClickInside(); chooseWithHeader(values.header, value)"
            >
                {{ value }}
            </div>
        </div>
    </div>
</div>
