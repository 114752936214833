import { Input, HostBinding, AfterViewInit, OnInit, ElementRef, HostListener, Directive } from '@angular/core';

@Directive({
    selector: '[setHeight]',
})
export class SetHeightDirective implements OnInit {
    @HostBinding('style.height.px') private height: number;

    @Input() set turnedOn(turnedOn: boolean) {
        if (turnedOn === false || turnedOn === true) {
            this._turnedOn = turnedOn;
            if (!turnedOn) {
                this.height = null;
            }
        }
    }

    private _turnedOn = true;

    _offset = 0;

    @Input() set offset(num: number) {
        this._offset = num;
        this.height = window.innerHeight - this._offset;
    }

    @HostListener('window:resize', ['$event.target'])
    onResize(): void {
        this.height = window.innerHeight - this._offset;
    }

    public ngOnInit() {
        this.height = window.innerHeight - this._offset;
    }
}
