import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { LanguageStoreService } from '../../../services/language-store.service';
import { TranslateService } from '../translate.service';
import { Subscription } from 'rxjs/Subscription';
import { map, startWith, switchMap } from 'rxjs/operators';

@Pipe({
    name: 'translate',
    pure: false,
})
export class TranslatePipe implements PipeTransform {
    private ids: string[] = [];
    private lastTranslationValue = '';
    private subscription = Subscription.EMPTY;

    constructor(
        private readonly translate: TranslateService,
        private readonly languageStoreService: LanguageStoreService,
        private readonly cdRef: ChangeDetectorRef
    ) {}

    transform(...ids: Array<string>): string {
        this.subscribeIfChanged(ids);
        return this.lastTranslationValue;
    }

    private subscribeIfChanged(ids: string[]): void {
        const newIds = ids.filter(i => typeof i !== 'undefined');
        if (this.hasChanged(newIds)) {
            this.ids = newIds;
            this.subscription.unsubscribe();
            this.subscription = this.languageStoreService.languageChange
                .pipe(
                    map(() => this.languageStoreService.getNameOfCurrentLanguage()),
                    startWith(this.languageStoreService.getNameOfCurrentLanguage()),
                    switchMap(lang => this.translate.select(this.ids, lang))
                )
                .subscribe(value => {
                    this.lastTranslationValue = value;
                    this.cdRef.markForCheck();
                });
        }
    }

    private hasChanged(ids): boolean {
        if (ids.length !== this.ids.length) return true;
        return this.ids.some((id, index) => id !== ids[index]);
    }
}
