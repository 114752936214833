import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { DOMService } from '../../services/dom-service';
import { SiteSettingsService } from '../../services/site-settings.service';
import { CookieCategories, CookieStrategy } from '../cookie-strategy';
import { Maybe } from 'true-myth';

export type ConsentCategory = 'cookie_cat_functional' | 'cookie_cat_statistic' | 'cookie_cat_marketing';

export interface ICookieInformation {
    getConsentGivenFor(consent: ConsentCategory): boolean;
}

@Injectable({
    providedIn: 'root',
})
export class GTMCookieStrategy implements CookieStrategy {
    constructor(private domService: DOMService, private siteSettingsService: SiteSettingsService) {}

    initCookieConsent(): Observable<CookieCategories> {
        return this.siteSettingsService.getSettingSingle<string>('cookie_information_gtm_id').pipe(
            take(1),
            filter(v => Maybe.isJust(v)),
            map(v => Maybe.unsafeGet(v)),
            switchMap(gtmId => this.initGTM(gtmId))
        );
    }

    initGTM(gtmId: string): Observable<CookieCategories> {
        window['dataLayer'] = window['dataLayer'] || [];
        window['dataLayer'].push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js',
        });

        this.domService
            .appendScript(`https://www.googletagmanager.com/gtm.js?id=${gtmId}`, true, script => {
                script.dataset.testid = 'gtm';
            })
            .subscribe();

        return new Observable<CookieCategories>(subscriber => {
            // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
            function listener() {
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                const CookieInformation: ICookieInformation = (<any>window).CookieInformation;
                const data: CookieCategories = {
                    functional: CookieInformation.getConsentGivenFor('cookie_cat_functional'),
                    marketing: CookieInformation.getConsentGivenFor('cookie_cat_marketing'),
                    statistic: CookieInformation.getConsentGivenFor('cookie_cat_statistic'),
                };
                subscriber.next(data);

                if (data.functional && data.marketing && data.statistic) {
                    subscriber.complete();
                    window.removeEventListener('CookieInformationConsentGiven', listener);
                }
            }

            window.addEventListener('CookieInformationConsentGiven', listener, false);

            return () => {
                window.removeEventListener('CookieInformationConsentGiven', listener);
            };
        });
    }
}
