export class AssigneeUserData {
    constructor(
        readonly uuid: string,
        readonly confirmed: boolean,
        readonly createdDate: Date,
        readonly email: string,
        readonly type: string,
        readonly roles: Array<string>,
        readonly username: string,
        readonly initials: string,
        readonly active: boolean
    ) {}
}
