<div class="form-item" [ngClass]="{ half: size === 1 }" *ngIf="!readonly">
    <basic-select
        [choosenInput]="selectedUuid"
        [placeholder]="'application_field' | translate: 'application_schema_uuid'"
        [values]="schemas"
        [translate]="true"
        [argumentTranslation]="'title'"
        (getChoosen)="chooseSchema($event)"
        [label]="'application_field' | translate: 'application_schema_uuid'"
    ></basic-select>
    <div
        class="select-box-label red"
        *ngIf="onSearch && selectedUuid"
        style="position: absolute; right: 7px; z-index: 1"
        (click)="reset()"
    >
        {{ 'general' | translate: 'delete' }}
    </div>
    <div class="form-focus"></div>
    <div class="form-error"></div>
</div>
