export class FondaFile {
    constructor(
        private _headContent: string, //
        readonly fileName: string
    ) {}

    public set headContent(headContent: string) {
        this._headContent = headContent;
    }

    public get headContent(): string {
        return this._headContent;
    }

    public get size(): number {
        return this._headContent.length;
    }

    public get mimeType(): string {
        const firstResultOfSpliting = this._headContent.split(';base64,', 3);
        return firstResultOfSpliting[0].split('data:')[1];
    }
}
