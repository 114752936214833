import { Injectable } from '@angular/core';
import { BehaviorSubject, MonoTypeOperatorFunction, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class LoaderService {
    private count$ = new BehaviorSubject<number>(0);

    /**
     * shows loader on a page, failing to call hide later can result in permanent loader.
     */
    public show() {
        this.count$.next(this.count$.getValue() + 1);
    }

    public hide() {
        this.count$.next(Math.max(this.count$.getValue() - 1, 0)); // ensure that it does not go lower then 0
    }

    public tapShow<T>(): MonoTypeOperatorFunction<T> {
        return tap<T>({
            next: () => this.show(),
        });
    }

    public tapHide<T>(): MonoTypeOperatorFunction<T> {
        return tap<T>({
            next: () => this.hide(),
            complete: () => this.hide(),
            error: () => this.hide(),
        });
    }

    public getState(): Observable<boolean> {
        return this.count$.asObservable().pipe(map(v => v > 0));
    }
}
