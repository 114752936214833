import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { BaseRecordComponent } from '../../base-record.component';

@Component({
    selector: 'bank-record',
    templateUrl: 'bank-record.component.html',
    styleUrls: ['bank-record.scss'],
})
export class BankRecordComponent extends BaseRecordComponent implements OnInit {
    @Input('uuid') public uuid: string;
    @Input('value') public value: object;
    @Input('isRequired') public isRequired: boolean;
    @Input('size') public size: number;

    @Input('readonly') public readonly = false;
    @Input('title') title: string;
    @Output('onChange') onChange = new EventEmitter<object>();
    public registrationNumber = new UntypedFormControl(
        '',
        Validators.compose([Validators.minLength(4), Validators.maxLength(4), Validators.pattern(/\d+/)])
    );
    public accountNumber = new UntypedFormControl(
        '',
        Validators.compose([Validators.minLength(6), Validators.maxLength(10), Validators.pattern(/\d+/)])
    );
    public initValue: object;
    configuration: object;

    public emitValue() {
        this.onChange.emit(this.getValue());
    }

    public isValid(): boolean {
        return this.registrationNumber.valid && this.accountNumber.valid;
    }

    public isEmpty(): boolean {
        return !this.value;
    }

    public isChanged(): boolean {
        return (
            JSON.stringify(this.getValue()) !==
            JSON.stringify({
                registrationNumber: this.initValue ? this.initValue['registrationNumber'] : null,
                accountNumber: this.initValue ? this.initValue['accountNumber'] : null,
            })
        );
    }

    public ngOnInit() {
        this.registrationNumber.valueChanges.subscribe(() => this.emitValue());
        this.accountNumber.valueChanges.subscribe(() => this.emitValue());

        if (this.value) {
            this.registrationNumber.setValue(this.value['registrationNumber']);
            this.accountNumber.setValue(this.value['accountNumber']);
        }
        this.initValue = this.getValue();
        this.emitValue();
    }

    public getValue(): object {
        return {
            registrationNumber: this.registrationNumber.value ? this.registrationNumber.value : null, // req
            accountNumber: this.accountNumber.value ? this.accountNumber.value : null, // req
        };
    }
}
