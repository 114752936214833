<form class="payment-container" *ngIf="!readonly">
    <div>
        <h3>
            {{ uuid | translate : 'title' }}
        </h3>
        <div>
            <button class="button color-blue smaller" (click)="openPaymentProfile()">
                {{ 'payment_profile' | translate : 'manage_payment_profiles' }}
            </button>
            <button
                class="button color-blue smaller"
                [disableOnApiCalls]="false"
                *ngIf="!canSave()"
                [disabled]="canSave()"
                (click)="save()"
            >
                {{ uuid | translate : 'save' }}
            </button>
            <button
                class="button color-blue smaller"
                [disableOnApiCalls]="false"
                *ngIf="canSave()"
                [disabled]="canSave()"
                (click)="save()"
            >
                {{ uuid | translate : 'saved' }}
            </button>
            <button class="button color-green smaller add" (click)="addNewPayment()">+</button>
        </div>
    </div>

    <table *ngIf="payments && payments.length > 0" class="payment-plan">
        <tr>
            <th>{{ uuid | translate : 'description' }}</th>
            <th>{{ uuid | translate : 'date' }}</th>
            <th>{{ uuid | translate : 'amount' }}</th>
        </tr>
        <ng-container *ngFor="let payment of payments; let ind = index">
            <tr
                payment-plan-row
                [rowEntry]="payment"
                [uuid]="uuid"
                [ind]="ind"
                [paymentProfiles]="paymentProfiles"
                #paymentRows
                (onChange)="changed = true; emitValue()"
                (onDelete)="deleteRow(payment); changed = true"
            ></tr>
        </ng-container>
        <tr style="text-align: right">
            <td></td>
            <td></td>
            <td></td>
            <td style="padding: 4px">{{ uuid | translate : 'overall_amount' }}: {{ getAmount() | commasInInput }}</td>
        </tr>
    </table>
    <div *ngIf="!payments || payments.length === 0">
        {{ uuid | translate : 'no_installments' }}
    </div>
</form>

<form class="payment-container" *ngIf="readonly">
    <div>
        <h3>
            {{ uuid | translate : 'title' }}
        </h3>
        <div>
            <button class="button color-blue smaller" (click)="openPaymentProfile()">
                {{ 'payment_profile' | translate : 'manage_payment_profiles' }}
            </button>
        </div>
    </div>

    <div *ngIf="!payments || payments.length === 0">
        {{ uuid | translate : 'no_installments' }}
    </div>

    <table *ngIf="payments && payments.length > 0" class="view-only payment-plan">
        <tr>
            <th>{{ uuid | translate : 'description' }}</th>
            <th>{{ uuid | translate : 'date' }}</th>
            <th>{{ uuid | translate : 'amount' }}</th>
        </tr>
        <ng-container *ngFor="let payment of payments; let ind = index">
            <tr>
                <td>{{ payment.name }}</td>
                <td>{{ payment.date | date : 'dd-MM-yyyy' }}</td>
                <td>{{ payment.amount | commasInInput }}</td>
                <td>{{ getPaymentByUuid(payment.paymentProfileUuid)?.name }}</td>
            </tr>
        </ng-container>
    </table>
</form>
