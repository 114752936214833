import { BackendUserData } from '../backend-user-data';
import { UploadedFondaFile } from '../../../models/uploaded-fonda-file';
import { CorrespondenceMessageAttachmentDto } from './correspondence-message-attachment-dto';

export class CorrespondenceMessageDto {
    constructor(
        readonly uuid: string,
        readonly sender: BackendUserData,
        readonly sentAt: Date,
        readonly body: string,
        readonly attachements: Array<CorrespondenceMessageAttachmentDto>
    ) {}
}
