import { ChangeDetectionStrategy, Component, Injectable, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from './auth/auth.service';

@Injectable({ providedIn: 'root' })
export class LogoutGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
        return !!this.authService.getToken() || this.router.createUrlTree(['/']);
    }
}

@Component({ selector: 'logout', template: '', changeDetection: ChangeDetectionStrategy.OnPush })
export class LogoutComponent implements OnInit {
    constructor(private authService: AuthService) {}

    ngOnInit(): void {
        this.authService.clearIdentity();
        window.location.href = window.location.host; // make a full reload redirect to landing page
    }
}
