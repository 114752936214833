import { Component, ElementRef, Input } from '@angular/core';
import { ButtonComponent } from '../button/button.component';

const ICON_CLASSES = {
    pencil: 'icon-pencil',
    trash: 'icon-trash',
    cross: 'icon-cross',
};

export type IconBtnSizes = 'normal' | 'small' | 'smallest';

@Component({
    selector: 'a[icon-btn], button[icon-btn]',
    templateUrl: './icon-button.component.html',
    styleUrls: ['./icon-button.component.scss'],
    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
    inputs: ['rounded', 'color', 'disabled', 'hidden'],
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        '[attr.disabled]': 'disabled || null',
        '[attr.aria-hidden]': 'hidden',
    },
})
export class IconButtonComponent extends ButtonComponent {
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('icon-btn') icon: keyof typeof ICON_CLASSES;

    constructor(eref: ElementRef) {
        super(eref);
        this.addClass('btn--icon');
        this.outline = true;
    }

    private _size: IconBtnSizes = 'normal';
    get size(): IconBtnSizes {
        return this._size;
    }

    @Input() set size(value: IconBtnSizes) {
        if (this._size !== value) {
            this.removeClass(this._size);
            this._size = value;
            this.addClass(this._size);
        }
    }

    get iconClass() {
        return ICON_CLASSES[this.icon];
    }
}
