import { ComponentFactoryResolver, EventEmitter, Injectable } from '@angular/core';
import { DOMService } from '../../services/dom-service';
import { ApplicantCaseOverviewPopupComponent } from './applicant-case-overview-popup.component';
import { SectionDto } from '../../api/dto/section-dto';
import { take, takeUntil } from 'rxjs/operators';
import { merge } from 'rxjs';

@Injectable()
export class ApplicantCaseOverviewPopupService {
    constructor(private factoryResolver: ComponentFactoryResolver, private domService: DOMService) {}

    public send = new EventEmitter();

    public initPopup(sections: Array<SectionDto>) {
        const component = this.domService.appendComponentToBody(ApplicantCaseOverviewPopupComponent);

        component.instance.send.pipe(take(1), takeUntil(component.instance.close)).subscribe(() => {
            this.send.emit();
        });

        merge(component.instance.close, component.instance.send)
            .pipe(take(1))
            .subscribe(() => {
                this.domService.removeComponentFromBody(component);
            });

        component.instance.initPopup(sections);
    }
}
