<div class="form-item" [ngClass]="{ half: size === 1 }" *ngIf="!readonly && onSearch">
    <input
        id="application-email"
        name="value1"
        placeholder=" "
        type="text"
        [formControl]="text"
        [maxlength]="configuration && configuration.max_length"
        [required]="isRequired"
    />
    <label for="application-email">{{ uuid | translate: 'title' }}</label>
    <div class="form-focus"></div>
    <div class="form-help"></div>
    <div class="form-error" [ngClass]="{ active: !text.valid }">Udfyld dette felt</div>
    <div
        class="form-error configuration active"
        *ngIf="configuration && configuration.max_length && text && text.value"
    >
        {{ text.value.length }} / {{ configuration.max_length }}
    </div>
</div>
<div class="form-item autofill" [ngClass]="{ half: size === 1 }" *ngIf="!readonly && !onSearch">
    <input
        id="application-email2"
        name="value1"
        placeholder=" "
        type="text"
        [ngModel]="text?.value"
        disabled
        [required]="isRequired"
    />
    <label for="application-email2">{{ title }}</label>
    <div class="form-focus"></div>
    <div class="form-help"></div>
    <div class="form-error" [ngClass]="{ active: !text.valid }">Udfyld dette felt</div>
</div>

<a class="form-item" *ngIf="readonly" [ngClass]="{ full: size === 2 }">
    <p>{{ uuid | translate: 'title' }}</p>
    <span>{{ text.value ? text.value : '-' }}</span>
    <div class="form-focus"></div>
    <div class="form-error"></div>
</a>
