import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { LoaderService } from '../../services/loader.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'root-loader',
    templateUrl: './root-loader.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RootLoaderComponent implements OnInit {
    show$: Observable<boolean>;

    constructor(private loaderService: LoaderService) {}

    ngOnInit(): void {
        this.show$ = this.loaderService.getState();
    }
}
