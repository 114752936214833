<div
    class="overlay applicant-font applicant"
    style="z-index: 99999"
    *ngIf="show"
    (click)="onContainerClick($event.target)"
    #container
>
    <div
        class="popup approve-popup"
        setMaxHeight
        [offset]="80"
        [ngClass]="{
            big: isBig(),
            medium: isMedium(),
            small: isSmall()
        }"
    >
        <div class="popup-header">
            <h2 [innerHTML]="header"></h2>
            <a class="close-btn smaller" (click)="onCancel()"><a class="icon-close"></a></a>
        </div>
        <p [innerHTML]="body"></p>

        <div class="actions">
            <button
                class="button"
                [ngClass]="{ hidden: !approveButton, 'color-blue-black': savingPopup }"
                (click)="onApproveClick()"
            >
                {{ approveButton }}
            </button>
            <a class="cancel-button" *ngIf="cancelButton" (click)="onCancel()">{{ cancelButton }}</a>
        </div>
    </div>
</div>
