export class WordService {
    public static addCommas(word: string): string {
        if (word && word.toString()) {
            let parts;
            if (word.toString().includes(',')) {
                parts = word.toString().split(',');
            } else {
                parts = word.toString().split('.');
            }
            if (parts && parts[1]) {
                parts[1] = parts[1].toString().substring(0, 2);
            }
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            return parts.join(',');
        } else {
            return word;
        }
    }
    // This method is responsible to add only thousand separator `.`, decimal separator `,` is not handled.
    // 80000 will be 80.000
    public static addDots_V2(word: string): string {
        if (word && word.toString()) {
            const tmp = word.toString().replace(/\./g, ''); // Remove existing dots
            const parts = tmp.toString().split('.');

            // If there are decimal parts, limit them to two digits
            if (parts && parts[1]) {
                parts[1] = parts[1].toString().substring(0, 2);
            }

            // Add dots as thousand separators
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            return parts.join('.');
        } else {
            return word;
        }
    }

    // transforms numbers while writing
    // (12.456 ... 7 ... 12.4567) -> 124.567
    public static addDots(word: string): string {
        if (word && word.toString()) {
            const tmp = word.toString().replace(/\./g, '');
            const parts = tmp.toString().split(',');
            if (parts && parts[1]) {
                parts[1] = parts[1].toString().substring(0, 2);
            }
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            return parts.join(',');
        } else {
            return word;
        }
    }

    public static transformSearchResponseKey(reference: string): { uuid: string; projectionType: string } {
        const split = reference && reference.split('{%}');
        return {
            uuid: split && split[0],
            projectionType: split && split[1] ? split[1] : '',
        };
    }

    public static isStringDate(value: string): boolean {
        const regex = new RegExp('\\d{4}\\-(0?[1-9]|1[012])\\-(0?[1-9]|[12][0-9]|3[01])*');
        return regex.test(value);
    }

    public static isObjectDate(date: any): boolean {
        return Object.prototype.toString.call(date) === '[object Date]';
    }

    public static isUuid(word: string): boolean {
        const regex = new RegExp('[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}');
        return regex.test(word);
    }

    public static convertToDate(value: string): Date | string {
        if (WordService.isStringDate(value)) {
            const date = new Date(value);
            return (
                date.getDate() +
                '-' +
                ((date.getMonth() + 1).toString().length > 1 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)) +
                '-' +
                date.getFullYear()
            );
        } else {
            return value;
        }
    }

    public static RemoveFirstParagraph(str: string): string {
        if (!str) return '';

        return str.replace('<p>', '').replace('</p>', '');
    }

    public static StripSlashes(str: string): string {
        if (!str) return '';
        str = str.replace(/\//gi, ' ');
        return str;
    }

    public static RemoveSlashesFromStrings(str: string): string {
        if (!str) {
            return '';
        }

        str = str.replace(/&nbsp;/g, '');
        str = str.replace(/\n/g, '');
        str = str.replace(/\t/g, '');
        str = str.replace(/\r/g, '');

        return str;
    }

    public static RemoveSingleParagraph(str: string): string {
        if (str && (str.match(/<p>/g) || []).length === 1) {
            str = str.replace('<p>', '').replace('</p>', '');
        }

        return str;
    }
}
