import {
    Input,
    HostBinding,
    AfterViewInit,
    OnInit,
    ElementRef,
    HostListener,
    Directive,
    OnDestroy,
} from '@angular/core';

@Directive({
    selector: '[remove-background]',
})
export class RemoveBackgroundOverflowDirective implements OnInit, OnDestroy {
    get active(): boolean {
        return this._active;
    }

    @Input() set active(value: boolean) {
        this._active = value;
        if (value) {
            document.body.style.overflowY = 'hidden';
        } else {
            document.body.style.overflowY = 'auto';
        }
    }

    private _active: boolean;

    public ngOnInit(): void {
        if (this.active) {
            document.body.style.overflowY = 'hidden';
        }
    }

    public ngOnDestroy(): void {
        document.body.style.overflowY = 'auto';
    }
}
