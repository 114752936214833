import { Pipe, PipeTransform } from '@angular/core';
import { WordService } from '../../../services/word-service';

@Pipe({
    name: 'commasInInput',
})
export class CommasInInputPipe implements PipeTransform {
    transform(word: string): string {
        return WordService.addDots_V2(word);
    }
}
