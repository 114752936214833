import { BoardMeetingApplicationDetailsDto } from './board-meeting-application-details-dto';
import { AgendaSectionsCategoryContentApplicationDto } from './agenda-sections-category-content-application-dto';
import { ShownReferencesDto } from './shown-references-dto';

export class AgendaSectionsCategoryContentDto {
    constructor(
        readonly applications: Array<AgendaSectionsCategoryContentApplicationDto>,
        readonly categoryUuid: string,
        readonly shownReference: Array<ShownReferencesDto>
    ) {}
}
