export enum SearchRecordTypes {
    RecordValue = 0,
    Category = 1,
    Status = 2,
    CreatedAt = 3,
    PostGrantStatus = 4,
    ApplicationUuid = 5,
    ApplicationSchemaUuid = 6,
    SubmittedAt = 7,
    IsClosed = 8,
}
