export class ApplicationLockStatusDto {
    constructor(
        readonly applicationUuid: string,
        readonly resourceType: string,
        readonly lockedByAccount: boolean,
        readonly lockedByOtherAccount: boolean,
        readonly lockOwner: string,
        readonly expireOn: string
    ) {}
}
