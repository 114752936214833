import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { RedirectionService } from '../services/redirection-service';
import { LandingPageRedirectionService } from './landing-page-redirection.service';

@Injectable()
export class LandingPageGuard implements CanActivate {
    constructor(
        private authService: AuthService,
        private landingPageRedirectionService: LandingPageRedirectionService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
        if (!this.authService.hasIdentity()) return true;
        return this.landingPageRedirectionService.redirectToUserLandingPage();
    }
}
