import { Component, Inject, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { BaseRecordComponent } from '../../base-record.component';
import { FormBuilder, UntypedFormControl, Validators } from '@angular/forms';

import { ApplicantValidator } from '../../../../applicant/applicant-validator';

@Component({
    selector: 'url-record',
    templateUrl: 'url-record.component.html',
})
export class UrlRecordComponent extends BaseRecordComponent implements OnInit {
    @Input('uuid') public uuid: string;
    @Input('value') public value: object;
    @Input('isRequired') public isRequired: boolean;
    @Input('size') public size: number;
    @Input('readonly') public readonly = false;
    private initValue: string;
    public text = new UntypedFormControl();
    @Input('title') title: string;
    @Output('onChange') onChange = new EventEmitter<object>();
    @Input('configuration') configuration: object;
    public emitValue() {
        this.onChange.emit(this.getValue());
    }

    public show() {}

    public ngOnInit() {
        this.initValue = this.value['text'];
        this.text.setValidators(ApplicantValidator.UrlRequired);
        if (this.isRequired) {
            this.text.setValidators(
                Validators.compose([ApplicantValidator.FieldRequired, ApplicantValidator.UrlRequired])
            );
        }
        this.text.setValue(this.value['text']);
        this.emitValue();
    }

    public isValid(): boolean {
        return !(this.isRequired && !this.value['text']);
    }

    public isChanged(): boolean {
        return this.initValue != this.text.value;
    }
    public isEmpty(): boolean {
        return !this.value;
    }
    public getValue(): object {
        this.value = {};
        this.value['text'] = this.text.value;
        return this.value;
    }

    public changeToCorrect() {
        if (this.text.valid && this.text.value) {
            if (
                this.text.value.toLowerCase().indexOf('http://') < 0 &&
                this.text.value.toLowerCase().indexOf('https://') < 0
            ) {
                const words = this.text.value.split('www.');
                this.text.setValue('http://www.' + words[1]);
            }
        }
        this.emitValue();
    }
}
