import { Inject, Injectable } from '@angular/core';
import { FondaApiService } from '../api/fonda-api.service';
import { UploadedFondaFile } from '../models/uploaded-fonda-file';
import { FondaFile } from '../models/fonda-file';
import { ToasterService } from 'angular2-toaster';

@Injectable()
export class FileService {
    static readonly ALLOWED_MIME_TYPES: Array<string> = [
        'image/jpeg', // jpeg
        'image/jpg', // jpg
        'image/png', // png
        'text/plain', // txt
        'application/pdf', // pdf
        'application/msword', // doc
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
        'application/vnd.ms-excel', // xls
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
        'application/vnd.oasis.opendocument.text', // odt
        'application/vnd.oasis.opendocument.spreadsheet', // ods
    ];

    static readonly API_MAX_FILE_SIZE = 25000000; // 25MB

    private _downloadedFiles: Array<UploadedFondaFile> = [];
    private _downloadedMetaFiles: { [uuid: string]: Promise<UploadedFondaFile> } = {};

    constructor(@Inject(FondaApiService) private apiService: FondaApiService, private toasterService: ToasterService) {}

    public loadFile(file: File): Promise<FondaFile> {
        return new Promise((resolve, reject) => {
            if (!this.hasFileAllowedType(file)) {
                this.toasterService.pop('error', 'Fejl ved upload af fil', 'Upload fil i gyldigt format');
                reject('Upload fil i gyldigt format');
            }
            const myReader: FileReader = new FileReader();
            myReader.onloadend = e => {
                const dto = new FondaFile(myReader.result as string, file.name);
                resolve(dto);
            };
            myReader.readAsDataURL(file);
        });
    }

    public uploadFile(file: FondaFile): Promise<UploadedFondaFile> {
        return new Promise((resolve, reject) => {
            if (!this.hasFileAllowedSize(file.size)) {
                this.toasterService.pop('error', 'Fejl ved upload af fil', 'Vælg fil mindre end 25 MB');
                reject('Vælg fil mindre end 25 MB');
            } else {
                this.apiService.postFile(file.fileName, this.getBaseContent(file.headContent)).then(uuid => {
                    const uploadedNcfFile = new UploadedFondaFile(uuid, file.fileName, file.mimeType, file.size);
                    uploadedNcfFile.headContent = file.headContent;
                    resolve(uploadedNcfFile);
                });
            }
        });
    }

    public downloadFile(uuid: string): Promise<UploadedFondaFile> {
        return new Promise(resolve => {
            if (this._downloadedFiles.find(file => file.uuid === uuid)) {
                resolve(this._downloadedFiles.find(file => file.uuid === uuid));
            } else {
                this.apiService.getFile(uuid).then(res => {
                    const uploadedNcfFile = new UploadedFondaFile(res.uuid, res.name, res.mimeType, res.size);
                    uploadedNcfFile.blob = res.blob;
                    this._downloadedFiles.push(uploadedNcfFile);
                    resolve(uploadedNcfFile);
                });
            }
        });
    }

    public async downloadFileWithBase64(uuid: string): Promise<UploadedFondaFile> {
        let file = await this.downloadFile(uuid);
        file = { ...file, mimeType: this.getMimeType(this.getFileExtension(file.fileName)) };
        if (file.blob) {
            return new Promise<UploadedFondaFile>((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.blob);
                reader.onloadend = () => {
                    file.headContent =
                        'data:' + file.mimeType + ';base64,' + this.getBaseContent(String(reader.result));
                    resolve(file);
                };
            });
        }
    }

    public getBlob(file: UploadedFondaFile): Blob {
        const binary = atob(this.getBaseContent(file.headContent).replace(/\s/g, ''));

        const len = binary.length;

        const buffer = new ArrayBuffer(len);

        const view = new Uint8Array(buffer);

        for (let i = 0; i < len; i++) {
            view[i] = binary.charCodeAt(i);
        }

        return new Blob([view], { type: file.mimeType });
    }

    public downloadFileToOwnMachine(file: UploadedFondaFile): void {
        // window.open(file.headContent, file.fileName);
        if (!file.headContent && !file.blob) {
            this.toasterService.pop(
                'error',
                'An error occurred while downloading',
                'There is no content for file: ' + file.fileName
            );
            return;
        }
        const blob = file.blob || this.getBlob(file);

        const downloadElement = document.createElement('a');
        downloadElement.href = URL.createObjectURL(blob);
        downloadElement.download = file.fileName;
        document.body.appendChild(downloadElement);
        setTimeout(() => {
            downloadElement.click();
        }, 100);

        setTimeout(() => {
            window.URL.revokeObjectURL(URL.createObjectURL(blob));
            document.body.removeChild(downloadElement);
        }, 100);
    }

    public async showFileInNewTab(file: UploadedFondaFile) {
        if ((file.headContent || file.blob) && file.fileName) {
            const x = window.open('', file.fileName);
            x.document.open();

            let src: string; // document source
            if (file.headContent) {
                src = file.headContent;
            } else {
                // read blob as base64
                const promise = new Promise((resolve, reject) => {
                    const fr = new FileReader();
                    fr.addEventListener('load', () => {
                        resolve(fr.result);
                    });
                    fr.addEventListener('error', () => {
                        reject();
                    });
                    fr.readAsDataURL(file.blob);
                });

                src = (await promise) as string;

                if (file.mimeType !== file.blob.type) {
                    src = src.replace(file.blob.type, file.mimeType);
                }
            }

            x.document.write('<iframe width="100%" height="100%" src="' + src + '"></iframe>');
            x.document.close();
        } else {
            throw new Error('File bad');
        }
    }

    public downloadMetaFile(uuid: string): Promise<UploadedFondaFile> {
        if (!this._downloadedMetaFiles[uuid]) {
            this._downloadedMetaFiles[uuid] = this.apiService
                .getFileMetaData(uuid)
                .then(res => new UploadedFondaFile(res.uuid, res.name, res.mimeType, res.size));
        }
        return this._downloadedMetaFiles[uuid];
    }

    public getBaseContent(fileContent: string): string {
        if (fileContent.includes('base64')) {
            const result = fileContent.split('base64,', 3);
            return result[1];
        } else {
            return fileContent;
        }
    }

    public getMimeType(extension: string): string {
        return FileService.ALLOWED_MIME_TYPES.find(type => type.includes(extension));
    }

    public getFileExtension(fileName): string {
        return fileName.split('.').pop();
    }

    private hasFileAllowedType(file: File): boolean {
        // return NfcFileService.ALLOWED_MIME_TYPES.some(value => value === file.type);
        return true;
    }

    private hasFileAllowedSize(size: number): boolean {
        return size <= FileService.API_MAX_FILE_SIZE;
    }
}
