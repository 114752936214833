import { SearchOrderBy } from '../../../caseworker/search/search-page/search-objects';

export class ResultApplication {
    applicationValues: Array<ResultApplicationValue>;
    applicationUuid: string;
    lockedByOtherCaseworker: boolean;
}

export class ResultApplicationValue {
    uuid: string;
    title: string;
    value: any;
    orderAble: boolean;
}

export class AllMoneyValue {
    uuid: string;
    value: number;
}

export interface ResultsInterface {
    applications: Array<ResultApplication>;
    orderBy: Array<SearchOrderBy>;
    allMoneyValues: Array<AllMoneyValue>;
}

export class ResultOrderOutput {
    constructor(readonly uuid: string, readonly asc: boolean) {}
}
