import { Inject, Injectable } from '@angular/core';
import { Language } from './language-store.service';
import { FondaApiService } from '../api/fonda-api.service';
import { FileService } from './file.service';

export interface PdfFileCreatorEntry {
    fullName: string;
    caseNumber: string;
    language: Language;
}

@Injectable()
export class PdfFileCreator {
    constructor(
        @Inject(FileService) private fileService: FileService,
        @Inject(FondaApiService) private apiService: FondaApiService
    ) {}

    public downloadPdf(body: string) {
        this.getPdf(body).then(uri => {
            const iframe = `<iframe width='100%' height='100%' src='${uri}'></iframe>`;
            const x = window.open();
            x.document.open();
            x.document.write(iframe);
            x.document.close();
        });
    }

    public createPdf(body: string): Promise<string> {
        return this.getPdf(body);
    }

    private async getPdf(body: string): Promise<string> {
        return this.apiService.postFileCreatePdfFromHtml(body).then(res => `data:application/pdf;base64,${res}`);
    }
}
