<fonda-popup (close)="close()">
    <h2 popup-header>Impersonator login</h2>
    <form [formGroup]="form" (ngSubmit)="submit()">
        <div class="form-item">
            <input type="email" formControlName="email" id="impersonator-login-email" placeholder="" />
            <label for="impersonator-login-email">E-mail</label>
        </div>
        <button btn color="fonda" type="submit" [outline]="true">Impersonate</button>
    </form>
</fonda-popup>
