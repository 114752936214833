import { Directive, ElementRef, Inject, Input, Renderer2 } from '@angular/core';
import { LanguageStoreService } from '../../../services/language-store.service';
import { TranslateService } from '../../translation/translate.service';

interface TranslationEntry {
    firstArgument: string;
    secondArgument: string;
}

@Directive({
    selector: '[translation]',
})
export class TranslationDirective {
    constructor(
        private translateService: TranslateService,
        private language: LanguageStoreService,
        private _eref: ElementRef,
        private renderer: Renderer2
    ) {
        this.language.languageChange.subscribe(() => this._translationEntry && this.refresh(this._translationEntry));
    }

    private _translationEntry: TranslationEntry;

    @Input('translationEntry') set translationEntry(translationEntry: { first: string; second: string }) {
        if (translationEntry) {
            this._translationEntry = { firstArgument: translationEntry.first, secondArgument: translationEntry.second };
            this.refresh(this._translationEntry);
        }
    }

    private refresh(translationEntry: TranslationEntry) {
        if (!this._eref.nativeElement) {
            return;
        }
        this.renderer.setProperty(
            this._eref.nativeElement,
            'innerHTML',
            this.translateService.get([translationEntry.firstArgument, translationEntry.secondArgument])
        );
    }
}
