import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'multipleOrderBy',
})
export class MultipleOrderByPipe implements PipeTransform {
    transform(companies: any[], path: string[], enable: boolean): any[] {
        if (!companies || !path || path.length === 0 || !enable) {
            return companies;
        }

        if (path.length === 1) {
            return companies.sort((a: any, b: any) => (a[path[0]] > b[path[0]] ? 1 : -1));
        }
        if (path.length === 2) {
            return companies
                .sort((a: any, b: any) => (a[path[1]] > b[path[1]] ? 1 : -1))
                .sort((a: any, b: any) => (a[path[0]] > b[path[0]] ? 1 : -1));
        }
    }
}
