import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CKEditorModule } from 'ng2-ckeditor';
import { ToasterModule } from 'angular2-toaster';
import { CorrespondenceThreadsComponent } from './components/correspondence-threads/correspondence-threads.component';
import { TextEditorComponent } from './components/text-editor/text-editor.component';
import { CorrespondencePopupComponent } from '../caseworker/components/correspondence-popup/correspondence-popup.component';
import { SectionTranslatePipe } from './translation/pipes/section-translate.pipe';
import { SelectWithHeadersComponent } from './components/select-with-headers-component/select-with-headers.component';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { CorrespondencePdfComponent } from '../caseworker/components/correspondence-popup/correspondence-pdf/correspondence-pdf.component';
import { ConfirmationPopupComponent } from './components/confirmation-popup/confirmation-popup.component';
import { DownloadableSectionComponent } from '../pdf-application/downloadable-section/downloadable-section.component';
import { PaymentProfileComponent } from './components/payment-profile/payment-profile.component';
import { FooterComponent } from './components/footer/footer.component';
import { FilesConverterPopupComponent } from '../components/files-converter-popup/files-converter-popup.component';
import { KeyValueSelectComponent } from './components/key-value-select/key-value-select.component';
import { ApplicantCaseOverviewPopupComponent } from '../components/applicant-case-overview-popup/applicant-case-overview-popup.component';
import { RouterModule } from '@angular/router';
import { FilesTableComponent } from './components/files-table/files-table.component';
import { RecordValuesEditComponent } from './components/record-values-edit/record-values-edit.component';
import { TranslationModule } from './translation/translation.module';
import { BasicFormControlsModule } from './basic-form-controls/basic-form-controls.module';
import { UtilsModule } from './utils/utils.module';
import { RecordsModule } from './records/records.module';
import { SearchUtilsModule } from './search-utils/search-utils.module';
import { CkeditorPopupService } from './components/text-editor/ckeditor-popup.service';
import { DynamicPopupService } from './components/confirmation-popup/dynamic-popup-service';
import { PaymentProfilePopupService } from './components/payment-profile/payment-profile-popup-service';
import { PopupModule } from './popup/popup.module';
import { ButtonsModule } from './buttons/buttons.module';
import { LoaderComponent } from './components/loader/loader.component';
import { LoaderService } from './services/loader.service';
import { RootLoaderComponent } from './components/root-loader/root-loader.component';
import { PagesModule } from './pages/pages';
import { TranslationEditorModule } from './translation-editor/translation-editor.module';
import { ContentContainerComponent } from './components/content-container/content-container.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { BadgeComponent } from './components/badge/badge.component';
import { ErrorCountBadgeComponent } from './components/error-count-badge/error-count-badge.component';
import { LanguageSelectComponent } from './components/language-select/language-select/language-select.component';
import { MessageDateDisplay } from './components/message-date-display/message-date-display.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ToasterModule,
        CKEditorModule,
        MatTabsModule,
        RouterModule,
        TranslationModule,
        BasicFormControlsModule,
        UtilsModule,
        RecordsModule,
        SearchUtilsModule,
        PopupModule,
        ButtonsModule,
        PagesModule,
        TranslationEditorModule,
    ],
    declarations: [
        CorrespondenceThreadsComponent,
        CorrespondencePopupComponent,
        FooterComponent,
        TextEditorComponent,
        SectionTranslatePipe,
        SelectWithHeadersComponent,
        CorrespondencePdfComponent,
        ConfirmationPopupComponent,
        DownloadableSectionComponent,
        PaymentProfileComponent,
        FilesConverterPopupComponent,
        KeyValueSelectComponent,
        ApplicantCaseOverviewPopupComponent,
        FilesTableComponent,
        RecordValuesEditComponent,
        LoaderComponent,
        RootLoaderComponent,
        ContentContainerComponent,
        PaginationComponent,
        BadgeComponent,
        ErrorCountBadgeComponent,
        LanguageSelectComponent,
        MessageDateDisplay,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ToasterModule,
        CKEditorModule,
        MatTabsModule,
        RouterModule,
        TranslationModule,
        BasicFormControlsModule,
        UtilsModule,
        RecordsModule,
        SearchUtilsModule,
        PopupModule,
        ButtonsModule,
        PagesModule,
        TranslationEditorModule,
        MessageDateDisplay,
        FilesConverterPopupComponent,
        FooterComponent,
        CorrespondenceThreadsComponent,
        CorrespondencePopupComponent,
        TextEditorComponent,
        SectionTranslatePipe,
        SelectWithHeadersComponent,
        CorrespondencePdfComponent,
        ConfirmationPopupComponent,
        DownloadableSectionComponent,
        PaymentProfileComponent,
        KeyValueSelectComponent,
        ApplicantCaseOverviewPopupComponent,
        FilesTableComponent,
        RecordValuesEditComponent,
        LoaderComponent,
        RootLoaderComponent,
        ContentContainerComponent,
        PaginationComponent,
        BadgeComponent,
        ErrorCountBadgeComponent,
        LanguageSelectComponent,
    ],
})
export class SharedModule {
    public static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [CkeditorPopupService, DynamicPopupService, PaymentProfilePopupService, LoaderService],
        };
    }
}
