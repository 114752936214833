import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[clickedOutside]',
})
export class ClickedOutsideDirective {
    @Output() clickedOutside = new EventEmitter<MouseEvent>();

    constructor(private elRef: ElementRef) {}

    @HostListener('window:click', ['$event'])
    onWindowClicked(event: MouseEvent) {
        const domEl = this.elRef.nativeElement;
        if (domEl !== event.target && !domEl.contains(event.target)) {
            this.clickedOutside.emit(event);
        }
    }
}
