export class CategoryDto {
    public static fromApiResponse(response: {
        uuid: string;
        is_deletable: boolean;
        account_dimension: any;
        account_system_account_number: any;
    }): CategoryDto {
        return new CategoryDto(
            response.uuid,
            response.is_deletable,
            response.account_dimension,
            response.account_system_account_number
        );
    }

    public static toCategoryUuid(category: CategoryDto): string {
        return category.uuid;
    }

    constructor(
        public readonly uuid: string,
        public readonly isDeletable: boolean,
        public readonly accountDimension: any,
        public readonly accountNumber: any
    ) {}
}
