import { Injectable, Provider } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class DownForMaintenanceHttpInterceptor implements HttpInterceptor {
    constructor(private router: Router) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap({
                error: (err: any) => {
                    if (err.status === 503 && !this.router.url.startsWith('/down-for-maintenance')) {
                        this.router.navigate(['down-for-maintenance'], {
                            queryParams: { returnUrl: window.location.href },
                        });
                    }
                },
            })
        );
    }
}

export const DOWN_FOR_MAINTENANCE_INTERCEPTOR_PROVIDER: Provider = {
    provide: HTTP_INTERCEPTORS,
    multi: true,
    useClass: DownForMaintenanceHttpInterceptor,
};
