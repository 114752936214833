import { Pipe, PipeTransform } from '@angular/core';
import { FondaApiGlobalVariables } from '../../../api/fonda-api-global-variables';
import { TranslateService } from '../translate.service';

@Pipe({
    name: 'sectionTranslate',
    pure: false,
})
export class SectionTranslatePipe implements PipeTransform {
    constructor(private translateService: TranslateService, private globalVariables: FondaApiGlobalVariables) {}

    transform(identifier: string, subId: string, subSubId: string, schemaUuid?: string): string {
        return this.translateService.get([
            identifier,
            schemaUuid || this.globalVariables.actualSchemaUuid,
            subId.toString(),
            subSubId,
        ]);
    }
}
