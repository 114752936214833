<div class="pdf-generator">
    <div style="margin-bottom: 20px">
        <table class="files-table">
            <tr>
                <th>{{ uuid | translate: 'title' }}</th>
                <th></th>
                <th></th>
            </tr>
            <tr>
                <td class="download" (click)="previewPdf()" *ngIf="fileName && text">
                    {{ fileName }}
                </td>
                <td *ngIf="!fileName">
                    <i>{{ uuid | translate: 'no_files_found' }}</i>
                </td>

                <td class="icon-container" *ngIf="!loadingPopup && !readonly && template">
                    <a *ngIf="template && fileName" class="trash-btn rounded smaller icon" (click)="deleteFile()"
                        ><a class="icon-trash icon"></a
                    ></a>
                </td>
                <td class="icon-container">
                    <div (click)="initPopup()" *ngIf="!loadingPopup && !readonly && template">
                        <a class="icon pencil-button smaller rounded"><a class="icon icon-pencil"></a></a>
                    </div>

                    <div class="small-loader-container" *ngIf="loadingPopup">
                        <div class="loader"></div>
                    </div>
                </td>
            </tr>
        </table>
    </div>
</div>
