import { NotificationContainerDto } from '../dto/notification-container-dto';
import { NotificationDto } from '../dto/notification-dto';

export class NotificationMapper {
    public static getNotification(jsonDataObject: any): Array<NotificationDto> {
        const noificationContainers: Array<NotificationContainerDto> = [];

        const notifications: Array<NotificationDto> = [];
        jsonDataObject.notifications.forEach(notification => {
            notifications.push(
                new NotificationDto(
                    notification.application,
                    notification.created_at,
                    notification.discarded,
                    notification.is_grouped,
                    notification.params,
                    notification.seen,
                    notification.type,
                    notification.url,
                    notification.user,
                    notification.uuid
                )
            );
        });

        // noificationContainers.push(new NotificationContainerDto(
        //     notifContainer.application,
        //     notifContainer.latest_notification_created_at,
        //     notifContainer.not_discarded,
        //     notifContainer.not_seen,
        //     notifications,
        //     notifContainer.params,
        //     notifContainer.type,
        //     notifContainer.url
        // ));

        return notifications;
    }
}
