import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { da } from 'date-fns/locale';
import { DatepickerOptions } from 'ng2-datepicker';
import { ApplicantValidator } from '../../../../applicant/applicant-validator';
import { BaseRecordComponent } from '../../base-record.component';

@Component({
    selector: 'date-record',
    templateUrl: 'date-record.component.html',
})
export class DateRecordComponent extends BaseRecordComponent implements OnInit {
    constructor(@Inject(LOCALE_ID) private locale: string) {
        super();
    }

    @Input('uuid') public uuid: string;
    @Input('value') public value: object;
    @Input('isRequired') public isRequired: boolean;
    @Input('size') public size: number;
    @Input('title') title: string;
    @Input('onSearch') onSearch = false;
    @Input('readonly') public readonly = false;
    @Output('onChange') onChange = new EventEmitter<object>();

    public dateValue = new UntypedFormControl();
    public dateValueFrom = new UntypedFormControl();
    public dateValueTo = new UntypedFormControl();

    public dateOutput: Date | null | Array<Date>;
    public dateInput: Date | { dateFrom: Date; dateTo: Date };
    public bothDates = false;
    configuration: object;

    public options: DatepickerOptions = {
        formatTitle: 'MMMM yyyy',
        format: 'dd-mm-yyyy',
        minYear: 1900,
        locale: da,
    };

    private initValue: any;
    private datePipe = new DatePipe(this.locale);

    public emitValue() {
        this.onChange.emit(this.getValue());
    }

    public formatDatePickerToInput(date: Date) {
        this.dateValue.setValue(
            (date.getDate().toString().length === 1 ? '0' + date.getDate() : date.getDate()) +
                '-' +
                ((date.getMonth() + 1).toString().length === 1 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) +
                '-' +
                date.getFullYear()
        );
    }

    public formatDatePickerToSearchInput(dates: { dateFrom: Date | null; dateTo: Date | null }) {
        if (dates.dateTo && dates.dateFrom) {
            this.dateValueFrom.setValue(
                (dates.dateFrom.getDate().toString().length === 1
                    ? '0' + dates.dateFrom.getDate()
                    : dates.dateFrom.getDate()) +
                    '-' +
                    ((dates.dateFrom.getMonth() + 1).toString().length === 1
                        ? '0' + (dates.dateFrom.getMonth() + 1)
                        : dates.dateFrom.getMonth() + 1) +
                    '-' +
                    dates.dateFrom.getFullYear()
            );
            this.dateValueTo.setValue(
                (dates.dateTo.getDate().toString().length === 1
                    ? '0' + dates.dateTo.getDate()
                    : dates.dateTo.getDate()) +
                    '-' +
                    ((dates.dateTo.getMonth() + 1).toString().length === 1
                        ? '0' + (dates.dateTo.getMonth() + 1)
                        : dates.dateTo.getMonth() + 1) +
                    '-' +
                    dates.dateTo.getFullYear()
            );

            this.dateOutput = [];
            this.dateOutput.push(dates.dateFrom);
            this.dateOutput.push(dates.dateTo);
        } else {
            this.dateValueFrom.setValue('');
            this.dateValueTo.setValue('');
        }
        this.emitValue();
    }

    public formatInputToDatePicker(date: string) {
        if (!this.onSearch) {
            let cutDate;
            try {
                cutDate = date.split('-');
            } catch (err) {
                this.dateInput = new Date(Date.now());
                this.dateOutput = null;
            }
            if (ApplicantValidator.dateRegex.test(date)) {
                this.dateInput = new Date(cutDate[2] + '-' + cutDate[1] + '-' + cutDate[0]);
                this.dateOutput = this.dateInput;
            } else {
                this.dateInput = new Date(Date.now());
                this.dateOutput = null;
            }
        }
    }

    public handleDates(dates: { dateFrom: Date; dateTo: Date }) {
        this.dateOutput = [];
        this.dateOutput.push(dates.dateFrom);
        this.dateOutput.push(dates.dateTo);

        this.formatDatePickerToSearchInput(dates);
        this.emitValue();
    }

    public changeToMultipleInputs() {
        this.bothDates = true;
    }

    public ngOnInit() {
        this.dateValue.disable();
        this.dateValueFrom.disable();
        this.dateValueTo.disable();

        if (this.isRequired) {
            this.dateValue.setValidators(ApplicantValidator.FieldRequired);
        }

        if (
            this.value['text'] &&
            Array.isArray(this.value['text']) &&
            Date.parse(this.value['text'][0]) &&
            Date.parse(this.value['text'][1])
        ) {
            this.dateInput = {
                dateFrom: new Date(this.value['text'][0]),
                dateTo: new Date(this.value['text'][1]),
            };
            this.formatDatePickerToSearchInput(this.dateInput);
        } else {
            this.initDateInput(this.value['text']);
        }
    }

    public isValid(): boolean {
        return !(this.isRequired && !this.dateValue.value);
    }

    public isChanged(): boolean {
        return this.initValue != this.dateValue.value;
    }

    public isEmpty(): boolean {
        return !this.value;
    }

    public getValue(): object {
        this.value = {};

        this.value['text'] = this.dateOutput;

        return this.value;
    }

    private initDateInput(date) {
        if (Date.parse(date)) {
            this.dateInput = date;
            this.dateOutput = date;
            this.dateValue.setValue(this.datePipe.transform(date, 'dd-MM-yyyy'));
            this.initValue = this.dateValue.value;
        } else {
            this.dateInput = new Date(Date.now());
            this.dateOutput = null;
            this.initValue = this.dateValue.value;
        }
    }
}
