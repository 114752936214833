import { SectionDto } from './section-dto';

export class CaseworkerApplicationDto {
    constructor(
        readonly applicantDefaultLanguage: string,
        readonly createdAt: Date,
        readonly applicantSchemaUuid: string,
        readonly applicantUuid: string,
        readonly uuid: string,
        readonly isClosed: boolean,
        readonly isDraft: boolean,
        readonly isGranted: boolean,
        public status: string,
        public subStatus: string,
        readonly applicantSections: Array<SectionDto>,
        readonly caseworkerSections: Array<SectionDto>,
        readonly postGrantSections: Array<SectionDto>,
        readonly accountEmail: string
    ) {}
}
