<ng-container *ngIf="!readonly && !isOnSearch">
    <section class="application-economy" style="margin-top: 0">
        <div class="granted-amount__header">
            <h3>{{ uuid | translate: 'title' }}</h3>
            <button class="button color-green smaller add" (click)="addNewGrant()">+</button>
        </div>
        <div class="external-investments-wrapper">
            <div style="margin-bottom: 20px" *ngIf="grantedAmounts?.length == 0">
                {{ uuid | translate: 'no_grants_found' }}
            </div>
            <div class="forms-with-cancel" *ngFor="let grantedObj of grantedAmounts">
                <div class="form-item half">
                    <input
                        id="application-float"
                        type="text"
                        OnlyNumber="true"
                        name="value2"
                        required
                        [ngModel]="grantedObj.amount | commasInInput"
                        (ngModelChange)="grantedObj.amount = $event; emitValue()"
                        placeholder=" "
                    />
                    <label for="application-float">{{ uuid | translate: 'amount' }}</label>
                    <div class="form-focus"></div>
                </div>
                <div class="form-item half half-last">
                    <basic-select
                        class="required"
                        [choosenInput]="getBoardMeetingByUuid(grantedObj.boardMeeting)?.name"
                        [placeholder]="uuid | translate: 'board_meeting'"
                        [values]="getBoardMeetingNames()"
                        (getChoosen)="grantedObj.boardMeeting = getBoardMeetingByName($event)?.uuid; emitValue()"
                        [label]="uuid | translate: 'board_meeting'"
                    ></basic-select>
                    <div
                        class="select-box-label red"
                        *ngIf="getBoardMeetingByUuid(grantedObj.boardMeeting)?.name"
                        style="position: absolute; right: 7px; z-index: 1"
                        (click)="grantedObj.boardMeeting = ''; emitValue()"
                    >
                        {{ 'general' | translate: 'delete' }}
                    </div>
                    <div class="form-focus"></div>
                    <div class="form-error"></div>
                </div>
                <a class="close-btn red smaller icon" (click)="deleteAmount(grantedObj)"
                    ><span class="icon-close"></span
                ></a>
            </div>
        </div>
    </section>
</ng-container>

<ng-container *ngIf="!readonly && isOnSearch">
    <div style="display: flex; position: relative">
        <div class="form-item between even first">
            <input
                id="{{ uuid }}-float2"
                type="text"
                step="0.01"
                name="value2"
                OnlyNumber="true"
                [ngModel]="float1 | commasInInput"
                (ngModelChange)="float1 = $event; emitValue()"
                placeholder=" "
                [required]="isRequired"
            />
            <label for="{{ uuid }}-float2">{{ title }} from</label>
            <div class="form-focus"></div>
        </div>
        <div class="form-item between even second">
            <input
                id="{{ uuid }}-float3"
                type="text"
                step="0.01"
                name="value2"
                OnlyNumber="true"
                [ngModel]="float2 | commasInInput"
                (ngModelChange)="float2 = $event; emitValue()"
                placeholder=" "
                [required]="isRequired"
            />
            <label for="{{ uuid }}-float3">{{ title }} to</label>
            <div class="form-focus"></div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="readonly && !isOnSearch">
    <h3>{{ uuid | translate: 'title' }}</h3>
    <div class="full granted-amount" style="font-size: 1rem">
        <table>
            <ng-container>
                <tr>
                    <th>
                        {{ uuid | translate: 'amount' }}
                    </th>
                    <th>
                        {{ uuid | translate: 'board_meeting' }}
                    </th>
                </tr>
                <ng-container *ngIf="grantedAmounts.length > 0">
                    <tr *ngFor="let grantedObj of grantedAmounts">
                        <td class="valuta">{{ grantedObj.amount | commasInInput }} kr.</td>
                        <td>{{ getBoardMeetingByUuid(grantedObj.boardMeeting)?.name }}</td>
                    </tr>
                </ng-container>
                <ng-container *ngIf="grantedAmounts.length == 0">
                    <tr>
                        <td>
                            <i>{{ uuid | translate: 'no_grants_found' }}</i>
                        </td>
                    </tr>
                </ng-container>
            </ng-container>
        </table>
    </div>
</ng-container>
