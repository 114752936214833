import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppLoadGuard } from './app-load.guard';
import { LandingPageGuard } from './auth/landing-page.guard';
import { CaseworkerLoginGuard } from './caseworker/login/login.guard';
import { DirectLoginComponent } from './components/direct-login/direct-login.component';
import { DirectLoginGuard } from './components/direct-login/direct-login.guard';
import { RecoverPasswordRedirectComponent } from './components/recover-password-redirect/recover-password-redirect.component';
import { LogoutComponent, LogoutGuard } from './logout';
import { UserChatWidgetHideGuard } from './user-chat-widget/user-chat-widget-hide.guard';
import { UserChatWidgetShowGuard } from './user-chat-widget/user-chat-widget-show.guard';

const routes: Routes = [
    {
        path: 'down-for-maintenance',
        loadChildren: () =>
            import('./pages/down-for-maintenance/down-for-maintenance.module').then(m => m.DownForMaintenanceModule),
        canActivate: [UserChatWidgetShowGuard],
    },
    {
        path: 'profile-completion',
        loadChildren: () =>
            import('./pages/profile-completion/profile-completion.module').then(m => m.ProfileCompletionModule),
    },
    {
        path: 'direct-login',
        component: DirectLoginComponent,
        canActivate: [DirectLoginGuard],
    },
    {
        path: 'logout',
        component: LogoutComponent,
        canActivate: [LogoutGuard],
    },
    {
        path: '',
        pathMatch: 'prefix',
        children: [
            { path: '', redirectTo: 'landing-page', pathMatch: 'full' },
            {
                path: 'landing-page',
                loadChildren: () => import('./pages/landing-page/landing-page.module').then(m => m.LandingPageModule),
                canActivate: [LandingPageGuard, UserChatWidgetShowGuard],
            },
            {
                path: 'recover-password',
                component: RecoverPasswordRedirectComponent,
            },
            {
                path: 'caseworker',
                loadChildren: () => import('./caseworker/caseworker.module').then(m => m.CaseworkerModule),
                canActivate: [UserChatWidgetShowGuard],
            },
            {
                path: 'applicant',
                loadChildren: () => import('./applicant/applicant.module').then(m => m.ApplicantModule),
                canActivate: [UserChatWidgetHideGuard],
            },
            {
                path: 'backend',
                loadChildren: () => import('./caseworker/login/login.module').then(m => m.CaseworkerLoginModule),
                canActivate: [CaseworkerLoginGuard],
            },
            {
                path: 'azure',
                loadChildren: () => import('./caseworker/azure/azure.module').then(m => m.AzureModule),
            },
        ],
        canActivateChild: [AppLoadGuard],
    },
    // Make sure it will redirect to the root app when none of the paths were matched before
    {
        path: '**',
        redirectTo: 'landing-page',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { enableTracing: false })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
