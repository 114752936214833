import { Inject, Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LocalStorageService } from '../services/local-storage-service';
import { Observable, Subject } from 'rxjs';

export interface AccountDescriptor {
    email: string;
    roles: string[];
    uuid: string;
    type: string;
}

@Injectable()
export class AuthService {
    public static IMPERSONATOR_TOKEN_KEY = 'impersonator-token';

    jwtHelper: JwtHelperService = new JwtHelperService();

    private _change$ = new Subject<void>();

    constructor(@Inject(LocalStorageService) private storageService: LocalStorageService) {}

    get change$(): Observable<void> {
        return this._change$.asObservable();
    }

    public setIdentity(jwtToken: string): void {
        if (jwtToken) {
            localStorage.setItem('token', jwtToken);
            this._change$.next();
        }
    }

    public clearIdentity(): void {
        localStorage.removeItem('token');
        localStorage.removeItem(AuthService.IMPERSONATOR_TOKEN_KEY);
        this._change$.next();
    }

    public hasIdentity(): boolean {
        return !!localStorage.getItem('token');
    }

    public getToken(): string | null {
        return localStorage.getItem('token');
    }

    public getUuid(): string {
        return JSON.parse(this.jwtHelper.decodeToken(this.getToken()).account_descriptor).uuid;
    }

    public isApplicant(): boolean {
        return (
            this.hasIdentity() &&
            JSON.parse(this.jwtHelper.decodeToken(this.getToken()).account_descriptor).type == 'applicant'
        );
    }

    public isBackend(): boolean {
        return (
            this.hasIdentity() &&
            JSON.parse(this.jwtHelper.decodeToken(this.getToken()).account_descriptor) &&
            JSON.parse(this.jwtHelper.decodeToken(this.getToken()).account_descriptor).type == 'backend'
        );
    }

    public isAdministrator(): boolean {
        return (
            this.isBackend() &&
            !!JSON.parse(this.jwtHelper.decodeToken(this.getToken()).account_descriptor).roles.find(
                role => role == 'administrator'
            )
        );
    }

    public isCaseworker(): boolean {
        return (
            this.isBackend() &&
            (!!JSON.parse(this.jwtHelper.decodeToken(this.getToken()).account_descriptor).roles.find(
                role => role == 'caseworker'
            ) ||
                !!JSON.parse(this.jwtHelper.decodeToken(this.getToken()).account_descriptor).roles.find(
                    role => role == 'board_member'
                ))
        );
    }

    public isBoardMeetingViewer(): boolean {
        return (
            this.isBackend() &&
            !!JSON.parse(this.jwtHelper.decodeToken(this.getToken()).account_descriptor).roles.find(
                role => role == 'board_meeting_agenda_viewer'
            )
        );
    }

    public clearLocalStorage() {
        this.storageService.clearLocalStorage();
        this._change$.next();
    }

    public isImpersonating(): boolean {
        return !!this.getImpersonatorToken();
    }

    public getImpersonatorToken(): undefined | string {
        return localStorage.getItem(AuthService.IMPERSONATOR_TOKEN_KEY) || undefined;
    }

    public setImpersonatorToken(token: string): void {
        localStorage.setItem(AuthService.IMPERSONATOR_TOKEN_KEY, token);
        this._change$.next();
    }

    public isLoggedIn() {
        return this.hasIdentity();
    }

    getAccountDescriptor(): AccountDescriptor {
        return JSON.parse(this.jwtHelper.decodeToken(this.getToken()).account_descriptor);
    }

    getImpersonatorAccountDescriptor(): AccountDescriptor {
        return JSON.parse(this.jwtHelper.decodeToken(this.getImpersonatorToken()).account_descriptor);
    }

    clearImpersonatorToken() {
        localStorage.removeItem(AuthService.IMPERSONATOR_TOKEN_KEY);
        this._change$.next();
    }
}
