import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { TranslateService } from '../../translation/translate.service';

@Component({
    selector: 'select-with-headers',
    templateUrl: 'select-with-headers.component.html',
})
export class SelectWithHeadersComponent {
    show = false;
    options: Array<string>;
    actionSelect = false;
    choosen: Array<string> = [];
    actualValue: {
        header: string;
        value: string;
    };
    checkbox = false;
    placeholder = 'Checkbox';
    itemsWithHeaders: Array<{
        header: string;
        values: Array<string>;
    }> = [];
    @Input('sort') sort = false;
    @Input('translate') public translate = false;
    @Input() containerPlace = 'left';
    @Input('selectWithHeaders') selectWithHeaders = false;
    @Output('getChoosen') public getChoosen = new EventEmitter<Array<string>>();
    @Output('getChoosenItemWithHeader') public getChoosenItemWithHeader = new EventEmitter<{
        header: string;
        value: string;
    }>();

    constructor(
        private _eref: ElementRef,
        private changeRef: ChangeDetectorRef,
        private translateService: TranslateService
    ) {}

    @Input('checkbox') set isCheckbox(checkbox: boolean) {
        this.checkbox = checkbox;
    }

    @Input('choosen') set choosenArray(choosen: Array<string>) {
        this.choosen = choosen;
    }

    @Input('placeholder') set actualPlaceholder(placeholder: string) {
        this.placeholder = placeholder;
    }

    @Input('itemsWithHeaders') set items(
        itemsWithHeaders: Array<{
            header: string;
            value: string;
        }>
    ) {
        if (itemsWithHeaders.length > 0) {
            this.itemsWithHeaders = [];
            itemsWithHeaders.forEach(item => {
                if (this.itemsWithHeaders.find(header => header.header === item.header)) {
                    this.itemsWithHeaders.find(header => header.header === item.header).values.push(item.value);
                } else {
                    const tmpValues: Array<string> = [];
                    tmpValues.push(item.value);
                    this.itemsWithHeaders.push({ header: item.header, values: tmpValues });
                }
            });

            this.changeRef.detectChanges();
        }
    }

    @HostListener('document:click', ['$event']) clickedOutside($event) {
        if (!this._eref.nativeElement.contains($event.target)) {
            // or some similar check
            this.show = false;
        }
    }

    public showDropdown(): boolean {
        return (this.itemsWithHeaders && this.itemsWithHeaders.length > 0) || (this.options && this.options.length > 0);
    }

    public clickInside() {
        if (!this.selectWithHeaders && this.checkbox) {
            this.show = true;
        } else if (!this.selectWithHeaders) {
            this.show = !this.show;
        }
    }

    public headersClickInside(headerClicked, event) {
        if (this.selectWithHeaders) {
            if (event) {
                event.stopPropagation();
            }
            if (headerClicked) {
                this.show = true;
            } else {
                this.show = !this.show;
            }
        }
    }

    public choose(option: string) {
        if (this.checkbox) {
            const optionPosition = this.choosen.indexOf(option);
            if (optionPosition === -1) {
                this.choosen.push(option);
            } else {
                this.choosen.splice(optionPosition, 1);
            }
        } else {
            this.choosen[0] = option;
        }
        this.getChoosen.emit(this.choosen);
    }

    public chooseWithHeader(header: string, value: string) {
        this.choosen[0] = value;
        this.actualValue = { header, value };
        this.getChoosenItemWithHeader.emit({ header, value });
    }

    public shouldShowValuesOnCheckbox(): boolean {
        return false;
    }
}
