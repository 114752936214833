export class PaymentProfileRecordValueDto {
    constructor(
        readonly uuid: string,
        readonly type: string,
        readonly fieldSize: number,
        readonly isRequired: boolean,
        readonly value: any,
        readonly strigifiedValue: string,
        readonly isEditable: boolean,
        readonly configuration: any
    ) {}
}
