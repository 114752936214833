import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'orderByDatePipe',
})
export class OrderByDatePipe implements PipeTransform {
    transform<T>(array?: null, arg?: keyof T, asc?: boolean): null;
    transform<T>(array: T[], arg: undefined, asc?: boolean): null;
    transform<T>(array: T[], arg: keyof T, asc?: boolean): T[];
    transform<T>(array?: T[] | null, arg?: string, asc?: boolean): T[] | null {
        const multiplier: number = asc ? 1 : -1;

        if (!array || array.length === 0) return null;
        if (!arg) return array;

        const arr = [...array];
        arr.sort((a: any, b: any) => (new Date(a[arg]).getTime() - new Date(b[arg]).getTime()) * multiplier);
        return arr;
    }
}
