import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { RedirectionService } from '../services/redirection-service';

@Injectable()
export class LandingPageRedirectionService {
    constructor(private authService: AuthService, private redirectionService: RedirectionService) {}

    redirectToUserLandingPage() {
        if (!this.authService.hasIdentity()) {
            return this.redirectionService.toLandingPage();
        }

        if (this.authService.isApplicant()) {
            return this.redirectionService.toApplicantLandingPage();
        }

        if (this.authService.isCaseworker()) {
            return this.redirectionService.toCaseworkerLandingPage();
        }

        if (this.authService.isBoardMeetingViewer()) {
            return this.redirectionService.toBoardMeetingViewer();
        }

        if (this.authService.isAdministrator()) {
            return this.redirectionService.toAdministration();
        }
    }
}
