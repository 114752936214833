import { BoardMeetingApplicationDetailsDto } from './board-meeting-application-details-dto';
import { AgendaSectionsCategory } from './agenda-sections-category';
import { AgendaSectionsManual } from './agenda-sections-manual';

export class BoardMeetingAgendaDto {
    constructor(
        readonly uuid: string,
        readonly name: string,
        readonly fiscalYear: number,
        readonly date: Date,
        readonly acceptingLastDate: Date,
        public published: boolean,
        readonly isSelectable: boolean,
        readonly applications: Array<BoardMeetingApplicationDetailsDto>,
        readonly agendaSections: Array<AgendaSectionsCategory | AgendaSectionsManual>,
        readonly agendaLockedByAnotherCaseworker: boolean
    ) {}
}
