import { Directive, HostListener, HostBinding, EventEmitter, Output } from '@angular/core';

@Directive({
    selector: '[dropFile]',
})
export class FileDragDirective {
    @Output() private fileChangeEmiter: EventEmitter<File> = new EventEmitter();
    @HostBinding('class.on-drag-over') private onDragOverClass = false;
    @HostListener('dragover', ['$event']) public onDragOver($event) {
        $event.preventDefault();
        $event.stopPropagation();
        this.onDragOverClass = true;
    }

    @HostListener('dragenter', ['$event']) public onDragEnter($event) {
        $event.preventDefault();
        $event.stopPropagation();
        this.onDragOverClass = true;
    }

    @HostListener('dragleave', ['$event']) public onDragLeave($event) {
        $event.preventDefault();
        $event.stopPropagation();
        this.onDragOverClass = false;
    }

    @HostListener('drop', ['$event']) public drop($event) {
        $event.preventDefault();
        $event.stopPropagation();
        if ($event.dataTransfer.files.length > 0) {
            this.fileChangeEmiter.emit($event);
        }
        this.onDragOverClass = false;
    }
}
