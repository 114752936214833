import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TokenDto } from '../api/dto/token.dto';
import { FondaApiService } from '../api/fonda-api.service';
import { AuthService } from '../auth/auth.service';

@Injectable({
    providedIn: 'root',
})
export class ImpersonatorService {
    constructor(private api: FondaApiService, private authService: AuthService) {}

    startImpersonating(email: string): Observable<TokenDto> {
        return this.api.getAccountTokenAsImpersonator(email).pipe(
            tap(token => {
                this.authService.setImpersonatorToken(this.authService.getToken());
                this.authService.setIdentity(token.token);
                window.location.pathname = '/';
            })
        );
    }

    stopImpersonating() {
        if (this.authService.isImpersonating()) {
            this.authService.setIdentity(this.authService.getImpersonatorToken());
            this.authService.clearImpersonatorToken();
            window.location.pathname = '/';
        }
    }
}
