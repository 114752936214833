<div
    class="overlay applicant-font applicant"
    style="z-index: 99999"
    remove-background
    escapeClick
    [active]="true"
    (escPressed)="onCancel()"
    (click)="onCancel()"
>
    <div class="popup approve-popup big" setMaxHeight [offset]="80" (click)="$event.stopPropagation()">
        <div class="popup-header">
            <h2>{{ 'general' | translate: 'overview' }}</h2>
            <a class="close-btn smaller" (click)="onCancel()"><a class="icon-close"></a></a>
        </div>

        <div class="admin-application-review applicant" style="float: none">
            <div class="container" *ngIf="sections && sections.length > 0">
                <div class="application-flow" *ngFor="let section of sections">
                    <h3 id="{{ 'applicant_application' | sectionTranslate: section.id:'title' }}">
                        {{ 'applicant_application' | sectionTranslate: section.id:'title' }}
                    </h3>
                    <record-values-edit [recordValuesEditInput]="section" [readonlyGroup]="true"></record-values-edit>
                </div>
            </div>
        </div>

        <div class="actions">
            <button class="button color-success-black" (click)="onSend()">
                {{ 'applicant_draft_mode' | translate: 'submit' }}
            </button>
            <a class="cancel-button" (click)="onCancel()">{{ 'general' | translate: 'cancel' }}</a>
        </div>
    </div>
</div>
