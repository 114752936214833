<div class="overlay" style="z-index: 99999" *ngIf="showPopup">
    <div class="popup approve-popup big" setMaxHeight [offset]="80">
        <div class="popup-header">
            <div>
                <a class="icon-btn-left smaller" *ngIf="addNewProfileMode" (click)="abortAddingNewPaymentProfile()"
                    ><div><div></div></div
                ></a>
                <h2>{{ 'payment_profile' | translate: 'payment_profiles' }}</h2>
            </div>
            <div>
                <ng-container *ngIf="!addNewProfileMode">
                    <a
                        class="button color-blue smaller"
                        *ngIf="!lock && !nameEditMode"
                        (click)="startAddingNewPaymentProfile()"
                        >{{ 'general' | translate: 'create' }}</a
                    >

                    <select-component
                        [options]="profileNames"
                        (getChoosen)="profileChoose($event[0])"
                        [containerPlace]="'right'"
                        [choosen]="chosenProfile?.name ? [chosenProfile.name] : []"
                    ></select-component>
                </ng-container>
                <a class="close-btn smaller" (click)="close()"><a class="icon-close"></a></a>
            </div>
        </div>
        <hr />
        <form class="profile-name" [ngClass]="{ center: addNewProfileMode }">
            <div>
                <ng-container *ngIf="!nameEditMode">
                    <h3>
                        <ng-container *ngIf="chosenProfile">
                            {{ chosenProfile.name }}
                        </ng-container>
                        <ng-container *ngIf="chosenProfile?.isDefault"> - Default </ng-container>
                    </h3>
                    <a
                        class="pencil-button smaller"
                        *ngIf="(!chosenProfile || !chosenProfile.isDefault) && !lock"
                        (click)="enterChangeNameMode()"
                        ><a class="icon-pencil"></a
                    ></a>
                </ng-container>

                <ng-container *ngIf="nameEditMode">
                    <div class="form-item half">
                        <input
                            id="payment-profile-name"
                            type="text"
                            [(ngModel)]="name"
                            name="payment-profile-name"
                            placeholder=" "
                            [required]="true"
                        />
                        <label for="payment-profile-name">
                            {{ 'payment_profile' | translate: 'payment_profile_name' }}
                        </label>
                        <div class="form-focus"></div>
                        <div class="form-error" [ngClass]="{ active: !name }">Indsæt mindst 0</div>
                    </div>
                    <div class="form-item half" *ngIf="addNewProfileMode">
                        <div class="checkbox">
                            <input
                                id="prefillPaymentProfile"
                                [(ngModel)]="prefilled"
                                name="prefilled"
                                type="checkbox"
                            />
                            <label class="noteCheckbox" for="prefillPaymentProfile">
                                {{ 'payment_profile' | translate: 'pre_populate' }}
                            </label>
                        </div>
                    </div>
                    <ng-container *ngIf="!addNewProfileMode">
                        <!--<a class="button color-green smaller" (click)="updatePaymentProfile()">-->
                        <!--{{'general' | translate: 'save'}}-->
                        <!--</a>-->
                        <a class="close-btn smaller red" (click)="abortNameChanging()"><a class="icon-close"></a></a>
                    </ng-container>
                </ng-container>
            </div>

            <div *ngIf="!lock">
                <ng-container *ngIf="!addNewProfileMode">
                    <button
                        class="button color-green smaller"
                        [disabled]="!canBeSaved()"
                        [disableOnApiCalls]="false"
                        (click)="updatePaymentProfile()"
                    >
                        {{ 'general' | translate: 'save' }}
                    </button>
                    <a class="button color-red smaller" *ngIf="!lockedApplication()" (click)="deleteProfile()">{{
                        'general' | translate: 'delete'
                    }}</a>
                </ng-container>
                <ng-container *ngIf="addNewProfileMode">
                    <a class="button color-green smaller" (click)="postNewPaymentProfile()">{{
                        'general' | translate: 'create'
                    }}</a>
                    <a class="button color-red smaller" (click)="abortAddingNewPaymentProfile()">{{
                        'general' | translate: 'cancel'
                    }}</a>
                </ng-container>
            </div>
        </form>

        <hr />

        <form *ngIf="!addNewProfileMode">
            <div class="admin-application-review">
                <div class="application-flow">
                    <record-values-edit
                        [recordValuesEditInput]="recordValues"
                        #recordValuesComponent
                    ></record-values-edit>
                </div>
            </div>
        </form>

        <div class="actions">
            <!--<button class="button" [ngClass]="{'hidden': !approveButton}" (click)="onApproveClick()">{{approveButton}}</button>-->
            <!--<a class="cancel-button" *ngIf="cancelButton" (click)="onCancel()">{{cancelButton}}</a>-->
        </div>
    </div>
</div>
