import { Component } from '@angular/core';
import { PopupService } from '../../shared/popup/popup.service';
import { ImpersonatorLoginPopupComponent } from './impersonator-login-popup.component';

@Component({
    selector: 'fonda-impersonator-login',
    templateUrl: './impersonator-login.component.html',
})
export class ImpersonatorLoginComponent {
    constructor(private popupService: PopupService) {}

    openImpersonatePopup() {
        this.popupService.spawnPopup(ImpersonatorLoginPopupComponent).subscribe();
    }
}
