<div class="form-item" [ngClass]="{ half: size === 1 }" *ngIf="!readonly">
    <basic-select
        [choosenInput]="selectedCaseworker && selectedCaseworker.username"
        [placeholder]="uuid | translate: 'title'"
        [values]="caseworkerNames"
        (getChoosen)="chooseCaseworker($event)"
        [label]="uuid | translate: 'title'"
    ></basic-select>
    <div
        class="select-box-label red"
        *ngIf="onSearch && (selectedCaseworker || selectedUuid)"
        style="position: absolute; right: 7px; z-index: 1"
        (click)="reset()"
    >
        {{ 'general' | translate: 'delete' }}
    </div>
    <div class="form-focus"></div>
    <div class="form-error"></div>
</div>

<a class="form-item" *ngIf="readonly" [ngClass]="{ full: size === 2 }">
    <p>{{ uuid | translate: 'title' }}</p>
    <span>{{ selectedCaseworker ? selectedCaseworker.username : '-' }}</span>
    <div class="form-focus"></div>
    <div class="form-error"></div>
</a>
