import { BackendUserData } from '../backend-user-data';

export class GetBoardMeetingNoteDto {
    constructor(
        readonly createdAt: Date,
        readonly createdBy: BackendUserData,
        readonly isPublic: boolean,
        readonly text: string,
        readonly uuid: string
    ) {}
}
