import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Observable, ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { Maybe } from 'true-myth';
import { UserAgentService } from './user-agent.service';

export enum Language {
    English = 0,
    Danish = 1,
}

export interface ILanguage {
    readonly number: number;
    readonly name: string;
    readonly abbr: string;
}

export class Lang implements ILanguage {
    constructor(readonly number: number, readonly name: string, readonly abbr: string) {}
}

export const LANGUAGES: ReadonlyArray<ILanguage> = [
    new Lang(Language.Danish, 'danish', 'da'),
    new Lang(Language.English, 'english', 'en'),
];

@Injectable()
export class LanguageStoreService {
    public languageChange = new Subject();
    private languages: readonly ILanguage[] = LANGUAGES;
    private languages$ = new ReplaySubject<readonly ILanguage[]>(1);

    constructor(private userAgentService: UserAgentService) {
        document.documentElement.lang = this.getAbbr(this.getLanguage());
    }

    public get languageList$(): Observable<readonly ILanguage[]> {
        return this.languages$.asObservable();
    }

    public setLanguage(language: number): void {
        localStorage.setItem('language', language.toString());
        this.languageChange.next();
        document.documentElement.lang = this.getAbbr(language);
    }

    public setCaseApplicantLanguage(language: number): void {
        localStorage.setItem('case-applicant-language', language.toString());
    }

    public setLanguageByName(language: string): boolean {
        const lang = this.languages.find(l => l.name === language);
        if (lang) {
            this.setLanguage(lang.number);
            return true;
        }
        return false;
    }

    public getLanguageFromName(language: string): number | null {
        const lang = this.languages.find(l => l.name === language);
        return lang ? lang.number : null;
    }

    public getLanguageName(language: number): string {
        return Maybe.of(this.languages.find(l => l.number === language))
            .map(lang => lang.name)
            .unwrapOr('');
    }

    public getLanguage(): number {
        if (localStorage.getItem('language')) {
            return Number(localStorage.getItem('language'));
        } else {
            return this.getDefaultLanguage().number;
        }
    }

    public getCaseApplicantLanguage(): number {
        if (localStorage.getItem('case-applicant-language')) {
            return Number(localStorage.getItem('case-applicant-language'));
        } else {
            return this.getDefaultLanguage().number;
        }
    }

    public getNameOfCurrentLanguage(): string {
        const lang = this.getLanguage();
        return Maybe.of(this.languages.find(l => l.number === lang))
            .map(l => l.name)
            .unwrapOr('');
    }

    public isLanguageSet(): boolean {
        return !!localStorage.getItem('language');
    }

    public getLanguageList(): ReadonlyArray<ILanguage> {
        return this.languages;
    }

    public setLanguageList(languages: readonly ILanguage[]) {
        this.languages = languages;

        const langAbbr = this.languages.map(lang => lang.abbr);
        if (!this.isLanguageSet()) {
            const defaultLanguageAbbr: string = this.getDefaultLanguage().abbr;
            const preferredLanguageAbbr: string =
                this.userAgentService.getPreferredLanguage(langAbbr) || defaultLanguageAbbr;
            this.setLanguage(this.languages.find(lang => lang.abbr === preferredLanguageAbbr).number);
        }

        const languageNumbers = new Set(this.languages.map(lang => lang.number));
        if (!languageNumbers.has(this.getLanguage())) {
            this.setLanguage(this.getDefaultLanguage().number);
        }

        this.setLanguage(this.getLanguage());
        this.languages$.next(languages);
    }

    public getAbbr(language: number): string {
        return Maybe.of(this.languages.find(l => l.number === language))
            .map(lang => lang.abbr)
            .unwrapOr('');
    }

    private getDefaultLanguage(): ILanguage {
        return this.languages.find(lang => lang.number === Language.English) || this.languages[0];
    }

    whenReady(): Observable<readonly ILanguage[]> {
        return this.languages$.pipe(take(1));
    }
}
