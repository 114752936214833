function has(object: any, key: string, type: string): boolean {
    return typeof object === 'object' && key in object && typeof object[key] === type;
}

export class PaginationDto {
    constructor(readonly pageNumber: number, readonly applicationsPerPage: number) {}

    static from(object: any): PaginationDto {
        const pageNumber = has(object, 'pageNumber', 'number') ? object.pageNumber : 1;
        const applicationsPerPage = has(object, 'applicationsPerPage', 'number') ? object.applicationsPerPage : 20;
        return new PaginationDto(pageNumber, applicationsPerPage);
    }
}
