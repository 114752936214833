import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FondaApiService } from '../../../api/fonda-api.service';
import { UploadedFondaFile } from '../../../models/uploaded-fonda-file';
import { CorrespondenceTokenValueDto } from '../../../api/dto/correspondence-dto/correspondence-token-value-dto';
import { CorrespondencePopupModel } from './correspondence-popup-model';
import { CorrespondenceNamingService } from '../../../services/naming-services/correspondence-naming-service';
import { FileService } from '../../../services/file.service';
import { FondaFile } from '../../../models/fonda-file';
import { CorrespondencePdfComponent, IPdfGenerateData } from './correspondence-pdf/correspondence-pdf.component';
import { CorrespondenceTemplatePopulateDto } from '../../../api/dto/correspondence-dto/correspondence-template-populate-dto';
import { FilesTableComponent } from '../../../shared/components/files-table/files-table.component';
import { AuthService } from '../../../auth/auth.service';
import { TranslateService } from '../../../shared/translation/translate.service';

export class PdfGenerateOutput {
    constructor(readonly fileName: string, readonly bodyText: string) {}
}

@Component({
    templateUrl: './correspondence-popup.component.html',
    selector: 'correspondence-popup',
    styleUrls: ['correspondence-popup.scss'],
})
export class CorrespondencePopupComponent implements OnInit {
    public accountEmail = '';
    public isOpen = false;
    public onlyPdf = false;
    public statusChangeName = '';
    public choosenTemplate: string;
    public choosenTemplateUuid: string;
    public isApplicant = false;
    public tokens: Array<CorrespondenceTokenValueDto> = [];
    public errorMessage: string;
    public bodyText = '';
    public headerText = '';
    public pdfBody = '';
    public pdfTitle = '';
    public pdfFileUuid = '';
    public templates: Array<CorrespondencePopupModel> = [];
    public templateNames: Array<string> = [];
    public loading = false;
    public popupTitle = '';

    @Output('messageSend') messageSend = new EventEmitter<{
        threadTitle: string;
        messageBody: string;
        statusName: string;
        filesUploaded: Array<UploadedFondaFile>;
        correspondenceTemplateUuid?: string;
    }>();

    public selectedIndex = 0;

    @ViewChild('pdfComponent', { static: true }) pdfComponent: CorrespondencePdfComponent;
    @ViewChild('filesTable', { static: true }) filesTable: FilesTableComponent;

    @Output('statusChangeWithoutMessage') statusChangeWithoutMessage = new EventEmitter<{ statusName: string }>();

    @Output() pdfGenerated = new EventEmitter<PdfGenerateOutput>();
    @Output() loadingFinished = new EventEmitter();
    @Output() closed = new EventEmitter();

    @Input('hideSubjectDropDown') hideSubjectDropDown = false;
    @Input('applicationUuid') applicationUuid: string;

    constructor(
        private apiService: FondaApiService,
        private translateService: TranslateService,
        private fileService: FileService,
        private auth: AuthService,
        public changeDet: ChangeDetectorRef
    ) {}

    public onGeneratePdf(obj: IPdfGenerateData, preventDeleting?: boolean) {
        if (!this.onlyPdf) {
            return this.fileService
                .uploadFile(new FondaFile(obj.baseUri, obj.fileName))
                .then(res =>
                    this.fileService.downloadMetaFile(res.uuid).then(metaData => {
                        metaData.headContent = res.headContent;
                        this.addFile(metaData, preventDeleting);
                        this.pdfFileUuid = res.uuid;
                        this.selectedIndex = 0;
                    })
                )
                .catch(err => {
                    console.error(err);
                    throw err;
                });
        } else {
            this.pdfGenerated.emit(new PdfGenerateOutput(obj.fileName, obj.bodyText));
            this.closed.emit();
        }
    }

    public showPdf(): boolean {
        return !!this.pdfBody && !this.pdfFileUuid;
    }

    public async initPopupComponent(
        applicationUuid: string,
        correspondenceTemplateUuid: string,
        schemaUuid?: string
    ): Promise<void> {
        if (this.templates.length < 1 && !this.hideSubjectDropDown && schemaUuid) {
            await this.fetchTemplates(schemaUuid);
        }
        this.overWriteData(
            await this.apiService.getApplicationCorrespondenceTemplate(applicationUuid, correspondenceTemplateUuid),
            correspondenceTemplateUuid
        );
        await this.handleFiles(correspondenceTemplateUuid);
        this.isOpen = true;
        return;
    }

    public addFile(uploadedNcfFile: UploadedFondaFile, preventDeleting?: boolean) {
        this.filesTable.addFile({ ...uploadedNcfFile, preventDeleting });
    }

    public addStaticFile(uploadedNcfFile: UploadedFondaFile, preventDeleting?: boolean) {
        this.filesTable.addStaticFile({ ...uploadedNcfFile, preventDeleting });
    }

    onFileDelete(uuid: string) {
        if (this.pdfFileUuid === uuid) {
            this.pdfFileUuid = '';
            this.pdfComponent.initPdfComponent(this.pdfBody, this.pdfTitle);
            this.selectedIndex = 1;
        }
    }

    public initManualMessagePopupComponent() {
        this.isOpen = true;
        this.isApplicant = this.auth.isApplicant();
        this.selectedIndex = 0;
        this.changeDet.detectChanges();
    }

    public initTemplatedMessagePopupComponent(templateParentUuid: string) {
        this.initManualMessagePopupComponent();
        if (this.templates && this.templates.length > 0) {
            // eslint-disable-next-line eqeqeq
            if (this.templates.find(template => template.parentUuid == templateParentUuid)) {
                this.chooseManualTemplate(
                    // eslint-disable-next-line eqeqeq
                    this.templates.find(template => template.parentUuid == templateParentUuid).name
                );
            }
        }
    }

    public async chooseManualTemplate(name: string) {
        // eslint-disable-next-line eqeqeq
        const template = this.templates.find(_template => _template.name == name);
        if (template) {
            this.loading = true;
            await this.initPopupComponent(this.applicationUuid, template.uuid);
            this.loading = false;
        }

        this.choosenTemplate = name;
        this.choosenTemplateUuid = template.uuid;
    }

    clickClose() {
        this.closed.emit();
        this.resetData();
    }

    public clickSend() {
        this.errorMessage = '';
        if (this.canSend()) {
            this.messageSend.emit({
                threadTitle: this.headerText,
                messageBody: this.bodyText,
                statusName: this.statusChangeName,
                filesUploaded: [...this.filesTable.files, ...this.filesTable.staticFiles],
                correspondenceTemplateUuid: this.choosenTemplateUuid,
            });
            this.clickClose();
        }
    }

    public ngOnInit() {
        if (!this.hideSubjectDropDown) {
            if (this.applicationUuid) {
                this.apiService.applicationCorrespondenceTokenValues(this.applicationUuid).then(res => {
                    this.tokens = res;
                });
            }
        }
    }

    public fetchTemplates(schemaUuid: string): Promise<void> {
        return this.apiService.getApplicationSchemaCorrespondenceTemplates(schemaUuid).then(templates => {
            this.templates = templates.map(template =>
                new CorrespondenceNamingService().getModel(this.translateService, template)
            );
            this.templateNames = this.templates.filter(_t => !_t.statusTransition).map(template => template.name);
            return;
        });
    }

    private async handleFiles(correspondenceTemplateUuid: string): Promise<void> {
        // eslint-disable-next-line eqeqeq
        const template = this.templates.find(t => t.uuid == correspondenceTemplateUuid);
        if (template && template.files && template.files.length > 0) {
            await this.downloadFilesMetaData(template.files).then(files =>
                files.forEach(file => this.addStaticFile(file))
            );
        }
        return;
    }

    private async downloadFilesMetaData(uuids: Array<string>): Promise<Array<UploadedFondaFile>> {
        const files: Array<UploadedFondaFile> = [];
        for (const uuid of uuids) {
            await this.fileService
                .downloadMetaFile(uuid)
                .then(file => files.push(file))
                .catch(err => console.error(err));
        }
        return files;
    }

    private canSend(): boolean {
        if (!this.bodyText) {
            this.errorMessage = this.translateService.get(['messages', 'popup_message_mandatory']);
            return false;
        }

        if (!this.headerText) {
            this.errorMessage = this.translateService.get(['messages', 'popup_subject_mandatory']);
            return false;
        }

        return true;
    }

    private overWriteData(correspondenceDto: CorrespondenceTemplatePopulateDto, correspondenceTemplateUuid: string) {
        if (this.filesTable) {
            this.filesTable.files = [];
        }
        this.choosenTemplateUuid = correspondenceTemplateUuid;
        this.headerText = correspondenceDto.subject;
        this.pdfTitle = correspondenceDto.subject;
        this.bodyText = correspondenceDto.body;
        if (!this.pdfBody) this.pdfBody = correspondenceDto.pdfBody;
        this.pdfComponent.initPdfComponent(this.pdfBody, this.headerText);
    }

    private resetData() {
        this.headerText = '';
        this.bodyText = '';
        this.onlyPdf = false;
        this.pdfFileUuid = '';
        this.choosenTemplate = '';
        this.choosenTemplateUuid = '';
        this.pdfBody = '';
        this.pdfTitle = '';
        if (this.filesTable) {
            this.filesTable.files = [];
        }
    }
}
