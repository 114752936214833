import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'removeCamelcase',
})
export class RemoveCamelcasePipe implements PipeTransform {
    transform(word: any): any {
        if (word && (typeof word === 'string' || word instanceof String)) {
            const k = word.replace(/_/g, ' ');
            if (k) {
                const wordArray = k.split(' ');
                const resultArray: Array<string> = [];
                wordArray.forEach(_word => {
                    resultArray.push(_word.charAt(0).toUpperCase() + _word.substr(1).toLowerCase());
                });
                return resultArray.join(' ');
            } else {
                return k;
            }
        } else {
            return word;
        }
    }
}
