import { PrimitiveTypes, ProjectionOptions } from '../caseworker/search/services/search-service';
import { RecordsInterface } from '../caseworker/search/components/search-filters/records-interface';

export class TypeResolverService {
    public static isBoolean(
        _recordData: { uuid: string; projectionType: string },
        filterEntry: Array<RecordsInterface>
    ): boolean {
        let result = false;
        const recordData = {
            uuid: _recordData.uuid,
            projectionType: _recordData.projectionType ? _recordData.projectionType : ProjectionOptions.Main,
        };
        filterEntry.forEach(filter => {
            if (
                filter.uuid === recordData.uuid &&
                filter.projections.find(proj => proj.projection === recordData.projectionType) &&
                filter.projections.find(proj => proj.projection === recordData.projectionType).primitive ===
                    PrimitiveTypes.Bool.primitive
            ) {
                result = true;
            }
        });
        return result;
    }

    public static isFloat(
        recordData: { uuid: string; projectionType: string },
        filterEntry: Array<RecordsInterface>
    ): boolean {
        let result = false;
        filterEntry.forEach(filter => {
            if (
                filter.uuid === recordData.uuid &&
                filter.projections.find(proj => proj.projection === recordData.projectionType) &&
                filter.projections.find(proj => proj.projection === recordData.projectionType).primitive ===
                    PrimitiveTypes.Float.primitive
            ) {
                result = true;
            }
        });
        return result;
    }
}
