import { CategoryPaymentDto } from './category-payment-dto';

export class BudgetDto {
    constructor(
        readonly perCategory: Array<{
            uuid: string;
            categoryPaymentDto: CategoryPaymentDto;
        }>,
        readonly total: CategoryPaymentDto
    ) {}
}
