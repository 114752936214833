import { Component, Inject, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { BaseRecordComponent } from '../../base-record.component';
import { UntypedFormControl, Validators } from '@angular/forms';

@Component({
    selector: 'float-record',
    templateUrl: 'float-record.component.html',
})
export class FloatRecordComponent extends BaseRecordComponent implements OnInit {
    @Input('uuid') public uuid: string;
    @Input('value') public value: object;
    @Input('isRequired') public isRequired: boolean;
    @Input('size') public size: number;
    @Input('title') title: string;
    @Input('onSearch') isOnSearch = false;
    @Input('configuration') configuration: object;
    @Input('readonly') public readonly = false;
    @Input() public useDelemeters = false;

    @Output('onChange') onChange = new EventEmitter<object>();

    private initValue: string;
    public text = new UntypedFormControl();

    public float1 = new UntypedFormControl();
    public float2 = new UntypedFormControl();
    public emitValue() {
        this.onChange.emit(this.getValue());
    }

    public ngOnInit() {
        if (this.isAnyLockingConfiguration()) {
            this.readonly = true;
            this.text.disable();
            this.float1.disable();
            this.float2.disable();
        }

        this.initValue = this.value['text'];
        if (this.isRequired) {
            this.text.setValidators(Validators.required);
        }

        if (!this.isOnSearch) {
            this.text.setValue(this.value['text']);
            if (this.readonly) {
                this.text.disable();
            }
            this.text.valueChanges.subscribe(() => {
                this.emitValue();
            });
        } else {
            this.float1.setValue(this.value['text'][0] ? this.value['text'][0] : '');
            this.float2.setValue(this.value['text'][1] ? this.value['text'][1] : '');

            this.float1.valueChanges.subscribe(() => {
                this.emitValue();
            });
            this.float2.valueChanges.subscribe(() => {
                this.emitValue();
            });
        }
        this.emitValue();
    }

    public isValid(): boolean {
        return !(this.isRequired && !this.value['text']);
    }

    public isChanged(): boolean {
        return this.initValue != this.text.value;
    }
    public isEmpty(): boolean {
        return !this.value;
    }

    public getValue(): object {
        this.value = {};
        let tmp;

        if (this.isOnSearch) {
            tmp = [parseFloat(Number(this.float1.value).toFixed(2)), parseFloat(Number(this.float2.value).toFixed(2))];
        } else {
            tmp = parseFloat(Number(this.text.value).toFixed(2));
        }
        this.value['text'] = tmp;
        return this.value;
    }

    private isAnyLockingConfiguration(): boolean {
        if (this.configuration) {
            if (
                this.configuration['flag'] &&
                (this.configuration['flag'] == 'balance' || this.configuration['flag'] == 'due_balance')
            ) {
                return true;
            }
        }
        return false;
    }
}
