import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'arrayToString',
})
export class ArrayToStringPipe implements PipeTransform {
    transform(word: string): string {
        if (word) {
            const mystring = word.replace(/,/g, ', ');
            return mystring;
        } else {
            return word;
        }
    }
}
