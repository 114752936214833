import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'ie-popup',
    templateUrl: './ie-popup.component.html',
    styleUrls: ['./ie-popup.component.scss'],
})
export class IePopupComponent implements OnInit {
    showPopup = false;

    ngOnInit() {
        this.showPopup = (<any>window).isIE && this.isLowIE();
    }

    private isLowIE(): boolean {
        return navigator.userAgent.toLowerCase().indexOf('msie') !== -1;
    }
}
