export class CorrespondenceTemplateManualDto {
    constructor(
        readonly uuid: string,
        readonly refUuid: string,
        readonly statusTransition: string,
        readonly parentUuid: string,
        readonly files: Array<{
            uuid: string;
            language: string;
        }>,
        readonly pdfGeneratorRecordUuid: string,
        readonly staticTokens?: Array<{
            key: string;
            value: string;
        }>,
        readonly isLogged?: boolean
    ) {}
}
