import { TextDto } from '../../../../api/dto/translation-dto/text-dto';
import { TranslationDto } from '../../../../api/dto/translation-dto/translation-dto';

export interface TranslationSettingData {
    id: string;
    divideKeys: Array<string>;
    orderByKeys: Array<string>;
    divideDropDown: boolean;
    rows: Array<string>;
}

export class TranslationsSettings {
    public static getTranslations(): Array<TranslationSettingData> {
        return [
            {
                id: TranslationTypes.Category_Type,
                divideKeys: [],
                orderByKeys: [],
                divideDropDown: false,
                rows: [TranslationKeys.Identifier],
            },
            {
                id: TranslationTypes.Status_Type,
                divideKeys: [],
                orderByKeys: [TranslationKeys.Identifier],
                divideDropDown: false,
                rows: [TranslationKeys.Identifier],
            },
            {
                id: TranslationTypes.Caseworker_Section_Type,
                divideKeys: [TranslationKeys.Identifier],
                orderByKeys: [TranslationKeys.SubIdentifier, TranslationKeys.SubSubIdentifier],
                divideDropDown: false,
                rows: [TranslationKeys.SubIdentifier, TranslationKeys.SubSubIdentifier],
            },
            {
                id: TranslationTypes.Applicant_Section_Type,
                divideKeys: [TranslationKeys.Identifier],
                orderByKeys: [TranslationKeys.SubIdentifier, TranslationKeys.SubSubIdentifier],
                divideDropDown: false,
                rows: [TranslationKeys.SubIdentifier, TranslationKeys.SubSubIdentifier],
            },
            {
                id: TranslationTypes.Post_Grant_Section_Type,
                divideKeys: [TranslationKeys.Identifier],
                orderByKeys: [TranslationKeys.SubIdentifier, TranslationKeys.SubSubIdentifier],
                divideDropDown: false,
                rows: [TranslationKeys.SubIdentifier, TranslationKeys.SubSubIdentifier],
            },
            {
                id: TranslationTypes.Application_Schema_Type,
                divideKeys: [],
                orderByKeys: [],
                divideDropDown: false,
                rows: [TranslationKeys.Identifier],
            },
            {
                id: TranslationTypes.Transition_Type,
                divideKeys: [],
                orderByKeys: [TranslationKeys.Identifier],
                divideDropDown: false,
                rows: [TranslationKeys.Identifier],
            },
            {
                id: TranslationTypes.Api_Response_Type,
                divideKeys: [TranslationKeys.Identifier],
                orderByKeys: [TranslationKeys.SubIdentifier],
                divideDropDown: false,
                rows: [TranslationKeys.SubIdentifier],
            },
            {
                id: TranslationTypes.Transition_Type,
                divideKeys: [],
                orderByKeys: [TranslationKeys.Identifier],
                divideDropDown: false,
                rows: [TranslationKeys.Identifier],
            },
            {
                id: TranslationTypes.Site_Text_Type,
                divideKeys: [TranslationKeys.Identifier],
                orderByKeys: [TranslationKeys.SubIdentifier],
                divideDropDown: false,
                rows: [TranslationKeys.SubIdentifier],
            },
            {
                id: TranslationTypes.Invalid_Form_Error_Code,
                divideKeys: [],
                orderByKeys: [TranslationKeys.Identifier],
                divideDropDown: false,
                rows: [TranslationKeys.Identifier],
            },
            {
                id: TranslationTypes.Email_Content,
                divideKeys: [TranslationKeys.Identifier],
                orderByKeys: [TranslationKeys.SubIdentifier],
                divideDropDown: false,
                rows: [TranslationKeys.SubIdentifier],
            },
            {
                id: TranslationTypes.Country_Type,
                divideKeys: [],
                orderByKeys: [TranslationKeys.Content],
                divideDropDown: false,
                rows: [TranslationKeys.Identifier],
            },
        ];
    }
}

export class TranslationTypes {
    static readonly Category_Type = 'category';
    static readonly Record_Type = 'record';
    static readonly Status_Type = 'status';
    static readonly Caseworker_Section_Type = 'caseworker_section';
    static readonly Post_Grant_Section_Type = 'post_grant_section';
    static readonly Application_Schema_Type = 'application_schema';
    static readonly Applicant_Section_Type = 'applicant_section';
    static readonly Transition_Type = 'transition';
    static readonly Api_Response_Type = 'api_response';
    static readonly Site_Text_Type = 'site_text';
    static readonly Invalid_Form_Error_Code = 'invalid_form_error_code';
    static readonly Email_Content = 'email_content';
    static readonly Country_Type = 'country';
}

export class TranslationKeys {
    static readonly Content = 'text';
    static readonly Identifier = 'identifier';
    static readonly SubIdentifier = 'subIdentifier';
    static readonly SubSubIdentifier = 'subSubIdentifier';
}

export class TranslationsSectionTypesRecords {
    static readonly Caseworker_Application = 'caseworker_application';
    static readonly Applicant_Application = 'applicant_application';
    static readonly Post_Grant_Application = 'post_grant_application';
}

export class TranslationHelper {
    public static ConvertDtoToData(translations: Array<TranslationDto>): Array<TranslationData> {
        const translationData: Array<TranslationData> = [];

        translations.forEach(translation => {
            const textsData: Array<TextData> = [];

            translation.texts.forEach(text => {
                const _textData = new TextData(
                    text.uuid,
                    text.type,
                    text.language,
                    text.identifier,
                    text.subIdentifier,
                    text.subSubIdentifier,
                    text.text,
                    text.referenceDetails
                );
                if (_textData.referenceDetails) {
                    _textData.sequence = _textData.referenceDetails.sequence;
                }
                textsData.push(_textData);
            });

            translationData.push(new TranslationData(textsData, translation.type));
        });

        return translationData;
    }
}

export class TextData extends TextDto {
    public title: string;
    public isOnEditMode: boolean;
    public draftText: string;
    public sequence: number;
}

export class TranslationData {
    referenceDetailOptions: Array<string> = [];

    constructor(public texts: Array<TextData>, public type) {}
}
