import { RecordAdapter } from '../search-page/record-adapter';
import { DefaultNonRecordFilters } from './search-service';
import { ApplicationTemplateDto } from '../../../api/dto/search-dto/application-template-dto';
import { RecordsInterface } from '../components/search-filters/records-interface';
import { SearchProjectionModel } from '../search-page/search-projection-model';
import { Injectable } from '@angular/core';
import { FondaApiService } from '../../../api/fonda-api.service';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '../../../shared/translation/translate.service';

export class FiltersOutput {
    constructor(
        readonly statuses: Array<string>,
        readonly postGrantStatuses: Array<string>,
        readonly possibleFilters: Array<RecordsInterface>
    ) {}
}

@Injectable({ providedIn: 'root' })
export class SearchFiltersService {
    constructor(private apiService: FondaApiService) {}

    public async getFilters(
        templateDto: ApplicationTemplateDto,
        translateService: TranslateService
    ): Promise<FiltersOutput> {
        let possibleFilters = [];
        templateDto.applicationRecord.forEach(record => {
            possibleFilters.push(new RecordAdapter(record, translateService));
        });

        possibleFilters = possibleFilters.concat(DefaultNonRecordFilters.GetArray(translateService)).sort();
        possibleFilters = possibleFilters.concat(await this.getNewFiltersToActions(translateService));

        return new FiltersOutput(
            await this.getStatuses(templateDto),
            await this.getPostGrantStatuses(templateDto),
            possibleFilters
        );
    }

    private async getNewFiltersToActions(translateService: TranslateService): Promise<Array<RecordsInterface>> {
        const _templates = await this.apiService.getApplicationTemplateCorrespondenceTemplate(
            environment.APPLICATION_TEMPLATE_UUID
        );
        const templates: Array<string> = _templates.filter(t => t.isLogged).map(t => t.uuid);
        return templates.map(templateUuid => ({
            uuid: 'started-thread-from-parent-' + templateUuid,
            type: 'action_performed:correspondence_thread_started',
            title: translateService.get([templateUuid, 'subject']) + ' - ' + translateService.get(['general', 'sent']),
            projections: [
                new SearchProjectionModel(
                    'BooleanDefaultFalseRecord',
                    ['equals'],
                    'bool',
                    'main',
                    translateService.get([templateUuid, 'subject']) + ' - ' + translateService.get(['general', 'sent'])
                ),
            ],
        }));
    }

    private async getStatuses(templateDto: ApplicationTemplateDto): Promise<Array<string>> {
        let statuses: Array<string> = [];

        for (const schemaUuid of templateDto.schemas) {
            if (!this.apiService._getApplicationSchema[schemaUuid]) {
                this.apiService._getApplicationSchema[schemaUuid] = this.apiService.getApplicationSchema(schemaUuid);
            }
            const schema = await this.apiService._getApplicationSchema[schemaUuid];
            statuses = statuses.concat(schema.statusSettings.map(s => s.name));
        }

        return Array.from(new Set(statuses));
    }

    private async getPostGrantStatuses(templateDto: ApplicationTemplateDto): Promise<Array<string>> {
        let postGrantStatuses: Array<string> = [];

        for (const schemaUuid of templateDto.schemas) {
            if (!this.apiService._getApplicationSchema[schemaUuid]) {
                this.apiService._getApplicationSchema[schemaUuid] = this.apiService.getApplicationSchema(schemaUuid);
            }
            const schema = await this.apiService._getApplicationSchema[schemaUuid];
            postGrantStatuses = postGrantStatuses.concat(schema.postGrantStatusSettings.map(s => s.name));
        }

        return Array.from(new Set(postGrantStatuses));
    }
}
