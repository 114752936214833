import { Directive, HostBinding, Input } from '@angular/core';

let randomId = 0;

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'input[fondaCheckbox]',
})
export class FondaCheckboxDirective {
    @HostBinding() type = 'checkbox';
    @Input() @HostBinding() id = 'fonda-checkbox-' + (++randomId).toString();
}
