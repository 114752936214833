<div class="form-item" [ngClass]="{ half: size === 1 }" *ngIf="!readonly">
    <basic-select
        [choosenInput]="selectedSubCategory && selectedSubCategory.title"
        [placeholder]="uuid | translate: 'title'"
        [values]="subTitles"
        (getChoosen)="chooseSubCategory($event)"
        [label]="uuid | translate: 'title'"
    ></basic-select>
    <!--<div class="select-box-label red" *ngIf="(selectedSubCategory)" style="position: absolute; right: 0px; z-index: 1" (click)="reset()">Fjern underkategori</div>-->
    <div class="form-focus"></div>
    <div class="form-error"></div>
</div>
<a class="form-item" *ngIf="readonly" [ngClass]="{ full: size === 2 }">
    <p>{{ uuid | translate: 'title' }}</p>
    <span>{{ selectedSubCategory?.title }}</span>
    <div class="form-focus"></div>
    <div class="form-error"></div>
</a>
