import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BasicFormControlsModule } from '../basic-form-controls/basic-form-controls.module';
import { BasicSearchPopupComponent } from './basic-search-popup/basic-search-popup.component';
import { SearchPaginationComponent } from './search-pagination/search-pagination.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { UtilsModule } from '../utils/utils.module';
import { TranslationModule } from '../translation/translation.module';

@NgModule({
    imports: [CommonModule, FormsModule, UtilsModule, BasicFormControlsModule, TranslationModule],
    declarations: [BasicSearchPopupComponent, SearchPaginationComponent, SearchResultsComponent],
    exports: [BasicSearchPopupComponent, SearchPaginationComponent, SearchResultsComponent],
})
export class SearchUtilsModule {}
