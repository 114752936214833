import { BackendUserData } from '../backend-user-data';

export class TranslationLogDto {
    constructor(
        readonly changerProfile: BackendUserData,
        readonly entityUuid: string,
        readonly occuredDate: string,
        readonly type: string,
        readonly data: any
    ) {}
}
